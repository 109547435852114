import React, {useEffect, useState } from "react";
import { Button, Dropdown, Flex, InputNumber, Tooltip} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { entityUpdate, getListAndPartialReadEntities} from "../../../../entity/actions/entityActions"
import { capitalize, get } from "lodash";
import EntityParamTag from "../../../../components/entityParamTag/EntityParamTag";
import { useTranslation } from "react-i18next";
import AntDesignSearchBox54origins from "../../../../54origins/components/AntDesignSearchBox54origins";
import { issuesStatusesTags } from "../../../../constants/issuesConstants";
import { ProjectTypeCommonConstants } from "../../../constants/Constants";
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import { array, bool, func, object, string } from "prop-types";
import BaseTableWithPagination from "../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination";
import PriorityPicker from "./PriorityPicker";
import { changeSortIssues, numberSorter } from "./utils";
import BaseCard from "../../../../components/_ui/BaseCard/BaseCard";
import AssigningUsersRoot from "../../../../assignUsersModal/AssigningUsersRoot";
import { getUuid } from "../../../../assignUsersModal/utils";
import WrapperAddIssue from "../../issues/newIssue/WrapperAddIssue";
import useTableFilter from "../../../../components/_ui/hooks/useTableFilter";
import { columnsFilterConfig, defaultIssueFilter } from "../../issuesView/constants/issueFilterOptions";
import Icon from '@mdi/react';
import WrapperAvatarsGroupForProjectWithHook from "../actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook";
import { getProjectParams } from "../../../selectors/selectors";
import { mdiAccountMultipleOutline, mdiChevronDown } from "@mdi/js";
import AttachIssueModal from "./AttachIssueModal";
import CompletedIssueModal from "./CompletedIssueModal";
import { defaultOrderRules } from "../../issuesView/utils";

import './LaunchPad.scss';


const IssuesTableForVectorsView = (
  {defaultPartition, 
   projectUUID, 
   allActors,
   issuesType,
   changeIssue,
   setChangeIssue,
   activeVector}) => {

let timerSearch
const {t} = useTranslation()
const dispatch = useDispatch()
const projectParams = useSelector(getProjectParams);

const {
  id: vectorID, 
  usersSearch: vectorUsers, 
  uuid: vectorUUID, 
  status: vectorStatus} = activeVector;

const issuesStatusItems = issuesStatusesTags.filter(status => status.value !== 'all')

 const defaultIssuesPagination = {
  currentPage: 1,
  limit: 5,
  offset: 0,
};

const {
  filterConfig: issueFilterConfig,
  changeFilterConfig: changeIssueFilterConfig,
} = useTableFilter('issue', {...defaultIssueFilter, status: undefined});


const [openUsersFilter, setOpenUsersFilter] = useState(false)
const [usersFilter, setUsersFilter] = useState([])

const [paginationOptions, changePaginationOptions] = useState(defaultIssuesPagination);
const [orderRules, changeOrderRules] = useState(defaultOrderRules);
const { currentPage: newCurrentPage} = paginationOptions;

const [issuesData, setIssuesData] = useState(null)
const [loading, setLoading] = useState(false)
const [selectedRow, setSelectedRow] = useState(null)
const [valueSearch, setValueSearch] = useState('')
const [totalCount, setTotalCount] = useState(0)
const [limit, setLimit] = useState(5);


const [usersModalOpen, setUsersModalOpen] = useState(false) 
const editUsers = (actors) => {
  const newUsersSearch = actors?.users.map(el => el.uuid)
  updateIssue(selectedRow.uuid, {users: actors?.users, usersSearch: newUsersSearch})
}

const changeLimit = (size) => {
  if (size !== limit) {
    setLimit(size);
  }
};

const currentSortCallback = (dataSort) => {
  if (!get(dataSort, 'order')) {
    changeOrderRules({
      orderBy: 'created',
      order: 'desc',
    });
  } else {
    changeOrderRules(dataSort);
  }
};

const getIssues = async (additionalParams) => {
    setLoading(true)
    const data = {
        entity_type: "issue",
        parent: projectUUID,
        depth: 0,
        ...paginationOptions,
        ...orderRules,
        params: {
            vector: vectorUUID,
          ...(issuesType === "mandatory" ? {mandatoryIssue: true} : []),
          ...issueFilterConfig,
          ...additionalParams
        },
        params_fields: {
          id: "id",
          title: 'title',
          status: "status",
          users: "users",
          usersSearch: "usersSearch",
          completedIssue: 'completedIssue',
          spentTime: "spentTime",
          plannedTime: "plannedTime",
          launchPriority: 'launchPriority',
          mandatoryIssue: 'mandatoryIssue',
        },
        search_data: {
            value: valueSearch,
            ignore_case: true,
            fields: {
              params: ['title'],
            },
          }
    };
    const constants = [
      "GET_ENTITY_REQUEST",
      "GET_ENTITY_SUCCESS",
      "GET_ENTITY_FAILURE"
    ]

      const issues = await dispatch(getListAndPartialReadEntities({
        data,
        constants,
        partition: defaultPartition
      }))
      setIssuesData(issues.data)
      setTotalCount(issues.total)
      setLoading(false)
}


const updateIssue = async (uuid, params) => {
  const data = {
    entity_type: 'issue',
    entity_uuid: uuid,
    params: params
  }
  const constants = [
    ProjectTypeCommonConstants.EDIT_ISSUE_REQUEST,
    ProjectTypeCommonConstants.EDIT_ISSUE_SUCCESS,
    ProjectTypeCommonConstants.EDIT_ISSUE_FAILURE
  ]

const options = {
  partition: defaultPartition
}

await dispatch(entityUpdate({
    data,
    constants,
    options,
  })) 
  setChangeIssue(true)
}

const getBackgroundColor = (status, completedIssue) => {
    switch(status) {
        case "test":
        case "review":
        case "ready for deploy":
        return "#C6EBCF";
        case 'backlog':
        case 'abandoned':
        return "#F8D7DA"
    }
    if(completedIssue) {
      return "#C6EBCF";
    }
}

const onSearchData = (value) => {
    changePaginationOptions(defaultIssuesPagination)
    setValueSearch(value);
  };

const filterByUsers = (usersData) => {
  const newUsers = usersData?.users?.map((el) => getUuid(el));
  if(newUsers.length > 0) {
    getIssues({ usersSearch: newUsers })
  } else {
    getIssues()
  }
  setUsersFilter(newUsers);
}  

useEffect(() => {
    if(vectorUUID && changeIssue) {
       getIssues()
       setChangeIssue(false)
    }
}, [changeIssue])

useEffect(() => {
  if(vectorUUID) {
     getIssues()
  }
}, [paginationOptions, valueSearch, issueFilterConfig, orderRules])


const changeStatusCallback = (status) => {
  if(selectedRow && selectedRow.status !== status) {
    updateIssue(selectedRow.uuid, {status: status})
  } 
}

const items = issuesStatusItems
  .filter(({ value }) => value)
  .map(({ value }) => ({
    key: value,
    label: (
      <div   
        onClick={() => changeStatusCallback(value)}>
        <EntityParamTag
        value={value}
        param="status"
        type="iconWithStatus"
      />
    </div>
    ),
  }));
    
const issuesColumns = [
      ...(issuesType === 'connected' ? [{
        dataIndex: 'id',
        title: 'ID',
        key: 'id',
        width: "5%",
        render: (cell, row) => {
          return ( get(row, ['params', 'id']) || cell);
        },
      }] : []),
      ...(issuesType === 'mandatory' ? [{
        dataIndex: 'launchPriority',
        title: "Priority",
        key: 'launchPriority',
        width: '5%',
        sorter: (row1, row2) => numberSorter(row1, row2, 'launchPriority'),
        render: (cell, row) => 
        <PriorityPicker 
        changelaunchPriority={updateIssue} 
        launchPriority={row.launchPriority}
        rowUUID={row.uuid}
        />
      }] : []),
        {
            dataIndex: 'title',
            width: "40%",
            key: 'title',
            title: (
              <div className="minWidth130">
                <AntDesignSearchBox54origins onSearch={onSearchData} />
              </div>
            ),
            render: (cell, row) => (cell ? (
              <p>{row.title}</p>
            ) : (
              <span>{'<Empty title>'}</span>
            )),
          },
        {
            dataIndex: 'usersSearch',
            key: "usersSearch",
            title: (
              <AssigningUsersRoot
                header="User filter"
                isOpen={openUsersFilter}
                setIsOpen={setOpenUsersFilter}
                isCheckWindowWidthMode={false}
                isClosePopoverOnLeaveMode
                isOneColumnMode={true}
                size={'extra small'}
                data={{
                  uuids: projectParams?.usersSearch,
                }}
                isPopover
                onUpdateUsers={(usersData) => filterByUsers(usersData)}
                globalOptions={{
                  uuids: projectParams?.usersSearch,
                  isOneColumnMode: true,
                  size: 'extra small',
                }}
                usersConfig={{
                  label: 'Users',
                  showSections: ['string-role'],
                  textSelectButton: 'Select',
                  textDeleteButton: 'Deselect',
                  defaultItems: usersFilter,
                }}
                popoverChild={(
                  <Flex className="ml-1 min-width-60px" align="center">
                  <span className="mr-1">{capitalize(t('Users'))}</span>
                    <Tooltip
                      placement="top"
                      onClick={() => setOpenUsersFilter(true)}
                      style={{
                        cursor: 'pointer',
                      }}
                      title={capitalize(t('wms.table.headers.assigned', 'Assigned'))}
                    >
                      <Icon
                        path={mdiAccountMultipleOutline}
                        role="button"
                        size={1}
                        className="text-secondary"
                      />
                      <Icon
                        path={mdiChevronDown}
                        role="button"
                        className="text-secondary ml-1"
                        size={1}
                      />
                    </Tooltip>
                    <WrapperAvatarsGroupForProjectWithHook
                      actors={usersFilter || []}
                      maxCount={3}
                      avatarSize={22}
                      popoverTrigger="click"
                      popoverPlacement="right"
                      checkProjectRole
                    />
                  </Flex>
                )}
              />
            ),
            width: "15%",
            render: (cell) => {
              const users = allActors.filter(user => cell.includes(user.uuid));
              const usernames = users.map(user => `${user.uinfo.first_name} ${user.uinfo.last_name}`);
              return (usernames.length > 0 ?  
              <span className="issue-users" onClick={() => setUsersModalOpen(true)}>{usernames.join(', ')}</span> 
              :
             <Button size="small" onClick={() => setUsersModalOpen(true)}>Edit users</Button>);
            }
          },
          {
            dataIndex: 'spentTime',
            key: "spentTime",
            title: "Spent time (hours)",
            width: "10%",
            sorter: (row1, row2) => numberSorter(row1, row2, "spentTime"),
            render: (cell, row) => (
              <InputNumber
              prefix={<ClockCircleOutlined />}
              controls={false}
              size="small"
              min={0}
              max={1000}
              onChange={(timeString) => {
                clearTimeout(timerSearch)
                timerSearch = setTimeout(() => {
                  updateIssue(row.uuid, {spentTime: timeString})
                }, 700)
              }} 
              value={get(row, ['params', 'spentTime']) || cell}
              onKeyPress={(e) => {
                if (!/[0-9.-]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              />
            )
          },
          {
            dataIndex: 'plannedTime',
            key: "plannedTime",
            title: "Planned time (hours)",
            width: "12%",
            sorter: (row1, row2) => numberSorter(row1, row2, "plannedTime"),
            render: (cell, row) => (
              <InputNumber
              prefix={<ClockCircleOutlined />}
              controls={false}
              size="small"
              min={0}
              max={1000}
              onChange={(timeString) => {
                clearTimeout(timerSearch)
                timerSearch = setTimeout(() => {
                  updateIssue(row.uuid, {plannedTime: timeString})
                }, 700)
              }} 
              value={get(row, ['params', 'plannedTime']) || cell}
              onKeyPress={(e) => {
                if (!/[0-9.-]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              />
            )
          },
        {
          dataIndex: 'status',
          key: 'status',
          width: "10%",
          sorter: (row1, row2) => (changeSortIssues(row1, row2, 'status')),
          ...(columnsFilterConfig && { ...columnsFilterConfig?.status }),
          ...(issueFilterConfig?.status && { defaultFilteredValue: issueFilterConfig?.status }),
          title: capitalize(t('wms.table.headers.status', 'status')),
          render: (cell, row) => (
              <Dropdown 
              menu={{items}}
              placement="left"
              trigger={['click']}
              overlayStyle={{
                 boxShadow: "0px 0px 11px -2px rgba(34, 60, 80, 0.2)",
                }}
              arrow
              >
              <div>
                <EntityParamTag
                value={get(row, ['params', 'status']) || cell}
                param="status"
                type={'valueAndIconWithArrow'}
                isOpen
              />
              </div>
            </Dropdown>
          ),
        },
        {
            dataIndex: 'Completed',
            key: "completedIssue",
            title: "Completed",
            width: "5%",
            render: (cell, row) => (
              <CompletedIssueModal
              selectedIssue={row}
              updateIssue={updateIssue}
              />
            ),
          },
          ...(issuesType === 'connected' ? [
            {key: "action",
              render: (cell, row) => <Button
               onClick={() => updateIssue(row.uuid, {mandatoryIssue: true})}
               disabled={row.mandatoryIssue}
               size="small"
               type="primary"
               >{row.mandatoryIssue ? "in mandatory" : "add to mandatory"}</Button>
              
            }
          ] : []),
          ...(issuesType === "mandatory" ? [
            {
              key: 'action',
              render: (cell, row) => <Button
              onClick={() => updateIssue(row.uuid, {mandatoryIssue: false})}
              size="small"
              color="danger"
               variant="solid"
              >remove from mandatory</Button>
             
            },
          ] : [])
       ]

return (
  <BaseCard className="w-100 mt-2 padding-16 issue-card-issues-view">
  <div className="flex">
    <h6 className="mt-1 mr-2">
      {capitalize(t(`${issuesType} Issues`))}
    </h6>
    <WrapperAddIssue
    partitionType={defaultPartition}
    createIssueCallback={() => setChangeIssue(true)}
    mandatoryIssue={issuesType === "mandatory"}
    activeVectorFromProps={activeVector}
    disableBtn={vectorStatus === "protected"}
    // hideView={hideViewCreateIssueModal}
    // disableBtn={isGlobalDisabled}
    defaultIssueState={{
      partition: defaultPartition
    }}
  />
  {issuesType === 'connected' &&
    <AttachIssueModal 
    defaultPartition={defaultPartition}
    projectUUID={projectUUID}
    vectorIssues={issuesData}
    updateIssue={updateIssue}
    vectorUUID={vectorUUID}
    vectorID={vectorID}
    vectorStatus={vectorStatus}
    />
  }
</div>

  <BaseTableWithPagination
      pageLimit={limit}
      loading={loading}
      newCurrentPage={newCurrentPage}
      useCustomPagination
      columns={issuesColumns}
      data={issuesData}
      total={totalCount}
      rowHoverable={false}
      rowKey="uuid"
      size="small"
      headerRowClassName="universal_header_table_row color-gray"
      pageSizeOptions={['5', '10', "25"]}
      changePageLimit={changeLimit}
      getPaginationOptions={changePaginationOptions}
      currentSortCallback={currentSortCallback}
      onRow={(record) => ({
        onClick: () => {
            setSelectedRow(record)
        },
        style: {
        cursor: 'pointeer', 
        backgroundColor: getBackgroundColor(record.status, record.completedIssue)
        },
      })}
      filterCallback={changeIssueFilterConfig}
/>
      <AssigningUsersRoot
          header="Assigning users"
          isOpen={usersModalOpen}
          setIsOpen={setUsersModalOpen}
          onUpdateUsers={editUsers}
          projectUuid={projectUUID}
          partition={defaultPartition}
          needUpdateProjectUsers
          isModal
          globalOptions={{
            uuids: allActors?.map((el) => getUuid(el)),
            roles: allActors,
            defaultColumn: 'project',
          }}
          tabs={{
            projectConfig: {
              label: 'Project',
              value: 'project',
              textSelectButton: 'Add to issue',
              defaultItems: allActors ?? [],
              showSections: ['string-role', 'button-add-user'],
            },
            vectorConfig: {
              label: 'Vector',
              value: 'vector',
              textSelectButton: 'Add to issue',
              defaultItems: vectorUsers ?? [],
              showSections: ['string-role', 'button-add-user'],
            },
          }}
          usersConfig={{
            label: 'Issue users',
            showSections: ['string-role', 'button-remove-user'],
            textDeleteButton: 'Remove users',
            defaultItems: selectedRow?.usersSearch ?? [],
          }}
        />
</BaseCard>
  )
}

IssuesTableForVectorsView.propTypes = {
    defaultPartition: string,
    allActors: array,
    projectUUID: string,
    issuesType: string,
    changeIssue: bool,
    setChangeIssue: func,
    activeVector: object
}

export default IssuesTableForVectorsView;

