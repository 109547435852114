import React from 'react';
import { bool, func, element } from 'prop-types';

import { useTranslation } from 'react-i18next';

import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

import './CardSidePanel.scss';

function CardSidePanel({
  isVisible,
  hideCloseButton = false,
  closeSidePanel,
  children,
}) {
  const { t } = useTranslation();

  return (
    <div className={`card-side-panel ${isVisible ? '--show' : ''}`}>
      {!hideCloseButton && (
        <BaseButton
          className="btnDanger ant-btn-flex close-button"
          tabIndex="-1"
          onClick={closeSidePanel}
        >
          <Icon path={mdiClose} size={0.8} />
        </BaseButton>
      )}
      {children}
    </div>
  );
}

export default CardSidePanel;

CardSidePanel.propTypes = {
  isVisible: bool,
  hideCloseButton: bool,
  closeSidePanel: func,
  children: element,
};
