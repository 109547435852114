import { useDispatch, useSelector } from "react-redux"
import { getPartitionPublicUUID } from "../../../../config/selectors/selectors"
import { entityUpdate, getListAndReadEntities, getOrCreateSingle } from "../../../../entity/actions/entityActions"
import { partitionNamesConfig } from "../../../../api/appConfig"
import { useEffect, useState } from "react"
import { addDataToIssues, getDayParams, getUserIssuesUUIDS, updateTodayDataParam, updateTodayParam } from "./utils"
import { updateUserBoard } from "../../../../myBoard/actions/userBoardActions"
import { antNotification } from "../../../../MainUtils"
import { get } from "lodash"



const useGeneralPlanboard = ({customActor, partitionPM, getFetchBoards, today}) => {
    const dispatch = useDispatch()
    const partitionPUBLIC = useSelector(getPartitionPublicUUID)

    const [publicUserUUID, setPublicUserUUID] = useState(null)
    const [generalPlanboardStorage, setGeneralPlanboardStorage] = useState(null)

    const [todayIssues, setTodayIssues] = useState(null)
    const [dayParams, setDayParams] = useState({})
    const [loading, setloading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)

    const getPublicUser = async () => {
        const data = {
          entity_type: "user",
          parent: partitionPUBLIC,
          depth: 0,
          actor: customActor
        }
        const constants = [
          "GET_ENTITY_REQUEST",
          "GET_ENTITY_SUCCESS",
          "GET_ENTITY_FAILURE"
        ]
        const publicUser = await dispatch(getListAndReadEntities({
          data,
          constants,
         partition: partitionNamesConfig.partition3,  
        }))
        setPublicUserUUID(publicUser.data[0].uuid)
      }

      const getOrCreateGeneralPlanboardStorage = async () => {

      const generalPlanboardStorage = await dispatch(getOrCreateSingle({
          data: {
              entity_type: 'generalBoardStorage',
              parent: publicUserUUID,
              params: {
                todayIssues: [],
                workTime: 8,
                date: today
              }
          },
          partition: partitionNamesConfig.partition3,
          constants: [
            "GET_OR_CREATE_GENERAL_BOARD_STORAGE_REQUEST",
            "GET_OR_CREATE_GENERAL_BOARD_STORAGE_SUCCESS",
            "GET_OR_CREATE_GENERAL_BOARD_STORAGE_FAILURE",
          ],
      }))
        setGeneralPlanboardStorage(generalPlanboardStorage)
        setTodayIssues(generalPlanboardStorage.params.todayIssues)
        console.log("todayIssues", todayIssues);
        console.log("GENERALPLANBOARD", generalPlanboardStorage);
        
        
    } 

    useEffect(() => {
        if(partitionPUBLIC && customActor) {
          setloading(true)
            getPublicUser()
        }
      }, [customActor])

    useEffect(() => {
        if(publicUserUUID) {
            getOrCreateGeneralPlanboardStorage()
            setloading(false)
        }
    }, [publicUserUUID])

    useEffect(() => {
      setDayParams({hours: generalPlanboardStorage?.params?.workTime, totalDayHours: getDayParams(todayIssues)})
      console.log("dayParams", dayParams);
      
    }, [todayIssues, generalPlanboardStorage])

    const updateGeneralPlanboardStorage = async (newParams = {}) => {
      setUpdateLoading(true)
        const data = {
            entity_uuid: generalPlanboardStorage.uuid,
            entity_type: "generalPlanboardStorage",
            params: {
                ...newParams
            },
            parent: publicUserUUID 
        }

        const options = {
            partition: partitionNamesConfig.partition3
        }

       await dispatch(entityUpdate({
            data,
            options,
            constants: [
                "UPDATE_GENERAL_BOARD_REQUEST",
                "UPDATE_GENERAL_BOARD_SUCCESS",
                "UPDATE_GENERAL_BOARD_FAILURE",
            ],
        }))
        getOrCreateGeneralPlanboardStorage()
        setUpdateLoading(false)
    }

    const getUserIssues = async (userIssuesUUIDS) => {
        const data = {
            entity_type: "issue",
            depth: 0,
            parent: partitionPM,
            entities: userIssuesUUIDS,
        };
        const constants = [
          "GET_ISSUES_REQUEST",
          "GET_ISSUES_SUCCESS",
          "GET_ISSUES_FAILURE"
        ]
    
        const options = {
          partition: "PM",
            
        }
        const userIssues = await dispatch(getListAndReadEntities({
          data,
          constants,
          options,
        }))
      
          return userIssues.data
    }

    const updateGeneralPlanboardStorageTodayIssues = async () => {
      setUpdateLoading(true)
        const fetchedBoards = await getFetchBoards()
        const userIssuesUUIDS = getUserIssuesUUIDS(fetchedBoards, today)
        const userIssues =  await getUserIssues(userIssuesUUIDS)

          if (userIssues && fetchedBoards && fetchedBoards.length > 0) {
              setTodayIssues(addDataToIssues(userIssues, fetchedBoards, today))
              updateGeneralPlanboardStorage({todayIssues: addDataToIssues(userIssues, fetchedBoards, today), workTime: 8})
          }
          setUpdateLoading(false)
    }
    
    // add update all boards func
    const saveIssue = async (issue, projectsSet) => {
      console.log("issue", issue);
      const issueUUID = get(issue, 'uuid')
      const issueChunk = get(issue, 'data.chunk')

      const newBoardData = {key: null, uuid: issueUUID, chunk: issueChunk}
      const projectUUID = get(issue, 'params.project');
      // console.log("newBoardData", newBoardData);
    
      const data = {
        entity_type: "userBoard",
        parent: projectUUID,
        depth: 0,
        actor: customActor,
        owner: customActor,
        project: projectUUID,
      }

      const constants = [
        "GET_ENTITY_REQUEST",
        "GET_ENTITY_SUCCESS",
        "GET_ENTITY_FAILURE"
      ]

      const options = {
        partition: "PM"
      }

      const boardData = await dispatch(getListAndReadEntities({data, constants, options}))
  
      const board = get(boardData, 'data[0]');
      const boardUUID = get(board, 'uuid')
      const newToday = updateTodayParam(get(board, `params[${today}]`), issueUUID, projectUUID, projectsSet);
      const newTodayData = updateTodayDataParam(get(board, `params[${today}__data]`), newBoardData, projectUUID, projectsSet)
          projectsSet.add(issue.params.project);
          console.log("PROJECTS", projectsSet);
          console.log("NEWTODAY", newToday);
          console.log("NEWTODAYDATA", newTodayData);
          
       await dispatch(updateUserBoard({
            entity_uuid: boardUUID,
            entity_type: 'userBoard',
            params: {
              [`${today}`]: newToday,
              [`${today}__data`]: newTodayData
            }
          }))
    }

    const saveIssuesInAllBoards = async () => {
      setSaveLoading(true);
        try {
          const projectsSet = new Set();
          for (const issue of todayIssues) {
            await saveIssue(issue, projectsSet);
          }
          console.log('projectSet',projectsSet);
          
          antNotification('success', 'Success');
        } catch (e) {
          console.log(e);
          antNotification('error', 'Error');
        }
      setSaveLoading(false);
    }

    return {generalPlanboardStorage,
            todayIssues,
            dayParams,
            updateGeneralPlanboardStorage, 
            updateGeneralPlanboardStorageTodayIssues, 
            saveIssuesInAllBoards,
            updateLoading,
            saveLoading,
            loading
          };
}

export default useGeneralPlanboard;

