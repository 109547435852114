import React from 'react';
import { mdiContentDuplicate, mdiArrowURightTopBold } from '@mdi/js';
import Icon from '@mdi/react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import BtnModal from '../../BtnModalComponent';

function ReRunTestCycleOrCase({
  title,
  titleTooltip,
  children,
  btnComponent = '',
  isArchive,
  btnIconStyle,
  disabled,
}) {
  return (
    <BtnModal
      className="flex justify-end "
      title={title}
      width="50%"
      blockPropagation
      zIndex={3}
      btnComponent={
          btnComponent || (
          <BaseButton
            className={!disabled && 'customAntOutGrinBtn'}
            size="small"
            disabled={disabled}
            style={btnIconStyle}
          >
            <Tooltip title={titleTooltip}>
              <Icon
                path={isArchive ? mdiArrowURightTopBold : mdiContentDuplicate}
                size={0.7}
                className="cursor-pointer"
              />
            </Tooltip>
          </BaseButton>
          )
        }
    >
      {children}
    </BtnModal>
  );
}
ReRunTestCycleOrCase.propTypes = {
  title: PropTypes.string,
  titleTooltip: PropTypes.string,
  children: PropTypes.element,
  btnComponent: PropTypes.element,
  isArchive: PropTypes.bool,
  btnIconStyle: PropTypes.any,
};
export default ReRunTestCycleOrCase;
