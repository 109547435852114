import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  head, isNil, propOr,
} from 'ramda';

import { get, isEmpty, omit } from 'lodash';
import ListOfAllTestCases from './ListOfAllTestCases';

import { antNotification, formatDateTimeForWms } from '../../../../MainUtils';
import {
  getTestCycleUUID,
  getProjectUUID,
  getMe,
} from '../../../selectors/selectors';
import { BugTrackerContext, ProjectMainLayerContext } from '../../../context/ProjectFlowListOfContexts';
import {
  createTestCaseForCycle, createTestCaseForSuite, getTestCycleFunc, updateTestCycle,
} from '../../../actions/QaActions';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { getListAndPartialReadEntities, incrementAndGetCount } from '../../../../entity/actions/entityActions';
import moment from 'moment';

function ImportCaseComponent({
  typeOfParent,
  importTestCaseCallback,
  parentData,
  toggleModal,
  cycleState,
  setCycleState
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getTestSuitePromise,
    getListOfTestCasesInCycle,
    getListOfTestCycles,
    getListOfTestSuites,
    getListOfTestCasesInSuite,
    partitionType,
    updateTestSuitePromise,
    qaEntityUUID,
  } = useContext(BugTrackerContext);

  const {
    sendAssignedNotifications,
  } = useContext(ProjectMainLayerContext);

  const testCycleUUID = useSelector(getTestCycleUUID);
  const projectUUID = useSelector(getProjectUUID);
  const myUserUuid = useSelector(getMe);


  const defaultConfig = {
    entity_type: 'testCase',
    entities: cycleState ? cycleState['existing cycle form']?.selectedSuites : [],
    depth: 0,
    params: {},
    parent: qaEntityUUID,
  };

  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState(defaultConfig);
  const [listAllTestCases, setListAllTestCases] = useState([]);
  const [checkedCheckbox, changeCheckedCheckbox] = useState(false);
  const [selectedUUID, changeSelectedUUID] = useState([]);
  const [assignUsers, changeAssignToUsersOption] = useState([]);

  function getListOfTestCasesFunc(customConfig) {
    const configData = customConfig || config;
    return dispatch(getListAndPartialReadEntities({
      data: {
        ...configData,
        params_fields: {
          id: 'id',
          title: 'title',
          description: 'description',
          testCaseUuidFromSuite: 'testCaseUuidFromSuite',
          steps: 'steps',
          comment: 'comment',
          suite: 'suite',
          typeOfTesting: 'typeOfTesting',
          casePriority: 'casePriority',
          status: 'status',
          tags: 'tags',
        },
        fields: {
          'actor.created': 'created',
          'actor.uinfo': 'uinfo',
        },
      },
      partition: partitionType,
      constants: ['REQUEST', 'SUCCESS', 'FAILURE'],
    }));
  }

  const getSelectedCases = () => listAllTestCases
    .filter((i) => selectedUUID.some((p) => p === i?.uuid))
    .map((item) => ({
      title: propOr('', 'title', item),
      comment: propOr('', 'comment', item),
      suite: propOr('', 'suite', item),
      description: propOr('', 'description', item),
      typeOfTesting: propOr([], 'typeOfTesting', item),
      casePriority: propOr('', 'casePriority', item),
      template: true,
      tags: propOr([], 'tags', item),
      status: propOr('unexecuted', 'status', item),
      cycle: testCycleUUID,
      executed_by: null,
      executed_on: null,
      steps: get(item, 'steps', []).map((i) => ({
        ...i,
        action: i.action = '',
        status: 'unexecuted',
      })),
      testCaseUuidFromSuite: [item.uuid],
      uuidFromSuite: item.parent,
      assignToUser: assignUsers,
    }));

  const attachTestCasesToSuite = async (selectedTestCases) => {
    const list = await Promise.all(selectedTestCases.map(async (i) => {
      const { increment } = await dispatch(incrementAndGetCount({
        entity_uuid: get(parentData, 'uuid'),
        field_name: 'case',
      }, partitionType));

      return ({
        ...i,
        id: `${get(parentData, 'params.id', 0)}C${increment}`,
        order: increment,
      });
    }));

    const newConfig = {
      parent: get(parentData, 'uuid'),
      params: list,
      partition: partitionType,
    };
    return dispatch(createTestCaseForSuite(newConfig));
  };

  const attachTestCasesToCycle = async (selectedTestCases) => {
    const list = await Promise.all(selectedTestCases.map(async (i) => {
      const { increment } = await dispatch(incrementAndGetCount({
        entity_uuid: get(parentData, 'uuid'),
        field_name: 'case',
      }, partitionType));

      return ({
        ...i,
        id: `${get(parentData, 'params.id', 0)}C${increment}`,
        closedCase: false,
        cycle: testCycleUUID,
        template: false,
        order: increment,
      });
    }));

    const newConfig = {
      parent: get(parentData, 'uuid'),
      partition: partitionType,
      params: list,
    };

    return dispatch(createTestCaseForCycle(newConfig));
  };

  const saveDataCallback = async () => {
    const selectedTestCases = getSelectedCases();

    let response = [];

    if (typeOfParent === 'suite') {
      response = await attachTestCasesToSuite(selectedTestCases);
    } else {
      response = await attachTestCasesToCycle(selectedTestCases);
    }

    antNotification('success', t('wms.noun.success', 'Success'));

    for (let i = 0; i < response.length; i++) {
      sendAssignedNotifications({
        entity_type: 'testCase',
        eventName: 'assigned_test_case',
        assignedUsers: assignUsers,
        entityUUID: get(response, `[${i}].uuid`),
        message: get(response, `[${i}].params.title`),
        entityUUIDsFields: {
          [typeOfParent === 'cycle' ? 'testCycleUUID' : 'testSuiteUUID']: get(response, '[0].parent'),
          testCaseUUID: get(response, `[${i}].uuid`),
          projectUUID,
        },
      });
    }

    if (typeOfParent === 'suite') {
      const currentSuite = await getTestSuitePromise(get(parentData, 'uuid'));
      let listOfTestingTypes = new Set();
      
      currentSuite?.params?.typeOfTesting?.map(type => {
        listOfTestingTypes.add(type);
      });

      selectedTestCases?.map((testCase) => {
        testCase?.typeOfTesting?.map(item => {
          listOfTestingTypes.add(item)
        })
      })

      const editData = {
        uuid: get(parentData, 'uuid'),
        partition: partitionType,
        params: {
          cases_count: get(currentSuite, '[0].params.cases_count', 0) + selectedUUID.length,
          typeOfTesting: Array.from(listOfTestingTypes),
        },
      };

      updateTestSuitePromise(editData).then(() => {
        getListOfTestCasesInSuite(get(parentData, 'uuid'));
        getListOfTestSuites();
      });
    } else if (typeOfParent === 'cycle') {
      const data = {
        uuid: get(parentData, 'uuid'),
        partition: partitionType,
      };

      dispatch(getTestCycleFunc(data))
        .then((currentCycle) => {
          const prevCasesCount = get(currentCycle, '[0].params.cases_count', 0);

          const progress = (!isNil(currentCycle[0].params.progress)
          && currentCycle[0].params.progress);

          const dataCycle = {
            uuid: get(parentData, 'uuid'),
            params: {
              cases_count: prevCasesCount + selectedUUID.length,
              helperInStatus: false,
              archive: false,
              progress: !isNil(progress)
                ? {
                  ...progress,
                  unexecuted: !isNil(progress) && progress.unexecuted + selectedUUID.length,
                  quantityCase: progress.quantityCase + selectedUUID.length,
                } : {
                  pass: 0,
                  block: 0,
                  fail: 0,
                  unexecuted: selectedUUID.length,
                  progress: 0,
                  quantityCase: selectedUUID.length,
                },
                modified_on: formatDateTimeForWms(moment()),
                modified_by: myUserUuid,
            },
            partition: partitionType,
            customConstants: ['REQUEST', ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE, 'FAILURE'],
          };

          dispatch(updateTestCycle(dataCycle)).then(() => {
            getListOfTestCasesInCycle(get(parentData, 'uuid'));
            getListOfTestCycles();
          });
        });
    }

    if (importTestCaseCallback) {
      importTestCaseCallback();
    }

    if(toggleModal){
      toggleModal(false);
    } 

    if(setCycleState){
      setCycleState({['existing cycle form']: {}});
    }
  };

  const handleChangeSuites = async (option) => {    
    // console.log('selectedOption', selectedOption);
    if (!option || isEmpty(option)) {    
      setListAllTestCases([]);
      return setConfig((prev) => (omit(prev, 'entities')));
    }
    const uuids = option.map((m) => m.value);

    if(setCycleState){
      setCycleState({['existing cycle form']: {
        ...cycleState['existing cycle form'],
        selectedSuites: uuids,
        suitesOptions: option
      }});
    }
    
    setConfig((prev) => ({
      ...prev,
      entities: uuids,
    }));
  };

  const getTestCasesCurrentEntity = async () => {
    setLoading(true);

    const data = {
      entity_type: 'testCase',
      depth: 0,
      params: {},
      parent: get(parentData, 'uuid'),
    };

    const res = await getListOfTestCasesFunc(data);
    // console.log('res', res);
    const getData = get(res, 'data').reduce((list, i) => {
      if (Array.isArray(get(i, 'testCaseUuidFromSuite', ''))) {
        return [...list, head(i.testCaseUuidFromSuite)];
      }
      return list;
    }, []);

    setConfig((prev) => ({
      ...prev,
      entities__not: getData,
    }));
    setLoading(false);
  };

  const getListCases = async () => {
    setLoading(true);
    const res = await getListOfTestCasesFunc();
    setListAllTestCases(get(res, 'data'));
    changeSelectedUUID((prev) => prev.filter((i) => get(res, 'data').some((p) => get(p, 'uuid', '') === i)));
    setLoading(false);
  };

  useEffect(() => {
    if(cycleState && cycleState['existing cycle form']?.selectedCases?.length > 0){
      changeSelectedUUID(cycleState['existing cycle form']?.selectedCases)
    }
  }, [])

  useEffect(() => {
    if (checkedCheckbox) {
      // console.log('checkedCheckbox', checkedCheckbox);
      getTestCasesCurrentEntity();
    } else {
      setConfig((prev) => ({
        ...omit(prev, 'entities__not'),
      }));
    }
  }, [checkedCheckbox]);

  useEffect(() => {
    if (!isEmpty(get(config, 'entities'))) {
      getListCases();
    }
  }, [JSON.stringify(config)]);

  useEffect(() => {
    if(setCycleState){
      setCycleState({['existing cycle form']: {
        ...cycleState['existing cycle form'],
        selectedCases: selectedUUID,
      }});
    }
  }, [selectedUUID])

  return (
    <ListOfAllTestCases
      loading={loading}
      checkedCheckbox={checkedCheckbox}
      listAllTestCases={listAllTestCases}
      selectedUUID={selectedUUID}
      assignToUsersOption={assignUsers}
      handleChangeSuites={handleChangeSuites}
      onChangeCheckbox={changeCheckedCheckbox}
      changeSelectedUUID={changeSelectedUUID}
      saveDataCallback={saveDataCallback}
      getSelectedCases={getSelectedCases}
      changeAssignToUsersOption={changeAssignToUsersOption}
      setConfig={setConfig}
      config={config}
      cycleState={cycleState || null}
      setCycleState={setCycleState}
    />
  );
}

ImportCaseComponent.propTypes = {
  typeOfParent: PropTypes.string,
  importTestCaseCallback: PropTypes.func,
};
export default ImportCaseComponent;
