import React, { useState } from 'react';
import { Tooltip } from 'antd';
import Icon from '@mdi/react';
import {
  mdiInformationOutline,
  mdiRefresh,
  mdiLoading,
} from '@mdi/js';
import {
  string, node, element, func,
} from 'prop-types';
import BaseButton from '../../../../../../components/_ui/BaseButton/BaseButton';

export default function WithRefreshvariant({
  tooltipPlacement = 'topLeft',
  tooltipTitle = '',
  refreshCallback,
  parent,
  partitionType,
}) {
  const [loading, setLoading] = useState(false);

  const handleRefreshScore = async () => {
    try {
      setLoading(true);
      await refreshCallback(parent, partitionType);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip
      title={(
        <div style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
          {tooltipTitle}
        </div>
)}
      placement={tooltipPlacement}
      color="#ffffff"
    >
      <BaseButton
        id="link"
        shape="circle"
        className="view_btn_link__info infoButtonWithRefresh"
        disabled={loading}
        onClick={handleRefreshScore}
      >
        <div className="infoBlock">
          <Icon
            path={mdiInformationOutline}
            size={1}
          />
        </div>
        <div className="refreshBlock">
          {loading ? <Icon path={mdiLoading} size={1} className="rotateAnimation" /> : <Icon path={mdiRefresh} size={1} />}
        </div>

      </BaseButton>
    </Tooltip>
  );
}

WithRefreshvariant.propTypes = {
  parent: string,
  partitionType: string,
  tooltipPlacement: string || node || element,
  tooltipTitle: string,
  refreshCallback: func,
};
