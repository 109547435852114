import { Card } from 'antd';
import React from 'react';

import ListOfNotificationsContainer from '../../../../../notifications/ListOfNotificationsContainer';
import BaseCard from "../../../../_ui/BaseCard/BaseCard";

function NotificationCardRoot() {
  return (
    <BaseCard className="w-full mt-3">
      <ListOfNotificationsContainer />
    </BaseCard>
  );
}

export default NotificationCardRoot;
