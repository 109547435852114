import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex, Tooltip,
} from 'antd';

import './userList.scss';
import cn from 'classnames';
import { get } from 'lodash';
import dayjs from 'dayjs';

export default function UserItemForMyBoard({
  onClickCallback,
  label = '',
  uuid = '',
  totalHours = 8,
  todayPlan = [],
  isSelected = false,
}) {
  const getPartOfText = (text, size) => (text?.length > size ? (
    <Tooltip
      title={text}
      className="user-item-tooltip"
    >
      {`${text.slice(0, size)}...`}
    </Tooltip>
  ) : <span className="">{text}</span> || '');

  return (
    <Flex
      align="start"
      justify='center'
      vertical
      onClick={() => onClickCallback(uuid)}
      className={cn("w-full h-full px-3 border-t border-gray-200 hover:bg-gray-100", {
        '!bg-sky-200 hover:!bg-sky-200': isSelected,
      })}
    >
      <span>{getPartOfText(label, 20)}</span>
      {todayPlan.length > 0 ? <span
        className='text-xs text-blue-500'
      >
        Used {todayPlan.reduce((acc, el) => acc + (el?.chunk ?? 0) / 2, 0)} hours out of {totalHours}
        {/* {todayPlan.length} issues in the plan (~{todayPlan.reduce((acc, el) => acc + (el?.chunk ?? 0) / 2, 0)} hours) */}
      </span> : (
        <span
          className='text-xs text-orange-500'
        >
          No plan for today
        </span>
      )}
    </Flex>
  );
}

UserItemForMyBoard.propTypes = {
  onClickCallback: PropTypes.func,
  label: PropTypes.string,
  uuid: PropTypes.string,
  isSelected: PropTypes.bool,
  todayPlan: PropTypes.array,
  size: PropTypes.number,
};
