import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Input, Popconfirm, Tag,
} from 'antd';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import { capitalize } from 'lodash';

import { useTranslation } from 'react-i18next';
import {
  projectDefaultQaTags,
  projectDefaultTags,
} from '../../projectFlow/components/project/constants/ProjectStatusOptions';
import EditButton from '../../projectFlow/components/commonComponents/EditButton';
import BaseTag from "../../components/_ui/BaseTag/BaseTag";

function TagsForEntity({
  defaultTags = [],
  entityType = '',
  addOrRemoveTagCallBack,
  isProjectDefaultTags = true,
  isCaseDefaultTags = false,
  isAddTagButton = false,
}) {
  const { t } = useTranslation();

  const [customTags, setCustomTags] = useState(defaultTags);
  const [newTag, setNewTag] = useState('');

  const onChange = (e) => {
    setNewTag(e?.target?.value);
  };

  const onCancel = () => {
    setNewTag('');
  };

  const onConfirm = () => {
    let isExists;
    switch (entityType) {
      case 'project':
        isExists = [...customTags, ...projectDefaultTags].some((tag) => tag === newTag.toLowerCase());
        break;
      case 'case':
        isExists = [...customTags, ...projectDefaultQaTags].some((tag) => tag === newTag.toLowerCase());
        break;
      default: break;
    }
    if (isExists) {
      antNotification('warning', t('notifications.text.warning.tag_exists', 'tag_exists'));
    } else {
      setCustomTags([...customTags, newTag]);
      addOrRemoveTagCallBack?.([...customTags, newTag]);
    }
    onCancel();
  };

  const removeTag = (removedTag) => {
    setCustomTags(customTags.filter((item) => item !== removedTag));
    addOrRemoveTagCallBack?.(customTags.filter((item) => item !== removedTag));
  };

  const okButtonProps = useMemo(() => ({ disabled: !newTag?.trim()?.length }), [newTag]);

  useEffect(() => {
    setCustomTags(defaultTags);
  }, [defaultTags]);

  return (
    <div className="flex flex items-start px-1">
      <div className="wrapper-title pb-0 flex items-center">
        <Popconfirm
          title={(
            <>
              <p>
                New
                {' '}
                {entityType}
                {' '}
                tag
              </p>
              <Input maxLength={15} value={newTag} onChange={onChange} />
            </>
          )}
          onConfirm={onConfirm}
          okButtonProps={okButtonProps}
          onCancel={onCancel}
          okText="Save"
          cancelText="Cancel"
        >
          <EditButton id='setEditProjectTagsButton' />
        </Popconfirm>
        <span className="whitespace-nowrap">
          {capitalize(entityType)}
          {' '}
          tags:
        </span>
      </div>
      <div className="grow wrapper-description pt-0 pl-0 pl-1">
        {projectDefaultTags.map((item) => <BaseTag color="orange" className="mx-1 mb-1">{capitalize(item)}</BaseTag>)}
        {customTags.map((item) => (
          <Popconfirm
            title={(
              <>
                <div>
                  Remove tag
                  <BaseTag className="mx-2" color="red">{item}</BaseTag>
                  from
                  {' '}
                  {entityType}
                  ?
                </div>
                <div>Are you sure?</div>
              </>
            )}
            onConfirm={() => removeTag(item)}
            // onCancel={onCancel}
            okText="Yes"
            cancelText="No"
          >
            <BaseTag
              color="blue"
              className="mx-1 mb-1 cursor-pointer"
            >
              {capitalize(item)}
              <Icon
                path={mdiClose}
                // color={'red'}
                size={0.6}
                className="ml-2 mb-1"
              />
            </BaseTag>
          </Popconfirm>
        ))}
      </div>
    </div>
  );
}

export default TagsForEntity;

TagsForEntity.propTypes = {
  addOrRemoveTagCallBack: PropTypes.func,
  defaultTags: PropTypes.array,
  entityType: PropTypes.string,
  isProjectDefaultTags: PropTypes.bool,
  isCaseDefaultTags: PropTypes.bool,
  isAddTagButton: PropTypes.bool,
};
