import { mdiReply } from '@mdi/js';
import Icon from '@mdi/react';
import { Input, Popover } from 'antd';
import { capitalize, get } from 'lodash';
import { bool, func, object } from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import BaseButton from '../components/_ui/BaseButton/BaseButton';
import CommentReply from '../projectFlow/components/comment/CommentReply';

import { sendNotification } from '../api/notificationsAPI';
import { antNotification } from '../MainUtils';
import { createComment } from '../projectFlow/actions/projectFlowActions';
import { checkExist } from './utilesNotifications';

const { TextArea } = Input;

export default function ReplyToNotification({
  customReplayData,
  replyCallback,
  disabled,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [text, setText] = useState('');
  const [visible, setVisible] = useState(false);

  const ref = useRef(null);

  const toggleReply = () => {
    if (!visible) {
      dispatch(checkExist(
        customReplayData,
        () => setVisible((prev) => !prev),
      ));
    } else {
      setVisible((prev) => !prev);
    }
  };

  const getUuidParent = () => {
    const { uuid, projectUUID, vectorUUID } = get(customReplayData, 'params.entity');

    return uuid || vectorUUID || projectUUID;
  };

  const sendNewNotification = () => {
    dispatch(
      sendNotification(
        'mention',
        get(customReplayData, 'sender'),
        text,
        { entity: get(customReplayData, 'params.entity') },
      ),
    );
  };

  const onSuccessComment = () => {
    if (replyCallback) {
      replyCallback();
    }
    setVisible(false);

    antNotification('success', t('wms.noun.success', 'Success'));
    setText('');
  };

  const sendComment = () => {
    const params = {
      comment: text,
      reply: {
        actorName: `${get(customReplayData, 'senderUinfo.first_name')} ${get(customReplayData, 'senderUinfo.last_name')}`,
        actorUUID: get(customReplayData, 'sender'),
        text: get(customReplayData, 'message'),
      },
    };

    sendNewNotification();

    const data = {
      parent: getUuidParent(),
      partition: get(customReplayData, 'params.entity.partition'),
      files: [],
      params,
      onSuccess: onSuccessComment,
    };

    dispatch(createComment(data));
  };

  const onChange = (e) => setText(e.target.value);

  const content = (
    <div className="wrapper_log mt-1 mb-1 wrapper-reply-popover">
      {customReplayData && (
        <CommentReply
          actorName={`${get(customReplayData, 'senderUinfo.first_name')} ${get(customReplayData, 'senderUinfo.last_name')}`}
          actorUUID={get(customReplayData, 'sender')}
          text={get(customReplayData, 'message')}
        />
      )}
      <TextArea
        rows={4}
        placeholder={`${capitalize(
          t('wms.placeholders.comment', 'enter your comment here'),
        )}...`}
        maxLength={4096}
        value={text}
        onChange={onChange}
      />
      <div className="flex justify-end mt-1 grow">
        <BaseButton
          onClick={sendComment}
          type="primary"
          disabled={!text.length}
        >
          {capitalize(
            t('wms.buttons.send', 'send'),
          )}
        </BaseButton>
      </div>
    </div>
  );

  return (
    <div ref={ref}>
      <Popover
        content={content}
        trigger="click"
        open={visible}
        disabled={disabled}
        onOpenChange={toggleReply}
        getPopupContainer={() => ref?.current}
      >
        <BaseButton
          size="small"
          disabled={disabled}
          className="btnPrimary-outline  mr-1"
        >
          <Icon path={mdiReply} size={0.7} className="mr-1" />
          {capitalize(
            t('wms.buttons.reply', 'reply'),
          )}
        </BaseButton>
      </Popover>
    </div>
  );
}

ReplyToNotification.propTypes = {
  customReplayData: object,
  replyCallback: func,
  disabled: bool,
};
