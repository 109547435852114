import { Button } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { propOr, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { mdiFileDocumentOutline } from '@mdi/js';
import Icon from '@mdi/react';

import { bool, string } from 'prop-types';
import { getProjectParams, getProjectUUID } from '../../../../selectors/selectors';
import { createNewDocumentRequest } from '../../../docView&ideas/actions/docsActions';
import { ProjectTypeCommonConstants } from '../../../../constants/Constants';
import { updateProjectRequest } from '../../../../actions/projectFlowActions';
import { antNotification } from '../../../../../MainUtils';
import { capitalize } from 'lodash';
import useURLParams from '../../../../../hooks/useURLParams';
import { partitionNamesConfig } from '../../../../../api/appConfig';

const styleTextEllipsis = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export default function InfrastructureDocument({
  partitionType,
  isGlobalDisabled,
  size = 'small',
  layout = 'horizontal',
  userUUID = '',
  isGrayTitle,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { routeNavigateAndClearParams, currentLocationPathname } = useURLParams();

  const [disabled, setDisabled] = useState(false);

  const projectUUID = useSelector(getProjectUUID);
  const projectParams = useSelector(getProjectParams);

  const projectInfrastructureUUID = pathOr('', ['infrastructureDoc', 'uuid'], projectParams);

  const projectName = propOr('', 'title', projectParams);
  const isDocumentExist = !!projectInfrastructureUUID;

  const buttonText = isDocumentExist ? 'To the document' : 'Create infrastructure document';

  const iconSize = {
    small: 0.7,
    medium: 0.85,
  };

  const classes = {
    margin: {
      horizontal: 'mr-2',
      vertical: 'mb-2',
    },
    display: {
      horizontal: 'inline-flex items-center',
      vertical: 'inline-flex flex-col items-start',
    },
  };

  const titleElement = () => {
    switch (size) {
      case 'medium':
        return (
          <h6 className={`${classes.margin[layout]}`}>
            {`${capitalize('Infrastructure doc link')}:`}
          </h6>
        );
      case 'small':
      default:
        return (
          <span className={`${classes.margin[layout]} wrapper-title ${isGrayTitle ? 'text-secondary' : ''}`}>
            {`${capitalize('Infrastructure doc link')}:`}
          </span>
        );
    }
  };

  const splitPath = currentLocationPathname.split('/');
  const userUUIDFromUrl = splitPath[splitPath.indexOf('user') + 1];

  const goToDoc = (uuid) => routeNavigateAndClearParams(
    `${partitionType === partitionNamesConfig.partition1 ? '/pm/projects/docs' : `/public/user/${userUUIDFromUrl || userUUID}/projects/docs`}`,
    {
      activeProject: projectUUID,
      activeDoc: uuid,
    },
  );

  const createProjectInfrastructureDocument = async () => {
    setDisabled(true);
    const projectInfrastructureDocumentName = projectName
      ? `${projectName} (project infrastructure document)`
      : 'Project infrastructure document';

    const config = {
      parent: projectUUID,
      partition: partitionType,
      params: {
        title: projectInfrastructureDocumentName,
        text: '',
        type: 'project infrastructure',
        status: 'system',
        lifeTime: moment().add('1', 'year').format('YYYY-MM-DD'),
        actualState: '0',
      },
    };

    const data = await dispatch(createNewDocumentRequest(config));

    if (!isEmpty(data)) {
      const docUUID = get(data, '[0].uuid', '');

      const projectConfig = {
        uuid: projectUUID,
        params: {
          infrastructureDoc: {
            uuid: docUUID,
            title: projectInfrastructureDocumentName,
          },
        },
        partition: partitionType,
        constants: [
          ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_REQUEST,
          ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_SUCCESS,
          ProjectTypeCommonConstants.UPDATE_PROJECT_WITHOUT_LOADING_FAILURE,
        ],
      };

      dispatch(updateProjectRequest(projectConfig)).then(() => {
        antNotification('success', t('wms.noun.success', 'Success'));
        goToDoc(docUUID);
      });
    }
    setDisabled(false);
  };

  return (
    <div>
      {/* className={`${classes.display[layout]}`} */}
      {titleElement()}
      <Button
        id={`${buttonText.split(' ').map(el => el[0].toUpperCase() + el.slice(1)).join('')}` + "Button"}
        className={`${isDocumentExist ? 'btnPrimary-outline' : 'btnWarning-outline'} mw-full`}
        size={size}
        type={isDocumentExist ? 'link' : 'dashed'}
        // danger={!isDocumentExist}
        disabled={isGlobalDisabled || disabled}
        onClick={isDocumentExist
          ? () => goToDoc(projectInfrastructureUUID)
          : () => createProjectInfrastructureDocument()}
      >
        {!isDocumentExist && (
          <Icon path={mdiFileDocumentOutline} size={iconSize[size]} className="mr-1 flex-shrink-0" />
        )}
        <span style={styleTextEllipsis}>{buttonText}</span>
      </Button>
    </div>
  );
}

InfrastructureDocument.propTypes = {
  partitionType: string,
  isGlobalDisabled: bool,
  size: string,
  layout: string,
  userUUID: string,
};
