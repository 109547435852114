import { array, func, object, string } from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlansForDay from "../../myBoard/plansForDayComponents/PlansForDay";
import { stopPropagation } from "../../../../54origins/utils54origins";
import { getIssueEntityFetching } from "../../../selectors/selectors";
import {  Button, Flex, Modal, Tooltip, } from "antd";
import IssueInfoRoot from "../../issues/issueInfo/IssueInfoRoot";
import { get} from "lodash";
import useGeneralPlanboard from "./useGeneralPlanboard";
import { entityUpdate } from "../../../../entity/actions/entityActions";
import SuggestedPlan from "../../myBoard/SuggestedPlan";
import useSuggestedIssuesInMainPlanboard from "./useSuggestedIssuesInMainBoard";
import Icon from "@mdi/react";
import { mdiHelpCircleOutline } from "@mdi/js";
import { getClassBtn } from "./utils";

const MainBoard = ({
    today,
    // fetchedBoards,
    partitionPM,
    partition,
    customActor,
    getClearIssue,
    projects,
    getFetchBoards,
    selectedUser
}) => {

    const dispatch = useDispatch()
    const issueFetching = useSelector(getIssueEntityFetching);
    const [activeIssue, setActiveIssue] = useState(null)

    const [sheduleHours, setSheduleHours] = useState(3)
 
    const {
      todayIssues, 
      updateGeneralPlanboardStorageTodayIssues,
      updateLoading,
      updateGeneralPlanboardStorage,
      saveIssuesInAllBoards,
      dayParams,
      saveLoading,
      loading
    } = useGeneralPlanboard({customActor, partitionPM, getFetchBoards, today})

    const {
      suggestedIssues, 
      isFetching, 
      paginationSettings,
      getSuggestedIssues,
      selectedProjects,
      setSelectedProjects
    } = useSuggestedIssuesInMainPlanboard({todayIssues, partitionPM, customActor, projects})

    

    
    // ACTIONFUNC

    const onChangeIssue = async (uuid) => {
      if (!issueFetching) {
        setActiveIssue(uuid)

        await getClearIssue(uuid)
          .catch((error) => {
            if (error.message) {
              console.log('error', error.message);
            }
          });
      }
    };

    const requestUpdateIssue = (data) => {
      const constants = [
        'UPDATE_ISSUE_FOR_MY_BOARD_REQUEST',
        'UPDATE_ISSUE_FOR_MY_BOARD_SUCCESS',
        'UPDATE_ISSUE_FOR_MY_BOARD_FAILURE',
      ];
  
      const options = {
        partition
      };
  
      return dispatch(entityUpdate({
        data,
        constants,
        options,
      }));
    };

    const resetIssueCallback = () => {
      if(todayIssues.find(issue => issue.uuid === activeIssue)) {
        updateGeneralPlanboardStorage()
      } else {
        getSuggestedIssues()
      }
      setActiveIssue(null)
      // getUserBoards()
    };

    const onActionCallback = async (value, type, e, day) => {
      stopPropagation(e);
      switch (type) {
        case 'q-view': {
          if (value === activeIssue) {
            resetIssueCallback();
          } else {
            onChangeIssue(value);
          }
          break;
        }
        case 'issue-time-update': {
          const uuid = get(value, 'uuid', '');
          const newTime = get(value, 'value', '');
          
          const newTodayIssues = todayIssues.map(el => {
            if(el.uuid === uuid) {
              el.data.chunk = newTime;
            }
            return el
          })
          requestUpdateIssue({
            entity_uuid: uuid,
            params: {
              boardData: {chunk: newTime}
            }
          });

          updateGeneralPlanboardStorage({todayIssues: newTodayIssues})
          break;
        }
        case 'day-available-time-update': {
          console.log("params", {value, type, e, day });
          updateGeneralPlanboardStorage({workTime: value})
          break;
        }
        case 'issue-remove': {
          console.log("params", {value, type, e, day });
          const newTodayIssues = todayIssues.filter(el => el.uuid !== value)
          updateGeneralPlanboardStorage({todayIssues: newTodayIssues})
          getSuggestedIssues()
          break;
        }
        case 'issue-complete': {
          const uuid = get(value, ['issue', 'uuid'], '');
          const projectUUID = get(value, ['issue', 'project'], '')
          const newStatus = get(value, ['newStatus'], '');
          const needComplete = get(value, ['complete'], false);
          console.log("params", {value, type, e, day });
          

          const newTodayIssues = todayIssues.map(el => {
            if(el.uuid === uuid) {
              el.params.status = newStatus;
              el.params.compeled = [customActor]
              el.done = needComplete;
            }
            return el
          })

          requestUpdateIssue({
            entity_uuid: uuid,
            parent: projectUUID,
            params: {
              status: newStatus,
              completed: [customActor]
            }
          })

          updateGeneralPlanboardStorage({todayIssues: newTodayIssues})
          break;
        }
        case 'issue-reopen': {
          const uuid = get(value, ['uuid'], '');
          const newTodayIssues = todayIssues.map(el => {
            if(el.uuid === uuid) {
              el.params.compeled = []
              el.done = false;
            }
            return el
          })
          updateGeneralPlanboardStorage({todayIssues: newTodayIssues})
          break;
        }
        case 'issue-update-total-time': {
          const uuid = get(value, 'uuid', '');
          const prevboardData = get(value, 'issue.boardData', {})
          const newBoardData = {
            ...prevboardData,
            chunk: get(value, 'value', ''),
          }
          requestUpdateIssue({
            entity_uuid: uuid,
            params: {
              boardData: newBoardData
            }
          });
          getSuggestedIssues()
          break;
        }
        case 'issue-add': {
          const newIssue = {
            params: {...value}, 
            data: {...value.data, projectTitle: value.projectTitle, uuid: value.uuid, key: null}, 
            uuid: value.uuid
          }
            const newTodayIssues = [...todayIssues, newIssue]
            console.log("newTodayIssues", newTodayIssues);
            
            updateGeneralPlanboardStorage({todayIssues: newTodayIssues})
            getSuggestedIssues()
            break;
        }
        case 'day-remove-all': {
          updateGeneralPlanboardStorage({todayIssues: []})
          getSuggestedIssues()
          break;
        }
        case 'add-issues-for-time': {
          console.log("params", {value, type, e, day });
          setSheduleHours(value)
          break;
        }
        default: return;
      }
    };

    // ENDACTIONFUNC

    const SaveAndGetButtons = () => {
      return <Flex gap="small" className="mb-1" align="center">
        <Flex align="center">
        <h6 className="mt-3.5 text-gray-500">General board member:</h6>
        <h6 className="ml-1 mt-2 text-lg">{selectedUser.label}</h6>
        </Flex>
      <Button
        disabled={false}
        className={`${getClassBtn("get")}`}
        size="small"
        onClick={() => updateGeneralPlanboardStorageTodayIssues()}
      >
        <Tooltip
      color="white"
      title={(
        <Flex
          vertical
          className='text-black'
        >
         Get user issues from all plans in projects
        </Flex>)
      }
    >
      <Icon
        path={mdiHelpCircleOutline}
        // className='ml-1'
        size={0.9}
      />
    </Tooltip>
        Get general planboard
  </Button>
  <Button
        disabled={false}
        className={`${getClassBtn("save")}`}
        size="small"
        onClick={() => saveIssuesInAllBoards()}
        loading={saveLoading}
        iconPosition="end"
      >
        <Flex>
        <Tooltip
      color="white"
      title={(
        <Flex
          vertical
          className='text-black'
        >
       Save the current configuration of plans. All plans will be overwritten
        </Flex>)
      }
    >
      <Icon
        path={mdiHelpCircleOutline}
        className='mr-1'
        size={0.9}
      />
    </Tooltip>
        Save
        </Flex>
  </Button>

      </Flex>
    }


    return (
  <Flex vertical className="p-2">
      <SaveAndGetButtons/>
      <Flex className='mb-2' vertical>
        <PlansForDay
        key={today}
        day={today}
        dayParams={dayParams}
        issues={todayIssues}
        partition={partition}
        loading={updateLoading || loading}
        actorUUID={customActor}
        onActionCallback={onActionCallback}
        hideElements={['addIssueBtn', "idColumn"]}
        isMainBoard={true}
        />
     <SuggestedPlan
        issues={suggestedIssues}
        loading={isFetching}
        mode="user"
        hideElements={["showAllBtn","computePlanBtn","showCompletedBtn"]}
        onActionCallback={onActionCallback}
        paginationSettings={paginationSettings}
        isMainBoard={true}
        projects={projects}
        sheduleHours={sheduleHours}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
    />
          {activeIssue && (
              <Modal
                open
                footer={null}
                destroyOnClose
                closable={false}
                onCancel={resetIssueCallback}
                width={820}
              >
                {activeIssue && <IssueInfoRoot
                  // hiddenView={hiddenInIssueInfo}
                  // disabledView={disabledInIssueInfo}
                  partitionType={partition}
                  resetIssueCallback={resetIssueCallback}
                  deleteCallback={() => {}}
                  onChangeRowCallback={(uuid) => onActionCallback(uuid, 'q-view')}
                  customProject={null}
                />}
              </Modal>
            )}
          </Flex>
      </Flex>
    )
     
    
}

MainBoard.propTypes = {
 today: string,
 fetchedBoards: array,
 customActor: string,
 partition: string,
 partitionPM: string,
 getClearIssue: func,
 projects: array,
 getFetchBoards: func,
 selectedUser: object
}

export default MainBoard