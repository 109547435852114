import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useURLParams from "../../../hooks/useURLParams";
import { getMe, getProjectParams, getProjectUUID } from "../../selectors/selectors";
import { get } from "lodash";
import useActorsW54 from "../../../actors/hooks/useActorsW54";
import UserList from "../../../actors/components/UserList";
import { fullName, getUuid } from "../../../assignUsersModal/utils";
import { Col, Flex, Row } from "antd";
import dayjs from 'dayjs';
import MyBoardNew from "./MyBoardNew";
import AntDesignSearchBox54origins from "../../../54origins/components/AntDesignSearchBox54origins";
import useUserBoards from "../../../myBoard/hooks/useUserBoards";
import UserListForMyBoard from "./commonComponents/UserListForMyBoard";

export default function AdminBoard({ partition = '' }) {
    const projectUUID = useSelector(getProjectUUID);
    const projectParams = useSelector(getProjectParams);

    // Получение project пользователей
    const userUUIDs = get(projectParams, 'usersSearch', []);
    const { users } = useActorsW54({ actors: userUUIDs });

    const { userBoards = [], onSetUserBoards } = useUserBoards({
        parent: projectUUID,
        partition,
    })

    const myActorUUID = useSelector(getMe);

    const [user, setUser] = useState('');
    const [searchData, setSearchData] = useState('');

    const projectUsersW54 = useMemo(() => users
        .map((item) => {
            const today = dayjs().format('YYYY-MM-DD');
            const board = userBoards?.find((board) => board?.actor === getUuid(item));
            const todayUUIDs = get(board, ['params', `${today}`], [])
            const todayPlan = get(board, ['params', `${today}__data`], [])
            const todaySettigns = get(board, ['params', `${today}__settigns`], {})

            return {
                value: getUuid(item),
                label: fullName(item),
                todayPlan: todayPlan?.filter(el => todayUUIDs?.includes(el?.uuid)),
                settings: todaySettigns,
            }
        })
        .filter((user) => user?.label?.toLowerCase()
            .includes(searchData.toLowerCase()))
        .sort((a, b) => {
            if (a.value === user) return -1;
            if (b.value === user) return 1;
            if (a?.todayPlan?.length && !b.todayPlan?.length) return -1;
            if (b?.todayPlan?.length && !a?.todayPlan?.length) return 1;
            return 0;
        }), [
        user,
        JSON.stringify(users),
        JSON.stringify(userBoards),
        searchData,
    ]);

    const onChangeUser = (newUser) => {
        if (newUser !== user) {
            setUser(newUser);
        }
    }

    useEffect(() => {
        if (userUUIDs?.includes(myActorUUID)) {
            setUser(myActorUUID);
        }
    }, [myActorUUID, JSON.stringify(userUUIDs)])

    return <Row
        gutter={32}
        className="ml-1"
    >
        <Col
            span={user ? 3 : 5}
            className="bg-white vector-card-issues-view !p-0 pr-1"
            style={{ maxHeight: 600, minHeight: 600 }}
        >
            <AntDesignSearchBox54origins
                classNameLabel="ml-3 mt-2 mb-2"
                onSearch={setSearchData}
            />
            <Flex
                className="h-full"
                style={{
                    maxHeight: 550,
                    minHeight: 550,
                    overflowY: 'scroll',
                }}
            >
                <UserListForMyBoard
                    total={projectUsersW54?.length}
                    items={projectUsersW54}
                    onClickCallback={onChangeUser}
                    selectedUUIDs={[user]}
                />
            </Flex>
        </Col>
        {user && <Col
            span={21}
        >
            <MyBoardNew
                customActor={user}
                onChangePlanCallback={onSetUserBoards}
                customActorLabel={projectUsersW54?.find(el => el.value === user)?.label}
                partition={partition}
            />
        </Col>}
    </Row>
}