import React, { useEffect, useState } from 'react';
import { string, number, arrayOf, func, object, bool, } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import moment from 'moment/min/moment-with-locales';
import { get, capitalize, isEqual, isEmpty } from 'lodash';


import { mdiChatProcessingOutline, mdiTimerSand } from '@mdi/js';

import {
  Row,
  Col,
  Tooltip,
  Badge,
  Flex,
} from 'antd';

import moment from 'moment';

import { prop } from 'ramda';
import Icon from '@mdi/react';

import { columnsFilterConfig } from './docView/constants/documentFilterOptions';


import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import ActionsForDocumentsTable from './docView/ActionsForDocumentsTable';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import CustomAvatar from "../../../components/CustomAvatar/CustomAvatar";
import DateLeft from '../../../components/DateLeft';

import {
  allProjDocsAndIdea,
  fetchingNewDoc,
  getTotalDocsAndIdea,
} from '../../selectors/selectors';
import { getAllDocPerms } from '../../reducers/slicers/quillSlicer';
import useURLParams from '../../../hooks/useURLParams';
import { useResize } from '../../../hooks/useResize';

import { dateWithTooltipTime, renderTableID } from '../../../MainUtils';
import { COMMENT_TYPE } from '../comment/commentType';

function ListOfDocuments({
  className,
  fetching = false,
  hiddenElements = [],
  partitionType,
  totalCount,
  defaultOrderConfig,
  defaultPaginationConfig,
  filterConfig,
  sizePerPageList = [10, 25, 50, 100],
  onMouseMiddleClick,
  setWithComments,
  setCurrentConfigAndGetRequest,
  deleteDocumentCallback,
  filterCallback,
  newDocumentCallback,
  selectDocumentCallback,
  updateDocumentsTableCallback,
  disabledOnRow = false,
  isQa = false,
}) {
  const { t } = useTranslation();

  const { getURLParams } = useURLParams();
  const { width } = useResize();

  const projDocsAndIdea = useSelector(allProjDocsAndIdea);
  const newDocFetching = useSelector(fetchingNewDoc);
  const total = useSelector(getTotalDocsAndIdea);

  const currentUserDocPerms = useSelector(getAllDocPerms);
  const isNoCreatePerm = !currentUserDocPerms?.create;
  const isNoUpdatePerm = !currentUserDocPerms?.update;
  const isNoDeletePerm = !currentUserDocPerms?.delete;

  const {
    activeDoc,
    activeNote,
    activeSheet,
    activeQaDoc,
  } = getURLParams();
  const selected = activeDoc || activeNote || activeSheet || activeQaDoc;

  const [config, setConfig] = useState({
    ...defaultOrderConfig,
    ...defaultPaginationConfig,
    ...filterConfig,
    search: '',
  });
  const [isConfigChanged, setIsConfigChanged] = useState(true);
  const [loading, setLoading] = useState(false);

  const changeConfig = (newConfig) => {
    const updatedConfig = {
      ...config,
      ...newConfig,
    }

    if (!isEqual(config, updatedConfig)) {
      setConfig(updatedConfig);
      setIsConfigChanged(true);
    }
  };

  const {
    currentPage,
    limit,
  } = config || {};

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectColumn: true,
    style: { background: '#e5f4fc' },
    selected: [],
  };

  const changePageLimit = (size) => {
    changeConfig({ currentPage: 1, limit: size, offset: 0 });
  };

  const onSearchData = (value) => {
    changeConfig({
      currentPage: 1,
      offset: 0,
      limit,
      search: value,
    });
  };

  const currentSortCallback = (dataSort) => {
    // const orderBy = get(dataSort, 'orderBy');
    const order = get(dataSort, 'order');

    if (!order) changeConfig(defaultOrderConfig);
    else changeConfig(dataSort);
  };

  const createNewDocument = async (documentType) => {
    if (loading) return;

    setLoading(true);
    await newDocumentCallback({
      type: documentType,
      open: true,
    });
    setLoading(false);
  };

  const commentsCountFormatter = (cell) => (cell >= 0) && (
    <Badge count={cell} showZero color="#fc676a" className="flex justify-center" />
  );

  const userFormatter = (cell) => {
    const fullName = `${prop('first_name', cell)} ${prop('last_name', cell)}`;
    return (
      <div className="flex flex-wrap items-center">
        <CustomAvatar
          fullName={fullName}
        />
      </div>
    );
  };

  const setSelectDoc = (row) => {
    const { entity_type, uuid } = row;

    selectDocumentCallback(uuid, entity_type);
  };

  const lifeTimeFormatter = (cell, row) => (cell && row.status !== 'important') && <DateLeft minus date={cell} type="tag" customStyle="text-neutral-600" />;

  const actionFormatter = (cell, row) => {
    if (get(row, 'type') !== 'project description'
      && get(row, 'type') !== 'project infrastructure') {
      return (
        <ActionsForDocumentsTable
          entityUUID={cell}
          entityInfo={row}
          partitionType={partitionType}
          deleteCallback={() => deleteDocumentCallback(cell, row)}
          refreshTableCallback={updateDocumentsTableCallback}
          isNoUpdatePerm={isNoUpdatePerm}
          isNoDeletePerm={isNoDeletePerm}
        />
      );
    }
  };

  const rowClassName = (row) => {
    const data = (color) => (`universal_table_row ${color} ${get(row, 'uuid') === selected
      ? 'selected'
      : ''
      }`);

    const rowData = (get(row, ['params', 'type']) === 'project description'
      || get(row, ['params', 'type']) === 'project infrastructure')
      ? data('lightRowOrange') : data();

    return rowData;
  };

  const onRow = (record) => ({
    onClick: () => {
      if (!disabledOnRow) {
        setSelectDoc(record);
      }
    },
    onMouseDown: (e) => {
      if (!disabledOnRow) {
        onMouseMiddleClick(e, record);
      }
    },
  });

  const docsColumns = [
    {
      dataIndex: 'id',
      title: 'ID',
      key: 'id',
      sorter: true,
      render: (cell, row) => {
        let letter;
        const { entity_type } = row;
        switch (entity_type) {
          case 'projectIdea': {
            letter = 'N';
            break;
          }
          case 'projectDocument': {
            letter = 'D';
            break;
          }
          case 'projectSpreadsheet': {
            letter = 'S';
            break;
          }
        }
        return (cell && renderTableID(cell, letter));
      },
    },
    {
      dataIndex: 'title',
      key: 'title',
      title: <AntDesignSearchBox54origins onSearch={onSearchData} />,
      render: (docName) => (
        <EntityParamTag
          value={docName}
          type="value"
          param="title"
          maxLength={32}
        />
      ),
    },
    {
      dataIndex: 'entity_type',
      key: 'entity_type',
      title: capitalize(t('wms.table.headers.type', 'type')),
      sorter: true,
      ...(columnsFilterConfig && { ...columnsFilterConfig?.type }),
      ...(filterConfig?.entity_type && { defaultFilteredValue: filterConfig?.entity_type }),
      render: (cell, row) => cell && (
        <EntityParamTag
          param='type'
          value={get(row, ['params', 'type']) || cell}
          type='badge'
        />
      ),
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: capitalize(t('wms.table.headers.status', 'status')),
      sorter: true,
      ...(columnsFilterConfig && { ...columnsFilterConfig?.status }),
      ...(filterConfig?.status && { defaultFilteredValue: filterConfig?.status }),
      filterResetToDefaultFilteredValue: true,
      render: (cell, row) => (
        <EntityParamTag
          value={get(row, ['params', 'status']) || cell}
          param="status"
          type={width > 1500 ? 'iconWithStatus' : 'icon'}
        />
      ),
    },
    {
      dataIndex: COMMENT_TYPE.TOTAL,
      key: COMMENT_TYPE.TOTAL,
      hidden: width < 1700,
      // sorter: true,
      title: (
        <Tooltip title={capitalize(t('wms.table.headers.comments', 'comments'))}>
          <Icon path={mdiChatProcessingOutline} size={1} />
        </Tooltip>
      ),
      render: commentsCountFormatter,
    },
    {
      dataIndex: 'uinfo',
      title: capitalize(t('wms.table.headers.owner', 'owner')),
      render: userFormatter,
    },
    {
      dataIndex: 'updated',
      key: 'modified',
      hidden: width < 1600,
      title: capitalize(t('wms.table.headers.modified', 'modified')),
      sorter: true,
      render: (cell) => dateWithTooltipTime(cell, 'DD-MM-YY'),
    },
    {
      dataIndex: 'lifeTime',
      key: 'lifeTime',
      hidden: width < 1420,
      sorter: true,
      title: (
        <Tooltip title={capitalize(t('wms.adjective.expired', 'expired'))}>
          <Icon path={mdiTimerSand} size={1} />
        </Tooltip>
      ),
      render: (cell, row) => row?.status?.includes('system') ? '' :  lifeTimeFormatter(cell, row)
    },
    {
      dataIndex: 'uuid',
      hidden: hiddenElements?.includes('deleteIcon'),
      title: capitalize(t('wms.table.headers.actions', 'actions')),
      render: actionFormatter,
    },
  ]
    .map((el) => ({
      ...el,
      onCell: () => ({
        onClick: () => setWithComments?.(el.dataIndex === COMMENT_TYPE.TOTAL),
      }),
    }))
    .filter((el) => !el.hidden);

  // const callbackToggleSwitch = (type, btntype) => {
  //   if (btntype === 'outdated') {
  //     const config = {
  //       ...state,
  //       limit: state.pageLimit,
  //       entity_type: type,
  //       params: { lifeTime__lt: moment(new Date()).format('YYYY-MM-DD') },
  //     };

  //     delete config.currentPage;
  //     delete config.defaultPageLimit;
  //     delete config.pageLimit;
  //     delete config.sizePerPage;
  //     delete config.sizePerPageList;

  //     changeState({ entity_type: type, params: { lifeTime__lt: moment(new Date()).format('YYYY-MM-DD') } });

  //     return setCurrentConfigAndGetRequest?.(config);
  //   }

  //   const config = {
  //     ...state,
  //     limit: state.pageLimit,
  //     entity_type: type,
  //     params: {},
  //   };

  //   delete config.currentPage;
  //   delete config.defaultPageLimit;
  //   delete config.pageLimit;
  //   delete config.sizePerPage;
  //   delete config.sizePerPageList;

  //   changeState({ entity_type: type, params: {} });
  //   return setCurrentConfigAndGetRequest?.(config);
  // };

  useEffect(() => {
    // console.log('effect 1 document');
    if (!isEmpty(filterConfig)) {
      changeConfig({
        ...filterConfig,
        currentPage: 1,
        offset: 0,
        limit,
      });
    }
  }, [filterConfig]);

  useEffect(() => {
    if (isConfigChanged) {
      // console.log('effect 2 document');
      setCurrentConfigAndGetRequest?.(config);
      setIsConfigChanged(false);
    }
  }, [isConfigChanged]);

  const headButtonDisabled = newDocFetching || fetching || isNoCreatePerm;

  return (
    <BaseCard className={`primaryCard w-full ${className}`}>
      <Row>
        <Col span={14} className="mb-2 just">
          <Flex gap="small" wrap="wrap">
            <h6>
              Documents
            </h6>
            {!hiddenElements?.includes('createBtns') && (
              <>
                {
                  !isQa &&
                  <>
                    <BaseButton
                      id="createDocumentButton"
                      className="btnPrimary-outline"
                      disabled={headButtonDisabled}
                      onClick={() => createNewDocument('projectDocument')}
                      size="small"
                    >
                      {capitalize(t('wms.noun.document', { verb: t('wms.verb.create') }, 'create document'))}
                    </BaseButton>
                    <BaseButton
                      id="createIdeaButton"
                      className="btnPrimary-outline"
                      disabled={headButtonDisabled}
                      onClick={() => createNewDocument('projectIdea')}
                      size="small"
                    >
                      {capitalize(t('wms.buttons.create_idea', 'create idea'))}
                    </BaseButton>
                    <BaseButton
                      id="createSpreadSheetButton"
                      className="btnPrimary-outline"
                      disabled={headButtonDisabled}
                      onClick={() => createNewDocument('projectSpreadsheet')}
                      size="small"
                    >
                      {capitalize(t('wms.buttons.create_spreadsheet', 'create spreadsheet'))}
                    </BaseButton>
                  </>
                }
                
                <BaseButton
                  id="createQaDocumentButton"
                  className="btnPrimary-outline"
                  disabled={headButtonDisabled}
                  onClick={() => createNewDocument('qaDocument')}
                  size="small"
                >
                  {capitalize(t('wms.buttons.create_spreadsheet', 'create qa document'))}
                </BaseButton>
              </>
            )}
          </Flex>
        </Col>

        <Col span={24} className="collapsedTable-table">
          <BaseTableWithPagination
            headerRowClassName="universal_header_table_row"
            rowSelection={selectRow}
            rowClassName={rowClassName}
            columns={docsColumns}
            data={projDocsAndIdea}
            total={totalCount || total}
            loading={fetching}
            useCustomPagination
            // disablePagination={(total || totalCount) <= defaultPageLimit}
            paginationSize="small"
            newCurrentPage={currentPage}
            pageLimit={limit}
            pageSizeOptions={sizePerPageList}
            onRow={onRow}
            changePageLimit={changePageLimit}
            getPaginationOptions={changeConfig}
            currentSortCallback={currentSortCallback}
            filterCallback={filterCallback}
          />
        </Col>
      </Row>
    </BaseCard>
  );
};

ListOfDocuments.propTypes = {
  className: string,
  hiddenElements: arrayOf(string),
  fetching: bool,
  partitionType: string,
  totalCount: number,
  defaultOrderConfig: object,
  defaultPaginationConfig: object,
  filterColumnsConfig: object,
  filterConfig: object,
  sizePerPageList: arrayOf(number),
  onMouseMiddleClick: func,
  setWithComments: func,
  setCurrentConfigAndGetRequest: func,
  deleteDocumentCallback: func,
  filterCallback: func,
  newDocumentCallback: func,
  selectDocumentCallback: func,
  updateDocumentsTableCallback: func,
  disabledOnRow: bool
};

export default ListOfDocuments;
