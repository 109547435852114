/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, Modal, Row, Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { cloneDeep, get } from 'lodash';
// import moment from 'moment';

import InfoTip from '../../../../components/infoTip/InfoTip';
import BreadCrumbsNEW from '../../../../components/breadcrumb/BreadCrumbsNEW';
import ProjectInfo from '../projectInfo/ProjectInfo';
import ListOfProjectsForAudit from './ListOfProjectsForAudit';

import axiosRequest from '../../../../api/apiAxios';
import useCheckPerm from '../../../../hooks/useCheckPerm';
import useURLParams from '../../../../hooks/useURLParams';
import useUserPublicPartition from '../../../../hooks/useUserPublicPartition';

import {
  getMe,
  getProjectFetching,
  getProjectUUID,
} from '../../../selectors/selectors';
import { getUserPublicProfileUUID } from '../../../../userFlow/store/selectors/selectors';
import { getListOfProjects, getProjectRequest, updateProjectScoring } from '../../../actions/projectFlowActions';
import { getRootEntityPartition } from '../../../../config/selectors/selectors';
import {
  clearListOfProjects,
  clearListOfPersonalProjects,
  clearProject,
} from '../../../actions/PtcActionsForHook';
import { selectProjectsKPM } from '../../../reducers/slicers/projectsKPMSlice';
import { CLEAR_ALL_PROJECT_MILESTONES } from '../../../reducers/slicers/projectMilestonesSlicer';
import { CLEAR_ALL_PROJECT_GOALS } from '../../../reducers/slicers/projectGoalsSlicer';
import { getUsersByUUID } from '../../../../userFlow/store/action-creators/userActions';

import { getUuidsfromParamsUsers } from '../../../../MainUtils';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';

import useDevScore from '../../../../hooks/projectAuditHooks/useDevScore';
import useOutdatedScore from '../../../../hooks/projectAuditHooks/useOutdatedScore';
import useQaStructureScoring from '../../../../hooks/projectAuditHooks/useQaStructureScoring';
import useQaActivityScoring from '../../../../hooks/projectAuditHooks/useQaActivityScoring';
import useArchiveScore from '../../../../hooks/projectAuditHooks/useArchiveScore';
import usePlanScore from '../../../../hooks/projectAuditHooks/usePlanScore';
import useProjectsFilter from '../utils/useProjectsFilter';
import {
  getQaStructureProjectsArr,
  getQaActivityProjectsArr,
  getOutdatedProjectsArr,
  getArchiveProjectsArr,
} from '../../../reducers/slicers/projectAllScoringSlicer';
import { getProjectsIssuesScoringData } from '../../../reducers/slicers/projectIssueScoringSlicer';

import { defaultConfigProjectsRequest } from '../utils/projectsFilterHelper';
import { partitionNamesConfig } from '../../../../api/appConfig';

const defaultNotSavedData = {
  notSaved: false,
  confirmNotSaved: false,
};

function ProjectsAudit({
  defaultPartition = partitionNamesConfig.partition1,
  initialPathForHistory = '/pm/projects/',
  doNotMakeProjectRequest,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const firstRenderRef = useRef(true);

  const {
    setSearchParams,
    getURLParams,
    routeNavigateAndClearParams,
    clearSearchParams,
  } = useURLParams();

  const userUUID = useSelector(getMe);
  const parentDefault = useSelector((state) => getRootEntityPartition(state, defaultPartition));
  const parentPm = useSelector((state) => getRootEntityPartition(state, partitionNamesConfig.partition1));
  const parentPublic = useSelector((state) => getRootEntityPartition(state, partitionNamesConfig.partition3));

  const projectUUID = useSelector(getProjectUUID);
  const projectFetching = useSelector(getProjectFetching);

  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const { actorMainPublicProjectUUID } = useUserPublicPartition(myPublicUUID, 'mainPublicProjectUUID');

  const { activeProject, activePartition } = getURLParams();

  // const selectedProjectUUID = !isNil(projectUUID) && !isEmpty(projectUUID);
  const { computeAllProjectsQaStructureScore } = useQaStructureScoring();
  const { computeAllProjectsQaActivityScore } = useQaActivityScoring();
  const { computeAllProjectsArchiveScore } = useArchiveScore();
  const { computeAllProjectsOutdatedScore } = useOutdatedScore();
  const { computeAllProjectsDevScore } = useDevScore();
  const { computeProjectsPlanScore } = usePlanScore();

  const devProjArr = useSelector(getProjectsIssuesScoringData);

  const planProjectsArr = useSelector(selectProjectsKPM);
  const qaStructureProjData = useSelector(getQaStructureProjectsArr);
  const qaActivityProjData = useSelector(getQaActivityProjectsArr);
  const outdatedProjData = useSelector(getOutdatedProjectsArr);
  const archiveProjData = useSelector(getArchiveProjectsArr);

  const isPublicPartition = defaultPartition === partitionNamesConfig.partition3;
  const isPmPartition = defaultPartition === partitionNamesConfig.partition1;

  const partitionPerms = useCheckPerm({
    entityUUID: parentDefault,
    partitionType: defaultPartition,
  });

  const {
    requestConfig,
    setRequestConfig,
    loadingIndicator,
    showGeneralProjects,
    showPersonalProjects,
    selectedFilterTags,
    filterByMetricParameters,
    searchTerm,
    onSearchCallback,
    onSelectAreaTagHandler,
    onSelectFilterTagHandler,
    onResetFilterHandler,
    onShowAllFilterHandler,
  } = useProjectsFilter(defaultPartition);
  // console.log('requestConfig state:', requestConfig);

  // const [newProjectFlag, changeNewProjectFlag] = useState(false);
  const [newProjectCreatedCallback, setNewProjectCreatedCallback] = useState(false);
  const [totalCountAll, setTotalCountAll] = useState(0);

  const [finalProjects, setFinalProjects] = useState([]);
  // console.log('!!! all projects:', finalProjects);

  const [notSavedData, setNotSavedData] = useState(defaultNotSavedData);

  const setIsNotSavedDataCallback = (bool) => {
    setNotSavedData({
      ...notSavedData,
      notSaved: bool,
    });
  };

  const getProjectMembers = (uuids) => {
    dispatch(getUsersByUUID({
      uuid: uuids,
      userType: 'project',
    }));
  };

  const getProjectInfo = (uuid, changePageFlag, customPartition) => {
    if (!doNotMakeProjectRequest) {
      dispatch(getProjectRequest(
        uuid,
        (data) => {
          if (data.length && changePageFlag) {
            setNewProjectCreatedCallback(true);
          }
          const users = get(data, '[0].params.users', []);

          if (activeProject !== uuid) {
            setSearchParams({ activeProject: uuid, activePartition: customPartition });
          }
          if (!isEmpty(users)) {
            getProjectMembers(getUuidsfromParamsUsers(users));
          }
        },
        customPartition,
      ));
    }
  };

  const totalCountProjects = useMemo(() => +totalCountAll, [totalCountAll]);

  const checkDevScore = (project) => {
    if (project?.scoringData) {
      return project?.scoringData;
    }
    if (devProjArr?.length) {
      return devProjArr.find((dataObj) => project?.uuid === dataObj?.uuid)?.scoringData;
    }
    return { issuesScoringAVG: '-' };
  };

  const checkIsReduxScoringValues = (projectsArr) => {
    // console.log('archiveProjData', archiveProjData);
    const finalProjectsArr = projectsArr.slice(0).map((project) => ({
      ...project,
      kpm: planProjectsArr.length
        ? planProjectsArr.find((dataObj) => project?.uuid === dataObj?.uuid)?.value
        : null,
      scoringData: checkDevScore(project),
      qaStructureScore: qaStructureProjData?.projectsArr?.length ? qaStructureProjData?.projectsArr.find((dataObj) => project?.uuid === dataObj?.uuid)?.qaStructureScore : '-',
      qaActivityScore: qaActivityProjData?.projectsArr?.length ? qaActivityProjData?.projectsArr.find((dataObj) => project?.uuid === dataObj?.uuid)?.qaActivityScore : '-',
      outdatedScore: outdatedProjData?.projectsArr?.length ? outdatedProjData?.projectsArr.find((dataObj) => project?.uuid === dataObj?.uuid)?.outdatedScore : '-',
      archiveScore: archiveProjData?.projectsArr?.length ? archiveProjData?.projectsArr.find((dataObj) => project?.uuid === dataObj?.uuid)?.archiveScore : '-',
    }));
    // console.log('finalProjectsArr', finalProjectsArr);
    return finalProjectsArr;
  };

  const getConfigAndGetProjectList = async () => {
    const projectRequests = [];
    const generalProjects = { data: [], total: 0 };
    const personalProjects = { data: [], total: 0 };
    const allProjects = { data: [], total: 0 };

    const fieldsConfig = {
      fields: {
        actor: 'actor',
        owner: 'owner',
        'owner.uinfo': 'owner_uinfo',
        'entity.created': 'created',
        modified: 'modified',
      },
      params_fields: {
        id: 'id',
        removed: 'removed',
        title: 'title',
        status: 'status',
        areas: 'areas',
        type: 'type',
        users: 'users',
        // scoringData: 'scoringData',
        descriptionDoc: 'descriptionDoc',
        infrastructureDoc: 'infrastructureDoc',
        issuesDevScore: 'issuesDevScore',
        gmPlanScore: 'gmPlanScore',
        outdatedDevScore: 'outdatedDevScore',
        qaStructureScoring: 'qaStructureScoring',
        qaActivityScoring: 'qaActivityScoring',
        archiveScoring: 'archiveScoring',
      },
    };

    // console.log('requestConfig:', requestConfig);

    const commonConfig = {
      ...requestConfig,
      ...fieldsConfig,
    };

    // console.log('common config:', commonConfig);

    if (showGeneralProjects && isPmPartition) {
      const generalConfig = {
        ...commonConfig,
        partition: partitionNamesConfig.partition1,
        parent: parentPm,
      };
      // console.log('general config:', generalConfig);

      const generalProjectsRequest = async (dispatch) => dispatch(
        getListOfProjects(generalConfig),
      )
        .then((res) => {
          generalProjects.data = res?.data?.map((el) => ({ ...cloneDeep(el), partition: partitionNamesConfig.partition1 })) || [];
          generalProjects.total = res?.total;
        }).catch((err) => {
          dispatch({
            type: ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_FAILURE,
            payload: [],
          });
        });

      projectRequests.push(generalProjectsRequest);
    }

    if (showPersonalProjects) {
      const personalConfig = {
        ...commonConfig,
        constants: [
          ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_REQUEST,
          ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_SUCCESS,
          ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_FAILURE,
        ],
        depth: 0,
        partition: partitionNamesConfig.partition3,
        parent: parentPublic,
      };
      // console.log('personal config:', personalConfig);

      const personalProjectsRequest = async (dispatch) => dispatch(
        getListOfProjects(personalConfig),
      )
        .then((res) => {
          personalProjects.data = res?.data?.map((el) => ({ ...cloneDeep(el), partition: partitionNamesConfig.partition3 }));
          personalProjects.total = res?.total;
        })
        .catch((err) => {
          dispatch({
            type: ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_FAILURE,
            payload: [],
          });
        });

      projectRequests.push(personalProjectsRequest);
    }

    await Promise.all(projectRequests.map(
      (request) => dispatch(request),
    ));

    allProjects.data = [
      ...generalProjects.data,
      ...personalProjects.data,
    ];

    allProjects.data = filterByMetricParameters(allProjects.data);
    allProjects.total = allProjects.data.length;

    // const finalProjectsData = checkIsReduxScoringValues(allProjects.data);
    // console.log('allProjects', allProjects);
    // console.log('checkIsShowArchiveProjects', checkIsShowArchiveProjects);

    setFinalProjects(allProjects.data);
    setTotalCountAll(allProjects.total);
  };

  const initFunc = async () => {
    setRequestConfig(defaultConfigProjectsRequest(userUUID));
  };

  const deleteProjectCallback = () => {
    dispatch(clearProject);
    clearSearchParams(['activeProject']);
    setRequestConfig({ ...requestConfig });
  };

  const quickViewProjectCallback = (uuid, customPartition) => {
    setSearchParams({ activeProject: uuid, activePartition: customPartition });
    getProjectInfo(uuid, false, customPartition);
  };

  // Not used now (but better save for future)
  const changeSortCallback = ({ order, orderBy, isParamSorted }) => {
    const orderConfig = {
      order,
    };

    orderConfig[isParamSorted ? 'order_by_params' : 'order_by'] = orderBy;

    setRequestConfig({
      ...requestConfig,
      ...orderConfig,
      offset: 0,
    });
  };

  const handleComputePlanScore = async (isAllScoring) => {
    const allProjectsCopy = Array.isArray(finalProjects) ? finalProjects.slice(0) : [];
    const resp = await computeProjectsPlanScore(allProjectsCopy, isAllScoring);
    // console.log('handleComputePlanScore resp', resp);
    if (!isAllScoring) setFinalProjects(resp);
    else return resp;
  };

  const handleComputeDevScore = async (isAllScoring) => {
    const allProjectsCopy = Array.isArray(finalProjects) ? finalProjects.slice(0) : [];
    const resp = await computeAllProjectsDevScore(allProjectsCopy, isAllScoring);
    // console.log('handleComputeDevScore resp', resp);
    if (!isAllScoring) {
      setFinalProjects(resp);
    } else {
      return resp;
    }
  };

  const handleComputeOutdatedScore = async (isAllScoring) => {
    const allProjectsCopy = Array.isArray(finalProjects) ? finalProjects.slice(0) : [];
    const resp = await computeAllProjectsOutdatedScore(allProjectsCopy, isAllScoring);
    if (!isAllScoring) {
      setFinalProjects(resp);
    } else {
      // console.log('handleComputeOutdatedScore resp', resp);
      return resp;
    }
  };

  const handleComputeArchiveScore = async (isAllScoring) => {
    try {
      const allProjectsCopy = Array.isArray(finalProjects) ? finalProjects.slice(0) : [];
      // console.log('handleComputeArchiveScore dataForCompute', dataForCompute);
      const resp = await computeAllProjectsArchiveScore(allProjectsCopy, isAllScoring);

      if (!isAllScoring) {
        setFinalProjects(resp);
      } else {
        // console.log('handleComputeOutdatedScore resp', resp);
        return resp;
      }
    } catch (e) {
      console.log('handleComputeArchiveScore e', e);
    }
  };

  const handleComputeQaStructureScore = async (isAllScoring) => {
    const allProjectsCopy = Array.isArray(finalProjects) ? finalProjects.slice(0) : [];
    const resp = await computeAllProjectsQaStructureScore(allProjectsCopy, isAllScoring);
    // console.log('handleComputeQaStructureScore', resp);
    if (!isAllScoring) {
      setFinalProjects(resp);
    } else {
      // console.log('handleComputeOutdatedScore resp', resp);
      return resp;
    }
  };

  const handleComputeQaActivityScore = async (isAllScoring) => {
    const allProjectsCopy = Array.isArray(finalProjects) ? finalProjects.slice(0) : [];
    const resp = await computeAllProjectsQaActivityScore(allProjectsCopy, isAllScoring);
    // console.log('handleComputeQaStructureScore', resp);
    if (!isAllScoring) {
      setFinalProjects(resp);
    } else {
      // console.log('handleComputeOutdatedScore resp', resp);
      return resp;
    }
  };

  const handleComputeAllScore = async () => {
    const requestsArr = [
      handleComputePlanScore,
      handleComputeDevScore,
      handleComputeOutdatedScore,
      handleComputeQaStructureScore,
      handleComputeQaActivityScore,
      handleComputeArchiveScore,
    ];
    const data = await Promise.all(requestsArr.map(async (request) => request(true)));
    // console.log('data:', data);
    const planScoreData = data[0];
    // .map((item) => ({ uuid: item?.uuid, kpm: item?.kpm }));
    const devScoreData = data[1];
    // .map((item) => ({ uuid: item?.uuid, scoringData: item?.scoringData }));
    const outdatedScoreData = data[2];
    // .map((item) => ({ uuid: item?.uuid, outdatedScore: item?.outdatedScore }));
    const qaStructureScoreData = data[3];
    // .map((item) => ({ uuid: item?.uuid, qaStructureScore: item?.qaStructureScore }));
    const qaActivityScoreData = data[4];
    // .map((item) => ({ uuid: item?.uuid, qaActivityScore: item?.qaActivityScore }));
    const archiveScoreData = data[5];
    // .map((item) => ({ uuid: item?.uuid, archiveScore: item?.archiveScore }));
    // const finalProjectsArray = finalProjects.map((project) => ({
    //   ...project,
    //   issuesDevScore: devScoreData
    //     .find((item) => item?.uuid === project?.uuid),
    //   gmPlanScore: planScoreData
    //     .find((item) => item?.uuid === project?.uuid),
    //   outdatedDevScore: outdatedScoreData
    //     .find((item) => item?.uuid === project?.uuid),
    //   qaStructureScoring: qaStructureScoreData
    //     .find((item) => item?.uuid === project?.uuid),
    //   qaActivityScoring: qaActivityScoreData
    //     .find((item) => item?.uuid === project?.uuid),
    //   archiveScoring: archiveScoreData
    //     .find((item) => item?.uuid === project?.uuid),
    // }));
    await Promise.all(finalProjects.map(async (project) => {
      const data = {
        uuid: project?.uuid,
        partition: project?.partition,
        params: {
          issuesDevScore: devScoreData
            .find((item) => item?.uuid === project?.uuid)?.issuesDevScore,
          gmPlanScore: planScoreData
            .find((item) => item?.uuid === project?.uuid)?.gmPlanScore,
          outdatedDevScore: outdatedScoreData
            .find((item) => item?.uuid === project?.uuid)?.outdatedDevScore,
          qaStructureScoring: qaStructureScoreData
            .find((item) => item?.uuid === project?.uuid)?.qaStructureScoring,
          qaActivityScoring: qaActivityScoreData
            .find((item) => item?.uuid === project?.uuid)?.qaActivityScoring,
          archiveScoring: archiveScoreData
            .find((item) => item?.uuid === project?.uuid)?.archiveScoring,
        },
      };
      return dispatch(updateProjectScoring(data));
    }));

    const finalProjectsData = finalProjects.map((project) => ({
      ...project,
      issuesDevScore: devScoreData
        .find((item) => item?.uuid === project?.uuid)?.issuesDevScore,
      gmPlanScore: planScoreData
        .find((item) => item?.uuid === project?.uuid)?.gmPlanScore,
      outdatedDevScore: outdatedScoreData
        .find((item) => item?.uuid === project?.uuid)?.outdatedDevScore,
      qaStructureScoring: qaStructureScoreData
        .find((item) => item?.uuid === project?.uuid)?.qaStructureScoring,
      qaActivityScoring: qaActivityScoreData
        .find((item) => item?.uuid === project?.uuid)?.qaActivityScoring,
      archiveScoring: archiveScoreData
        .find((item) => item?.uuid === project?.uuid)?.archiveScoring,
    }));
    // console.log('finalProjectsData:', finalProjectsData);

    setFinalProjects(finalProjectsData);
  };

  const resetProjectCallback = () => {
    dispatch(clearProject);
    clearSearchParams(['activeProject', 'activePartition']);
    if (isPmPartition) routeNavigateAndClearParams('/pm/audit');
    if (isPublicPartition) routeNavigateAndClearParams(`/public/user/${myPublicUUID}/audit/`);
  };

  useEffect(() => () => {
    axiosRequest.abort('', ProjectTypeCommonConstants.FETCH_LIST_OF_PROJECT_REQUEST);
    axiosRequest.abort('', ProjectTypeCommonConstants.GET_LIST_OF_PERSONAL_PROJECTS_REQUEST);
    dispatch(clearProject);
    dispatch(clearListOfProjects);
    dispatch(clearListOfPersonalProjects);
    // dispatch(SET_KPM_COMPUTED_TIME('reset'));
    dispatch(CLEAR_ALL_PROJECT_GOALS());
    dispatch(CLEAR_ALL_PROJECT_MILESTONES());
  }, []);

  useEffect(() => {
    if (!requestConfig && parentDefault && userUUID) {
      initFunc();
    }
  }, [parentDefault, userUUID]);

  useEffect(() => {
    if (parentDefault
      && userUUID
      && parentPublic
      && actorMainPublicProjectUUID
      && requestConfig
    ) {
      // console.log('userUUID', userUUID);
      getConfigAndGetProjectList();
      // firstRenderRef.current = false;
    }
  }, [userUUID,
    requestConfig,
    parentDefault,
    parentPublic,
    actorMainPublicProjectUUID,
  ]);

  useEffect(() => {
    if (activePartition && activeProject) {
      getProjectInfo(activeProject, false, activePartition);
    }
    return () => dispatch({ type: ProjectTypeCommonConstants.SET_PROJECTS_FETCHING });
  }, []);

  return (
    <div className={isPublicPartition ? 'w-full mt-3' : 'w-full'}>
      <Row className={isPublicPartition ? 'hidden' : 'mb-3'}>
        <Col>
          <InfoTip
            title="Project audit"
            text={t(
              'wms.projectflow.projects.help.hint',
              'Project flow describes a preset sequence of activities required to plan, produce, deliver'
              + ' and maintain project product, along with information, materials, and resources required by '
              + 'the project.',
            )}
          />
        </Col>
      </Row>
      <Row className={isPublicPartition ? 'hidden' : ''}>
        <Col span={24} className="py-1">
          <BreadCrumbsNEW />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="flex">
          <Col span={24}>
            <ListOfProjectsForAudit
              perms={partitionPerms}
              totalCount={totalCountProjects}
              allData={finalProjects}
              partition={defaultPartition}
              partitionUUID={parentDefault}
              initialPathForHistory={initialPathForHistory}
              loadingIndicator={loadingIndicator}
              searchTerm={searchTerm}
              selectedFilterTags={selectedFilterTags}
              onComputeKPM={handleComputePlanScore}
              onComputeKPI={handleComputeDevScore}
              onComputeOutdated={handleComputeOutdatedScore}
              onComputeArchive={handleComputeArchiveScore}
              onComputeQaStructure={handleComputeQaStructureScore}
              onComputeQaActivity={handleComputeQaActivityScore}
              onComputeAllScore={handleComputeAllScore}
              onResetFilter={onResetFilterHandler}
              onShowAllFilter={onShowAllFilterHandler}
              onSelectFiltersCallback={onSelectFilterTagHandler}
              onSelectTagsCallback={onSelectAreaTagHandler}
              onSearchProjectCallback={onSearchCallback}
              changeSortCallback={changeSortCallback}
              newProjectCreatedCallback={newProjectCreatedCallback}
              quickViewProjectCallback={quickViewProjectCallback}
              setNewProjectCreatedCallback={setNewProjectCreatedCallback}
              // optionalSelectedFilters={optionalSelectedFilters}
            />
          </Col>

          {activeProject && activePartition && (
          <Modal
            open
            footer={null}
            destroyOnClose
            closable={false}
            onCancel={resetProjectCallback}
            width={820}
          >
            <Spin spinning={projectFetching}>
              <Col span={24}>
                <ProjectInfo
                  partitionType={activePartition}
                  setIsNotSavedDataCallback={setIsNotSavedDataCallback}
                  notSavedData={notSavedData}
                  setNotSavedData={setNotSavedData}
                  updateProjectCallback={getConfigAndGetProjectList}
                  view="projectFlow"
                  deleteEntityCallback={deleteProjectCallback}
                  pathGoToTask={activePartition === partitionNamesConfig.partition1 ? {
                    pathname: `${initialPathForHistory}issuesnew`,
                    search: `?activeProject=${projectUUID}`,
                  } : {
                    pathname: `/public/user/${myPublicUUID}/projects/issuesnew`,
                    search: `?activeProject=${projectUUID}`,
                  }}
                  resetProjectCallback={resetProjectCallback}
                  isInModal
                />
              </Col>
            </Spin>
          </Modal>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ProjectsAudit;

ProjectsAudit.propTypes = {
  defaultPartition: PropTypes.string,
  doNotMakeProjectRequest: PropTypes.bool,
  initialPathForHistory: PropTypes.string,
};
