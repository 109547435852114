import { useDispatch, useSelector } from "react-redux";
import { setNotificationSenderFilter } from "../../projectFlow/actions/PtcActionsForHook";
import { getSenderFilterNotification } from "../../projectFlow/selectors/selectors";
import { useTranslation } from "react-i18next";

import { Row, Col, Tooltip, Avatar } from "antd";
import BaseButton from "../../components/_ui/BaseButton/BaseButton";
import Icon from "@mdi/react";
import { timeToCalendar } from "../../MainUtils";
import { capitalize, get } from "lodash";
import { UserOutlined } from "@ant-design/icons";

export default function SenderSelectListItem(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    uuid,
    user_data,
    last_message,
    unread_messages,
    onMarkAsViewed,
    toggleVisible,
    handleSenderCallback,
  } = props;
  const { first_name, last_name, email } = user_data;

  const filterBySender = useSelector(getSenderFilterNotification);

  const handleSetSenderFilter = () => {
    const data = { uuid: uuid, ...user_data };
    dispatch(setNotificationSenderFilter(data));
    toggleVisible();
    handleSenderCallback();
  };

  return (
    <div
      key={uuid}
      role="button"
      tabIndex="0"
      className={`cursor-pointer customNotifications-wrapper ${filterBySender?.uuid === uuid ? 'selected' : ''}`}
      onClick={handleSetSenderFilter}
      style={{ minWidth: "350px" }}
    >
      <Row className="flex justify-start">
        <Col
          span={4}
          className={"flex items-center" + " justify-center"}
        >
          <Avatar
            size={45}
            style={{
              backgroundColor: !unread_messages ? "#bfbfbf" : "#1890ff",
            }}
          >
            <UserOutlined />
          </Avatar>
        </Col>
        <Col span={16} className="px-2 flex items-center">
          <h6
            style={{ color: !unread_messages ? "#bfbfbf" : "#1890ff" }}
            className="mb-2"
          >
            {/* {capitalize(t(data.title))} */}
            {first_name} {last_name}{" "}
            {unread_messages ? `(${unread_messages})` : ""}
          </h6>
        </Col>
      </Row>
    </div>
  );
}
