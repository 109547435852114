import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { bool, func } from 'prop-types';
import { Modal } from 'antd';

import { useTranslation } from 'react-i18next';
import BaseModal from '../../components/_ui/BaseModal/BaseModal';
import { capitalize } from 'lodash';

function BtnModal({
  afterClose,
  blockPropagation,
  btnComponent,
  children,
  className,
  classNameModal,
  confirmDescription,
  confirmOnClose = false,
  destroyOnClose = true,
  footer,
  handleCancelCallbackFunc,
  openFromOuterComponent = false,
  getContainer,
  style,
  zIndex,
  title,
  width,
  closable,
}) {
  const { t } = useTranslation();
  const [modalFlag, toggleModal] = useState(false);
  const handleToggleModal = () => toggleModal(!modalFlag);

  useEffect(() => {
    if (openFromOuterComponent) {
      handleToggleModal();
    }
  }, [openFromOuterComponent]);

  const propagation = (e) => (blockPropagation ? e.stopPropagation() : e);

  const [confirmFlag, setConfirmFlag] = useState(false);

  const showConfirm = () => {
    Modal.confirm({
      title,
      width: '600px',
      content: confirmDescription,
      zIndex,
      okText: capitalize(t('wms.buttons.remove.yes', 'yes')),
      okType: 'danger',
      cancelText: capitalize(t('wms.buttons.remove.no', 'no')),
      open: confirmFlag,
      centered: true,
      mask: false,
      okButtonProps: { className: 'dangerButton' },

      onOk() {
        setConfirmFlag(false);
        handleToggleModal();
        if (handleCancelCallbackFunc) {
          handleCancelCallbackFunc();
        }
      },

      onCancel() {
        setConfirmFlag(false);
      },
    });
  };

  const cancelBtnCallbackInChildren = () => {
    console.log('aaa');
    
    if (handleCancelCallbackFunc) {
      handleCancelCallbackFunc();
    }
    toggleModal(false);
  };

  const onClickOpenModal = (e) => {
    propagation(e);
    handleToggleModal();
  };

  const onCancelModal = (e) => {
    propagation(e);

    if (confirmOnClose) {
      setConfirmFlag(true);
      showConfirm();
    } else {
      toggleModal(false);
      if (handleCancelCallbackFunc) {
        handleCancelCallbackFunc();
      }
    }
  };
  const formRef = useRef(null);

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      console.log('Clicked outside the form');
      // Выполните необходимые действия, например, закрытие формы
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const styleModals = confirmFlag ? { ...style, opacity: 0 } : style;
  const stopPropagation = (e) => propagation(e);

  return (
    <>
      <BaseModal
        title={title}
        open={modalFlag}
        className={classNameModal}
        onClick={propagation}
        destroyOnClose={destroyOnClose}
        handleCancel={onCancelModal}
        handleOk={handleToggleModal}
        getContainer={getContainer}
        zIndex={zIndex}
        width={width}
        centered
        footer={footer}
        style={styleModals}
        afterClose={afterClose}
        closable={closable}
      >
        <div>
          {modalFlag && (
            <div onClick={stopPropagation}>
              {React.cloneElement(children, {
                handleOk: handleToggleModal,
                toggleModal: handleToggleModal,
                cancelBtnCallbackInChildren,
                handleCancel: handleToggleModal,
              })}

            </div>
          )}
        </div>
      </BaseModal>

      {btnComponent && (
        <span
          className={`flex h-fit ${className}`}
          onClick={onClickOpenModal}
        >
          {btnComponent}
        </span>
      )}

    </>
  );
}

BtnModal.propTypes = {
  afterClose: PropTypes.func,
  blockPropagation: PropTypes.bool,
  btnComponent: PropTypes.element,
  children: PropTypes.element,
  className: PropTypes.string,
  confirmDescription: PropTypes.string,
  confirmOnClose: PropTypes.bool,
  destroyOnClose: PropTypes.bool,
  footer: PropTypes.any,
  handleCancelCallbackFunc: PropTypes.any,
  openFromOuterComponent: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zIndex: PropTypes.number,
  getContainer: func,
  closable: bool,
};

export default BtnModal;
