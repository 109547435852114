import React, { useEffect, useState } from 'react';
import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { Flex, Row } from 'antd';
import { any, func, string } from 'prop-types';

let timerSearch;

export default function AssignUsersHeader({
  header = 'Assigning users',
  valueSearch = '',
  setValueSearch,
}) {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value);
    clearTimeout(timerSearch);
    timerSearch = setTimeout(() => {
      setValueSearch(e.target.value.trim());
    }, 700);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      clearTimeout(timerSearch);
      setValueSearch(e.target.value);
    }
  };

  useEffect(() => {
    if (valueSearch !== value) {
      setValue(valueSearch);
    }
  }, [valueSearch]);

  return (
    <>
      <Flex
        className='w-full'
        align="center"
        style={{ fontSize: '16px' }}
      >
        {header}
      </Flex>
      <Flex
        align="center"
        className="w-full pb-2 pt-1"
        style={{
          borderBottom: '1px solid #DCDCDC',
        }}
      >
        <Icon path={mdiMagnify} size={1} color="#BEBEBE" />
        <input
          className="header-search-assigned-users ml-2"
          value={value}
          style={{
            color: valueSearch?.length > 0 && 'black',
          }}
          placeholder="Search"
          onChange={handleChange}
          onKeyDown={onKeyDown}
        />
      </Flex>
    </>
  );
}

AssignUsersHeader.propTypes = {
  valueSearch: string,
  header: any,
  setValueSearch: func,
};
