import { Tag, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getAreas } from '../../../selectors/selectors';

import './AreasList.scss';
import BaseTag from "../../../../components/_ui/BaseTag/BaseTag";

function AreasList({
  areasFromTable,
  areas,
  onlyProjectAreas = false,
}) {
  const allAreas = useSelector(getAreas);

  const { t } = useTranslation();

  const [collapsed, setCollapsed] = useState(false);

  let filteredAreas = areas.filter(
    (item) => typeof item !== 'object'
      && !isNil(item)
      && !isEmpty(item),
  );

  if (onlyProjectAreas) {
    filteredAreas = filteredAreas.filter((item) => allAreas.some(
      (area) => area?.name === item,
    ));
  }

  const collapseHandler = (e) => {
    e.stopPropagation();
    setCollapsed((prev) => !prev);
  };

  const collapseAreas = useMemo(() => {
    if (collapsed) {
      return filteredAreas;
    }
    return filteredAreas.slice(0, 1);
  }, [collapsed, areas]);

  return (
    <div className="flex flex-wrap">
      {areasFromTable
        ? (
          collapseAreas
            ? collapseAreas.map((area, id) => {
              const currentLabel = area.length > 16
                ? `${area.slice(0, 16)}...`
                : area;
              return (
                <div key={`area${id}`}>
                  <Tooltip
                    placement="left"
                    title={area.length > 16 ? area : ''}
                  >
                    <BaseTag
                      className="rounded-2xl m-1 border-area"
                    >
                      {currentLabel}
                    </BaseTag>
                  </Tooltip>
                </div>
              );
            })
            : null
        )
        : (
          filteredAreas
            ? filteredAreas.map((area, id) => {
              const currentLabel = area.length > 16
                ? `${area.slice(0, 16)}...`
                : area;
              return (
                <div key={`area${id}`}>
                  <Tooltip
                    placement="left"
                    title={area.length > 16 ? area : ''}
                  >
                    <BaseTag
                      className="rounded-2xl m-1 border-area"
                    >
                      {currentLabel}
                    </BaseTag>
                  </Tooltip>
                </div>
              );
            })
            : null
        )}

      {filteredAreas.length > 1 && areasFromTable && (
      <div
        className="areasFromTable-button-icon"
        onClick={collapseHandler}
      >
        <Icon
          path={collapseAreas.length > 1 ? mdiChevronLeft : mdiChevronRight}
          size={1}
        />
      </div>
      )}
    </div>
  );
}

AreasList.propTypes = {
  addButton: PropTypes.any,
  addButtonCallback: PropTypes.any,
  areas: PropTypes.any,
  onlyProjectAreas: PropTypes.bool,
};

export default AreasList;
