import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ListOfActorsTags from '../../entity/components/ListOfActorsTags';
import ActorsModalBtn from '../../entity/components/ActorsModalBtn';

import { getDefaultGroup } from '../../actors/slices/defaultGroupsSlice';

function AddActorsForNewActor({
  actorType = 'user',
  btnModalLabel,
  hideElements,
  onSaveCallBack,
}) {
  const defaultGroup = useSelector(getDefaultGroup);

  const initLocalActors = actorType === 'user' || actorType === 'classic_user' ? [defaultGroup] : [];

  const [localActors, setLocalActors] = useState(initLocalActors);

  const onSaveCallBackAndSetLocalActors = (actors) => {
    const { selectedActors } = actors;
    setLocalActors(selectedActors);

    onSaveCallBack?.(actors);
  };

  const actorsUUIDs = localActors?.map((item) => item.uuid);

  return (
    <div className="ml-auto">
      <ActorsModalBtn
        btnSize="small"
        currentActorsUUIDs={actorsUUIDs}
        actorType={actorType}
        btnModalLabel={btnModalLabel}
        hideElements={hideElements}
        doNotMakeRequest={['saveActors']}
        onSaveCallBack={onSaveCallBackAndSetLocalActors}
      />
      <ListOfActorsTags
        actors={localActors}
      />
    </div>
  );
}

export default AddActorsForNewActor;

AddActorsForNewActor.propTypes = {
  actorType: PropTypes.string,
  btnModalLabel: PropTypes.string,
  hideElements: PropTypes.array,
  onSaveCallBack: PropTypes.func,
};
