import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { getLocale } from "../../../../locale/selectors";

import { List } from "antd";

import PropTypes from "prop-types";

export const HistoryListItem = ({
    item,
    selectedState,
    currentActualState,
    selectVersionHandler
}) => {
    const lang = useSelector(getLocale);

    const { version_id: versionID, createdBy, timestamp, from_state_id } = item;

    let dateLangFormat;
    switch (lang) {
        case "ru": {
            dateLangFormat = "DD-MM-YYYY, kk:mm:ss";
            break;
        }
        case "en":
        default: {
            dateLangFormat = "D MMMM YYYY, hh:mm:ss A";
            break;
        }
    }

    const versionDate = timestamp
        ? moment(timestamp).locale(lang).format(dateLangFormat)
        : null;
    const selected = versionID === selectedState;
    const currentVersion = versionID === currentActualState;
    // console.log('crdt selectedState', selectedState);
    // console.log('crdt currentActualState', currentActualState);
    const descriptionData = (
        <>
            {currentVersion && (
                <>
                    <span className="current-version mb-1">Current version</span>
                    <br />
                </>
            )}
            {timestamp && (
                <>
                    {versionDate}
                    <br />
                </>
            )}
            {createdBy && (
                <>
                    created by: &nbsp;
                    {createdBy}
                </>
            )}
             {from_state_id && (
                <>
                    from state: &nbsp;
                    {from_state_id}
                </>
            )}
        </>
    );
    return (
        <List.Item
            className={`${selected ? "--selected" : ""}`}
            data-version={versionID}
            onClick={selectVersionHandler}
        >
            <List.Item.Meta                
                title={<div className="version-title">{`Version ${versionID}`}</div>}
                description={descriptionData}
            />
            {selected && <div className="selected-marker" />}
        </List.Item>
    );
};

HistoryListItem.propTypes = {
    item: PropTypes.object,
    selectedState: PropTypes.string,
    currentActualState: PropTypes.string,
    selectVersionHandler: PropTypes.func
};

export default HistoryListItem;
