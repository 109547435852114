import {object, string} from 'prop-types';
import React from 'react';
import { mdiLink } from '@mdi/js';
import { message, Tooltip } from 'antd';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

import CopyToClipboardBtn54origins from '../54origins/components/CopyToClipboardBtn54origins';
import { partitionNamesConfig } from '../api/appConfig';

function CopyURL({
  className,
  partition,
  placement,
  entityType,
  entityUUID,
  qaEntityParent,
  buttonStyle = {},
}) {
  const { t } = useTranslation();
  const location = useLocation();

  const splitPath = location.pathname.split('/');
  const userUUIDFromUrl = splitPath[splitPath.indexOf('user') + 1];

  // Парсинг ссылки в компоненте ShortLinksRedirect

  const publicLinks = () => {
    const getLink = (shortEntityName) => `pb/u=${userUUIDFromUrl}/${shortEntityName}=${entityUUID}`;
    const getQALink = (shortEntityName) => `pb/u=${userUUIDFromUrl}/${shortEntityName}=${entityUUID}=${qaEntityParent}`;

    switch (entityType) {
      case 'projectIdea': return getLink('n');
      case 'qaDocument': return getLink('qad');
      case 'projectDocument': return getLink('d');
      case 'projectSpreadsheet': return getLink('s');
      case 'workConfig': return getLink('wc');
      case 'vector': return getLink('v');
      case 'issue': return getLink('i');
      case 'goal': return getLink('g');
      case 'milestone': return getLink('ml');
      case 'testSuite': return getQALink('ts');
      case 'testCycle': return getQALink('tc');
      case 'checklist': return getQALink('cl');

      default: return `pb/u=${userUUIDFromUrl}/p=${entityUUID}`;
    }
  };

  const pmLinks = () => {
    switch (entityType) {
      case 'projectIdea': return `pm/n=${entityUUID}`;
      case 'qaDocument': return `pm/qad=${entityUUID}`;
      case 'projectDocument': return `pm/d=${entityUUID}`;
      case 'projectSpreadsheet': return `pm/s=${entityUUID}`;
      case 'vector': return `pm/v=${entityUUID}`;
      case 'issue': return `pm/i=${entityUUID}`;
      case 'goal': return `pm/g=${entityUUID}`;
      case 'milestone': return `pm/ml=${entityUUID}`;
      case 'testSuite': return `pm/ts=${entityUUID}=${qaEntityParent}`;
      case 'testCycle': return `pm/tc=${entityUUID}=${qaEntityParent}`;
      case 'checklist': return `pm/cl=${entityUUID}=${qaEntityParent}`;

      default: return `pm/p=${entityUUID}`;
    }
  };

  const getPath = () => {
    if (partition === partitionNamesConfig.partition1) {
      return pmLinks();
    }
    return publicLinks();
  };

  return (
    <Tooltip
      placement={placement || 'topLeft'}
      title={capitalize(t('wms.labels.copy_short_link'))}
      color="orange"
    >
      <>
        <CopyToClipboardBtn54origins
          id={`copyShortLinkButton${entityType}`}
          btnClassName={`view_btn_link__orange ${className}`}
          disableDefaultLabel
          iconPath={mdiLink}
          iconSize={1}
          shape="circle"
          copySuccessCallback={() => message.success('Link copied')}
          text={`${window.location.origin}/s/${getPath()}`}
        />
      </>
    </Tooltip>
  );
}

CopyURL.propTypes = {
  className: string,
  partition: string,
  placement: string,
  entityType: string,
  entityUUID: string,
  buttonStyle: object,
};

export default CopyURL;
