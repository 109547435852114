import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mdiBellOutline, mdiEmailCheckOutline, mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import { capitalize, get } from 'lodash';

import {
  Badge, Card, Col, Dropdown, Empty, Row,
} from 'antd';

import BaseButton from '../components/_ui/BaseButton/BaseButton';
import SenderListItem from './GroupInterface/SenderListItem';

import axiosRequest from '../api/apiAxios';
import { ProjectTypeCommonConstants } from '../projectFlow/constants/Constants';
import { notificationUrl } from '../api/appConfig';
import { getUserPublicProfileUUID } from '../userFlow/store/selectors/selectors';
import { getUnseenNotifications } from '../projectFlow/selectors/selectors';
import useURLParams from '../hooks/useURLParams';
import BaseCard from "../components/_ui/BaseCard/BaseCard";

function NotificationsDropDown({ onMarkAsViewed, onMarkAsViewedAll }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { routeNavigateAndClearParams } = useURLParams();

  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);
  const unseenNotifications = useSelector(getUnseenNotifications);

  const [visible, setVisible] = useState(false);
  const [viewedNotifications, setViewedNotifications] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [fetching, setFetching] = useState(true);

  const [senders, setSenders] = useState([]);

  const toggleVisible = () => setVisible((prev) => !prev);

  const onClickAllNotifications = () => {
    routeNavigateAndClearParams(
      `/public/user/${myPublicEntityUUID}/dashboard`,
      {
        activeCard: 'notifications',
      },
    );
    toggleVisible();
  };

  const menu = [
    {
      key: 'notifications',
      className: 'p-0',
      label: (
        <BaseCard className="customNotifications-card" loading={fetching}>
          <Row className="customNotifications-head flex justify-between py-3">
            <Col span={12} className="pl-3">
              {myPublicEntityUUID && (
                <BaseButton
                  size="small"
                  className="btnPrimary-outline"
                  onClick={onClickAllNotifications}
                >
                  <Icon path={mdiOpenInNew} size={0.7} className="mr-1" />
                  {capitalize(
                    t(
                      'notifications.buttons.all_notifications',
                      'all notifications',
                    ),
                  )}
                </BaseButton>
              )}
            </Col>
            <Col span={12} className="flex justify-end pr-3">
              <BaseButton
                size="small"
                className="btnPrimary-outline"
                disabled={unseenNotifications.length === 0}
                onClick={() => {
                  onMarkAsViewedAll();
                  toggleVisible();
                }}
              >
                <Icon path={mdiEmailCheckOutline} size={0.7} className="mr-1" />
                {capitalize(
                  t('notifications.buttons.mark_all_read', 'mark all as read'),
                )}
              </BaseButton>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="customNotifications-body">
              {senders?.length !== 0 ? (
                // viewedNotifications.map(notificationItemRender)
                senders.map((sender) => (
                  <div key={sender?.uuid}>
                    <SenderListItem
                      {...sender}
                      onMarkAsViewed={onMarkAsViewed}
                      toggleVisible={toggleVisible}
                    />
                  </div>
                ))
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={capitalize(
                    t('wms.labels.empty_not_viewed_notifications'),
                  )}
                />
              )}
              {/* <ShowMoreBtn
                show={totalCount > viewedNotifications.length}
                toggleShowMoreCallback={toggleShowMore}
              /> */}
            </Col>
          </Row>
        </BaseCard>
      ),
    },
  ];

  const getAllMentionedProjects = async () => {
    dispatch(
      axiosRequest.get(
        'projects_where_mentioned/',
        [
          ProjectTypeCommonConstants.GET_MENTIONED_PROJECTS_REQUEST,
          ProjectTypeCommonConstants.GET_MENTIONED_PROJECTS_SUCCESS,
          ProjectTypeCommonConstants.GET_MENTIONED_PROJECTS_FAILURE,
        ],
        {
          doNotCheckError: true,
          onSuccess: (response) => {
            getAllSenders(response?.data);
          },
          tokenName: 'Notification',
          url: notificationUrl,
        },
      ),
    );
  };

  const getAllSenders = async (projects_where_mentioned) => {
    const basic_filter_uuids = [...projects_where_mentioned]?.map(
      (project) => project?.project_uuid,
    );
    const basic_filter_string = `basic_uuid=${basic_filter_uuids.join(
      '&basic_uuid=',
    )}`;

    dispatch(
      axiosRequest.get(
        `/senders/?limit=${limit || 100}&offset=${
          offset || 0
        }&${basic_filter_string}`,
        [
          ProjectTypeCommonConstants.GET_SENDERS_REQUEST,
          ProjectTypeCommonConstants.GET_SENDERS_SUCCESS,
          ProjectTypeCommonConstants.GET_SENDERS_FAILURE,
        ],
        {
          doNotCheckError: true,
          onSuccess: (response) => {
            const finalData = Array.isArray(response?.data)
              ? response?.data?.sort(
                (sender1, sender2) => sender2?.unread_messages - sender1?.unread_messages,
              )
              : [];
            setSenders(finalData);
          },
          tokenName: 'Notification',
          url: notificationUrl,
        },
      ),
    );
  };

  const toggleDropdownBtn = () => {
    toggleVisible();
    if (!visible) {
      initFunc();
    } else {
      setOffset(0);
      setLimit(10);
      setFetching(true);
      setViewedNotifications([]);
    }
  };

  const initFunc = () => {
    dispatch(
      axiosRequest.get(
        `notification?&viewed=false&order=created_at_desc&limit=${limit}&offset=${offset}`,
        [
          'GET_VIEWED_NOTIFICATIONS_REQUEST',
          'GET_VIEWED_NOTIFICATIONS_SUCCESS',
          'GET_VIEWED_NOTIFICATIONS_FAILURE',
        ],
        {
          doNotCheckError: true,
          onSuccess: (response) => {
            // console.log('response',response)
            setTotalCount(get(response, 'info.not_viewed_count', 0));
            setViewedNotifications(response.result);
            setFetching(false);
          },
          tokenName: 'Notification',
          url: notificationUrl,
        },
      ),
    );

    getAllMentionedProjects();
  };

  useEffect(() => {
    if (visible) {
      initFunc();
    }
  }, [unseenNotifications.length, limit, offset]);

  return (
    <div className="header-dropdown-wrapper">
      <Dropdown
        menu={{ items: menu }}
        arrow
        placement="bottom"
        trigger={['click']}
        open={visible}
        onOpenChange={toggleDropdownBtn}
        overlayClassName="customNotifications-dropdown"
      >
        <Row className="flex justify-center cursor-pointer w-full">
          <Col>
            <Badge count={unseenNotifications.length}>
              <Icon size={1} path={mdiBellOutline} />
            </Badge>
          </Col>
        </Row>
      </Dropdown>
    </div>
  );
}

export default NotificationsDropDown;

NotificationsDropDown.propTypes = {
  onMarkAsViewed: PropTypes.func.isRequired,
  onMarkAsViewedAll: PropTypes.func.isRequired,
};
