import { useDispatch, useSelector } from "react-redux";
import { setNotificationSenderFilter } from "../../projectFlow/actions/PtcActionsForHook";
import { getUserPublicProfileUUID } from "../../userFlow/store/selectors/selectors";
import { getSenderFilterNotification } from "../../projectFlow/selectors/selectors";
import useURLParams from "../../hooks/useURLParams";
import { useTranslation } from "react-i18next";

import { Row, Col, Tooltip, Avatar } from "antd";
import BaseButton from "../../components/_ui/BaseButton/BaseButton";
import Icon from "@mdi/react";
import { timeToCalendar } from "../../MainUtils";
import { capitalize, get } from "lodash";
import { UserOutlined } from "@ant-design/icons";
import { mdiEmailCheckOutline } from "@mdi/js";

export default function SenderListItem(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { routeNavigateAndClearParams } = useURLParams();


  const filterBySender = useSelector(getSenderFilterNotification);

  const {
    uuid,
    user_data,
    last_message,
    unread_messages,
    onMarkAsViewed,
    toggleVisible,
    isSendersSelect,
  } = props;
  const { first_name, last_name, email } = user_data;

  const setViewed = () => {
    if (!isSendersSelect) {
      onMarkAsViewed(uuid);
    }
  };

  const stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const myPublicEntityUUID = useSelector(getUserPublicProfileUUID);

  const handleSetSenderFilter = () => {
    const data = { uuid: uuid, ...user_data };
    dispatch(setNotificationSenderFilter(data));
    routeNavigateAndClearParams(
      `/public/user/${myPublicEntityUUID}/dashboard`,
      {
        activeCard: "notifications",
      }
    );
    toggleVisible();
  };

  return (
    <div
      key={uuid}
      role="button"
      tabIndex="0"
      className={`cursor-pointer customNotifications-wrapper ${filterBySender?.uuid === uuid ? 'selected' : ''}`}
      onClick={handleSetSenderFilter}
      style={{ minWidth: "350px" }}
    >
      <Row className="flex justify-center">
        <Col
          span={4}
          className={"flex items-center" + " justify-center"}
        >
          <Avatar
            size={45}
            style={{
              backgroundColor: !unread_messages ? "#bfbfbf" : "#1890ff",
            }}
          >
            {/* {first_name?.[0]} {last_name?.[0]} */}
            <UserOutlined />
          </Avatar>
          {/* <Icon
          path={data.path}
          size={1}
          color={!!unread_messages ? "#bfbfbf" : "#1890ff"}
        /> */}
        </Col>
        <Col span={16} className="px-2">
          <h6
            style={{ color: !unread_messages ? "#bfbfbf" : "#1890ff" }}
            className="mb-2"
          >
            {/* {capitalize(t(data.title))} */}
            {first_name} {last_name}{" "}
            {unread_messages ? `(${unread_messages})` : ""}
          </h6>
          {last_message && !isSendersSelect && (
            <p className="customNotifications-message mb-2">{last_message}</p>
          )}
          {/* <span style={{ color: "#bfbfbf" }}>
          {timeToCalendar(created, true)}
        </span> */}
        </Col>
        {!isSendersSelect && (
          <Col
            span={4}
            className="flex items-center justify-center"
            onClick={stopPropagation}
          >
            <Tooltip
              // title={capitalize(
              //   t("notifications.buttons.mark_viewed", "mark as viewed")
              // )}
              title={"Mark all the sender's messages as read (in dev)"}
              trigger="hover"
              placement="topRight"
            >
              <BaseButton
                className="btnPrimary-outline"
                size="small"
                disabled={!unread_messages}
                onMouseEnter={stopPropagation}
                onMouseLeave={stopPropagation}
                // onClick={setViewed}
              >
                <Icon path={mdiEmailCheckOutline} size={0.9} />
              </BaseButton>
            </Tooltip>
          </Col>
        )}
      </Row>
    </div>
  );
}
