import React from 'react';
import { createRoot } from 'react-dom/client';

// Locale
import 'dayjs/locale/ru';

// Tailwind
import './styles/tailwind.css';

// Fonts
// import '@fontsource/ubuntu/300.css';
// import '@fontsource/ubuntu/400.css';
// import '@fontsource/ubuntu/500.css';
// import '@fontsource/ubuntu/700.css';
// import '@fontsource/ubuntu/300-italic.css';
// import '@fontsource/ubuntu/400-italic.css';
// import '@fontsource/ubuntu/500-italic.css';
// import '@fontsource/ubuntu/700-italic.css';

// Old styles
import './styles/customStyles.css';
import './styles/questionable_styles.scss';

// New styles
// import './styles/antd-overrides/overrides.scss';
import './styles/custom/custom.scss';

import AppComponent from './components/AppComponent/AppComponent';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <AppComponent />
  );
}
