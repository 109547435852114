import React from 'react';

import { capitalize, toPairs } from 'lodash';
import { bool, func, object } from 'prop-types';
import BaseButton from '../../components/_ui/BaseButton/BaseButton';

export default function UserPermsComponent({
  perms = {},
  onChangePerm,
  isDisabled,
}) {
  const permsObject = {
    create: perms?.create ?? false,
    read: perms?.read ?? false,
    update: perms?.update ?? false,
    delete: perms?.delete ?? false,
    list: perms?.list ?? false,
    set: perms?.set ?? false,
  };
  return (
    <>
      {toPairs(permsObject).map(([key, value]) => (
        <BaseButton
          key={key}
          size="small"
          shape="default"
          disabled={isDisabled}
          className={`p-0 ml-1 user-perm-circle ${value ? 'user-perm-circle-selected' : ''} ${isDisabled ? 'disabled-selected-circle' : ''}`}
          onClick={(e) => onChangePerm?.(e, 'perms', {
            ...perms,
            [key]: !value,
          })}
          style={{
            cursor: isDisabled ? 'auto' : 'pointer',
          }}
        >
          {capitalize(key[0])}
        </BaseButton>
      ))}
    </>
  );
}

UserPermsComponent.propTypes = {
  perms: object,
  onChangePerm: func,
  isDisabled: bool,
};
