import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes, {
  array,
  func, object, string,
} from 'prop-types';
import { mdiCommentTextMultipleOutline } from '@mdi/js';
import Icon from '@mdi/react';

import { path } from 'ramda';

import { Col, Row, Space } from 'antd';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import GoToButton from '../../../../components/buttons/GoToButton';
import CopyURL from '../../../../components/CopyURL';
import AddToBookmark from '../../AddToBookmark';
import RestoreEntityTypeBtn from '../../archiveState/RestoreEntityTypeBtn';
import CommonInfo from '../../commonComponents/CommonInfo';
import DragTasksWrapper from '../dragTask/DragTasksWrapper';
import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';

import { capitalize } from 'lodash';
import { getUserPublicProfileUUID } from '../../../../userFlow/store/selectors/selectors';
import { getVectorInfo } from '../../../selectors/selectors';
import HeaderCard from '../../commonComponents/HeaderCard';
import { partitionNamesConfig } from '../../../../api/appConfig';

export default function VectorTopBar({
  partitionType,
  viewMode,
  entityType = 'vector',
  afterDeleteABookmark,
  hiddenView,
  resetVector,
  restoreActionCallback,
  notSavedData,
  setNotSavedData,
  dragIssueCallback,
  deleteVectorCallback,
  openSidePanel,
}) {
  const { t } = useTranslation();

  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const vector = _.head(useSelector(getVectorInfo));

  const {
    // entity_type = '',
    created,
    modified = '',
    parent,
    uinfo: {
      first_name: creatorFirstName,
      last_name: creatorLastName,
    } = {},
    params: {
      status = '',
      issue_increment = 0,
    } = {},
    uuid,
  } = vector || {};
  const creatorInfo = `${creatorFirstName} ${creatorLastName}`;

  const {
    hideFull,
    fideGoToBtn,
    hideClose,
    hideEdit,
    hideDelete,
    hideDragTask,
    hideActivityBtn,
  } = useMemo(() => ({
    hideFull: hiddenView.includes('fullEdit'),
    fideGoToBtn: hiddenView.includes('goToBtn'),
    hideClose: hiddenView.includes('close'),
    hideEdit: hiddenView.includes('edit'),
    hideDelete: hiddenView.includes('delete'),
    hideDragTask: hiddenView.includes('dragTask'),
    hideActivityBtn: hiddenView.includes('activity'),
  }), [hiddenView]);

  const pathForGoToBtn = {
    pathname: partitionType === partitionNamesConfig.partition1
      ? '/pm/projects/issuesnew'
      : `/public/user/${myPublicUUID}/projects/issuesnew`,
    search: `activeProject=${parent}&activeVector=${uuid}`,
  };

  const subTitle = (
    <Row className="flex mb-1 w-full gap5 items-center">
      <CommonInfo
        creator={creatorInfo}
        createdDate={created}
        lastActivity={modified}
        placement="topLeft"
      />
      <CopyURL
        partition={partitionType}
        entityType={entityType}
        entityUUID={uuid}
      />
      {!hideFull && (
        <AddToBookmark
          afterDeleteABookmark={afterDeleteABookmark}
          entityType={entityType}
          entityUUID={uuid}
          partition={partitionType}
        />
      )}

      {(!fideGoToBtn && !hideFull) && (
        <GoToButton
          pathForLabelTranslate="wms.buttons.go_to_vector"
          pathToRedirect={pathForGoToBtn}
        />
      )}
    </Row>
  );

  const headerButtons = (
    <Row
      align="center"
    // size={[5, 5]}
    >
      {!hideClose && (
        <RestoreEntityTypeBtn
          archiveEntityUUID={uuid}
          callbackAction={restoreActionCallback}
        />
      )}

      {(!hideEdit && !hideFull) && (
        <>
          {(status !== 'protected'
            && !hideDragTask && issue_increment > 0)
            && (
              <Col className="mb-1">
                <DragTasksWrapper
                  partition={partitionType}
                  parent={uuid}
                  updateCallback={dragIssueCallback}
                />
              </Col>
            )}

          {!hideActivityBtn && (
            <BaseButton
              id="activitesButton"
              className="btnPrimary-outline ml-1 mb-1"
              size="small"
              onClick={openSidePanel}
              disabled={false}
            >
              <Icon path={mdiCommentTextMultipleOutline} size={0.7} className="mr-1" />
              {capitalize(t('', 'activities'))}
            </BaseButton>
          )}

          {!hideDelete && (
            <Col className="ml-1 mb-1">
              <HardDeleteEntityContainer
                name="remove"
                typeInLabel="vector"
                labelMode
                sizeBtn="small"
                entityUUID={uuid}
                entityType={entityType}
                type="entity"
                notSavedData={notSavedData}
                setNotSavedData={setNotSavedData}
                className="btnDanger-outline line-height-inherit"
                actionForEntity={deleteVectorCallback}
                partitionType={partitionType}
              />
            </Col>
          )}
        </>
      )}
    </Row>
  );

  return (
    <HeaderCard
      // title={capitalize(t(`wms.noun.${viewMode}`, { before: '' })?.trim())}
      title={ capitalize(t(viewMode))}
      spanTitleSection={15}
      spanDescriptionSection={9}
      subTitle={subTitle}
      extra={headerButtons}
      backCallback={resetVector}
    />
  );
}

VectorTopBar.propTypes = {
  partitionType: string,
  viewMode: string,
  afterDeleteABookmark: func,
  hiddenView: PropTypes.arrayOf(PropTypes.string),
  resetVector: func,
  restoreActionCallback: func,
  entityType: string,
  notSavedData: object,
  setNotSavedData: func,
  dragIssueCallback: func,
  deleteVectorCallback: func,
  openSidePanel: func,
};
