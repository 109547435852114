import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getListOfIssues } from "../../../actions/projectFlowActions";
import { addDataToUnplannedIssues } from "./utils";
import { excludeSectionFilterResult, getNestedFilters } from "../../../../components/_ui/helpers/filterHelper";
import { get, isEmpty, isEqual, omit } from "lodash";
import { issueStatusFilterOptions } from "../../../../constants/allFilterOptions";

const useSuggestedIssuesInMainPlanboard = (
    {todayIssues, 
     partitionPM, 
     customActor,
     projects
    }) => {
    const dispatch = useDispatch()

const defaultIssuesConfig = {
    offset: 0,
    limit: 100,
    depth: 0,
    }

  const [config, setConfig] = useState(defaultIssuesConfig);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [suggestedIssues, setSuggestedIssues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedProjects, setSelectedProjects] = useState([])

  const {
    limit,
  } = config;

  const [issueFilterConfig, setFilterConfig] = useState({
    title: getNestedFilters(
      ['validation:section', 'development:section', 'testing:section', 'purgatory:section'],
      issueStatusFilterOptions,
    ),
  });

  const prepareFilterConfig = (config) => {
    const fixedConfig = { ...config };
    if (!isEmpty(fixedConfig)) {
      const filterKeys = Object.keys(fixedConfig);
      filterKeys.forEach(
        (key) => fixedConfig[key] = excludeSectionFilterResult(fixedConfig[key]),
      );
    }
    return fixedConfig;
  };


  const getSuggestedIssues = async () => {
    try {
      setIsFetching(true);
    //   const uuids_not = uniq(todayIssues.flatMap(day => get(currentBoard, ['params', day], [])))
        const uuids_not = todayIssues.map(issue => issue.uuid)

      const newConfig = {
        parent: partitionPM,
        entity_uuids__not: uuids_not,
        ...config,
        params: {
            usersSearch: [customActor],
          ...(selectedProjects.length > 0 && { project: selectedProjects }),
          ...config.params,
        },
        params_fields: {
          project: "project"
        },
        partition: "PM",
        constants: [
          'GET_ISSUES_SUGGESTED_PLAN_REQUEST',
          'GET_ISSUES_SUGGESTED_PLAN_SUCCESS',
          'GET_ISSUES_SUGGESTED_PLAN_FAILURE',
        ],
      }

      if (issueFilterConfig?.title?.length > 0) {
        newConfig.params.status = issueFilterConfig?.title
      }

      const res = await dispatch(
        getListOfIssues(newConfig),
      );

      
      if (res?.data) {
        setTotal(res?.total)
        const issues = res?.data?.filter(el => !uuids_not.includes(el?.uuid));
        const issuesWithData = addDataToUnplannedIssues(issues, projects)

        setSuggestedIssues(issuesWithData)
        console.log("IISESE", issuesWithData);
        
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if(todayIssues) {
        getSuggestedIssues()
    }
  }, [todayIssues, config, selectedProjects])

  useEffect(() => {
    setSelectedProjects([])
  }, [projects])

  return {
    suggestedIssues,
    isFetching,
    getSuggestedIssues,
    selectedProjects,
    setSelectedProjects,
    paginationSettings: {
        limit,
        total,
        issueFilterConfig,
        changeIssueFilterConfig: (config) => {
          const fixedConfig = prepareFilterConfig(config);
          console.log(issueFilterConfig, fixedConfig)
          if (!isEqual(issueFilterConfig, fixedConfig)) {
            setFilterConfig(fixedConfig);
            setConfig(prev => ({ ...prev, offset: 0 }));
            setCurrentPage(1)
          };
        },
        newCurrentPage: currentPage,
        setLimit: (newLimit) => {
          setCurrentPage(1);
          setConfig(prev => ({ ...prev, offset: 0, limit: newLimit }));
        },
        setNewPage: (newPage) => {
          setCurrentPage(newPage);
          setConfig(prev => ({ ...prev, offset: (newPage - 1) * prev.limit }))
        },
        setPartOfConfig: (newPart) => {
          const valueSearch = get(newPart, 'search', '')
  
          if (valueSearch) {
            setConfig(prev => ({
              ...prev,
              ...omit(newPart, ['search']),
              searchData: {
                value: valueSearch,
                ignore_case: true,
                fields: { params: ['title'] },
              }
            }))
            return;
          } else {
            setConfig(prev => ({ ...omit(prev, 'searchData'), newPart }))
          }
  
        },
      },
  }
}

export default useSuggestedIssuesInMainPlanboard;