import React, {
  useState,
} from 'react';
import { string } from 'prop-types';
import { Flex, Row } from 'antd';
import UniGeneralButtons from '../uniGeneralButtons/UniGeneralButtons';
import { useSelector } from 'react-redux';
import { isAdminOrRoot } from '../../../entity/selectors/selectors';
import useGetUserPerm from '../../../permissions/hooks/useGetUserPerm';
import useURLParams from '../../../hooks/useURLParams';

import MyBoardNew from './MyBoardNew'
import AdminBoard from './AdminBoard';
import ComputePlanSettings from './ComputePlanSettings';
import BaseCard from '../../../components/_ui/BaseCard/BaseCard';


function MyBoardRoot({
  partition,
}) {
  const {
    getURLParams,
  } = useURLParams();

  const { activeProject } = getURLParams();

  const adminOrRoot = useSelector(isAdminOrRoot);

  const [tab, setTab] = useState('board');

  const { perm } = useGetUserPerm({
    entityUUID: activeProject,
    partitionType: partition,
  });

  const checkTab = (type) => type === tab;

  const tabs = [
    { name: 'board', title: 'My plans' },
    {
      name: 'admin_board', title: 'Admin view board', hidden: !(adminOrRoot || (
        perm.set
        && perm.read
        && perm.list
        && perm.create
        && perm.delete
        && perm.update
      ))
    },
    // { name: "settings", title: "Settings" }
  ].filter(el => !el?.hidden);

  return (
    <>
      {adminOrRoot || perm.set ? <Row span={24} className="mb-3">
        <UniGeneralButtons
          viewMode={tab}
          switchViewFunc={setTab}
          tabs={tabs}
        />
      </Row> : null}

      {checkTab('board') && (
        <MyBoardNew partition={partition} />
      )}

      {checkTab('admin_board') && (adminOrRoot || perm.set) && (
        <AdminBoard partition={partition} />
      )}

      {/* {checkTab('settings') && (
        <BaseCard
          className={"mb-2 px-4 py-2 myboard-plan-card-issues-view"}
        >
          <ComputePlanSettings />
        </BaseCard>
      )} */}
    </>
  );
}

MyBoardRoot.propTypes = {
  partition: string,
};

export default MyBoardRoot;
