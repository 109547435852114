import { Card, Col } from 'antd';
import { head } from 'lodash';
import {
  array, bool, func, number, object, string,
} from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';


import WrapperCommentsAndTimeLogs from '../WrapperCommentsAndTimeLogs';
import VectorInfo from '../vector/vectorInfo/VectorInfo';
// add import
import WorkBlockInfoForVectorsTable from './WorkBlockInfoForVectorsTable';
import CardSidePanel from '../commonComponents/cardSidePanel/CardSidePanel';
import ActivitiesWrapper from '../commonComponents/activities/activitiesWrapper/ActivitiesWrapper';

import useActivitiesDashboard from '../commonComponents/activities/useActivitiesDashboard';
import { ACTIVITIES_TABS } from '../commonComponents/activities/activitiesTabs';

import { getVectorFetching, getVectorInfo, getVectorUUID } from '../../selectors/selectors';

const activitiesTabs = [
  { name: ACTIVITIES_TABS.COMMENTS },
  { name: ACTIVITIES_TABS.TIME_LOG },
  { name: ACTIVITIES_TABS.HISTORY_OF_CHANGES },
];

export default function WrapperWorkBlockInfoCardForVectorsTable({
  afterDeleteABookmark,
  customVectorData,
  customVectorUuid,
  defaultTemplateState,
  deleteVectorCallback,
  dragIssueCallback,
  disabledView,
  hiddenView,
  notSavedData,
  partitionType,
  resetVectorCallback,
  restoreActionCallback,
  setIsModalVisible,
  setIsNotSavedDataCallback,
  setNotSavedData,
  updateVectorModal,
  viewMode = 'vector',
  // add ListIssueProps
  disabledMultiEdit,
  isGlobalDisabled,
  newCurrentPage,
  countOfData,
  getIssue,
  getIssues,
  getIssuesRequestSetting,
  filterConfig,
  filterCallback,
  onChangeRowCallback,
  newIssueSuccessCallback,
  // add vector 
  vectorData,
  vectorDataFetching,
  vectorUUID,
  //add document modal props
  activeDocumentModal,
  setActiveDocumentModal,
  activeDocument,
  setActiveDocument,
  activeDocumentType,
  setActiveDocumentType,
  // add issue modal props
  activeIssue,
  setActiveIssue,
  activeIssueModal,
  setActiveIssueModal,
  // add hide props
  setHideVectors,
  saveDocument,
}) {

  const targetData = customVectorData || vectorData;
  const finalVectorUUID = customVectorUuid || vectorUUID;
    
  const {
    isSidePanelOpen,
    selectedActivity,
    setSelectedActivity,
    closeSidePanel,
    clickActivitiesHandler,
    clickDashboardButtonHandler,
  } = useActivitiesDashboard();

  return (
    <Col
      className="overflow-hidden"
      id="vector_info_component_and_comments"
    >
      <WorkBlockInfoForVectorsTable
        activitiesTabs={activitiesTabs}
        afterDeleteABookmark={afterDeleteABookmark}
        setIsModalVisible={setIsModalVisible}
        updateVectorModal={updateVectorModal}
        setIsNotSavedDataCallback={setIsNotSavedDataCallback}
        notSavedData={notSavedData}
        setNotSavedData={setNotSavedData}
        defaultTemplateState={defaultTemplateState}
        disabledView={disabledView}
        viewMode={viewMode}
        hiddenView={hiddenView}
        restoreActionCallback={restoreActionCallback}
        deleteVectorCallback={deleteVectorCallback}
        resetVectorCallback={resetVectorCallback}
        dragIssueCallback={dragIssueCallback}
        partitionType={partitionType}
        clickActivitiesHandler={clickActivitiesHandler}
        clickDashboardButtonHandler={clickDashboardButtonHandler}
        // add props
        disabledMultiEdit={disabledMultiEdit}
        isGlobalDisabled={isGlobalDisabled}
        newCurrentPage={newCurrentPage}
        countOfData={countOfData}
        getIssue={getIssue}
        getIssues={getIssues}
        getIssuesRequestSetting={getIssuesRequestSetting}
        filterConfig={filterConfig}
        filterCallback={filterCallback}
        onChangeRowCallback={onChangeRowCallback}
        newIssueSuccessCallback={newIssueSuccessCallback}
        // add vector 
        vectorData={vectorData}
        vectorDataFetching={vectorDataFetching}
        vectorUUID={vectorUUID}
        // add documentModal props 
        activeDocumentModal={activeDocumentModal}
        setActiveDocumentModal={setActiveDocumentModal}
        activeDocument={activeDocument}
        setActiveDocument={setActiveDocument}
        activeDocumentType={activeDocumentType}
        setActiveDocumentType={setActiveDocumentType}
        // add issue modal props
        activeIssue={activeIssue}
        setActiveIssue={setActiveIssue}
        activeIssueModal={activeIssueModal}
        setActiveIssueModal={setActiveIssueModal}
        setHideVectors={setHideVectors}
        saveDocument={saveDocument}
      />
            
      <CardSidePanel
        isVisible={isSidePanelOpen}
        closeSidePanel={closeSidePanel}
      >
        <ActivitiesWrapper
          inPanel
          isPanelOpen={isSidePanelOpen}
          parentUUID={vectorUUID}
          partitionType={partitionType}
          selectedActivity={selectedActivity}
          setSelectedActivity={setSelectedActivity}
          tabs={activitiesTabs}
          targetData={targetData}
        />
      </CardSidePanel>
    </Col>
  );
}
WrapperWorkBlockInfoCardForVectorsTable.propTypes = {
  setIsNotSavedDataCallback: func,
  notSavedData: object,
  setNotSavedData: func,
  defaultTemplateState: object,
  viewMode: string,
  disabledView: array,
  hiddenView: array,
  restoreActionCallback: func,
  deleteVectorCallback: func,
  resetVectorCallback: func,
  dragIssueCallback: func,
  partitionType: string,
  parentType: string,
  fetchingComments: bool,
  updateVectorModal: func,
  setIsModalVisible: func,
  customVectorData: object,
  customVectorUuid: string,
  afterDeleteABookmark: func,
  // add ListIssue props 
  countOfData: number,
  getIssuesRequestSetting: func,
  newIssueSuccessCallback: func,
  onChangeRowCallback: func,
  filterConfig: object,
  filterCallback: func,
  getIssues: func,
  isGlobalDisabled: bool,
  newCurrentPage: number,
  getIssue: func,
  disabledMultiEdit: bool,
  vectorData: object,
  vectorDataFetching: bool,
  vectorUUID: string,
  //add modals props
  activeDocumentModal: bool,
  setActiveDocumentModal: func,
  activeDocument: string,
  setActiveDocument: func,
  activeDocumentType: string,
  setActiveDocumentType: func,
  activeIssue: string,
  setActiveIssue: func,
  activeIssueModal: bool,
  setActiveIssueModal: func,
  setHideVectors: func,
  saveDocument: bool,
  setSpinModal: func
};
