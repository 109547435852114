import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// import { useTranslation } from 'react-i18next';
import { Timeline, Col, Row } from 'antd';
import Icon from '@mdi/react';
import { mdiPlaylistCheck } from '@mdi/js';
import { isNil } from 'ramda';
import moment from 'moment';
import { omit } from 'lodash';
import BaseButton from '../../../../../../components/_ui/BaseButton/BaseButton';

import { selectProjectsKPM } from '../../../../../reducers/slicers/projectsKPMSlice';
import { getIssuesScoringData, getIssuesOutdatedData } from '../../../../../reducers/issues/IssuesSlicer';
import {
  getVectorsScoringData, getProjectParams, getProject,
} from '../../../../../selectors/selectors';

import { capitalize } from 'lodash';
import { kpmInfo } from '../../../../milestones/helpers/milestoneUtils';
import {
  devScoreTooltipText,
  outdatedScoreTooltipText,
  parseOutdatedColor,
  parseScoringColor,
  parsePlanScoreColor,
} from '../../utils/scoringUtils';
import StepNum from '../stepNum/StepNum';

import ScoringStepBlock from './ScoringStepBlock';
import usePlanScore from '../../../../../../hooks/projectAuditHooks/usePlanScore';
import useDevScore from '../../../../../../hooks/projectAuditHooks/useDevScore';
import useDevVectorsScore from '../../../../../../hooks/projectAuditHooks/useDevVectorsScore';
import useOutdatedScore from '../../../../../../hooks/projectAuditHooks/useOutdatedScore';
import useQaStructureScoring from '../../../../../../hooks/projectAuditHooks/useQaStructureScoring';
import useQaActivityScoring from '../../../../../../hooks/projectAuditHooks/useQaActivityScoring';
import useArchiveScore from '../../../../../../hooks/projectAuditHooks/useArchiveScore';

import {
  getCurrentProjectQaStructureScore,
  getCurrentProjectQaActivityScore,
  getOutdatedProjectsArr,
  getQaStructureProjectsArr,
  getQaActivityProjectsArr,
  getArchiveProjectsArr,
  getCurrentProjectArchiveScore,
} from '../../../../../reducers/slicers/projectAllScoringSlicer';
import { ProjectTypeCommonConstants } from '../../../../../constants/Constants';
import { updateProjectScoring } from '../../../../../actions/projectFlowActions';

export default function ProjectScoring({
  parent,
  partitionType,
  isInModal,
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const projectData = useSelector(getProject);

  const { computeSingleProjectPlanScore } = usePlanScore();

  const { computeProjectVectorsDevScore } = useDevVectorsScore();

  const { computeProjectDevScore } = useDevScore();

  const { computeProjectOutdatedScore } = useOutdatedScore();

  const {
    computeQaStructureScore,
    handleQaStructureBackground,
    qaStructureTooltipText,
  } = useQaStructureScoring();

  const {
    computeQaActivityScore,
    handleQaActivityBackground,
    qaActivityTooltipText,
  } = useQaActivityScoring();

  const {
    computeProjectArchiveScore,
    handleArchiveBackground,
    archiveTooltipText,
  } = useArchiveScore();

  const issuesScoringData = useSelector(getIssuesScoringData);
  // const vectorsScoringData = useSelector(getVectorsScoringData);

  // const projectsKPM = useSelector(selectProjectsKPM);

  const [loadingAllScore, setLoadingAllScore] = useState(false);
  // const [scoreObj, setScoreObj] = useState({});

  // const {
  //   planScoreFromState,
  // } = scoreObj;

  // const { value: planScore } = projectsKPM.find((item) => item?.uuid === parent) || {};

  const issuesOutdatedData = useSelector(getIssuesOutdatedData);
  const qaStructureData = useSelector(getCurrentProjectQaStructureScore);
  const qaActivityData = useSelector(getCurrentProjectQaActivityScore);
  const archivedata = useSelector(getCurrentProjectArchiveScore);
  const projectParams = useSelector(getProjectParams);

  // console.log('issuesOutdatedData', issuesOutdatedData);

  const cashedOutdatedData = useSelector(getOutdatedProjectsArr)?.projectsArr;
  const cashedQaStructureData = useSelector(getQaStructureProjectsArr)?.projectsArr;
  const cashedQaActivityData = useSelector(getQaActivityProjectsArr)?.projectsArr;
  const cashedArchiveData = useSelector(getArchiveProjectsArr)?.projectsArr;

  const cashedIssuesOutdatedScore = Array.isArray(cashedOutdatedData) ? cashedOutdatedData?.find((item) => item?.uuid === parent)?.outdatedScore : '-';
  const cashedQaStructureScore = Array.isArray(cashedQaStructureData) ? cashedQaStructureData?.find((item) => item?.uuid === parent)?.qaStructureScore : '-';
  const cashedQaActivityScore = Array.isArray(cashedQaActivityData) ? cashedQaActivityData?.find((item) => item?.uuid === parent)?.qaActivityScore : '-';
  const cashedArchiveScore = Array.isArray(cashedArchiveData) ? cashedArchiveData?.find((item) => item?.uuid === parent)?.archiveScore : '-';

  // console.log('cashedIssuesOutdatedScore', cashedIssuesOutdatedScore);
  // console.log('cashedQaStructureScore', cashedQaStructureScore);
  // console.log('cashedQaActivityScore', cashedQaActivityScore);
  // console.log('cashedArchiveScore', cashedArchiveScore);

  // const checkValue = (val1, val2) => {
  //   // console.log('val1, val2', val1, val2);
  //   if (val1 === '-' && val2 === '-') {
  //     return '-';
  //   }
  //   if (!val1 && !val2) {
  //     return 0;
  //   }
  //   const check1 = typeof val1 === 'number';
  //   const check2 = typeof val2 === 'number';
  //   if (check1 && !check2) {
  //     return val1;
  //   }
  //   if (!check1 && check2) {
  //     return val2;
  //   }
  //   if (check1 && check2) {
  //     return val1;
  //   }
  //   return val1 || val2;
  // };

  const issuesDevScore = projectParams?.issuesDevScore?.issuesScoringAVG ?? '-';
  const planScore = projectParams?.gmPlanScore?.planScoreValue ?? '-';
  const devVectorsScore = projectParams?.vectorsDevScore?.vectorsScoringAVG ?? '-';
  const finalOutdatedScore = projectParams?.outdatedDevScore?.outdatedScore ?? '-';
  const qaStructureScore = projectParams?.qaStructureScoring?.qaStructureScore ?? '-';
  const qaActivityScore = projectParams?.qaActivityScoring?.qaActivityScore ?? '-';
  const archiveScore = projectParams?.archiveScoring?.archiveScore ?? '-';
  // const finalOutdatedScore = checkValue(issuesOutdatedData, cashedIssuesOutdatedScore);

  // const issuesDevScore = checkValue(
  //   issuesScoringData?.avgVal,
  //   projectParams?.scoringData?.issuesDevScore?.issuesScoringAVG,
  // );
  // const qaStructureScore = checkValue(qaStructureData?.score, cashedQaStructureScore);
  // const qaActivityScore = checkValue(qaActivityData?.score, cashedQaActivityScore);
  // const archiveScore = checkValue(archivedata?.score, cashedArchiveScore);

  const planScoreProps = {
    score: planScore >= 0 && !isNil(planScore) ? planScore : '-',
    title: 'Plan score',
    buttonClassname: parsePlanScoreColor(planScore, true),
    refreshCallback: async (isAllScoring = false) => {
      const newProject = await computeSingleProjectPlanScore(
        parent,
        partitionType,
        true,
        isAllScoring,
      );

      if (isAllScoring) {
        return newProject;
      }

      dispatch({
        type: ProjectTypeCommonConstants.PROJECT_SUCCESS,
        payload: newProject,
      });
    },
    tooltipInfoText: kpmInfo,
  };

  const devVectorsScoreProps = {
    score: devVectorsScore,
    title: 'Dev score (vectors)',
    buttonClassname: parseScoringColor(devVectorsScore, true),
    refreshCallback: async (isAllScoring = false) => {
      const newProject = await computeProjectVectorsDevScore(
        parent,
        partitionType,
        true,
        isAllScoring,
      );
      console.log(newProject);
      if (isAllScoring) {
        return newProject;
      }

      dispatch({
        type: ProjectTypeCommonConstants.PROJECT_SUCCESS,
        payload: newProject,
      });
    },
    tooltipInfoText: devScoreTooltipText('vectors'),
  };

  // console.log('vectorsScoringData?.avgVal', vectorsScoringData?.avgVal);

  const devIssuesScoreProps = {
    score: issuesDevScore,
    title: 'Dev score (issues)',
    buttonClassname: parseScoringColor(issuesDevScore, true),
    refreshCallback: async (isAllScoring = false) => {
      const newProject = await computeProjectDevScore(
        parent,
        partitionType,
        true,
        isAllScoring,
      );

      if (isAllScoring) {
        return newProject;
      }

      dispatch({
        type: ProjectTypeCommonConstants.PROJECT_SUCCESS,
        payload: newProject,
      });
    },
    tooltipInfoText: devScoreTooltipText('issues'),
  };

  const outdatedScoreProps = {
    score: finalOutdatedScore,
    title: 'Outdated dev score',
    buttonClassname: parseOutdatedColor(finalOutdatedScore, true),
    refreshCallback: async (isAllScoring = false) => {
      const newProject = await computeProjectOutdatedScore(
        parent,
        partitionType,
        true,
        isAllScoring,
      );

      if (isAllScoring) {
        return newProject;
      }

      dispatch({
        type: ProjectTypeCommonConstants.PROJECT_SUCCESS,
        payload: newProject,
      });
    },
    tooltipInfoText: outdatedScoreTooltipText('issues'),
  };

  const qaStructureScoreProps = {
    score: qaStructureScore,
    title: 'Qa structure score',
    buttonClassname: handleQaStructureBackground(qaStructureScore, true),
    refreshCallback: async (isAllScoring = false) => {
      const newProject = await computeQaStructureScore(
        parent,
        partitionType,
        true,
        isAllScoring,
      );

      if (isAllScoring) {
        return newProject;
      }

      dispatch({
        type: ProjectTypeCommonConstants.PROJECT_SUCCESS,
        payload: newProject,
      });
    },
    tooltipInfoText: qaStructureTooltipText,
  };

  const qaActivityScoreProps = {
    score: qaActivityScore,
    title: 'Qa activity score',
    buttonClassname: handleQaActivityBackground(qaActivityScore, true),
    refreshCallback: async (isAllScoring = false) => {
      const newProject = await computeQaActivityScore(
        parent,
        partitionType,
        true,
        isAllScoring,
      );

      if (isAllScoring) {
        return newProject;
      }

      dispatch({
        type: ProjectTypeCommonConstants.PROJECT_SUCCESS,
        payload: newProject,
      });
    },
    tooltipInfoText: qaActivityTooltipText,
  };

  // --------------------------------

  const dataForArchiveRecompute = {
    uuid: parent,
    partition: partitionType,
    modified: projectData?.[0]?.modified,
    title: projectData?.[0]?.params?.title,
    infraDocSymbols: projectData?.[0]?.params?.infrastructureDoc?.symbols_count || 0,
    descrDocSymbols: projectData?.[0]?.params?.descriptionDoc?.symbols_count || 0,
  };

  const archiveScoreProps = {
    score: archiveScore,
    title: 'Archive score',
    buttonClassname: handleArchiveBackground(archiveScore, true),
    refreshCallback: async (isAllScoring = false) => {
      const newProject = await computeProjectArchiveScore(
        dataForArchiveRecompute,
        true,
        isAllScoring,
      );

      if (isAllScoring) {
        return newProject;
      }

      dispatch({
        type: ProjectTypeCommonConstants.PROJECT_SUCCESS,
        payload: newProject,
      });
    },
    tooltipInfoText: archiveTooltipText,
  };

  // --------------------------------

  const propsArr = [
    planScoreProps,
    devVectorsScoreProps,
    devIssuesScoreProps,
    outdatedScoreProps,
    qaStructureScoreProps,
    qaActivityScoreProps,
    archiveScoreProps,
  ];

  const handleAllScore = async () => {
    try {
      setLoadingAllScore(true);
      const res = await Promise.all(propsArr.map(async (item) => item?.refreshCallback(true)));
      const data = {
        uuid: parent,
        partition: partitionType,
        params: {
          gmPlanScore: omit(res[0], ['uuid']),
          vectorsDevScore: omit(res[1], ['uuid']),
          issuesDevScore: omit(res[2], ['uuid']),
          outdatedDevScore: omit(res[3], ['uuid']),
          qaStructureScoring: omit(res[4], ['uuid']),
          qaActivityScoring: omit(res[5], ['uuid']),
          archiveScoring: omit(res[6], ['uuid']),
        },
      };
      const newProject = await dispatch(updateProjectScoring(data));
      // console.log(newProject);
      dispatch({
        type: ProjectTypeCommonConstants.PROJECT_SUCCESS,
        payload: newProject,
      });
    } catch (e) {
      console.log('handleAllScore e', e);
    } finally {
      setLoadingAllScore(false);
    }
  };

  return (
    <>
      {isInModal ? (
        <div className="wrapper-title mb-4" style={{ marginLeft: '-8px' }}>
          {capitalize(
            t('wms.projectflow.project.info.link', 'Project scoring'),
          )}
          :
        </div>
      ) : (
        <h6 className="mb-4">
          {`${capitalize(
            t('wms.projectflow.project.info.link', 'Project scoring'),
          )}:`}
        </h6>
      )}
      <div />
      <Row className="flex flex-wrap">
        <Col span={12} className="m-0 p-0" style={{ minWidth: '220px' }}>
          <Timeline className="wrapper-time-line">
            {propsArr.slice(0, 4).map((itemProps, idx) => (
              <Timeline.Item dot={<StepNum num={idx + 1} key={`${itemProps?.title}_${idx + 1}`} />}>
                <ScoringStepBlock
                  parent={parent}
                  partitionType={partitionType}
                  score={itemProps?.score}
                  title={itemProps?.title}
                  buttonClassname={itemProps?.buttonClassname}
                  refreshCallback={itemProps?.refreshCallback}
                  tooltipInfoText={itemProps?.tooltipInfoText}
                  disabled={loadingAllScore}
                />
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
        <Col span={12} className="m-0 p-0" style={{ minWidth: '220px' }}>
          <Timeline className="wrapper-time-line">
            {propsArr.slice(4).map((itemProps, idx) => (
              <Timeline.Item dot={<StepNum num={idx + 5} key={`${itemProps?.title}_${idx + 5}`} />}>
                <ScoringStepBlock
                  parent={parent}
                  partitionType={partitionType}
                  score={itemProps?.score}
                  title={itemProps?.title}
                  buttonClassname={itemProps?.buttonClassname}
                  refreshCallback={itemProps?.refreshCallback}
                  tooltipInfoText={itemProps?.tooltipInfoText}
                  disabled={loadingAllScore}
                />
              </Timeline.Item>
            ))}
            <Timeline.Item dot={<StepNum num={<Icon path={mdiPlaylistCheck} size={1} />} />}>
              <BaseButton
                id='handleAllScoreButton'
                className="rounded-pill btnPrimary-outline"
                size="small"
                loading={loadingAllScore}
                onClick={handleAllScore}
              >
                Compute all scores
              </BaseButton>
            </Timeline.Item>
          </Timeline>
        </Col>
      </Row>

    </>
  );
}

ProjectScoring.propTypes = {
  parent: string,
  partitionType: string,
  isInModal: bool,
};
