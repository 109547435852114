import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Input, Button, Col } from 'antd';
import { capitalize } from 'lodash';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

const { TextArea } = Input;

function NewArea({
  onFormChange,
  // name = '',
  // description,
  t,
  // row,
  // updateArea,
  // handleOk,
  createArea,
  // ButtonActive,
  switchModal,
  closeModal,
  areaData,
}) {
  // const [names, setNames] = useState(name);
  return (
    <div style={{ position: 'relative' }}>
      <p>
        {capitalize('tag name')}
        :
      </p>
      <Input
        id="create_area_name"
        name="name"
        // defaultValue={name}
        showCount
        maxLength={25}
        placeholder={capitalize('tag name')}
        onChange={(event) => {
          onFormChange(event);
          // setNames(event.target.value);
        }}
      />
      <p className="mt-2">
        {capitalize('tag description')}
        :
      </p>
      <TextArea
        id="create_area_description"
        // style={ButtonActive && { marginBottom: '30px' }}
        name="description"
        // defaultValue={description}
        onChange={onFormChange}
        placeholder={capitalize('tag description')}
        rows={4}
      />
      <Col
        className="mt-3 flex justify-end"
      >
        <Button
          onClick={() => {
            closeModal();
            switchModal();
          }}
          className="btnSecondary-outline"
        >
          {capitalize(t('wms.verb.cancel', 'cancel'))}
        </Button>
        <BaseButton
          className="btnPrimary ml-2"
          onClick={() => {
            switchModal();
            createArea();
            closeModal();
          }}
          disabled={areaData.disabled}
        >
          Create tag
        </BaseButton>
      </Col>
      {/* {ButtonActive && ( */}
      {/* <Button */}
      {/*  type="primary" */}
      {/*  size="default" */}
      {/*  style={{ position: 'absolute', bottom: '-10px', right: '0px' }} */}
      {/*  disabled={names.replace(/\s/g, '').length === 0} */}
      {/*  onClick={() => { */}
      {/*    updateArea(row); */}
      {/*    handleOk(); */}
      {/*  }} */}
      {/* > */}
      {/*  {capitalize(t('wms.verb.save', 'save'))} */}
      {/* </Button> */}
      {/* )} */}
    </div>
  );
}

NewArea.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string,
  onFormChange: PropTypes.func,
  createArea: PropTypes.func,
  switchModal: PropTypes.func,
  closeModal: PropTypes.func,
  areaData: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(NewArea);
