import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { List } from 'antd';
import { capitalize, get } from "lodash";
import { useTranslation } from "react-i18next";
import BaseButton from "../../../../components/_ui/BaseButton/BaseButton";

import cn from 'classnames';
import { array, func, string } from "prop-types";
import AntDesignSearchBox54origins from "../../../../54origins/components/AntDesignSearchBox54origins";
import Icon from "@mdi/react";
import { mdiArrowDown, mdiChevronDown } from "@mdi/js";

const ProjectFilterForUnplannedIssues = ({
   projects,
   selectedProjects,
   setSelectedProjects
}) => {

const {t} = useTranslation()

const [isOpen, setIsOpen] = useState(false);
const [valueSearch, setValueSearch] = useState('')

const [filteredProjects, setFilteredProjects] = useState([])

const toggleBtn = () => {
    setIsOpen((prev) => !prev)
    setValueSearch('')
};  

useEffect(() => {
    console.log("selectedProjets", selectedProjects);
    
}, [selectedProjects])

return (
    <Popover
        placement="left"
        trigger="click"
        destroyTooltipOnHide
        open={isOpen}
        onClick={toggleBtn}
        content={(
        <div className="relative">
        <div
            className="wrapper_select_release"
            onClick={(e) => e.stopPropagation()}
            >
            <div className="flex items-center justify-between p-2 wrapper_header_assigned_user_btn">
                <BaseButton
                id="saveBtn"
                className="mr-1"
                type="primary"
                disabled={!filteredProjects}
                onClick={() => setSelectedProjects(filteredProjects)}
                >
                {capitalize(t('wms.verb.save', 'save'))}
                </BaseButton>
                <div className="">
                <AntDesignSearchBox54origins onSearch={setValueSearch} styles={{width: 315}}/>
                </div>
            </div>

                <div className="wrapper_list_assigned_user_btn height-350">
                    <List
                    className="p-2"
                    dataSource={projects}
                    loading={false}
                    renderItem={(item, key) => (
                        <List.Item
                        key={(item?.uuid || item.label) + key}
                        className={cn(
                            'item_from_list_items',
                            {
                            selected: filteredProjects.includes(item.uuid),
                            // disabled: vectorIssues.filter(el => el?.uuid?.includes(item?.uuid)).length > 0
                            disabled: false
                            },
                        )}
                        onClick={() => filteredProjects.includes(item.uuid) ? 
                            setFilteredProjects(filteredProjects.filter(el => el !== item.uuid)) 
                            : setFilteredProjects(prev => [...prev, item.uuid])
                        }
                        >
                        <List.Item.Meta
                            title={(
                            <div className="flex items-center">
                                {/* <span className="inline-flex pl-1 pr-3 text-xs whitespace-nowrap">
                                {`${item?.id || 0}`}
                                </span> */}
                                <span className="inline-flex px-1">{item?.title}</span>
                            </div>
                            )}
                        />
                        </List.Item>
                        )}
                        />
                    </div>
                    </div>
            <div className="substrate_assigned_users" onClick={toggleBtn} role="none" />
        </div>
        )}
    >
    {/* <BaseButton
    id='addIssueButton'
    size="small"
    className={'ml-2'}
    type="primary"
    ghost
    disabled={"protected"}
    >
    {capitalize(t('Attach issue'))}
    </BaseButton> */}
   <Icon path={mdiChevronDown} size={1} />
    </Popover>

    );
}

export default ProjectFilterForUnplannedIssues;

ProjectFilterForUnplannedIssues.propTypes = {
  projects: array,
  selectedProjects: array,
  setSelectedProjects: func
}