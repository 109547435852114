import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getVector, getIssueNew } from '../projectFlow/actions/projectFlowActions';
import { getProjectGoalRequest } from '../projectFlow/actions/projectGoalsActions';
import { getDocumentOrIdea } from '../projectFlow/actions/DocumentsActions';
import useURLParams from '../hooks/useURLParams';
import {getChecklistFunc, getTestCase, getTestCycleFunc, getTestSuiteFunc} from '../projectFlow/actions/QaActions';

import { PROJECT_TABS } from '../projectFlow/components/project/projectView/projectTabs';
import { getProjectMilestoneRequest } from '../projectFlow/actions/projectMilestonesActions';
import { partitionNamesConfig } from '../api/appConfig';

function ShortLinksRedirect() {
  const dispatch = useDispatch();
  const { partition: urlPartition } = useParams();

  const { routeNavigateTo, currentLocationPathname } = useURLParams();

  const goToDefaultPage = () => routeNavigateTo('/');
  const goToPublicDefaultPage = () => routeNavigateTo('public/user');

  const checkParentAndMakeAction = (parent, action, ...params) => (parent
    ? action(parent, ...params)
    : goToDefaultPage());

  const checkParentForVectorAndMakeRedirect = (uuid, partition, callback) => {
    dispatch(getVector({ uuid, partition }))
      .then((res) => {
        const parentForVector = res[0]?.parent || null;
        checkParentAndMakeAction(parentForVector, callback);
      })
      .catch(() => {
        goToDefaultPage();
      });
  };

  const checkParentForIssueAndMakeRedirect = (uuid, partition, callback) => {
    dispatch(getIssueNew({ uuid, partition }))
      .then((res) => {
        const {
          params: {
            project: projectUUID,
            vector: vectorUUID,
          } = {}
        } = res[0] || {};

        if(projectUUID) {
          checkParentAndMakeAction(projectUUID, callback);
        }

        if(vectorUUID) {
          checkParentForVectorAndMakeRedirect(vectorUUID, partition, callback)
        }

        goToDefaultPage();
      })
      .catch(() => {
        goToDefaultPage();
      });
  };

  const checkParentForDocumentAndMakeRedirect = (uuid, partition, callback) => {
    dispatch(getDocumentOrIdea({ uuid, partition }))
      .then((res) => {
        const parentForIssue = res[0]?.parent || null;
        checkParentAndMakeAction(parentForIssue, callback);
      })
      .catch(() => {
        goToDefaultPage();
      });
  };

  const checkParentForGoalAndMakeRedirect = (uuid, partition, callback) => {
    dispatch(getProjectGoalRequest({ uuid, partition }))
      .then((res) => {
        const parentForGoal = res[0]?.parent || null;
        checkParentAndMakeAction(parentForGoal, callback);
      })
      .catch(() => {
        goToDefaultPage();
      });
  };

  const checkParentForMilestoneAndMakeRedirect = (uuid, partition, callback) => {
    dispatch(getProjectMilestoneRequest({ uuid, partition }))
      .then((res) => {
        const parentForMilestone = res[0]?.parent || null;
        checkParentAndMakeAction(parentForMilestone, callback);
      })
      .catch(() => {
        goToDefaultPage();
      });
  };

  const checkParentForTestSuiteAndMakeRedirect = (uuid, partition, callback) => {
    dispatch(getTestSuiteFunc({ uuid, partition }))
      .then((res) => {
        const parentForTestSuite = res[0]?.parent || null;
        checkParentAndMakeAction(parentForTestSuite, callback);

      })
      .catch(() => {
        goToDefaultPage();
      });
  };

  const checkParentForTestCycleAndMakeRedirect = (uuid, partition, callback) => {
    dispatch(getTestCycleFunc({ uuid, partition }))
      .then((res) => {
        const parentForTestCycle = res[0]?.parent || null;
        checkParentAndMakeAction(parentForTestCycle, callback);

      })
      .catch(() => {
        goToDefaultPage();
      });
  };

  const checkParentForChecklistAndMakeRedirect = (uuid, partition, callback) => {
    dispatch(getChecklistFunc({ uuid, partition }))
      .then((res) => {
        const parentForChecklist = res[0]?.parent || null;
        checkParentAndMakeAction(parentForChecklist, callback);

      })
      .catch(() => {
        goToDefaultPage();
      });
  };

  const getPmLink = () => {
    const arrOfRout = currentLocationPathname.split('=');
    const pathForEntity = arrOfRout[0];
    const entityUUID = arrOfRout[1];

    switch (pathForEntity) {
      case '/s/pm/p':
        routeNavigateTo('/pm/projects/project', {
          activeProject: entityUUID,
          tab: PROJECT_TABS.INFO,
        });
        break;
      case '/s/pm/v':
        checkParentForVectorAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition1,
          (parent) => routeNavigateTo('/pm/projects/issuesnew', {
            activeProject: parent,
            activeVector: entityUUID,
          }),
        );
        break;
      case '/s/pm/i':
        checkParentForIssueAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition1,
          (parent) => routeNavigateTo('/pm/projects/issuesnew', {
            activeProject: parent,
            activeIssue: entityUUID,
          }),
        );
        break;
      case '/s/pm/d':
        checkParentForDocumentAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition1,
          (parent) => routeNavigateTo('/pm/projects/docs', {
            activeProject: parent,
            activeDoc: entityUUID,
          }),
        );
        break;
      case '/s/pm/qad':
        checkParentForDocumentAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition1,
          (parent) => routeNavigateTo('/pm/projects/qa', {
            activeProject: parent,
            activeTab: 'documents',
            activeQaDoc: entityUUID,
          }),
        );
        break;
      case '/s/pm/n':
      case '/s/pm/id':
        checkParentForDocumentAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition1,
          (parent) => routeNavigateTo('/pm/projects/docs', {
            activeProject: parent,
            activeNote: entityUUID,
          }),
        );
        break;
      case '/s/pm/s':
        checkParentForDocumentAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition1,
          (parent) => routeNavigateTo('/pm/projects/docs', {
            activeProject: parent,
            activeSheet: entityUUID,
          }),
        );
        break;
      case '/s/pm/g':
        checkParentForGoalAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition1,
          (parent) => routeNavigateTo('/pm/projects/planning', {
            activeProject: parent,
            goal: entityUUID,
          }),
        );
        break;
      case '/s/pm/ml':
        checkParentForMilestoneAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition1,
          (parent) => routeNavigateTo('/pm/projects/planning', {
            activeProject: parent,
            milestone: entityUUID,
          }),
        );
        break;
        case '/s/pm/ts':
          checkParentForTestSuiteAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition1,
          (parent) => routeNavigateTo('/pm/projects/qa', {
            activeProject: arrOfRout[2],
            activeTab: "suites",
            activeSuite: entityUUID,
          }),
        );
        break;
        case '/s/pm/tc':
          checkParentForTestCycleAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition1,
          (parent) => routeNavigateTo('/pm/projects/qa', {
            activeProject: arrOfRout[2],
            activeTab: "cycles",
            activeCycle: entityUUID,
          }),
        );
        break;
        case '/s/pm/cl':
          checkParentForChecklistAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition1,
          (parent) => routeNavigateTo('/pm/projects/qa', {
            activeProject: arrOfRout[2],
            activeTab: "checklists",
            activeChecklist: entityUUID,
          }),
        );
        break;
      default:
        goToDefaultPage();
        break;
    }
  };

  const getPublicLink = () => {
    const location = currentLocationPathname.split('=');

    const userUUID = location[1].split('/')[0];
    const entityUUID = location[2];
    const pathForEntity = location[1].split('/')[1];

    // Писать ссылку в компоненте CopyURL

    switch (pathForEntity) {
      case 'p':
        routeNavigateTo(`/public/user/${userUUID}/projects/project`, {
          activeProject: entityUUID,
          tab: PROJECT_TABS.INFO,
        });
        break;
      case 'v':
        checkParentForVectorAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition3,
          (parent) => routeNavigateTo(`/public/user/${userUUID}/projects/issuesnew`, {
            activeProject: parent,
            activeVector: entityUUID,
          }),
        );
        break;
      case 'i':
        checkParentForIssueAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition3,
          (parent) => routeNavigateTo(`/public/user/${userUUID}/projects/issuesnew`, {
            activeProject: parent,
            activeIssue: entityUUID,
          }),
        );
        break;
      case 'd':
        checkParentForDocumentAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition3,
          (parent) => routeNavigateTo(`/public/user/${userUUID}/projects/docs`, {
            activeProject: parent,
            activeDoc: entityUUID,
          }),
        );
        break;
      case 'qad':
        checkParentForDocumentAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition3,
          (parent) => routeNavigateTo(`/public/user/${userUUID}/projects/qa`, {
            activeProject: parent,
            activeTab: 'documents',
            activeDoc: entityUUID,
          }),
        );
        break;
      case 'n':
        checkParentForDocumentAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition3,
          (parent) => routeNavigateTo(`/public/user/${userUUID}/projects/docs`, {
            activeProject: parent,
            activeNote: entityUUID,
          }),
        );
        break;
      case 's':
        checkParentForDocumentAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition3,
          (parent) => routeNavigateTo(`/public/user/${userUUID}/projects/docs`, {
            activeProject: parent,
            activeSheet: entityUUID,
          }),
        );
        break;
      case 'g':
        checkParentForGoalAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition3,
          (parent) => routeNavigateTo(`/public/user/${userUUID}/projects/planning`, {
            activeProject: parent,
            goal: entityUUID,
          }),
        );
        break;
      case 'ml':
        checkParentForMilestoneAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition3,
          (parent) => routeNavigateTo(`/public/user/${userUUID}/projects/planning`, {
            activeProject: parent,
            milestone: entityUUID,
          }),
        );
        break;
      case 'ts':
        checkParentForTestSuiteAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition3,
          (parent) => routeNavigateTo(`/public/user/${userUUID}/projects/qa`, {
            activeProject: location[3],
            activeTab: "suites",
            activeSuite: entityUUID,
          }),
        );
        break;
        case 'tc':
          checkParentForTestCycleAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition3,
          (parent) => routeNavigateTo(`/public/user/${userUUID}/projects/qa`, {
            activeProject: location[3],
            activeTab: "cycles",
            activeCycle: entityUUID,
          }),
        );
        break;
        case 'cl':
          checkParentForChecklistAndMakeRedirect(
          entityUUID,
          partitionNamesConfig.partition3,
          (parent) => routeNavigateTo(`/public/user/${userUUID}/projects/qa`, {
            activeProject: location[3],
            activeTab: "checklists",
            activeChecklist: entityUUID,
          }),
        );
        break;
      default:
        goToPublicDefaultPage();
        break;
    }
  };

  const initFunc = () => {
    if (urlPartition === 'pm') {
      return getPmLink();
    }

    return getPublicLink();
  };

  useEffect(() => {
    initFunc();
  }, []);

  return null;
}

export default ShortLinksRedirect;
