import React, { useMemo } from 'react';
import {
  arrayOf,
  number,
  object,
  string,
  oneOf,
  oneOfType,
  bool,
} from 'prop-types';
import { useSelector } from 'react-redux';

import ActorAvatarsGroup from './ActorAvatarsGroup';

import { getProjectParams } from '../../../selectors/selectors';
import useActorsW54 from '../../../../actors/hooks/useActorsW54';
import { getInitialsFromActors } from '../../../../actors/actorsUtils';

function WrapperAvatarsGroupForProjectWithHook({
  wrapperClassName = '',
  actors = [],
  maxCount,
  popoverPlacement,
  popoverTrigger,
  avatarSize,
  avatarGap,
  avatarStyles,
  listAvatarGap,
  listAvatarSize,
  wrapperListStyles,
  tooltipPlacement,
  groupButtonSize,
  groupButtonStyles,
  withSearch = true,
  checkInProject = true,
  checkProjectRole = false,
}) {
  const projectParams = useSelector(getProjectParams);

  const { users: usersW54 = [] } = useActorsW54({ actors });

  const getRoleFromProps = (uuid) => actors.find((user) => user.uuid === uuid)?.role || '';

  const getRoleFromProject = (uuid) => {
    const { users = [] } = projectParams;
    return users.find((user) => user.uuid === uuid)?.role || '';
  };

  const getRole = (uuid) => {
    let role = '';

    if (checkProjectRole) role = getRoleFromProject(uuid);
    else role = getRoleFromProps(uuid);

    return role;
  };

  const currentActors = useMemo(() => {
    const existedActors = [];
    const allActors = getInitialsFromActors(usersW54);
    actors.forEach((actor) => {
      let actorUUID = '';
      if (typeof actor === 'object') {
        actorUUID = actor?.uuid;
      } else if (typeof actor === 'string') {
        actorUUID = actor;
      }

      if (checkInProject && !projectParams?.usersSearch?.includes(actorUUID)) return;

      if (allActors.has(actorUUID)) {
        existedActors.push({
          ...allActors.get(actorUUID),
          role: getRole(actorUUID),
        });
      }
    });

    return existedActors;
  }, [JSON.stringify(usersW54),
  JSON.stringify(actors),
  JSON.stringify(projectParams?.usersSearch)
  ]);
  // console.log('currentActors', currentActors);

  return (
    <div className={`flex items-center ${wrapperClassName}`}>
      <ActorAvatarsGroup
        actorAvatars={currentActors}
        maxCount={maxCount}
        popoverPlacement={popoverPlacement}
        popoverTrigger={popoverTrigger}
        avatarGap={avatarGap}
        avatarSize={avatarSize}
        avatarStyles={avatarStyles}
        listAvatarGap={listAvatarGap}
        listAvatarSize={listAvatarSize}
        wrapperListStyles={wrapperListStyles}
        tooltipPlacement={tooltipPlacement}
        groupButtonSize={groupButtonSize}
        groupButtonStyles={groupButtonStyles}
        withSearch={withSearch}
      />
    </div>
  );
}

WrapperAvatarsGroupForProjectWithHook.propTypes = {
  wrapperClassName: string,
  actors: oneOfType([arrayOf(object), arrayOf(string)]),
  maxCount: number,
  popoverPlacement: string,
  popoverTrigger: string,
  avatarSize: oneOfType([number, oneOf(['small', 'default', 'large']), object]),
  avatarGap: number,
  avatarStyles: object,
  listAvatarGap: number,
  listAvatarSize: oneOfType([number, oneOf(['small', 'default', 'large']), object]),
  wrapperListStyles: object,
  tooltipPlacement: string,
  checkInProject: bool,
  groupButtonSize: oneOf(['small', 'middle', 'large']),
  groupButtonStyles: object,
  withSearch: bool,
  checkProjectRole: bool,
};

export default WrapperAvatarsGroupForProjectWithHook;
