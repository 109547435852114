import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card, Collapse, Row, Tag,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, prop, propOr, tryCatch } from "ramda";
import '../style/Qa.scss';

import { capitalize, get } from 'lodash';
import { useTranslation } from 'react-i18next';


import {
  getArchiveTestCycleUUID,
  getProjectUUID,
  getTestCaseUuidInCycle,
  getTestCaseUuidInSuite,
  getTestCycleUUID,
  getTestSuiteUUID,
  globalUpdateEntity,
  globalUserFilterTags,
  isChangedGlobalUserFilter,
  lastActiveTestCaseUuid,
  getMe,
} from '../../selectors/selectors';
import { getCurrentArchiveStateUUID } from '../../selectors/archiveStateSelectors';
import {
  clearOneTestCaseinTestCycleForClass,
  clearOneTestCaseinTestSuiteForClass,
  clearSingleCaseForClass,
  createTestCycle,
  createTestCaseForSuite,
  createTestCaseForCycle,
  createTestSuite,
  getTestCase,
  getTestCycleFunc,
  getListOfTestCyclesFunc,
  updateTestSuite,
  getListOfTestCasesInSuiteFunc,
  getTestSuiteFunc,
  getArchivedTestCycleFunc,
  getListOfArchivedTestCyclesFunc,
  getListOfTestCasesInCycleFunc,
  getListOfArchivedTestCasesFunc,
  clearOneTestCaseinTestSuite,
  clearOneTestCaseinTestCycle,
  clearTestCycle,
  clearSuite,
  clearListTestCaseInSuiteInStore,
  clearListOfTestCasesInCycle,
  clearArchivedTestCycle,
  clearListOfTestCasesInArchiveCycle, createChecklist, getChecklistFunc,
  getOrCreateQAEntity,
} from '../../actions/QaActions';
import axiosRequest from '../../../api/apiAxios';
import {
  getHeadUUID,
} from '../../../MainUtils';

import { BugTrackerContext, ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';
import { sendSystemComment } from '../../actions/projectFlowActions';
import { getListAndPartialReadEntities } from '../../../entity/actions/entityActions';
import { clearGlobalUpdateEntity } from '../../reducers/slicers/breadcrumbsSlicer';
import { qaNotify } from './utilsQa';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import SuiteSection from './suite/SuiteSection';
import CycleSection from './cycles/CycleSection';
import useURLParams from '../../../hooks/useURLParams';
import { partitionNamesConfig } from '../../../api/appConfig';
import GeneralOverview from './generalOverview/GeneralOverview';
import { descriptionTypeOFTesting } from './QaUtils';
import { capitalizeFirstChar } from '../../../MainUtils';
import RunQa from './RunQa'
import useGetUserPerm from '../../../permissions/hooks/useGetUserPerm';
import { Link } from 'react-router-dom';
import {Radio, Tabs} from 'antd';
import DocsStartPage from '../docView&ideas/DocsStartPage';

export default function BugTrackerTemplate({
  partition = partitionNamesConfig.partition1,
  getProjectMembers,
  viewMode,
  changeViewMode,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMounted = useRef(null);

  const {
    getURLParams, routeNavigateTo, setSearchParams, clearSearchParams, addAndRemoveSearchParams,
  } = useURLParams();

  const getState = useSelector((state) => state);
  const projectUUID = useSelector(getProjectUUID);
  const archiveStateUUID = useSelector(getCurrentArchiveStateUUID);
  const testCycleUUID = useSelector(getTestCycleUUID);
  const testSuiteUUID = useSelector(getTestSuiteUUID);
  const lastActiveTestCaseUUID = useSelector(lastActiveTestCaseUuid);
  const testCaseUuidInSuite = useSelector(getTestCaseUuidInSuite);
  const testCaseUuidInCycle = useSelector(getTestCaseUuidInCycle);
  const globalUpdate = useSelector(globalUpdateEntity);
  const archiveTestCycle = useSelector(getArchiveTestCycleUUID);
  const isChangedUserFilter = useSelector(isChangedGlobalUserFilter);
  const userFilterTags = useSelector(globalUserFilterTags);
  const myActorUUID = useSelector(getMe);

  const {
    initialPathForHistory,
    sendAssignedNotifications,
  } = useContext(ProjectMainLayerContext);

  const {
    activeCycle, activeSuite, activeCaseCycle, activeCaseSuite, activeTab, activeChecklist,
  } = getURLParams();

  const finalParentForQA = archiveStateUUID || projectUUID;
  // const defaultTotalCount = { suite: 0, cycle: 0, archive: 0 };
  const defaultConfigTemplate = {
    parent: qaEntityUUID,
    partition,
    limit: 5,
    offset: 0,
    orderBy: 'created',
    order: 'desc',
  };
  const defaultConfigs = {
    suite: defaultConfigTemplate,
    checkList: defaultConfigTemplate,
    cycle: { params: { archive: false },...defaultConfigTemplate },
    archive: { params: { archive: true }, ...defaultConfigTemplate },
  };

  const defaultConfigTemplateCase = {
    orderBy: 'order',
    order: 'asc',
    params: {},
    with_files: false,
    partition,
    limit: 5,
    offset: 0,
  };

  const defaultConfigsCase = {
    suite: { parent: activeSuite, ...defaultConfigTemplateCase },
    cycle: { parent: activeCycle, ...defaultConfigTemplateCase },
    cycleArchive: { parent: '', ...defaultConfigTemplateCase },
  };

  // const [files, changeFiles] = useState([]);
  const [totalCountSuite, setTotalCountSuite] = useState(0);
  const [totalCountCycle, setTotalCountCycle] = useState(0);
  const [totalCountArchive, setTotalCountArchive] = useState(0);
  const [totalCheckList, setTotalCountCheckList] = useState(0);

  const [totalCountCaseSuite, setTotalCountCaseSuite] = useState(0);
  const [totalCountCaseCycle, setTotalCountCaseCycle] = useState(0);
  const [totalCountCaseCycleArchive, setTotalCountCaseCycleArchive] = useState(0);

  const [config, setConfig] = useState(defaultConfigs);
  const [configsCase, setConfigsCase] = useState(defaultConfigsCase);
  const [axiosListOfCyclesCounter, changeAxiosListOfCyclesCounter] = useState(1);

  const [selectedTypeOfTesting, changeSelectedTypeOfTesting] = useState([]);
  const [isCreatorCycleByType, changeIsCreatorCycleByType] = useState(null);
  
  const [qaEntityUUID, setQAEntityUUID] = useState(null)
  const [qaEntityParams, setQAEntityParams] = useState({})
  const [activeCycleUUID, setActiveCycleUUID] = useState()

  const switchViewFunc = (tab) => {
      addAndRemoveSearchParams(
      { activeTab: tab },
      ['activeCycle', 'activeSuite', 'activeCaseCycle', 'activeCaseSuite', 'activeChecklist', 'ts_type_of_testing', 'tcy_status', 'tcy_archive', 'tc_status', 'tc_type_of_testing', 'checkliststatus'],
    );
    changeViewMode(tab.toLowerCase());
  };

  const onPageChange = (e) => {
    switchViewFunc(e.target.value);
  };

  const changeQAHistory = ({
    entity,
    uuid = '',
    expandedRows = [],
    clearCases,
  }) => {
    const uuidPositionFromExpanded = expandedRows.indexOf(uuid);
    const previousUUIDFromExpanded = expandedRows[uuidPositionFromExpanded - 1];
    const nextUUIDFromExpanded = expandedRows[uuidPositionFromExpanded + 1];

    switch (entity) {
      case 'suite':
        setSearchParams({ activeSuite: uuid });
        break;
      case 'clearSuite':
        clearSearchParams(['activeSuite', 'activeCaseSuite']);
        dispatch(clearOneTestCaseinTestSuite);
        dispatch(clearSuite);
        if (clearCases) {
          dispatch(clearListTestCaseInSuiteInStore);
        }
        break;
      case 'cycle':
        setSearchParams({ activeCycle: uuid });
        break;
      case 'clearCycle':
        clearSearchParams(['activeCycle', 'activeCaseCycle']);
        dispatch(clearOneTestCaseinTestCycle);
        dispatch(clearTestCycle);
        if (clearCases) {
          dispatch(clearListOfTestCasesInCycle);
        }
        break;
      case 'clearCycleArchive':
        dispatch(clearArchivedTestCycle);
        if (clearCases) {
          dispatch(clearListOfTestCasesInArchiveCycle);
        }
        break;
      case 'caseSuite':
        // if selected case is closed
        if (uuid === activeCaseSuite) {
          if (!isNil(previousUUIDFromExpanded) || !isNil(nextUUIDFromExpanded)) {
            // if single test case was closed, select next or previous test case
            setSearchParams({ activeCaseSuite: previousUUIDFromExpanded || nextUUIDFromExpanded });
          } else {
            clearSearchParams(['activeCaseSuite']);
          }
          break;
        }
        setSearchParams({ activeCaseSuite: uuid });
        break;
      case 'caseCycle':
        if (uuid === activeCaseCycle) {
          if (!isNil(previousUUIDFromExpanded) || !isNil(nextUUIDFromExpanded)) {
            // if single test case was closed, select next or previous test case
            setSearchParams({ activeCaseCycle: previousUUIDFromExpanded || nextUUIDFromExpanded });
          } else {
            clearSearchParams(['activeCaseCycle']);
          }
          break;
        }

        setSearchParams({ activeCaseCycle: uuid });
        break;
      case 'checklist':
        setSearchParams({ activeChecklist: uuid });
        break;
      default:
        break;
    }
  };

  function getListOfTestSuitesFunc({
    parent,
    params,
    partition,
    limit,
    offset,
    search_data,
    orderBy,
    order,
    constants,
    entities,
  }) {
    const data = {
      entity_type: 'testSuite',
      entities,
      parent,
      params: { ...params },
      limit,
      offset,
      search_data,
      params_fields: {
        id: 'id',
        title: 'title',
        description: 'description',
        assignToUser: 'assignToUser',
        cases_count: 'cases_count',
        typeOfTesting: 'typeOfTesting',
      },
    };

    if (orderBy) {
      data['order_by'] = orderBy;
      data.order = order;
    }

    const currentConstants = constants || [
      ProjectTypeCommonConstants.LIST_OF_TEST_SUITES_REQUEST,
      ProjectTypeCommonConstants.LIST_OF_TEST_SUITES_SUCCESS,
      ProjectTypeCommonConstants.LIST_OF_TEST_SUITES_FAILURE,
    ];

    return dispatch(getListAndPartialReadEntities(
      { data, partition, constants: currentConstants },
    ));
  }

  const createPromiseNewTestSuite = (params, notUpdateStore) => {
    const data = {
      parent: qaEntityUUID,
      params,
      partition,
    };

    if (notUpdateStore) {
      data.customConstants = ['REQUEST', 'SUCCESS', 'FAILURE'];
    }

    const uniqKey = `testSuite${Date.now()}`;
    qaNotify({
      param: 'info',
      key: uniqKey,
      message: 'Creating new test suite...',
    });

    return dispatch(createTestSuite(data))
      .then((res) => {
        qaNotify({
          param: 'success',
          key: uniqKey,
          message: 'Test suite created',
        });
        return res;
      });
  };

  const createPromiseNewTestCycle = (params, customConstants) => {
    const data = {
      parent: qaEntityUUID,
      params,
      partition,
    };

    if (customConstants) {
      data.customConstants = ['REQUEST', 'SUCCESS', 'FAILURE'];
    }

    const uniqKey = `testCycle${Date.now()}`;
    qaNotify({
      param: 'info',
      key: uniqKey,
      message: 'Creating new test cycle...',
    });

    return dispatch(createTestCycle(data)).then((res) => {
      qaNotify({
        param: 'success',
        key: uniqKey,
        message: 'Test cycle created',
      });
      return res;
    });
  };

  const createPromiseNewChecklist = (params, customConstants) => {
    const data = {
      parent: qaEntityUUID,
      params,
      partition,
    };

    if (customConstants) {
      data.customConstants = ['REQUEST', 'SUCCESS', 'FAILURE'];
    }

    const uniqKey = `checklist${Date.now()}`;
    qaNotify({
      param: 'info',
      key: uniqKey,
      message: 'Creating new checklist...',
    });

    return dispatch(createChecklist(data)).then((res) => {
      qaNotify({
        param: 'success',
        key: uniqKey,
        message: 'Checklist created',
      });
      return res;
    });
  };

  function getListOfChecklistsFunc({
    parent,
    params,
    partition,
    limit,
    offset,
    search_data,
    orderBy,
    order,
    constants,
    entities,
  }) {
    const data = {
      entity_type: 'checklist',
      entities,
      parent,
      params: { ...params },
      limit,
      offset,
      search_data,
      params_fields: {
        id: 'id',
        title: 'title',
        status: 'status',
        helperInStatus: 'helperInStatus',
      },
    };

    if (orderBy) {
      data[orderBy === 'created' ? 'order_by' : 'order_by_params'] = orderBy;
      data.order = order;
    }

    const currentConstants = constants || [
      ProjectTypeCommonConstants.LIST_OF_CHECKLIST_REQUEST,
      ProjectTypeCommonConstants.LIST_OF_CHECKLIST_SUCCESS,
      ProjectTypeCommonConstants.LIST_OF_CHECKLIST_FAILURE,
    ];

    return dispatch(getListAndPartialReadEntities(
      { data, partition, constants: currentConstants },
    ));
  }

  const createPromiseNewTestCaseForSuite = (dataConfig) => {
    const data = {
      ...dataConfig,
      partition,
    };

    const uniqKey = `testCaseForSuite${Date.now()}`;
    qaNotify({
      param: 'info',
      key: uniqKey,
      message: 'Creating new test case...',
    });

    return dispatch(createTestCaseForSuite(data))
      .then((response) => {
        const assignedUsers = get(data, 'params.assignToUser');

        qaNotify({
          param: 'success',
          key: uniqKey,
          message: 'Test case created',
        });

        sendAssignedNotifications({
          entity_type: 'testCase',
          eventName: 'assigned_test_case',
          assignedUsers,
          entityUUID: get(response, '[0].uuid'),
          message: get(response, '[0].params.title'),
          entityUUIDsFields: {
            testSuiteUUID: get(response, '[0].parent'),
            testCaseUUID: get(response, '[0].uuid'),
            projectUUID,
          },
        });

        return ({ response, getState });
      })
      .catch(() => {
        qaNotify({
          param: 'error',
          key: uniqKey,
          message: 'Test case error',
        });
      });
  };

  const createPromiseNewTestCaseForCycle = ({ parent, params }) => {
    const data = {
      parent,
      params,
      partition,
    };

    const uniqKey = `testCaseForCycle${Date.now()}`;

    qaNotify('startTestCase', uniqKey);

    return dispatch(createTestCaseForCycle(data))
      .then(() => {
        qaNotify('endTestCase', uniqKey);
      })
      .catch(() => {
        qaNotify({
          param: 'error',
          key: uniqKey,
          message: 'Test case error',
        });
      });
  };

  const getListOfTestSuites = async (customConfig) => { 
    if (qaEntityUUID) {
      let currentConfig = customConfig || config.suite;

      if (userFilterTags.length !== 0) {
        currentConfig = {
          ...currentConfig,
          params: {
            ...currentConfig?.params,
            assignToUser: userFilterTags,
          },
        };
      }

      if (currentConfig?.params?.typeOfTesting?.length === 0) {
        delete currentConfig.params.typeOfTesting;
      }

      const data = await getListOfTestSuitesFunc({ ...currentConfig, parent: qaEntityUUID });      
      setTotalCountSuite(prop('total', data));
      return prop('data', data);
    }
  };

  const getListOfChecklists = async (customConfig) => {
    if (qaEntityUUID) {
      const currentConfig = customConfig || config.checkList;
      const data = await getListOfChecklistsFunc({ ...currentConfig, parent: qaEntityUUID });
      setTotalCountCheckList(prop('total', data));

      return prop('data', data);
    }
  };

  const getListOfTestCycles = async (customConfig) => {
    if (qaEntityUUID) {
      let currentConfig = customConfig || config.cycle;

      if (userFilterTags.length !== 0) {
        currentConfig = {
          ...currentConfig,
          params: {
            ...currentConfig?.params,
            assignToUser: userFilterTags,
          },
        };
      }

      if (currentConfig?.params?.typeOfTesting?.length === 0) {
        delete currentConfig.params.typeOfTesting;
      }

      const data = await dispatch(getListOfTestCyclesFunc(
        { ...currentConfig, parent: qaEntityUUID },
      ));
      setTotalCountCycle(prop('total', data));
      return prop('data', data);
    }
  };

  const getListTestCycleInArchive = async (customConfig) => {
    if (qaEntityUUID) {
      let currentConfig = customConfig || config.archive;

      if (userFilterTags.length !== 0) {
        currentConfig = {
          ...currentConfig,
          params: {
            ...currentConfig?.params,
            assignToUser: userFilterTags,
          },
        };
      }

      if (currentConfig?.params?.typeOfTesting?.length === 0) {
        delete currentConfig.params.typeOfTesting;
      }

      const data = await dispatch(getListOfArchivedTestCyclesFunc(
        { ...currentConfig, parent: qaEntityUUID },
      ));
      setTotalCountArchive(prop('total', data));
      return prop('data', data);
    }
  };

  const getListOfTestCasesInSuite = async (parent, customConfig) => {
 
    let newConfig = customConfig || configsCase.suite;

    if (userFilterTags.length !== 0) {
      newConfig = {
        ...newConfig,
        params: {
          ...newConfig?.params,
          assignToUser: userFilterTags,
        },
      };
    }

    if(parent) {
      const data = await dispatch(getListOfTestCasesInSuiteFunc({ ...newConfig, parent }));
      setTotalCountCaseSuite(prop('total', data));
      return data;
    } else {
      return [];
    }


  };

  const getListOfTestCasesInCycle = async (parent, customConfig) => {
    let newConfig = customConfig || configsCase.cycle;

    newConfig = {
      ...newConfig,
      params_fields: {
        id: 'id',
        title: 'title',
        description: 'description',
        comment: 'comment',
        typeOfTesting: 'typeOfTesting',
        order: 'order',
        steps: 'steps',
        casePriority: 'casePriority',
        assignToUser: 'assignToUser',
        status: 'status',
        template: 'template',
        counterBug: 'counterBug',
        executed_by: 'executed_by',
        executed_on: 'executed_on',
        tags: 'tags',
      },
      fields: {
        parent: 'parent',
        entity_type: 'entity_type',
        actor: 'actor',
        'actor.created': 'created',
        'actor.uinfo': 'uinfo',
      },
    };
    if (userFilterTags.length !== 0) {
      newConfig.params.assignToUser = userFilterTags;
    }

    const data = await dispatch(getListOfTestCasesInCycleFunc({ ...newConfig, parent }));
    setTotalCountCaseCycle(prop('total', data));
    return prop('data', data);
  };

  const getListTestCaseInCycleFromArchive = async (parent, customConfig) => {
    let newConfig = customConfig || configsCase.cycleArchive;

    if (userFilterTags.length !== 0) {
      newConfig = {
        ...newConfig,
        params: {
          ...newConfig?.params,
          assignToUser: userFilterTags,
        },
      };
    }

    const data = await dispatch(getListOfArchivedTestCasesFunc({ ...newConfig, parent }));
    setTotalCountCaseCycleArchive(prop('total', data));
    return prop('data', data);
  };

  const deleteCycle = (uuid) => {
    if (activeCycle === uuid) {
      clearSearchParams(['activeCycle', 'activeCaseCycle']);
    }
    getListOfTestCycles();
  };

  const deleteChecklist = (uuid) => {
    if (activeChecklist === uuid) {
      clearSearchParams(['activeChecklist']);
    }
    getListOfChecklists();
  };

  const qaRedirect = (entity, uuid = '') => {
    const search = {};
    switch (entity) {
      case 'suite':
        if (testCycleUUID) search.activeCycle = testCycleUUID;
        routeNavigateTo(`${initialPathForHistory}qa`, {
          activeProject: projectUUID,
          activeSuite: uuid,
          ...search,
        });
        break;

      default:
        break;
    }
  };

  const updateTestSuitePromise = ({ uuid, params, customConstants }) => {
    const data = {
      uuid: uuid || testSuiteUUID,
      params: { ...params },
      partition,
      customConstants,
    };

    const uniqKey = `testSuiteUpd${Date.now()}`;
    qaNotify({
      param: 'info',
      key: uniqKey,
      message: 'Updating test suite...',
    });

    console.log('tracker');

    return dispatch(updateTestSuite(data)).then((res) => {
      qaNotify({
        param: 'success',
        key: uniqKey,
        message: 'Test suite updated',
      });
      return res;
    });
  };

  const getTestCycleFromArchive = (uuid) => {
    const data = {
      uuid,
      partition,
    };
    return dispatch(getArchivedTestCycleFunc(data));
  };

  const getTestCaseFunc = (uuid) => {
    const params = {
      uuid,
      partition,
    };

    return dispatch(getTestCase(params));
  };

  const selectTestCycle = (uuid, withRequest = true) => {
    setActiveCycleUUID(uuid)
    const historyParams = {
      entity: 'cycle',
      uuid,
    };
    if (withRequest) {
      axiosRequest.abort('', [...Array(axiosListOfCyclesCounter).keys()]);

      changeAxiosListOfCyclesCounter(axiosListOfCyclesCounter + 1);
      dispatch(
        getTestCycleFunc({
          uuid,
          partition,
          cancelToken: axiosListOfCyclesCounter,
        }),
      );
      getListOfTestCasesInCycle(uuid);
    }
    changeQAHistory(historyParams);
  };

  const selectChecklist = (uuid, withRequest = true) => {
    const historyParams = {
      entity: 'checklist',
      uuid,
    };
    if (withRequest) {
      axiosRequest.abort('', [...Array(axiosListOfCyclesCounter).keys()]);

      changeAxiosListOfCyclesCounter(axiosListOfCyclesCounter + 1);
      dispatch(
        getChecklistFunc({
          uuid,
          partition,
          cancelToken: axiosListOfCyclesCounter,
        }),
      );
    }
    changeQAHistory(historyParams);
  };

  const selectTestCycleInArchive = (uuid) => {
    getTestCycleFromArchive(uuid);
    getListTestCaseInCycleFromArchive(uuid);
  };

  const getTestSuitePromise = (uuid, signal) => (
    dispatch(getTestSuiteFunc({
      uuid,
      partition,
      signal,
    }))
  );

  const AddCommentsCase = (comment, caseUUID) => {
    const data = {
      action: 'changed',
      entity: 'testCase',
      param: 'preconditions',
      value: '',
      descriptionComment: comment,
    };

    dispatch(sendSystemComment(caseUUID, data, partition));
  };

  const removeOneTestCaseInStore = (uuid) => {
    if (lastActiveTestCaseUUID === uuid) {
      dispatch(clearSingleCaseForClass());
    }
    if (testCaseUuidInSuite === uuid) {
      dispatch(
        clearOneTestCaseinTestSuiteForClass(),
      );
      return;
    }

    if (testCaseUuidInCycle === uuid) {
      dispatch(clearOneTestCaseinTestCycleForClass());
    }
  };

  const createEntitiesFromJSON = async (data) => {
    for (let i = 0; i < data.length; i += 1) {
      const { params, entity_type, listOfTestCases } = data[i];
      const listOfTestCasesLength = listOfTestCases.length;

      const createCasesFunc = async (uuid, func) => {
        if (listOfTestCasesLength !== 0) {
          for (let j = 0; j < listOfTestCasesLength; j += 1) {
            await func({ parent: uuid, params: listOfTestCases[j].params });
          }
        }
      };

      switch (entity_type) {
        case 'testSuite':
          await createPromiseNewTestSuite(params, true).then(async (suite) => {
            await createCasesFunc(getHeadUUID(suite), createPromiseNewTestCaseForSuite);
          });
          break;
        case 'testCycle':
          if (!params?.archive) {
            await createPromiseNewTestCycle(params, true).then((cycle) => {
              createCasesFunc(getHeadUUID(cycle), createPromiseNewTestCaseForCycle);
            });
          } else {
            await createPromiseNewTestCycle(params, true)
              .then(async (cycle) => {
                await createCasesFunc(getHeadUUID(cycle), createPromiseNewTestCaseForCycle);
              });
          }
          break;
        default:
          break;
      }
    }

    if (data.length !== 0) {
      getListOfTestSuites();
      getListOfTestCycles();
      getListTestCycleInArchive();
    }
  };

  const setNewConfigCallback = (type, data) => {
    setConfig({ ...config, [type]: data });
    switch (type) {
      case 'suite': return getListOfTestSuites(data);
      case 'cycle': return getListOfTestCycles(data);
      case 'checklist': return getListOfChecklists(data);
      case 'archive': return getListTestCycleInArchive(data);
      default:
    }
  };

  const setNewConfigCaseCallback = (type, data) => {
    setConfigsCase({ ...configsCase, [type]: data });
    switch (type) {
      case 'suite':
        return getListOfTestCasesInSuite(prop('parent', data) || activeSuite, data);
      case 'cycle': return getListOfTestCasesInCycle(prop('parent', data) || activeCycle, data);
      case 'cycleArchive': return getListTestCaseInCycleFromArchive(prop('parent', data) || archiveTestCycle, data);
      default:
    }
  };

  const updateFromGlobal = () => {
    const { entityType, action } = globalUpdate;
    if (entityType === 'testSuite' && (action === 'delete' || action === 'update')) {
      getListOfTestSuites();
    }
    if (entityType === 'activeCaseSuite' && action === 'delete') {
      getListOfTestCasesInSuite(activeSuite);
    }
    if (entityType === 'testCycle' && action === 'delete') {
      getListOfTestCycles();
    }
  };

  const initFunc = () => {
    getProjectMembers();
    getListOfTestSuites();
    getListOfTestCycles();
    getListTestCycleInArchive();
    getListOfChecklists();

    if ((
      isChangedUserFilter && isMounted.current)
      || (!isMounted?.current && userFilterTags?.length !== 0)
    ) {
      changeQAHistory({ entity: 'clearSuite' });
      changeQAHistory({ entity: 'clearCycle' });

      setConfig(defaultConfigs);
      return;
    }

    isMounted.current = true;

    if (activeSuite) {
      getTestSuitePromise(activeSuite);
      getListOfTestCasesInSuite(activeSuite);
    }

    if (activeCycle) {
      dispatch(getTestCycleFunc({
        uuid: activeCycle,
        partition,
      }));
      getListOfTestCasesInCycle(activeCycle);
    }

    if (activeCaseSuite) {
      getTestCaseFunc(activeCaseSuite, 'suite');
    }

    if (activeCaseCycle) {
      getTestCaseFunc(activeCaseCycle, 'cycle');
    }

    if (activeTab) {
      changeViewMode(activeTab);
    }
  };

  const { reloadPerm } = useGetUserPerm({
    entityUUID: projectUUID,
    partitionType: partition,
    needCheckPerm: false,
  })

  const renderTabs = () => {
    return(
      <div>
      <Radio.Group
        value={viewMode}
        onChange={onPageChange}
        style={{
          marginBottom: 16,
        }}
      >
        <Radio.Button value="cycles">Test Cycles</Radio.Button>
        <Radio.Button value='suites'>Test Suites</Radio.Button>
        <Radio.Button value="checklists">Checklists</Radio.Button>
        <Radio.Button value="documents">Documents</Radio.Button>
        <Radio.Button value="generaloverview">QA Overview</Radio.Button>
      </Radio.Group>
      </div>
    )
  }

  useEffect(() => {
    if (projectUUID && partition && myActorUUID) {      
      dispatch(getOrCreateQAEntity({
        actor: myActorUUID,
        parent: projectUUID,
        partition: partition,
      })).then(({uuid, params}) => {
        setQAEntityParams(params)
        setQAEntityUUID(uuid)        
      });
    }
  }, [projectUUID, partition, myActorUUID]);

  useEffect(() => {
    // logic for routing
    if (qaEntityUUID) {
      initFunc();
    }
  }, [JSON.stringify(userFilterTags), qaEntityUUID]);

  useEffect(() => {
    if (globalUpdate.entityType) {
      updateFromGlobal();
      dispatch(clearGlobalUpdateEntity());
    }
  }, [globalUpdate.entityType]);

  const BugTrackerContextData = useMemo(() => ({
    AddCommentsCase,
    changeQAHistory,
    createPromiseNewTestCycle,
    createPromiseNewTestSuite,
    getListOfTestCasesInCycle,
    getListOfTestCasesInSuite,
    getListOfTestCycles,
    getListOfTestSuites,
    getListTestCaseInCycleFromArchive,
    getListTestCycleInArchive,
    getTestCaseFunc,
    getTestSuitePromise,
    getListOfChecklists,
    partitionType: partition,
    qaRedirect,
    removeOneTestCaseInStore,
    selectTestCycle,
    updateTestSuitePromise,
    getListOfTestSuitesFunc,
    deleteChecklist,
    createPromiseNewChecklist,
    selectChecklist,
    changeIsCreatorCycleByType,
    isCreatorCycleByType,
    qaEntityUUID,
    qaEntityParams,
    setQAEntityParams,
    suiteConfig: config.suite,
    setNewConfigCallback1: setNewConfigCallback,
    createEntitiesFromJSON,
    activeCycleUUID,
  }), [partition, configsCase, config, finalParentForQA, qaEntityUUID, activeCycleUUID, changeQAHistory]);

  return (
    <BugTrackerContext.Provider
      value={BugTrackerContextData}
    >
      {
        renderTabs()
      }

      <Row span={24}>
        {viewMode === 'generaloverview' && (
        <GeneralOverview
          partition={partition}
          switchViewFunc={switchViewFunc}
          changeSelectedTypeOfTesting={changeSelectedTypeOfTesting}
          getListOfTestSuitesFunc={getListOfTestSuitesFunc}
        />
        )}
        {viewMode === 'suites' && (
          <>

            <SuiteSection
              partition={partition}
              totalCountSuite={totalCountSuite}
              totalCountCase={totalCountCaseSuite}
              configSuite={config.suite}
              configsCase={configsCase.suite}
              setNewConfigCaseCallback={setNewConfigCaseCallback}
              setNewConfigCallback={setNewConfigCallback}
              createEntitiesFromJSON={createEntitiesFromJSON}
              selectedTypeOfTesting={selectedTypeOfTesting}
            />

          </>
        )}
        {viewMode === 'cycles'
          && (

            <>
            <CycleSection
                  partition={partition}
                  title={capitalizeFirstChar(t('wms.projectflow.qa.cycles', 'test cycles'))}
                  id="mainCycleCollapse"
                  classNameBtn="btnBlue-outline"
                  typeCard="primaryCard"
                  mainClassName="primaryCard"
                  customFilterStyle={{ marginLeft: -25, marginTop: -25 }}
                  configsCase={configsCase.cycle}
                  config={config.cycle}
                  totalCountCase={totalCountCaseCycle}
                  totalCountCycle={totalCountCycle}
                  deleteCycle={deleteCycle}
                  setNewConfigCaseCallback={setNewConfigCaseCallback}
                  createEntitiesFromJSON={createEntitiesFromJSON}
                  setNewConfigCallback={setNewConfigCallback}
                  selectTestCycleCallback={selectTestCycle}
                  suiteConfig={config.suite}
                />

          {/* <CycleSection
                  partition={partition}
                  title={capitalizeFirstChar(t('wms.projectflow.qa.archive', 'archive'))}
                  id="mainArchiveCollapse"
                  classNameBtn="btnYellow-outline"
                  typeCard="yellowCard"
                  mainClassName="yellowCard"
                  customFilterStyle={{ marginLeft: -25, marginTop: -25 }}
                  defaultCollapseState={false}
                  isArchive
                  config={config.archive}
                  configsCase={configsCase.cycleArchive}
                  totalCountCase={totalCountCaseCycleArchive}
                  totalCountCycle={totalCountArchive}
                  deleteCycle={getListTestCycleInArchive}
                  setNewConfigCaseCallback={setNewConfigCaseCallback}
                  setNewConfigCallback={setNewConfigCallback}
                  createEntitiesFromJSON={createEntitiesFromJSON}
                  selectTestCycleCallback={selectTestCycleInArchive}
                /> */}
            </>

          )}
        {viewMode === 'checklists'
          && (
            <RunQa
              totalCountCheckList={totalCheckList}
              changeSelectedTypeOfTesting={changeSelectedTypeOfTesting}
              partition={partition}
              totalCountCase={totalCountCaseCycleArchive}
              setNewConfigCaseCallback={setNewConfigCaseCallback}
              config={config.cycle}
              configsCase={configsCase.cycle}
              totalCountCycle={totalCountCycle}
              setNewConfigCallback={setNewConfigCallback}
              createEntitiesFromJSON={createEntitiesFromJSON}
              deleteCycle={getListTestCycleInArchive}
              selectTestCycleCallback={selectTestCycle}
            />
          )}

          {viewMode === 'documents'
            && (
              <DocsStartPage 
                defaultPartition={partition}
                isQa 
              />
            )}
      </Row>
    </BugTrackerContext.Provider>
  );
}

BugTrackerTemplate.propTypes = {
  getProjectMembers: PropTypes.func,
  partition: PropTypes.string,
};
