import { mdiCardPlusOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { get, head, omit } from 'lodash';
import {
  array, bool, func, string,
} from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import BaseButton from '../../../../../components/_ui/BaseButton/BaseButton';

import { getUuidsfromParamsUsers } from '../../../../../MainUtils';
import { getIssueNew, getVector } from '../../../../actions/projectFlowActions';
import { getProject, getVectorInfo } from '../../../../selectors/selectors';
import useURLParams from '../../../../../hooks/useURLParams';
import { convertB64ToBlob } from '../../../../../components/uploader/utils';

export default function CloneIssueBtn({
  disabled,
  issueUUID,
  partitionType,
  setDefaultIssueState,
  buttonClassName = '',
}) {
  const dispatch = useDispatch();

  // const { t } = useTranslation();

  const { getURLParams } = useURLParams();

  const projectData = head(useSelector(getProject));
  const vectorData = head(useSelector(getVectorInfo));

  const { activeVector, activeProject } = getURLParams();
  const getListUsers = () => {
    if (activeProject && !activeVector) {
      const projectUUIDs = getUuidsfromParamsUsers(projectData?.params?.users);
      return projectUUIDs;
    }
    if (activeVector) {
      return getUuidsfromParamsUsers(vectorData?.params?.users);
    }
    return [];
  };

  const getIssue = () => dispatch(getIssueNew({
    uuid: issueUUID,
    params: {},
    constants: ['REQUEST', 'SUCCESS', 'FAILURE'],
    withFiles: true,
    partition: partitionType,
  }));

  const getFiles = async (res) => {
    const resFiles = get(res, '[0].files', []);
    const list = resFiles.map((i) => `data:${i.content_type};base64, ${i.file}`);

    const blobs = await convertB64ToBlob(list);

    const files = blobs.map((i, index) => new File(
      [i],
      resFiles[index].filename,
      { type: resFiles[index].content_type },
    ));

    return files;
  };

  const onClickOpen = async (e) => {
    e.stopPropagation();
    const res = await getIssue();
    let issueVector = vectorData ?? null;
    if (res?.[0].params?.vector && !activeVector) {
      const resVector = await dispatch(getVector({
        uuid: res?.[0].params?.vector,
        partition: partitionType,
        constants: [
          'GET_VECTOR_FOR_CLONE_ISSUE_REQUEST',
          'GET_VECTOR_FOR_CLONE_ISSUE_SUCCESS',
          'GET_VECTOR_FOR_CLONE_ISSUE_FAILURE',
        ],
      }));
      issueVector = resVector?.[0] || null;
    }
    const files = await getFiles(res);

    const isClosed = get(res, '[0].params.status') === 'closed'
    || get(res, '[0].params.status') === 'deployed'
    || get(res, '[0].params.status') === 'abandoned';

    const isValidEstimatedTime = ['easy', 'normal', 'difficult', 'very_complicated'].includes(get(res, '[0].params.estimated_time'));
    const cloneStartData = {
      usersUuidsForAssigned: getListUsers(),
      assignedProject: activeProject || '',
      assignedVector: issueVector?.uuid || activeVector || '',
      selectedVectorData: { ...omit(issueVector, ['params']), ...(issueVector?.params ?? {}) } || {},
      selectedProjectData: projectData || {},
      isGlobalCreateModal: false,
      ...omit(get(res, '[0].params'), ['id', 'lifeTime']),
      estimated_time: isValidEstimatedTime ? get(res, '[0].params.estimated_time') : 'easy',
      title: `[Cloned on base - ${get(res, '[0].params.id')}] - ${get(res, '[0].params.title')}`,
      description: `The task was cloned on the basis - ${get(res, '[0].params.id')} \n${get(res, '[0].params.description', '')}`,
      status: isClosed ? 'created' : get(res, '[0].params.status'),
      files,
    };
    setDefaultIssueState(cloneStartData);
  };

  return (
    <BaseButton
      id="cloneIssueButton"
      size="small"
      type="primary"
        // className="description-font"
      className={buttonClassName}
      ghost
      onClick={onClickOpen}
      disabled={disabled}
    >
      <Icon path={mdiCardPlusOutline} size={0.7} className="mr-1" />
      Clone issue
    </BaseButton>
  );
}
CloneIssueBtn.propTypes = {
  disabled: bool,
  issueUUID: string,
  partitionType: string,
  buttonClassName: string,
  setDefaultIssueState: func,
};
