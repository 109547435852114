import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Flex, Input, Row, Tooltip,
} from 'antd';
import Icon from '@mdi/react';
import {
  mdiArrowULeftTop, mdiCog, mdiPlusBoxOutline, mdiShieldAccount, mdiTrashCan,
} from '@mdi/js';

import { stopPropagation } from '../../54origins/utils54origins';
import BaseButton from '../../components/_ui/BaseButton/BaseButton';
import UserPermsComponent from './UserPermsComponent';

import './userList.scss';
import { isEqual } from 'lodash';

const keyPerms = ['create', 'read', 'update', 'delete', 'list', 'set'];

const hideRoleForSizes = ['small', 'extra small'];

export default function UserItemForList({
  options = {},
  isSelected = false,
  label = '',
  changes = {},
  uuid = '',
  role = '',
  perms = {},
  item = {},
  isWMSAdmin = false,
  onClickCallback,
  onChangeSize,
  onUserChangeCallback,
  disabled = false,
  textSelectButton = '',
  textDeleteButton = '',
  showSections = [],
}) {
  const {
    size = 'default',
    isOneColumnMode = false,
  } = options;

  const [adminState, setAdmin] = useState(false);

  const [isSettingsOpen, setSettingsState] = useState(false);

  const getPartOfText = (text, count, className) => (text?.length > count ? (
    <Tooltip
      title={text}
      className="user-item-tooltip"
    >
      <span
        className={className}
        style={{
          lineHeight: 0.9,
          maxWidth: role.length ? 135 : 200,
          wordBreak: 'break-all',
        }}
      >
        {`${text.slice(0, count)}...`}
      </span>
    </Tooltip>
  ) : <span
    className={className}
    style={{
      maxWidth: role.length ? 135 : 200,
      wordBreak: 'break-all',
      lineHeight: 0.9,
    }}
  >{text}</span> || '');

  const onUpdateUser = (e, key, value) => {
    stopPropagation(e);

    if (key === 'isAdmin') {
      setAdmin((prev) => !prev);
    }

    onUserChangeCallback(key, value, uuid, item);
  };

  const getCount = () => {
    switch (size) {
      case 'large': return role.length ? 24 : 35;
      case 'middle': return role.length ? 15 : 23;
      case 'small': return 20;
      case 'extra small': return 15;
      default: return role.length ? 24 : 35;
    }
  };

  const checkChanges = () => {
    // console.log(changes, perms);
    return changes?.added || (changes?.role?.wasChanged && changes?.role?.value !== role)
      || (changes?.isAdmin?.wasChanged && changes?.isAdmin?.value !== adminState && !isWMSAdmin)
      || (changes?.perms?.wasChanged && !isEqual(perms, changes?.perms?.value))
    // switch (field) {
    //   case 'role': {
    //     return changes?.role?.wasChanged && changes?.role?.value !== role;
    //   }
    //   case 'isAdmin': {
    //     return changes?.isAdmin?.wasChanged && changes?.isAdmin?.value !== adminState && !isWMSAdmin;
    //   }
    //   case 'create':
    //   case 'read':
    //   case 'update':
    //   case 'delete':
    //   case 'list':
    //   case 'set': {
    //     console.log(field, changes?.perms, changes)
    //     return changes?.perms?.wasChanged && changes?.perms?.value?.[field] !== perms?.[field] && !isWMSAdmin;
    //   }
    //   default: return false;
    // }
  }

  useEffect(() => {
    if (keyPerms.every((key) => perms[key])) {
      setAdmin(true);
    }
  }, [perms]);

  return (
    <Flex
      align={isSettingsOpen ? 'start' : 'center'}
      justify={isSettingsOpen ? 'center' : 'space-between'}
      vertical={isSettingsOpen}
      className={`user-item-for-list pl-2 pr-3 mr-2 ${checkChanges() ? 'bg-yellow-100' : ''}`}
    >
      <Flex
        align="center"
      >
        {getPartOfText(label, getCount())}
        {showSections.includes('input-role') && isSettingsOpen ? (
          <Input
            style={{
              height: '18px',
              width: 150,
            }}
            className="ml-2"
            onClick={stopPropagation}
            size="small"
            placeholder="Enter role ..."
            value={role}
            onChange={(e) => {
              onUpdateUser(e, 'role', e?.target?.value);
            }}
          />
        ) : null}
        {showSections.includes('string-role') && !hideRoleForSizes.includes(size) && !isSettingsOpen ? (
          <Flex
            align="center"
            className={`ml-1`}
          >
            <span className='text-stone-400 mr-1'>{role ? '/' : ''}</span>
            {' '}
            {getPartOfText(role, 8, 'text-stone-400')}
          </Flex>
        ) : null}
      </Flex>

      {showSections.includes('admin')
        || showSections.includes('perms') ? (
        <Flex className={`w-100 ${isSettingsOpen ? 'mt-1' : ''}`} align={'center'}>
          {showSections.includes('button-delete-user') ? (
            <BaseButton
              size="small"
              shape="default"
              className="p-0 mr-1 view_btn_link_circle_for_assign view_btn_red"
              onClick={() => onClickCallback(uuid)}
              style={{
                paddingTop: -3,
              }}
            >
              <Icon path={mdiTrashCan} size={0.75} />
            </BaseButton>
          ) : null}

          {isSettingsOpen ? (
            <BaseButton
              size="small"
              shape="default"
              className="p-0 mr-1 view_btn_link_circle_for_assign view_btn_orange"
              onClick={() => {
                setSettingsState(false);
                onChangeSize();
              }}
              style={{
                paddingTop: -3,
              }}
            >
              <Icon path={mdiArrowULeftTop} size={0.75} />
            </BaseButton>
          ) : null}
          {showSections.includes('admin') ? (
            <Tooltip
              title={adminState ? 'Delete from admins' : 'Add to admins'}
            >
              <BaseButton
                size="small"
                shape="default"
                disabled={isWMSAdmin}
                className={`p-0 ${adminState ? 'view_btn_link_circle_for_assign' : 'view_btn_link_circle_not_active'} 
                ${isWMSAdmin ? 'disabled-selected-circle' : ''} `}
                onClick={(e) => onUpdateUser(e, 'isAdmin', !adminState)}
              >
                <Icon path={mdiShieldAccount} size={0.75} />
              </BaseButton>
            </Tooltip>
          ) : null}
          {!isSettingsOpen ? (
            <BaseButton
              size="small"
              shape="default"
              className="p-0 ml-1 view_btn_link_circle_for_assign"
              onClick={() => {
                setSettingsState(true);
                onChangeSize();
              }}
            >
              <Icon path={mdiCog} size={0.75} />
            </BaseButton>
          ) : null}
          {showSections.includes('perms') && isSettingsOpen ? (
            <UserPermsComponent
              perms={perms}
              isDisabled={adminState || isWMSAdmin}
              onChangePerm={onUpdateUser}
            />
          ) : null}
        </Flex>
      ) : null}

      {showSections.includes('button-add-user') || (isOneColumnMode && !isSelected) ? (
        <BaseButton
          type="primary"
          size="small"
          disabled={disabled}
          onClick={() => onClickCallback(uuid)}
        >
          {textSelectButton || 'Add to project'}
        </BaseButton>
      ) : null}
      {showSections.includes('button-remove-user') || (isOneColumnMode && isSelected) ? (
        <BaseButton
          type="primary"
          size="small"
          danger
          onClick={() => onClickCallback(uuid)}
        >
          {textDeleteButton || 'Delete from project'}
        </BaseButton>
      ) : null}
    </Flex>
  );
}

UserItemForList.propTypes = {
  options: PropTypes.object,
  onClickCallback: PropTypes.func,
  onUserChangeCallback: PropTypes.func,
  label: PropTypes.string,
  uuid: PropTypes.string,
  role: PropTypes.string,
  perms: PropTypes.object,
  isSelected: PropTypes.bool,
  showSections: PropTypes.array,
  item: PropTypes.object,
  changes: PropTypes.bool,
  isWMSAdmin: PropTypes.bool,
  onChangeSize: PropTypes.func,
  textSelectButton: PropTypes.string,
  disabled: PropTypes.bool,
  textDeleteButton: PropTypes.string,
};
