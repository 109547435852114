import React from "react";

import BaseButton from "../../../../components/_ui/BaseButton/BaseButton";

import { Flex } from "antd";

import Icon from "@mdi/react";
import { mdiArrowDownBoldOutline, mdiArrowUpBoldOutline } from "@mdi/js";

import cn from "classnames";
import { get } from "lodash";
import { array, bool, func, number, object } from "prop-types";

export default function IssueOrderButtons({
  issue,
  index,
  issues,
  onActionCallback,
  isFirstItem,
  isLastItem,
  done,
}) {
  const buttonStyle = {
    width: 20,
    height: 20
  }

  const btnClass = 'border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white';
  const disabledBtnClass = 'border-gray-300 bg-white text-gray-300 hover:bg-white hover:text-gray-300';

  const handleButtonClick = (e, newIndex) => {
    const newKey = get(issues, [newIndex, 'data', 'key']);
    const prevKey = get(issue, ['data', 'key']);

    onActionCallback?.({
      newKey,
      prevKey,
    }, 'day-change-order-of-issues', e);
  };

  return <Flex
    align='center'
  >
    <Flex
      vertical
    >
      <BaseButton
        className={cn(`mb-0.5 border ${btnClass}`, {
          [disabledBtnClass]: isFirstItem,
          //  || done,
        })}
        size="small"
        disabled={isFirstItem}
        //  || done}
        style={buttonStyle}
        onClick={(e) => handleButtonClick(e, index - 1)}
      >
        <>
          <Icon
            path={mdiArrowUpBoldOutline}
            className="-mt-1"
            size={0.7}
          />
        </>
      </BaseButton>
      <BaseButton
        className={cn(`border ${btnClass}`, {
          [disabledBtnClass]: isLastItem,
          //  || done
        })}
        size="small"
        disabled={isLastItem}
        //  || done}
        style={buttonStyle}
        onClick={(e) => handleButtonClick(e, index + 1)}
      >
        <>
          <Icon
            path={mdiArrowDownBoldOutline}
            className="-mt-0.5"
            size={0.7}
          />
        </>
      </BaseButton>
    </Flex>
    <span className="text-sm ml-3">
      {index + 1}
    </span>
  </Flex>
}

IssueOrderButtons.propTypes = {
  issue: object,
  index: number,
  issues: array,
  onActionCallback: func,
  isFirstItem: bool,
  isLastItem: bool,
  done: bool,
}

