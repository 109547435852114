import React, { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import { array, func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import IssueRecordVoiceDescriptionBtn from '../../../projectFlow/components/issues/IssueRecordVoiceDescriptionBtn';

import { getIssueNew } from '../../../projectFlow/actions/projectFlowActions';
import { removeFile } from '../../../projectFlow/actions/issues-actions';


export default function AudioUploader({
  partition,
  disabled = [],
  issueUUID,
  audioList,
  setAudio,
  onChangeFilesCallback,
  onInitCallback,
}) {
  const dispatch = useDispatch();

  const issueFilesFetching = useSelector((state) => (
    state.projectManagementService.issue.fetchingFiles));

  const [fetching, setFetching] = useState(false);
  const [filesList, setFiles] = useState([]);
  const [defaultAllList, setDefaultAllList] = useState([]);

  const disabledEdit = useMemo(() => disabled.includes('uploadFiles') || disabled.includes('fullEdit'), [disabled.length]);

  const checkAndDeleteFile = async (newFiles) => {
    const arrNeedDelete = defaultAllList.filter(
      (p) => newFiles.every((i) => get(i, 'uuid', '') !== p.uuid),
    );

    return Promise.all(arrNeedDelete.map((i) => dispatch(
      removeFile(issueUUID, i.uuid, partition),
    )));
  };

  const saveFileCallback = async (files) => {
    await checkAndDeleteFile(files);

    onChangeFilesCallback({ files });
  };

  const initFunc = (customFiles) => {
    const { files, audioFiles } = (customFiles || []).reduce((acc, curr) => {
      if (curr?.content_type && !curr?.content_type.includes('audio')) {
        return { ...acc, files: [...acc.files, curr] };
      }
      if (curr?.content_type && curr?.content_type.includes('audio')) {
        return { ...acc, audioFiles: [...acc.audioFiles, curr] };
      }
      return acc;
    }, { files: [], audioFiles: [] });

    setFiles(files);
    setAudio(audioFiles);
    setDefaultAllList(customFiles);

    if (onInitCallback) {
      onInitCallback?.(audioFiles.length === 0);
    }
  };

  const getFilesForIssue = async () => {
    setFetching(true);
    const constants = [
      'FILES_REQUEST', 'FILES_SUCCESS', 'FILES_FAILURE',
    ];

    const res = await dispatch(getIssueNew({
      uuid: issueUUID,
      params: {},
      constants,
      withFiles: true,
      partition,
    }));

    initFunc(get(res, '[0].files', []));

    setFetching(false);
  };

  const getAudioFileCallback = (newAudio) => {
    const newAudioList = [];

    if (audioList.length) {
      newAudioList.push(...audioList);
    }

    newAudioList.push(newAudio);
    saveFileCallback([...filesList, ...newAudioList]);
  };

  useEffect(() => {
    if (issueUUID && !issueFilesFetching) {
      getFilesForIssue();
    }
  }, [issueUUID, issueFilesFetching]);

  return (
    <IssueRecordVoiceDescriptionBtn
      getAudioFileCallback={getAudioFileCallback}
      currentListAudioLength={audioList.length}
      disabled={fetching || disabledEdit}
    />
  );
}

AudioUploader.propTypes = {
  disabled: array,
  partition: string,
  issueUUID: string,
  audioList: array,
  setAudio: func,
  onChangeFilesCallback: func,
  onInitCallback: func,
};
