import React, { useContext, useEffect, useState } from 'react';
import {
  head, pathOr, prop, propOr,
} from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import ListOfNotifications from './ListOfNotifications';

import {
  markAsViewedAllNotifications,
  markAsViewedNotification,
  resetAllViewedNotifications,
} from '../projectFlow/actions/PtcActionsForHook';
import { getSenderFilterNotification, getMe, getUnseenNotifications } from '../projectFlow/selectors/selectors';
import { ProjectTypeCommonConstants } from '../projectFlow/constants/Constants';
import axiosRequest from '../api/apiAxios';
import { SocketContext } from '../socket/SocketProvider';
import { notificationUrl } from '../api/appConfig';

function ListOfNotificationsContainer() {
  const dispatch = useDispatch();

  const { sendNotifyEmit } = useContext(SocketContext);

  const getMeUUID = useSelector(getMe);
  const unseenNotifications = useSelector(getUnseenNotifications);
  const filterBySender = useSelector(getSenderFilterNotification);

  const [pageOptions, setPageOptions] = useState({
    offset: 0,
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  });

  const initialRules = {
    orderBy: 'created_at',
    order: '_desc',
  };

  const [allNotifications, setAllNotifications] = useState([]);
  const [filterFlag, setFilterFlag] = useState('all');
  const [orderRules, changeOrderRules] = useState(initialRules);

  const [fetching, setFetching] = useState(false);

  const setNotificationsWithUsers = (data, viewedCount, unseenCount) => {
    let totalSize;

    switch (filterFlag) {
      case 'all':
      default:
        totalSize = viewedCount + unseenCount;
        break;
      case 'unseen':
        totalSize = unseenCount;
        break;
      case 'viewed':
        totalSize = viewedCount;
        break;
    }
    setPageOptions((prev) => ({ ...prev, totalSize }));

    setAllNotifications(data);
  };

  const onMarkAsViewedAll = () => {
    dispatch(markAsViewedAllNotifications);
    sendNotifyEmit(
      'all_status',
      getMeUUID,
    );
  };

  const onMarkAsViewed = (uuid) => {
    const unreadData = dispatch(markAsViewedNotification(uuid));

    sendNotifyEmit(
      'status',
      uuid,
      unreadData,
    );
  };

  const getAllViewedNotifications = async (limit) => {
    setFetching(true);
    const { sizePerPage, offset } = pageOptions;

    const getViewedUrl = () => {
      switch (filterFlag) {
        case 'unseen':
          return '&viewed=false';
        case 'viewed':
          return '&viewed=true';
        default:
          return '';
      }
    };

    // const notifyURL = `notification?${getViewedUrl()}&order=${orderRules.orderBy}${orderRules.order}&limit=${limit || sizePerPage}&offset=${offset}`;
    const notifyURL = `notification?${getViewedUrl()}&sender=${filterBySender?.uuid || ''}&limit=${limit || sizePerPage}&offset=${offset}&order=${orderRules.orderBy}${orderRules.order}`;

    await dispatch(
      axiosRequest.get(
        notifyURL,
        [
          ProjectTypeCommonConstants.GET_ALL_VIEWED_NOTIFICATIONS_REQUEST,
          {
            type: ProjectTypeCommonConstants.GET_ALL_VIEWED_NOTIFICATIONS_SUCCESS,
            payload: (response) => {
              setNotificationsWithUsers(
                pathOr([], ['result'], response),
                pathOr(0, ['info', 'viewed_count'], response),
                pathOr(0, ['info', 'not_viewed_count'], response),
              );
              return response.result;
            },
          },
          ProjectTypeCommonConstants.GET_ALL_VIEWED_NOTIFICATIONS_FAILURE,
        ],
        {
          tokenName: 'Notification',
          url: notificationUrl,
        },
      ),
    );
    setFetching(false);
  };

  const onChangeTable = ({ pageLimit, currentPage, offset }) => {
    if (pageLimit || offset) {
      setPageOptions((prev) => ({
        ...prev,
        offset,
        page: currentPage,
        sizePerPage: pageLimit,
      }));
    }
  };

  const remotePagination = {
    sizePerPageList: [10, 25, 50, 100],
    defaultPageLimit: 10,
    totalSize: pageOptions.totalSize,
    page: pageOptions.page,
    onChange: onChangeTable,
  };

  const onSelectTags = (tags) => {
    if (tags) {
      const tag = prop('value', head(tags)) === 'read' ? 'viewed'
        : propOr('all', 'value', head(tags));
      setFilterFlag(tag);
    }

    setPageOptions((prev) => ({
      ...prev,
      offset: 0,
      page: 1,
    }));
  };

  useEffect(() => {
    getAllViewedNotifications(pageOptions.sizePerPage);
  }, [
    filterFlag,
    unseenNotifications.length,
    pageOptions.offset,
    pageOptions.sizePerPage,
    orderRules.order,
    orderRules.orderBy,
    filterBySender?.uuid,
  ]);

  useEffect(() => () => {
    dispatch(resetAllViewedNotifications);
  }, []);

  return (
    <ListOfNotifications
      // getPaginationOptions={getPaginationOptions}
      loading={fetching}
      allNotifications={allNotifications}
      remotePagination={remotePagination}
      sizePerPage={pageOptions.sizePerPage}
      unseenNotifications={unseenNotifications}
      changeOrderRules={changeOrderRules}
      onMarkAsViewed={onMarkAsViewed}
      onMarkAsViewedAll={onMarkAsViewedAll}
      onSelectTags={onSelectTags}
    />
  );
}

export default ListOfNotificationsContainer;
