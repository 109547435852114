export const theme = {
  // hashed: false,
  token: {
    fontFamily: 'Ubuntu',
    colorText: '#242424',
    colorPrimary: '#1890ff',
    borderRadius: 4,
  },
  components: {
    Layout: {
      headerBg: '#ffffff',
      siderBg: '#ffffff',
    },
    Button: {
      fontWeight: 500,
    },
  },
};
