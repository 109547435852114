// import React from 'react';
import {
  mdiAccountPlusOutline,
  mdiCommentMultipleOutline,
  mdiCommentTextMultipleOutline,
  mdiEmailOutline,
  mdiBullseyeArrow,
} from '@mdi/js';
import { Modal } from 'antd';
import { get } from 'lodash';
import { entityPartialRead } from '../entity/actions/entityActions';
import { partitionNamesConfig } from '../api/appConfig';

export const defaultFilters = [
  {
    tags: [
      {
        value: 'all',
        label: 'wms.buttons.all',
      },
    ],
    isReset: true,
  },
  {
    tags: [
      {
        value: 'unseen',
        label: 'notifications.filters.unread',
      },
      {
        value: 'read',
        label: 'notifications.filters.read',
      },
    ],
    isRadio: true,
  },
];

export const getNotifyData = (event) => {
  switch (event) {
    case 'mention':
      return {
        path: mdiCommentMultipleOutline,
        title: 'notifications.events.mention',
      };
    case 'comment':
      return {
        path: mdiCommentTextMultipleOutline,
        title: 'notifications.events.comment',
      };
    case 'assigned':
      return {
        path: mdiAccountPlusOutline,
        title: 'notifications.events.assigned',
      };
    case 'assigned_goal':
      return {
        path: mdiBullseyeArrow,
        title: 'notifications.events.assigned_goal',
      };
    case 'assigned_milestone':
      return {
        path: mdiBullseyeArrow,
        title: 'notifications.events.assigned_milestone',
      };
    case 'assigned_vector':
      return {
        path: mdiAccountPlusOutline,
        title: 'notifications.events.assigned_vector',
      };
    case 'assigned_release':
      return {
        path: mdiAccountPlusOutline,
        title: 'notifications.events.assigned_release',
      };
    case 'assigned_test_case':
      return {
        path: mdiAccountPlusOutline,
        title: 'notifications.events.assigned_test_case',
      };
    case 'assigned_test_cycle':
      return {
        path: mdiAccountPlusOutline,
        title: 'notifications.events.assigned_test_cycle',
      };
    case 'assigned_test_suite':
      return {
        path: mdiAccountPlusOutline,
        title: 'notifications.events.assigned_test_suite',
      };
    case 'message':
      return {
        path: mdiEmailOutline,
        title: 'notifications.events.message',
      };
    default:
      return {};
  }
};

export const entityNotFound = () => {
  Modal.error({
    title: 'URL for this notification is not available. Probably it was removed.',
    // title: 'Entity is not available. Possible reasons are:',
    // content: (
    //   <ul className="wrapper-entity-not-available">
    //     <li>Entity removed</li>
    //     <li>You don't have access to the entity (Contact admin)</li>
    //   </ul>
    // ),
    width: 500,
    centered: true,
  });
};

export const checkExist = (row, callback) => {
  const {
    uuid, projectUUID, vectorUUID, entity_type, partition,
  } = get(row, 'params.entity');

  const data = {
    entity_type,
    entity_uuid: uuid || vectorUUID || projectUUID,
  };
  const constants = [
    'CHECK_EXIST_NOTIFICATION_REQUEST',
    'CHECK_EXIST_NOTIFICATION_SUCCESS',
    'CHECK_EXIST_NOTIFICATION_FAILURE',
  ];

  const options = {
    partition,
    onSuccess: callback,
    onFailure: entityNotFound,
  };

  return entityPartialRead({
    data,
    constants,
    options,
  });
};

export const generateLinkNotifications = (entity) => {
  // console.log('entity', entity);
  const search = {};
  const type = get(entity, 'params.entity.entity_type');
  const uuid = get(entity, 'params.entity.uuid');
  const partition = get(entity, 'params.entity.partition', partitionNamesConfig.partition1);
  const parent = get(entity, 'params.entity.parent');
  const user = get(entity, 'params.entity.user');
  const projectUUID = get(entity, 'params.entity.projectUUID');
  const vectorUUID = get(entity, 'params.entity.vectorUUID');
  const releaseUUID = get(entity, 'params.entity.releaseUUID');
  const testCaseUUID = get(entity, 'params.entity.testCaseUUID');
  const testSuiteUUID = get(entity, 'params.entity.testSuiteUUID');
  const testCycleUUID = get(entity, 'params.entity.testCycleUUID');

  const pmPartition = () => {
    switch (type) {
      case 'projectDocument':
        return {
          pathname: '/pm/projects/docs',
          search: {
            activeProject: projectUUID,
            activeDoc: uuid,
          },
        };
      case 'projectIdea':
        return {
          pathname: '/pm/projects/docs',
          search: {
            activeProject: projectUUID,
            activeNote: uuid,
          },
        };
      case 'projectSpreadsheet':
        return {
          pathname: '/pm/projects/docs',
          search: {
            activeProject: projectUUID,
            activeSheet: uuid,
          },
        };
      case 'projectGoal':
        return {
          pathname: '/pm/projects/planning',
          search: {
            activeProject: projectUUID,
            goal: uuid,
          },
        };
      case 'projectMilestone':
        return {
          pathname: '/pm/projects/planning',
          search: {
            activeProject: projectUUID,
            milestone: uuid,
          },
        };
      case 'vector':
        return {
          pathname: '/pm/projects/issuesnew',
          search: {
            activeProject: projectUUID,
            activeVector: vectorUUID,
          },
        };
      case 'testCase':
        if (testCycleUUID) {
          search.activeCycle = testCycleUUID;
          search.activeCaseCycle = testCaseUUID;
        } else {
          search.activeSuite = testSuiteUUID;
          search.activeCaseSuite = testCaseUUID;
        }
        return {
          pathname: '/pm/projects/qa',
          search: {
            activeProject: projectUUID,
            ...search,
          },
        };
      case 'testCycle':
        return {
          pathname: '/pm/projects/qa',
          search: {
            activeProject: projectUUID,
            activeCycle: testCycleUUID,
          },
        };
      case 'testSuite':
        return {
          pathname: '/pm/projects/qa',
          search: {
            activeProject: projectUUID,
            activeSuite: testSuiteUUID,
          },
        };
      case 'issue':
        const searchParams = {
          activeProject: projectUUID,
          activeIssue: uuid,
        };

        if (vectorUUID) {
          searchParams.activeVector = vectorUUID;
        }

        return {
          pathname: '/pm/projects/issuesnew',
          search: searchParams,
        };
      default: return {
        pathname: '/pm/projects/issuesnew',
        search: {
          activeProject: projectUUID,
        },
      };
    }
  };

  const publicPartition = () => {
    switch (type) {
      case 'projectDocument':
        return {
          pathname: `/public/user/${user}/projects/docs`,
          search: {
            activeProject: projectUUID,
            activeDoc: uuid,
          },
        };
      case 'projectIdea':
        return {
          pathname: `/public/user/${user}/projects/docs`,
          search: {
            activeProject: projectUUID,
            activeNote: uuid,
          },
        };
      case 'projectSpreadsheet':
        return {
          pathname: `/public/user/${user}/projects/docs`,
          search: {
            activeProject: projectUUID,
            activeSheet: uuid,
          },
        };
      case 'projectGoal':
        return {
          pathname: `/public/user/${user}/projects/planning`,
          search: {
            activeProject: projectUUID,
            goal: uuid,
          },
        };
      case 'projectMilestone':
        return {
          pathname: `/public/user/${user}/projects/planning`,
          search: {
            activeProject: projectUUID,
            milestone: uuid,
          },
        };
      case 'vector':
        return {
          pathname: `/public/user/${user}/projects/issuesnew`,
          search: {
            activeProject: projectUUID,
            activeVector: vectorUUID,
          },
        };
      case 'issue':
        const searchParams = {
          activeProject: projectUUID,
          activeIssue: uuid,
        };
        if (vectorUUID) searchParams.activeVector = vectorUUID;
        return {
          pathname: `/public/user/${user}/projects/issuesnew`,
          search: searchParams,
        };
      case 'testCase':
        return {
          pathname: `/public/user/${user}/projects/qa`,
          search: `?activeProject=${projectUUID}&${testCycleUUID ? `activeCycle=${testCycleUUID}` : `activeSuite=${testSuiteUUID}`}&${testCycleUUID ? 'activeCaseCycle' : 'activeCaseSuite'}=${testCaseUUID}`,
        };
      case 'testCycle':
        return {
          pathname: `/public/user/${user}/projects/qa`,
          search: {
            activeProject: projectUUID,
            activeCycle: testCycleUUID,
          },
        };
      case 'testSuite':
        return {
          pathname: `/public/user/${user}/projects/qa`,
          search: {
            activeProject: projectUUID,
            activeSuite: testSuiteUUID,
          },
        };
      default:
        return {
          pathname: `/public/user/${user}/projects/issuesnew`,
          search: {
            activeProject: projectUUID,
          },
        };
    }
  };

  switch (partition) {
    case partitionNamesConfig.partition3: return publicPartition();
    default: return pmPartition();
  }
};
