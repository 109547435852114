import { mdiFileVideoOutline, mdiPlayCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { bool, object } from 'prop-types';
import React, { useRef } from 'react';
import BtnModal from '../../projectFlow/components/BtnModalComponent';
import ViewVideo from './ViewVideo';

export default function WrapperModalForViewVideo({
  file,
  withContainer,
}) {
  const ref = useRef();
  const openModalBtn = (
    <div
      className="flex items-center videoAttached"
    >
      <Icon
        className="fileIcon"
        color="#bfbfbf"
        path={mdiFileVideoOutline}
        size={2}
      />
      <div className="wrapper-fileLoad">
        <Icon
          className="fileLoad"
          path={mdiPlayCircle}
          size={0.9}
        />
      </div>
    </div>
  );

  return (
    <div ref={ref}>
      <BtnModal
        width={750}
        centered
        footer={null}
        destroyOnClose
        getContainer={() => withContainer ? ref?.current : null}
        btnComponent={openModalBtn}
      >
        <ViewVideo
          file={file}
        />
      </BtnModal>
    </div>
  );
}

WrapperModalForViewVideo.propTypes = {
  file: object,
  withContainer: bool,
};
