import React, { memo, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NotificationsDropDown from './NotificationsDropDown';

import {
  addUnseenNotifications,
  markAsViewedAllNotifications,
  markAsViewedNotification,
} from '../projectFlow/actions/PtcActionsForHook';
import { UsersPartitionConstants } from '../userFlow/constants/Constants';
import { getSyncData } from '../userFlow/store/selectors/dailySectionSelectors';
import { getMe } from '../projectFlow/selectors/selectors';

import { SocketContext } from '../socket/SocketProvider';

function NotificationsDropDownContainer() {
  const { sendNotifyEmit } = useContext(SocketContext);

  const dispatch = useDispatch();

  const getMeUUID = useSelector(getMe);
  const syncData = useSelector(getSyncData);

  const onMarkAsViewedAll = () => {
    dispatch(markAsViewedAllNotifications);
    sendNotifyEmit('all_status', getMeUUID);
  };

  const onMarkAsViewed = (uuid) => {
    const unreadData = dispatch(markAsViewedNotification(uuid));

    sendNotifyEmit('status', uuid, unreadData);
  };

  const initBrowserPushFunc = () => {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      // new Notification('Hi there!');
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        // if (permission === 'granted') {
        //   new Notification('Hi there!');
        // }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  };

  useEffect(() => {
    initBrowserPushFunc();
  }, []);

  useEffect(() => {
    if (syncData?.length) {
      const checkIsWeekPlanningPage = location?.pathname?.includes('planning');
      if (!checkIsWeekPlanningPage) {
        dispatch({
          type: UsersPartitionConstants.CLEAR_SYNC_DATA,
        });
      }
    }
  }, [syncData]);

  return (
    <NotificationsDropDown
      onMarkAsViewed={onMarkAsViewed}
      onMarkAsViewedAll={onMarkAsViewedAll}
    />
  );
}

export default memo(NotificationsDropDownContainer);
