import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  Card, Col, Row, Tooltip,
} from 'antd';
import moment from 'moment';
import {
  capitalize,
  get, isEmpty, isNil, omit,
  uniq, isEqual,
  lowerCase
} from 'lodash';
import Icon from '@mdi/react';
import { mdiRestore } from '@mdi/js';

import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import ExpandModeLayout from '../commonComponents/expandModeLayout/ExpandModeLayout';
// import ListOfCircleActors from '../../../entity/components/ListOfCircleActors/ListOfCircleActors';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import DateLeft from '../../../components/DateLeft';
import { getAllEntitiesAuditData, getAllEntitiesAuditLoading } from '../../selectors/auditSelectors';
import { getCurrentArchiveStateUUID } from '../../selectors/archiveStateSelectors';
import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';
import { getListOfAllEntitiesByTypes } from '../../actions/auditActions';
import useEntityListSort from '../../../hooks/useEntityListSort';
import ActivityEntityTypeFilter from './activityFilter/ActivityEntityTypeFilter';
import WrapperVectorInfoCardWithComments from '../vector/vectorInfo/WrapperVectorInfoCardWithComments';
import ProjectInfo from '../project/projectInfo/ProjectInfo';
import DocViewWrapper from '../../containers/docView&ideas/DocViewWrapper';
import ProjectGoalView from '../goals/ProjectGoalView';
import IssueInfoRoot from '../issues/issueInfo/IssueInfoRoot';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

//import modified
import { modifiedColumnFilter } from '../project/constants/projectFilterOptions';

import { clearIdea, clearIssue, clearVector } from '../../actions/PtcActionsForHook';
import {
  getInfoFetching,
  getProjDocOrIdeas,
  getProjectParams,
  getProjectUUID,
  getVectorUUID,
  globalUserFilterTags,
} from '../../selectors/selectors';
import {
  dateWithTooltipTime,
  filterUuidsAndGetParamsUsers,
  getUuidsfromParamsUsers,
} from '../../../MainUtils';
import { checkIsMinDateForExpired } from '../issues/issueInfo/utils';

import { getLocale } from '../../../locale/selectors';
import { getProjectGoalRequest } from '../../actions/projectGoalsActions';
import { clearProject } from '../../actions/projectFlowActions';
import useURLParams from '../../../hooks/useURLParams';
import { useResize } from '../../../hooks/useResize';
import CustomCardComponent from '../commonComponents/CustomCardComponent';
import useActivitiesDashboard from '../commonComponents/activities/useActivitiesDashboard';
import useActorsW54 from '../../../actors/hooks/useActorsW54';
import WrapperAvatarsGroupForProject from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import useTableFilter from '../../../components/_ui/hooks/useTableFilter';
import { columnsFilterConfig, defaultProjectFilter } from '../project/constants/projectFilterOptions';

//
import { modifiedTag } from '../../../constants/commonConstants';

const defaultEntityTypes = [
  'issue',
  'vector',
  'projectGoal',
  'projectDocument',
  'projectIdea',
  'projectSpreadsheet',
  'testSuite',
  'testCycle',
];

const defaultNotSavedData = {
  notSaved: false,
  confirmNotSaved: false,
};

const parentTypeComment = {
  activeNote: 'idea',
  activeSheet: 'spreadsheet',
  activeVector: 'vector',
  activeDoc: 'document',
  activeIssue: 'issue',
  project: 'project',
  goal: 'goal',
};

const entityString = {
  project: 'project',
  issue: 'activeIssue',
  vector: 'activeVector',
  projectGoal: 'goal',
  projectIdea: 'activeNote',
  projectDocument: 'activeDoc',
  projectSpreadsheet: 'activeSheet',
};

function ListOfAllEntities({
  modified,
  setModified
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getURLParams,
    clearSearchParams,
    addAndRemoveSearchParams,
    routeNavigateTo,
    setSearchParams,
  } = useURLParams();

  const { width } = useResize();

  const {
    defaultPartition,
    getClearVector,
    switchViewFunc,
    backToProjects,
    getProjectMembers,
    getClearIssue,
    getDocument,
    initialPathForHistory,
  } = useContext(ProjectMainLayerContext);

  //config
  const defaultProjectOrder = {
    orderBy: 'created',
    order: 'desc',
  };
  
  const defaultProjectPagination = {
    currentPage: 1,
    limit: 25,
    offset: 0,
  };

  const {
    filterConfig: projectFilterConfig,
    changeFilterConfig: changeProjectFilterConfig,
  } = useTableFilter('project', defaultProjectFilter)

  const changeProjectFilterConfigWrapper = (filters) => {
    const newFilters = {entity_type: filters.entity_type}
    setCurrentFilter([{
      key: 'date',
      value: filters.modified && filters.modified.length > 0 ? filters.modified[0] : '',
      label: 'wms.filters.this_week',
    }])
    changeProjectFilterConfig(newFilters)
  }

  const defaultProjectConfig = {
    ...defaultProjectPagination,
    ...defaultProjectOrder,
    ...projectFilterConfig
  }

  // new config 
  const [currentFilter, setCurrentFilter] = useState([modifiedTag[1]]);
  const [modifiedConfig, setModifiedConfig] = useState(null);

  const createConfigFromSelectedTabs = (tabs = []) => {
    if (isEmpty(tabs)) {
      return setModifiedConfig({});
    }
    let startDate;
    let endDate;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    const activeTab = tabs[0];

    const getStartDate = (param) => moment()
      .startOf(param)
      .format(dateFormat);

    const getEndDate = (param) => moment().endOf(param).format(dateFormat);

    switch (activeTab.value) {
      case 'today':
        startDate = getStartDate('day');
        endDate = getEndDate('day');
        break;
      case 'this_week':
        startDate = getStartDate('week');
        endDate = getEndDate('week');
        break;
      case 'this_month':
        startDate = getStartDate('month');
        endDate = getEndDate('month');
        break;
      case 'last_week':
        startDate = moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .format(dateFormat);
        endDate = moment()
          .subtract(1, 'weeks')
          .endOf('week')
          .format(dateFormat);
        break;
      case 'last_month':
        startDate = moment()
          .subtract(1, 'month')
          .startOf('month')
          .format(dateFormat);
        endDate = moment()
          .subtract(1, 'month')
          .endOf('month')
          .format(dateFormat);
        break;
      default:
        break;
    }

    const config = {
      modified__gte: startDate,
      modified__lte: endDate,
    };

    return setModifiedConfig(config);
  };

  useEffect(() => {
    if (!isNil(modifiedConfig)) {
      if (isEmpty(modifiedConfig)) {
        setModified({
          modified__gte: '',
          modified__lte: '',
        });
      } else {
        setModified(modifiedConfig);
      }
    }
  }, [modifiedConfig]);

  useEffect(() => {
    createConfigFromSelectedTabs(currentFilter);
  }, [currentFilter]);


  const locale = useSelector(getLocale);
  const userFilterTags = useSelector(globalUserFilterTags);
  const allEntities = useSelector(getAllEntitiesAuditData);
  const archiveStateUUID = useSelector(getCurrentArchiveStateUUID);
  const vectorUUID = useSelector(getVectorUUID);
  const projectParams = useSelector(getProjectParams);
  const allEntitiesLoading = useSelector(getAllEntitiesAuditLoading);
  const projectUUID = useSelector(getProjectUUID);
  const projDocOrIdeas = useSelector(getProjDocOrIdeas);

  const [projectState, setProjectState] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [totalCount, setTotalCount] = useState([]);
  const [sortSettings, setSortSettings] = useState({});
  const [currentConfig, setCurrentConfig] = useState(defaultProjectConfig);
  const [notSavedData, setNotSavedData] = useState(defaultNotSavedData);
  const [showEditor, setShowEditor] = useState(false);

  //add config
  const [isConfigChanged, setIsConfigChanged] = useState(true)

  const changeConfig = (newConfig) => {
    const updatedConfig = {
      ...currentConfig,
      ...newConfig,
    }

    if (!isEqual(currentConfig, updatedConfig)) {
      setCurrentConfig(updatedConfig);
      setIsConfigChanged(true);
    }
  };

  const {
    isSidePanelOpen,
  } = useActivitiesDashboard();

  const {
    activeVector,
    activeIssue, activeDoc,
    activeNote,
    activeSheet,
    goal,
  } = getURLParams();

  const dateFormat = locale === 'en' ? 'MM-DD-YY' : 'DD-MM-YY';

  const changeGeneralState = (params) => setProjectState((prev) => ({
    ...prev,
    ...params,
  }));

  const resetEntity = (type) => {
    setSearchParams({ activeAuditSectionTab: `views.${type}` });

    dispatch(clearVector);
    dispatch(clearIdea);
  };

  const deleteEntityCallback = async (type) => {
    changeGeneralState({
      currentView: 'project',
    });
    // navigate({
    //   pathname: `${initialPathForHistory}audit`,
    //   search: `?activeProject=${projectUUID}&activeAuditSectionTab=views.${type}`,
    // });
    routeNavigateTo(`${initialPathForHistory}audit`, {
      activeAuditSectionTab: `views.${type}`,
    });
    dispatch(clearVector);
    dispatch(clearIdea);
  };

  const { currentView, selectedEntity } = projectState;

  const finalUUID = archiveStateUUID || projectUUID;

  const getListOfAllEntities = async (config) => {
    
    if (!config.parent || !config.entity_type) return;
    const data = await dispatch(getListOfAllEntitiesByTypes(config, defaultPartition));

    const total = get(data, 'total');
    setTotalCount(total);
  };

  const setCurrentConfigAndGetRequest = (newConfig) => {
    setCurrentConfig(newConfig);
    getListOfAllEntities(newConfig);
  };

  const selectEntity = (row) => {
    const { entity_type, uuid } = row;

    addAndRemoveSearchParams(
      { [entityString[entity_type]]: uuid },
      [
        'activeIssue',
        'activeVector',
        'activeDoc',
        'activeNote',
        'activeSheet',
        'goal',
      ],
    );
  };

  const setIsNotSavedDataCallback = (bool) => {
    setNotSavedData({
      ...notSavedData,
      notSaved: bool,
    });
  };

  const updateListOfIssues = async () => {
    await getListOfAllEntities();
  };

  const defaultProjectState = async () => {
    resetEntity('activity');
    await getListOfAllEntities();
  };

  const defaultVectorState = async () => {
    getClearVector(vectorUUID);
    await getListOfAllEntities();
  };

  const assignToUserFormatter = (cell, row) => {
    const {
      entity_type,
    } = row;

    const projectUsersUUIDs = getUuidsfromParamsUsers(projectParams.users);
    let users = [];

    if (entity_type === 'testCycle' || entity_type === 'testSuite') {
      users = row?.assignToUser.filter((el) => projectUsersUUIDs.includes(el));
    } else if (entity_type === 'issue') {
      users = row?.usersSearch
        .filter((el) => projectUsersUUIDs.includes(el));
    } else if (entity_type === 'projectGoal' || entity_type === 'projectMilestone') {
      users = row?.responsible?.map((el) => el?.uuid)
        .filter((el) => projectUsersUUIDs.includes(el));
    } else {
      users = getUuidsfromParamsUsers(row?.users)
        .filter((el) => projectUsersUUIDs.includes(el));
    }

    if (isNil(users) || isEmpty(users)) {
      return null;
    }

    return (
      // <ListOfCircleActors
      //   projectUsers={projectParams.users}
      //   actors={users}
      //   countOfActors={2}
      // />
      <WrapperAvatarsGroupForProject
        actors={users}
        avatarSize="small"
        avatarGap={4}
        avatarStyles={{ cursor: 'pointer' }}
        groupButtonSize="small"
        checkProjectRole
      />
    );
  };

  const lifeTimeFormatter = (cell) => (cell && !checkIsMinDateForExpired(cell))
    && <DateLeft minus date={cell} type="tag" customStyle="text-neutral-600" />;

  const columns = [
    {
      dataIndex: ['title'],
      title: capitalize(t('wms.table.headers.title', 'title')),
      className: 'minWidth130',
      render: (cell) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
          maxLength={30}
        />
      ),
    },
    {
      dataIndex: 'entity_type',
      key: 'entity_type',
      title: capitalize(t('wms.table.headers.type', 'type')),
      sorter: true,
      ...(columnsFilterConfig && { ...columnsFilterConfig?.entity_type }),
      ...(projectFilterConfig?.entity_type && { defaultFilteredValue: projectFilterConfig?.entity_type }),
      filteredValue: projectFilterConfig.entity_type,
      render: (cell, row) => cell && (
        <EntityParamTag
        value={get(row, ['params', 'type']) || cell}
          type="badge"
          param="entity"
        />
      ),
    },
    {
      dataIndex: ['status'],
      title: capitalize(t('wms.table.headers.status', 'status')),
      // hidden: isExpanded,
      render: (cell) => cell && (
        <EntityParamTag
          param="status"
          value={cell}
          type="icon"
        />
      ),
    }, {
      dataIndex: ['assigned_users'],
      title: capitalize(t('wms.table.headers.assigned', 'assigned')),
      // hidden: isExpanded,
      render: (cell, row) => assignToUserFormatter(cell, row),
    },
    {
      dataIndex: 'created',
      title: capitalize(t('wms.table.headers.created', 'created')),
      // hidden: isExpanded,
      sorter: (a, b, c) => setSortSettings({ sortValue: c, sortType: 'created' }),
      render: (cell) => dateWithTooltipTime(cell, dateFormat),
    },
    {
      dataIndex: 'modified',
      title: capitalize(t('wms.table.headers.modified', 'modified')),
      // hidden: width < 1400,
      // sorter: (a, b, c) => setSortSettings({ sortValue: c, sortType: 'modified' }),
      sorter: true,
      ...modifiedColumnFilter,
      filteredValue: currentFilter[0].value.length > 0 ? [currentFilter[0].value] : null,
      defaultFilteredValue: currentFilter[0].value.length > 0 ? [lowerCase(currentFilter[0].value)] : null,
      render: (cell) => dateWithTooltipTime(cell, dateFormat),
      filterMultiple: false,
    },
    {
      dataIndex: ['lifeTime'],
      key: 'lifeTime',
      title: capitalize(t('wms.adjective.expired')),
      // hidden: isExpanded,
      sorter: (a, b, c) => setSortSettings({ sortValue: c, sortType: 'lifeTime' }),
      render: (cell, row) => lifeTimeFormatter(cell, row),
    },
  ].filter((el) => !el.hidden);

  const rowClassName = (row) => {
    const data = `universal_table_row ${get(row, 'uuid') === selectedEntity
      ? 'selected'
      : ''
      }`;

    return data;
  };

  const selectRow = {
    mode: 'radio',
    clickToSelect: false,
    hideSelectColumn: true,
    selected: [selectedEntity],
    style: { background: '#e5f4fc' },
  };

  const onRow = (row) => ({
    onClick: (e) => {
      const { tagName } = e.target;

      const {
        entity_type,
      } = row;

      if (tagName !== 'svg' && tagName !== 'path') {
        if (entity_type !== 'testCycle' && entity_type !== 'testSuite') {
          selectEntity(row);
        }
      }
    },
  });

  const onPageChange = ({ pageLimit: newPageLimit, currentPage: newCurrentPage, offset }) => {
    setCurrentConfig({
      ...currentConfig,
      offset,
      limit: newPageLimit,
    });
    setCurrentPage(newCurrentPage);
  };

  const remotePagination = {
    sizePerPageList: [25, 50, 100],
    defaultPageLimit: 25,
    totalSize: totalCount,
    page: currentPage,
    onChange: onPageChange,
    // onSizePerPage: onSizePerPageTable,
  };

  const setCurrentConfigCallback = (data) => {
    setCurrentConfig(data);
    setCurrentPage(1);
  };

  const resetFunc = () => {
    setProjectState({
      currentView: 'project',
      selectedEntity: '',
    });
    resetEntity('activity');
  };

  const initFunc = () => {
    if (activeDoc || activeNote || activeSheet) setShowEditor(false);

    let view = '';
    const uuid = activeVector
      || activeIssue
      || activeDoc
      || activeNote
      || activeSheet
      || goal;

    if (activeVector) {
      getClearVector(activeVector);
      view = 'activeVector';
    }

    if (activeIssue) {
      getClearIssue(activeIssue);
      view = 'activeIssue';
    }

    if (goal) {
      dispatch(getProjectGoalRequest({
        uuid: goal,
        partition: defaultPartition,
      }));
      view = 'goal';
    }

    if (activeNote) {
      getDocument(activeNote, 'projectIdea');
      view = 'activeNote';
    }
    if (activeDoc) {
      getDocument(activeDoc, 'projectDocument');
      view = 'activeDoc';
    }
    if (activeSheet) {
      getDocument(activeSheet, 'projectSpreadsheet');
      view = 'activeSheet';
    }

    changeGeneralState({
      currentView: view || 'project',
      selectedEntity: uuid || '',
    });
  };

  const resetProjectCallback = () => {
    dispatch(clearProject);
    clearSearchParams(['activeProject']);
    backToProjects();
  };

  const sortedEntities = useEntityListSort(allEntities, sortSettings);

  // Получение пользователей
  const userUUIDs = sortedEntities?.flatMap((item) => {
    const {
      entity_type,
    } = item;

    const projectUsersUUIDs = getUuidsfromParamsUsers(projectParams.users);
    let users = [];

    if (entity_type === 'testCycle' || entity_type === 'testSuite') {
      users = item?.assignToUser.filter((el) => projectUsersUUIDs.includes(el));
    } else if (entity_type === 'issue') {
      users = item?.usersSearch
        .filter((el) => projectUsersUUIDs.includes(el));
    } else if (entity_type === 'projectGoal' || entity_type === 'projectMilestone') {
      users = item?.responsible?.map((el) => el?.uuid)
        .filter((el) => projectUsersUUIDs.includes(el));
    } else {
      users = getUuidsfromParamsUsers(item?.users)
        .filter((el) => projectUsersUUIDs.includes(el));
    }

    return users;
  });
  useActorsW54({ actors: uniq(userUUIDs) });

  useEffect(() => {
    initFunc();
    return () => {
      dispatch(clearVector);
      dispatch(clearIdea);
      dispatch(clearIssue);
    };
  }, [
    archiveStateUUID,
    activeVector,
    activeIssue,
    activeDoc,
    activeNote,
    activeSheet,
    goal,
  ]);

  useEffect(() => {
    if (modified) {
      const {
        modified__gte,
        modified__lte,
      } = modified;

      const config = {
        entity_type: defaultEntityTypes,
        parent: finalUUID,
        depth: 0,
        params: {},
        offset: 0,
        limit: 25,
        ...omit(currentConfig, ['modified__gte', 'modified__lte']),
      };
      // console.log('config',config)

      if (userFilterTags.length !== 0) {
        config.params.usersSearch = userFilterTags;
      } else {
        delete config.params.usersSearch;
      }

      if (modified__gte && modified__lte && currentConfig?.modified__gte !== modified__gte) {
        config.modified__gte = modified__gte;
        config.modified__lte = modified__lte;
        config.offset = 0;
        setCurrentPage(1);
      }

      if (JSON.stringify(currentConfig) !== JSON.stringify(config)) {
        setCurrentConfig(config);
      }
    }
  }, [JSON.stringify(userFilterTags), modified?.modified__gte, modified?.modified__lte]);

  useEffect(() => {
    if (!isEmpty(currentConfig) && !isNil(currentConfig)) {
      getListOfAllEntities(currentConfig);
    }
  }, [JSON.stringify(currentConfig)]);

  useEffect(() => {
    // console.log('effect 1 document');
    if (!isEmpty(projectFilterConfig)) {
      changeConfig({
        ...projectFilterConfig,
        currentPage: 1,
        offset: 0,
        //limit change
        limit: 25,
      });
    }
  }, [projectFilterConfig]);

  useEffect(() => {
    if (isConfigChanged) {
      // console.log('effect 2 document');
      setCurrentConfigAndGetRequest(currentConfig);
      setIsConfigChanged(false);
    }
  }, [isConfigChanged]);

  return (
    <Row className="mt-2" gutter={[16, 16]} type="flex" style={{ flex: '1 0 auto' }}>
      <ExpandModeLayout localStorageTarget="projectLog">
        <ExpandModeLayout.Table>
          <BaseCard className="primaryCard">
            {/* <div className="w-full flex justify-end">
              {currentView && currentView !== 'project' && (
                <BaseButton
                  size="small"
                  className="customAntOutWarningBtn ml-1"
                  onClick={resetFunc}
                >
                  <Icon
                    path={mdiRestore}
                    size={0.75}
                    className="mr-1"
                  />
                  {capitalize(t('wms.buttons.reset', 'reset'))}
                </BaseButton>
              )}
            </div> */}
            <Col span={24} className="collapsedTable-table">
              <BaseTableWithPagination
                pageLimit={pageLimit}
                changePageLimit={setPageLimit}
                useCustomPagination
                onRow={onRow}
                columns={columns}
                data={sortedEntities}
                rowSelection={selectRow}
                loading={allEntitiesLoading}
                total={get(remotePagination, 'totalSize')}
                pageSizeOptions={get(remotePagination, 'sizePerPageList')}
                disablePagination={get(remotePagination, 'totalSize') <= get(remotePagination, 'defaultPageLimit')}
                newCurrentPage={get(remotePagination, 'page')}
                defaultPageLimit={get(remotePagination, 'defaultPageLimit')}
                getPaginationOptions={get(remotePagination, 'onChange')}
                rowClassName={rowClassName}
                paginationSize="small"
                headerRowClassName="universal_header_table_row"
                filterConfig={projectFilterConfig}
                filterColumnsConfig={columnsFilterConfig}
                filterCallback={changeProjectFilterConfigWrapper}
              />
            </Col>
          </BaseCard>
        </ExpandModeLayout.Table>
        <ExpandModeLayout.Card>
          {currentView === 'project' && (
            <BaseCard className="p-0 borderR10 dangerCard">
              <ProjectInfo
                partitionType={defaultPartition}
                getProjectMembers={getProjectMembers}
                deleteEntityCallback={backToProjects}
                resetProjectCallback={resetProjectCallback}
              />
            </BaseCard>
          )}

          {currentView === 'activeVector' && (
            <CustomCardComponent
              type="vector"
              style={{ minHeight: isSidePanelOpen ? '72vh' : 'unset' }}
            >
              <WrapperVectorInfoCardWithComments
                defaultTemplateState={defaultVectorState}
                viewMode="vector"
                disabledView={['priorityIssue', 'responsible', 'assigned_users', 'edit']}
                hiddenView={['close', 'goToBtn', 'edit', 'delete']}
                restoreActionCallback={defaultProjectState}
                deleteVectorCallback={() => deleteEntityCallback('activity')}
                partitionType={defaultPartition}
                parentType={parentTypeComment[currentView]}
              />
            </CustomCardComponent>
          )}

          {currentView === 'activeIssue' && (
            <CustomCardComponent type="issue">
              <IssueInfoRoot
                actorsTypeForSelect={['defaultUsers', 'projectActors']}
                // actorsForAssignedUsers={projectUsers}
                hiddenView={[
                  'resetIssueBtn',
                  'linkToProject',
                  'goButton',
                  'deleteIssueBtn',
                  'editBtn',
                  'edit',
                ]}
                disabledView={['typeIssue', 'statusCollapse', 'priorityIssue', 'assignedVector', 'uploadFiles']}
                deleteCallback={() => deleteEntityCallback('activity')}
                switchViewFunc={switchViewFunc}
                updateCallback={updateListOfIssues}
                restoreEntityCallback={defaultProjectState}
                partitionType={defaultPartition}
              />
            </CustomCardComponent>
          )}

          {(currentView === 'activeNote' || currentView === 'activeDoc' || currentView === 'activeSheet') && (
            <CustomCardComponent
              id="document"
              type="document"
              params={{
                closeBtns: true,
                isDisabled: true,
              }}
            >
              <DocViewWrapper
                defaultPartition={defaultPartition}
                showEditor={showEditor}
                currentSelected={projDocOrIdeas[0]}
                setShowEditor={setShowEditor}
              />
            </CustomCardComponent>
          )}

          {currentView === 'goal' && (
            <CustomCardComponent
              type="goal"
            >
              <ProjectGoalView
                defaultPartition={defaultPartition}
                hideElements={['backBtn', 'options']}
                disabledElements={['title', 'scale', 'status', 'life_time', 'assign_users', 'textarea']}
              />
            </CustomCardComponent>
          )}
        </ExpandModeLayout.Card>
      </ExpandModeLayout>
    </Row>
  );
}

export default ListOfAllEntities;
