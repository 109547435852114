import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiSquareEditOutline } from '@mdi/js';

import { propOr } from 'ramda';
import { Tooltip } from 'antd';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import BtnModal from '../../BtnModalComponent';

import { capitalize } from 'lodash';
import useActorsW54ModalStyle from '../../../../actors/hooks/useActorsW54ModalStyle';
import EditTestSuite from './EditTestSuite';

function EditTestSuiteModalBtn({
  testSuiteData,
}) {
  const { t } = useTranslation();

  const [isAssignOpen, setIsAssignOpen, styleNewSuiteModal] = useActorsW54ModalStyle();

  return (
    <BtnModal
      title={capitalize(
        t('wms.modals.headers.edit_test_suite', 'edit test suite'),
      )}
      width="50%"
      style={styleNewSuiteModal}
      btnComponent={(
        <BaseButton
          className="ant-btn customAntOutPrimaryBtn ant-btn-default ant-btn-sm"
          style={{ borderRadius: '0px' }}
        >
          <Tooltip
            title={capitalize(
              t('wms.tooltips.edit', 'edit'),
            )}
          >
            <Icon path={mdiSquareEditOutline} size={0.7} />
          </Tooltip>
        </BaseButton>
      )}
    >
      <EditTestSuite
        isAssignOpen={isAssignOpen}
        setIsAssignOpen={setIsAssignOpen}
        defaultTitle={propOr('', 'title', testSuiteData)}
        defaultDescription={propOr('', 'description', testSuiteData)}
        assignToUser={propOr([], 'assignToUser', testSuiteData)}
        entityUUID={testSuiteData?.uuid}
      />
    </BtnModal>
  );
}

EditTestSuiteModalBtn.propTypes = {
  testSuiteData: PropTypes.object,
};

export default EditTestSuiteModalBtn;
