import PropTypes, {
  array, bool, func, number, object,
} from 'prop-types';
import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Row, Tooltip, Badge, Space, Dropdown, Button, Flex,
} from 'antd';
import { isNil, propOr } from 'ramda';
import Icon from '@mdi/react';
import {
  mdiAccountCheckOutline,
  mdiAccountMultipleOutline,
  mdiChevronDown, mdiDotsVertical, mdiIdentifier, mdiPriorityLow, mdiTableOfContents,
} from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import {
  get, groupBy, head, isEmpty, omit, uniq,
} from 'lodash';
import moment from 'moment';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import TestStatusDropdown from '../componentsForMultipleUse/TestStatusDropdown';
import InformationCard from '../componentsForMultipleUse/InformationCard';
import TestCaseInfoCard from '../cardInfo.js/TestCaseInfoCard';
import WrapperEntityFilter from '../componentsForMultipleUse/WrapperEntityFilter';
import ActionFormatterInTestCase from './ActionFormatterInTestCase';
import ReRunSelectedInCycleWrapper from '../../../containers/qaView/ReRunSelectedinCycleWraper';
import TestCase from './TestCase';
import TestCaseOrderFormatter from './TestCaseOrderFormatter';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';
import EntityParamTag from '../../../../components/entityParamTag/EntityParamTag';
import BaseTableWithPagination from '../../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import ImportTestCaseModal from './ImportTestCaseModal';
import NewTestCaseModalHook from './NewTestCaseModalHook';
import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';
import TestCaseFilter from './TestCaseFilter';

import { antNotification, formatDateTimeForWms } from '../../../../MainUtils';
import { capitalize } from 'lodash';
import {
  fetchingTestCase,
  getMe,
  getProject, getTestCycle, getTestCycleUUID, getTestCase,
  getTestSuiteUUID,
  getProjectParams,
} from '../../../selectors/selectors';
import { BugTrackerContext } from '../../../context/ProjectFlowListOfContexts';
import {
  clearCassesInCycle,
  clearCassesInSuit,
  resetTags,
} from '../../../actions/projectFlowActions';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import {
  clearOneTestCaseinTestCycle, updateTestCaseFunc, updateTestCycle,
} from '../../../actions/QaActions';
import { constantsForDeleteCycle, constantsForDeleteSuite, statusName } from '../QaUtils';
import { entityUpdate } from '../../../../entity/actions/entityActions';
import useURLParams from '../../../../hooks/useURLParams';
import { stopPropagation } from '../../../../54origins/utils54origins';
import WrapperAddIssue from '../../issues/newIssue/WrapperAddIssue';
import useActorsW54 from '../../../../actors/hooks/useActorsW54';
import WrapperAvatarsGroupForProject from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';

import { typeOfTestingOptions, cycleStatusTags} from '../../../../constants/allFilterOptions';
import { columnsFilterConfig, cyclesFilterConfig, defaultCasesFilter } from '../constants/qaFilterOptions';
import useTableFilter from '../../../../components/_ui/hooks/useTableFilter';
import { getUuid } from '../../../../assignUsersModal/utils';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';

function ExpendRows({
  expanded,
  record,
  typeOfParent,
  changeStateFromExpandComponent,
}) {
  const classNameIcon = typeOfParent === 'suite'
    ? 'btnWarning-link'
    : typeOfParent === 'cycle'
      ? 'btnBlue-link'
      : 'btnYellow-link';

  return (
    <Tooltip
      placement="leftTop"
      title={record?.uuid}
    >
      <BaseButton
        onClick={() => changeStateFromExpandComponent(record?.uuid)}
        className={classNameIcon}
      >
        <Icon path={mdiChevronDown} size={1} rotate={expanded ? 180 : 0} />
      </BaseButton>
    </Tooltip>
  );
}

function ListOfTestCases({
  colorTable,
  isArchive,
  typeOfParent,
  selectedCycle,
  entityUUID,
  totalCount,
  config,
  setNewConfigCallback,
  dataTable,
  parentData,
  disabledBtnImport,
}) {
  const {
    filterConfig: testCaseFilterConfig,
    changeFilterConfig: changeCaseFilterConfig,
  } = useTableFilter('testCase', {});
  
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const newController = new AbortController();
  const { signal } = newController;

  const {
    getListOfTestCasesInSuite,
    changeQAHistory,
    partitionType,
    getListOfTestCasesInCycle,
    getListOfTestCycles,
    updateTestSuitePromise,
    getListOfTestSuites,
    getListTestCaseInCycleFromArchive,
    getListTestCycleInArchive,
  } = useContext(BugTrackerContext);

  const { getURLParams, clearSearchParams } = useURLParams();

  const fetchingSingleTestCase = useSelector(fetchingTestCase);
  const myUserUuid = useSelector(getMe);
  const testSuiteUUID = useSelector(getTestSuiteUUID);
  const selectedProject = useSelector(getProject);

  const {
    activeProject,
    activeCaseCycle,
    activeCaseSuite,
    redirecttocycle,
    redirecttosuite,
  } = getURLParams();

  const [selectRows, changeSelectRows] = useState([]);
  const [expandedRows, changeExpandedRows] = useState([]);

  const [sizePerPage, setSizePerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const [isEditAssignUsers, setEditAssignUsers] = useState(false);
  const [isEditExecutors, setEditExecutors] = useState(false);
  const [usersFilter, setUsersFilter] = useState([]);
  const [executorsFilter, setExecutorsFilter] = useState([]);

  const [newIssueModalFlag, changeNewIssueModalFlag] = useState(false);
  const [dataForIssue, saveDataForIssue] = useState();
  const [orderRules, changeOrderRules] = useState({});
  const [testCase, setTestCase] = useState(null);

  const projectParams = useSelector(getProjectParams);
  const testCycleUUID = useSelector(getTestCycleUUID);
  const testCycle = useSelector(getTestCycle);
  const defaultValuesForExpanded = {
    currentUUIDFromExpand: '',
    uuidFromExpandedIsEdit: false,
  };
  const [valuesFromExpanded, changeCurrentUUIDFromExpand] = useState(defaultValuesForExpanded);

  const { currentUUIDFromExpand, uuidFromExpandedIsEdit } = valuesFromExpanded;

  // Получение case пользователей
  const userUUIDs = dataTable.flatMap((item) => item?.assignToUser);
  useActorsW54({ actors: uniq(userUUIDs) });

  const hideViewCreateIssueModal = ['assignedProject'];
  const defaultIssueState = {
    assignedProject: activeProject || '',
    testCaseData: dataForIssue,
    tracker: 'bug',
  };

  const selectedKeys = useMemo(() => (selectRows.map((i) => i?.uuid)), [selectRows.length]);

  const testCaseName = typeOfParent === 'suite' ? 'caseSuite' : 'caseCycle';
  const customConstantsForRemoveAction = typeOfParent === 'suite'
    ? constantsForDeleteSuite
    : constantsForDeleteCycle;

  const toggleNewIssueModal = () => changeNewIssueModalFlag(!newIssueModalFlag);
  const newIssueSuccessCallback = () => {    
    toggleNewIssueModal();

    const data = {
      uuid: testCycleUUID,
      partition: partitionType,
      params: { 
        counterBug: +get(testCycle, '[0].params.counterBug', 0) + 1,
        modified_on: formatDateTimeForWms(moment()),
      },
      customConstants: ['REQUEST', ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE, 'FAILURE'],
    };

    let counterBug = testCase?.counterBug || 0;

    if(testCase){
      dispatch(updateTestCaseFunc({
        uuid: testCase?.uuid,
        params: { 
          counterBug: counterBug + 1,
        },
        partition: partitionType,
        signal
      }));
    }
    dispatch(updateTestCycle(data));
    getListOfTestCycles();
    getListOfTestCasesInCycle(testCycleUUID)
  };

  const handleOnExpandOLD = (uuid) => {
    let newExpandedRowsState;

    if (expandedRows.includes(uuid)) {
      newExpandedRowsState = expandedRows.filter((rowUuid) => rowUuid !== uuid);
    } else {
      newExpandedRowsState = [...expandedRows, uuid];
    }

    changeExpandedRows(newExpandedRowsState);
  };

  const customChangeExpand = (uuid) => {
    if (expandedRows.includes(uuid)) {
      changeExpandedRows(expandedRows.filter((x) => x !== uuid));
    } else {
      changeExpandedRows([...expandedRows, uuid]);
    }
  };

  const changeStateFromExpandComponent = useCallback((uuid) => {
    changeCurrentUUIDFromExpand({
      uuidFromExpandedIsEdit: true,
      currentUUIDFromExpand: uuid,
    });
  }, []);

  const closeAllExpandedRows = () => { };

  const updateHistoryAndExpand = () => {
    const uuid = redirecttosuite ? activeCaseSuite : activeCaseCycle;

    clearSearchParams([redirecttocycle ? 'redirecttocycle' : 'redirecttosuite']);

    handleOnExpandOLD(uuid);
  };

  const updateTestCase = async (data) => {
    const constants = [
        ProjectTypeCommonConstants.EDIT_TEST_CASE_REQUEST,
        ProjectTypeCommonConstants.EDIT_TEST_CASE_SUCCESS,
        ProjectTypeCommonConstants.EDIT_TEST_CASE_FAILURE,
    ];

    const res = await dispatch(entityUpdate({
      data,
      constants,
      options: {
        partition: partitionType,
      },
    })).then((response) => {
      antNotification(
        'success',
        t(
          'notifications.text.success.change_test_case_data',
          'Test case has been changed',
        ),
      );
      return response;
    })
      .catch((response) => {
        antNotification(
          'error',
          t('notifications.text.error.base', 'Error'),
        );
        return response;
      });

    return res;
  };

  const getProgress = (status, prevStatus) => {
    const { progress } = parentData?.params;

    const newData = {
      ...progress,
      quantityCase: totalCount,
      [statusName(status)]: get(progress, `${statusName(status)}`, 0) + 1,
      [statusName(prevStatus)]: get(progress, `${statusName(prevStatus)}`, 1) - 1,
    };

    return newData;
  };

  const onChangeStatusCallback = async (status, row) => {
    if (status === 'fail' || status === 'block') {
      setTestCase(row);
      toggleNewIssueModal();
      const issueData = {
        case: row.uuid,
        caseTitle: row.title,
        allSteps: row.steps,
        cycle: propOr('', 'parent', row),
        title: propOr('', 'title', row),
      };

      saveDataForIssue(issueData);
    }
    // Update Case
    const data = {
      entity_type: 'testCase',
      uuid: row?.uuid,
      partition: partitionType,
      params: {
        status,
        executed_by: myUserUuid,
        executed_on: formatDateTimeForWms(moment()),
      },
    };

    data.params.closedCase = (status !== 'unexecuted' && status !== 'in progress');

    if (row?.steps.length !== 0) {
      data.params.steps = row?.steps.map((step) => ({ ...step, status: status === 'in progress' ? 'unexecuted' : status }));
    }

    const response = await updateTestCase({ ...omit(data, ['partition', 'uuid']), entity_uuid: row?.uuid });

    getListOfTestCasesInCycle(parentData?.uuid);

    changeExpandedRows((prev) => prev.filter((i) => i !== head(response)?.uuid));

    // Update Cycle
    const dataCycle = {
      entity_type: 'testCycle',
      uuid: row?.parent,
      partition: partitionType,
      params: {
        status: 'in progress',
        progress: getProgress(status, row?.status),
        executed_on: formatDateTimeForWms(moment()),
        executed_by: myUserUuid,
      },
      customConstants: ['REQUEST', ProjectTypeCommonConstants.UPDATE_TEST_CYCLE_IN_STORE, 'FAILURE'],
    };

    dispatch(updateTestCycle(dataCycle))
      .then(() => {
        antNotification('success', t('notifications.text.success.change_status_test_cycle', 'Test cycles status has been changed'));
        getListOfTestCycles();
      });
  };

  const statusFormatter = (cell, row) => (
    <TestStatusDropdown
      currentStatus={cell}
      additionalBlocking={(parentData.status !== 'unexecuted' && parentData.status !== 'in progress')}
      isArchive={typeOfParent === 'cycleArchive'}
      row={row}
      type="case"
      onChangeStatusCallback={onChangeStatusCallback}
    />
  );

  const updateAfterRemoveCasesInSuite = (entityUUIDS) => {
    const { cases_count } = parentData?.params;
    
    const listOfTestingTypes = new Set()

    if(cases_count > 1){     
      getListOfTestCasesInSuite(parentData?.uuid).then(({data}) => {
        console.log(data);
        
        if(data){
          data?.map(item => item?.typeOfTesting?.forEach(listOfTestingTypes.add, listOfTestingTypes))
        }
      
        const editSuiteParams = {
          cases_count: data?.length || 0,
          typeOfTesting: Array.from(listOfTestingTypes),
        };

        updateTestSuitePromise({ params: editSuiteParams }).then(() => {        
          if (getListOfTestSuites) {
            getListOfTestSuites();
          } else {
            dispatch(setGlobalUpdateEntity({ entityType: 'testSuite', action: 'update' }));
          }
        });
      })
    }
    else{      
      updateTestSuitePromise({ params: {cases_count: 0, typeOfTesting: []} }).then(() => {        
        if (getListOfTestSuites) {
          getListOfTestSuites();
        } else {
          dispatch(setGlobalUpdateEntity({ entityType: 'testSuite', action: 'update' }));
        }
      }); 
    }    
  };

  const updateOneTestCyclesData = async (newProgress, casesCount) => {
    const newHelperInStatus = !!(((parentData.params.status === 'unexecuted' || parentData.params.status === 'in progress')
      && (newProgress.unexecuted === 0 && newProgress.progress === 0 && totalCount !== 0)));

    const data = {
      uuid: parentData?.uuid,
      partition: partitionType,
      params: {
        cases_count: casesCount,
        progress: newProgress,
        helperInStatus: newHelperInStatus,
        modified_on: formatDateTimeForWms(moment()),
        modified_by: myUserUuid,
      },
      customConstants: [
        'REQUEST',
        ProjectTypeCommonConstants[!isArchive ? 'UPDATE_TEST_CYCLE_IN_STORE' : 'UPDATE_TEST_CYCLE_ARCHIVE_IN_STORE'],
        'FAILURE'],
    };

    dispatch(updateTestCycle(data)).then(() => {
      if (isArchive) {
        getListTestCycleInArchive();
      } else {
        getListOfTestCycles();
      }
    });
  };

  const checkFieldAndGetValue = (name, progress, groupStatuses) => (
    groupStatuses.hasOwnProperty(name)
      ? get(progress, statusName(name), 0) - groupStatuses[name].length
      : get(progress, statusName(name), 0));

  const updateAfterRemoveCasesInCycle = async (entityUUIDS, testCases) => {
    const {
      params: {
        progress, cases_count = 0,
      }, uuid,
    } = parentData || {};

    const statusesFromRemoved = testCases.map((i) => i?.status);
    const groupStatuses = groupBy(statusesFromRemoved);

    const casesCount = cases_count === 0 ? 0 : Math.abs(cases_count - entityUUIDS.length);

    const newProgress = {
      pass: checkFieldAndGetValue('pass', progress, groupStatuses),
      block: checkFieldAndGetValue('block', progress, groupStatuses),
      fail: checkFieldAndGetValue('fail', progress, groupStatuses),
      progress: checkFieldAndGetValue('in progress', progress, groupStatuses),
      unexecuted: checkFieldAndGetValue('unexecuted', progress, groupStatuses),
      quantityCase: casesCount,
    };

    updateOneTestCyclesData(
      newProgress,
      casesCount,
    );

    typeOfParent === 'cycleArchive'
      ? await getListTestCaseInCycleFromArchive(uuid)
      : await getListOfTestCasesInCycle(uuid);

    if (entityUUIDS.every((p) => p === activeCaseCycle)) {
      clearSearchParams(['activeCaseCycle']);
      dispatch(clearOneTestCaseinTestCycle);
    }

    changeSelectRows([]);
  };

  const removeCasesCallback = (entityUUIDS, testCases) => {
    if (typeOfParent === 'suite') {
      updateAfterRemoveCasesInSuite(entityUUIDS);
    }

    if (typeOfParent === 'cycle' || typeOfParent === 'cycleArchive') {
      updateAfterRemoveCasesInCycle(entityUUIDS, testCases);
    }
  };

  const actionsFormatter = (cell, row) => (
    <ActionFormatterInTestCase
      partitionType={partitionType}
      row={row}
      typeOfParent={typeOfParent}
      parentUUID={parentData?.uuid}
      removeCasesCallback={removeCasesCallback}
      parentData={parentData}
      selectedKeys={selectedKeys}
      casesCount={!isNil(dataTable) && dataTable.length}
    />
  );
  const removeCallback = () => removeCasesCallback(selectedKeys, selectRows);

  const updateOrderCallback = async (direction, index, e) => {
    e.stopPropagation();
    const id = direction === 'down' ? index + 1 : index - 1;

    await updateTestCase({
      entity_type: 'testCase',
      entity_uuid: get(dataTable[index], 'uuid'),
      params: { order: get(dataTable[id], 'order', 0) },
    });

    await updateTestCase({
      entity_type: 'testCase',
      entity_uuid: get(dataTable[id], 'uuid'),
      params: { order: get(dataTable[index], 'order', 0) },
    });

    if (typeOfParent === 'suite') {
      return getListOfTestCasesInSuite(parentData?.uuid);
    }

    if (typeOfParent === 'cycleArchive') {
      return getListTestCaseInCycleFromArchive(parentData?.uuid);
    }

    if (typeOfParent === 'cycle') {
      return getListOfTestCasesInCycle(parentData?.uuid);
    }
  };

  const orderFormatter = (cell, row, index) => (
    <TestCaseOrderFormatter
      uuid={row.uuid}
      index={index}
      length={dataTable.length}
      updateOrderCallback={updateOrderCallback}
    />
  );

  const priorityFormatter = (cell) => (
    <EntityParamTag
      param="priority"
      value={cell}
      type="char"
    />
  );

  const badgeNumberSteps = (cell) => (
    <Badge
      showZero
      style={{ backgroundColor: colorTable }}
      count={!isNil(cell) ? cell.length : 0}
    />
  );

  const setSearchIssue = (value) => {
    const data = {
      ...config,
      offset: 0,
      search_data: {
        value,
        ignore_case: true,
        fields: {
          params: ['title', 'customId'],
        },
      },
    };
    if (typeOfParent !== 'suite') {
      data.parent = selectedCycle;
    }

    setNewConfigCallback(typeOfParent, data);
    setCurrentPage(1);
  };

  const searchInTitle = (
    <Col className="flex align-item-end minWidth130">
      <AntDesignSearchBox54origins
        onSearch={setSearchIssue}
        placeholder="Search"
      />
    </Col>
  );

  const columns = [
    {
      dataIndex: ['id'],
      title: (<Icon path={mdiIdentifier} size={1} />),
      render: (cell) => <span className="whitespace-nowrap text-xs">{cell}</span>,
    },
    {
      dataIndex: ['title'],
      title: searchInTitle,
      render: (cell) => cell && (
        <EntityParamTag
          param="title"
          value={cell}
          type="value"
        />
      ),
    },
    {
      dataIndex: ['typeOfTesting'],
      key: 'typeOfTesting',
      title: capitalize(t('wms.table.headers.includedTestingTypes', 'Testing Types')),
      render: (cell) => cell && (
        <EntityParamTag
          param={typeOfParent}
          value={cell}
          type="tags"
        />
      ),
      filters: typeOfTestingOptions,
      ...(columnsFilterConfig && { ...columnsFilterConfig?.typeOfTesting }),
      ...(testCaseFilterConfig?.typeOfTesting && { defaultFilteredValue: testCaseFilterConfig?.typeOfTesting}),
    },
    {
      dataIndex: ['steps'],
      title: (
        <Tooltip
          placement="top"
          title={capitalize(t('wms.table.headers.steps'))}
        >
          <Icon path={mdiTableOfContents} size={1} />
        </Tooltip>
      ),
      render: badgeNumberSteps,
    },
    {
      dataIndex: ['casePriority'],
      key: 'casePriority',
      sorter: true,
      title: (
        <Tooltip
          placement="top"
          title={capitalize(t('wms.table.headers.priority', 'Priority'))}
        >
          <Icon path={mdiPriorityLow} size={1} />
        </Tooltip>
      ),
      render: priorityFormatter,
    },
    {
      dataIndex: ['assignToUser'],
      title: (
        <AssigningUsersRoot
          header="User filter"
          isOpen={isEditAssignUsers}
          setIsOpen={setEditAssignUsers}
          isCheckWindowWidthMode={false}
          isClosePopoverOnLeaveMode
          isOneColumnMode={true}
          size={'extra small'}
          data={{
            uuids: projectParams?.usersSearch,
          }}
          isPopover
          onUpdateUsers={(usersData) => {
            const newUsers = usersData?.users?.map((el) => getUuid(el));
            setUsersFilter(newUsers);
          }}
          globalOptions={{
            uuids: projectParams?.usersSearch,
            isOneColumnMode: true,
            size: 'extra small',
          }}
          usersConfig={{
            label: 'Users',
            showSections: ['string-role'],
            textSelectButton: 'Select',
            textDeleteButton: 'Deselect',
            defaultItems: usersFilter,
          }}
          popoverChild={(
            <Flex className="ml-1 min-width-60px" align="center">
              <Tooltip
                placement="top"
                onClick={() => setEditAssignUsers(true)}
                style={{
                  cursor: 'pointer',
                }}
                title={capitalize(t('wms.table.headers.assigned', 'Assigned'))}
              >
                <Icon
                  path={mdiAccountMultipleOutline}
                  role="button"
                  size={1}
                  className="text-secondary"
                />
                <Icon
                  path={mdiChevronDown}
                  role="button"
                  className="text-secondary ml-1"
                  size={1}
                />
              </Tooltip>
              <WrapperAvatarsGroupForProjectWithHook
                actors={usersFilter || []}
                maxCount={3}
                avatarSize={22}
                popoverTrigger="click"
                popoverPlacement="right"
                checkProjectRole
              />
            </Flex>
          )}
        />
      ),
      render: (cell, row) => ((!isNil(
            row.assignToUser,
          ) && row.assignToUser.length !== 0)
            ? (
              <WrapperAvatarsGroupForProject
                actors={get(row, 'assignToUser', [])}
                avatarSize="small"
                avatarGap={4}
                avatarStyles={{ cursor: 'pointer' }}
                groupButtonSize="small"
                checkProjectRole
              />
            )
            : ''),
    },
    {
      dataIndex: ['executed_on'],
      title: capitalize(t('wms.table.headers.executionDate', 'Execution date')),
      sorter: true,
      render: (cell) => (
        <div>
          {cell}
        </div>
      ),
    },
    {
      dataIndex: ['lastEditor'],
      title: (
        <AssigningUsersRoot
          header="User filter"
          isOpen={isEditExecutors}
          setIsOpen={setEditExecutors}
          isCheckWindowWidthMode={false}
          isClosePopoverOnLeaveMode
          isOneColumnMode={true}
          size={'extra small'}
          data={{
            uuids: projectParams?.usersSearch,
          }}
          isPopover
          onUpdateUsers={(usersData) => {
            const newUsers = usersData?.users?.map((el) => getUuid(el));
            setExecutorsFilter(newUsers);
          }}
          globalOptions={{
            uuids: projectParams?.usersSearch,
            isOneColumnMode: true,
            size: 'extra small',
          }}
          usersConfig={{
            label: 'Users',
            showSections: ['string-role'],
            textSelectButton: 'Select',
            textDeleteButton: 'Deselect',
            defaultItems: executorsFilter,
          }}
          popoverChild={(
            <Flex className="ml-1 min-width-60px" align="center">
              <Tooltip
                placement="top"
                onClick={() => setEditExecutors(true)}
                className='cursor-pointer flex flex-row'
                title={capitalize(t('wms.table.headers.executed_by', 'Executed by'))}
              >
                <Icon
                  path={mdiAccountCheckOutline}
                  role="button"
                  className="text-secondary"
                  size={1}
                />
                <Icon
                  path={mdiChevronDown}
                  role="button"
                  className="text-secondary ml-1"
                  size={1}
                />
              </Tooltip>
              <WrapperAvatarsGroupForProjectWithHook
                actors={executorsFilter || []}
                maxCount={3}
                avatarSize={22}
                popoverTrigger="click"
                popoverPlacement="right"
                checkProjectRole
              />
            </Flex>
          )}
        />
      ),
      render: (cell, row) => ((!isNil(
            row.executed_by,
          ) && row.executed_by.length !== 0)
            ? (
              <WrapperAvatarsGroupForProject
                actors={[row?.executed_by]}
                avatarSize="small"
                avatarGap={4}
                avatarStyles={{ cursor: 'pointer' }}
                groupButtonSize="small"
                checkProjectRole
              />
            )
            : ''),
    },
    {
      dataIndex: ['status'],
      className: typeOfParent === 'suite' ? 'hidden' : '',
      title: capitalize(t('wms.table.headers.status', 'Status')),
      filters: cycleStatusTags,
      ...(cyclesFilterConfig && { ...cyclesFilterConfig?.status }),
      ...(testCaseFilterConfig?.status && { defaultFilteredValue: testCaseFilterConfig?.status }),
      render: statusFormatter,
    },
    {
      dataIndex: 'uuid',
      title: capitalize(t('wms.table.headers.order', 'Order')),
      render: orderFormatter,
    },
    {
      dataIndex: ['actions'],
      title: capitalize(t('wms.table.headers.actions', 'actions')),
      render: actionsFormatter,
    },
  ];

  const parentClassExpandRow = {
    suite: 'select_test_suite_row not-hide-checkbox',
    cycle: 'select_test_cycle_row not-hide-checkbox',
    cycleArchive: 'select_test_cycle_archive_row not-hide-checkbox',
  };

  const headerWrapperClassReactTable = {
    suite: 'header-table-test-suite not-hide-checkbox',
    cycle: 'header-table-test-cycle not-hide-checkbox',
    cycleArchive: 'header-table-test-cycle-archive not-hide-checkbox',
  };

  const rowClassName = (row) => `
  ${get(row, 'casePriority') === 'urgent' ? 'red_table_row' : ''}
  ${parentClassExpandRow[typeOfParent]} ${expandedRows.some((i) => i === get(row, 'uuid'))
      ? 'selected'
      : ''} `;

  const expandRow = {
    expandedRowRender: (row, index, indent, expanded) => expanded && (
      <div className={`wrapper_expand_row_test_case ${typeOfParent}`}>
        <div className="px-1 w-full">
          <InformationCard
            textCollapse={capitalize(t('wms.buttons.test_case_info', 'test case info'))}
          >
            <TestCaseInfoCard
              data={row}
              typeOfParent={typeOfParent}
            />
          </InformationCard>
          <TestCase
            getProgress={getProgress}
            isArchive={isArchive}
            entityUUID={row.actor}
            testCaseUUID={row.uuid}
            typeOfParent={typeOfParent}
            handleOnExpand={customChangeExpand}
          />
        </div>
      </div>
    ),
    expandedRowKeys: expandedRows,
    expandedRowClassName: () => 'expanded_case_wrapper',
    columnWidth: 5,
    expandIcon: ({ expanded, rowKey }) => (
      <ExpendRows
        expanded={expanded}
        rowKey={rowKey}
        typeOfParent={typeOfParent}
        changeStateFromExpandComponent={changeStateFromExpandComponent}
      />
    ),
  };

  const onRow = (record) => ({
    onClick: () => {
      const { uuid } = record || {};

      if (!fetchingSingleTestCase) {
        const historyParams = {
          entity: testCaseName,
          uuid,
          expandedRows,
        };

        customChangeExpand(uuid);

        changeQAHistory(historyParams);
      }
    },
  });

  const changeLimit = (data) => {
    setSizePerPage(data);
    setCurrentPage(1);

    const param = {
      ...config,
      limit: data,
      offset: 0,
    };
    if (typeOfParent === 'cycleArchive') {
      param.parent = selectedCycle;
    }
    setNewConfigCallback(typeOfParent, param);
  };

  const currentSortCallback = (dataSort) => {
    if (!get(dataSort, 'order')) {
      changeOrderRules({
        orderBy: 'order',
        order: 'asc',
      });
    } else {
      changeOrderRules(dataSort);
    }
  };

  const changePaginationOptions = ({
    pageLimit,
    currentPage,
    offset,
  }) => {
    const data = {
      ...config,
      offset,
      limit: pageLimit,
    };

    if (typeOfParent === 'cycle') {
      data.parent = selectedCycle;
    }

    setNewConfigCallback(typeOfParent, data);
    setCurrentPage(currentPage);
  };

  const rowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys, selectedRows) => {
      changeSelectRows(selectedRows);
    },
    selectedRowKeys: selectedKeys,
  };

  useEffect(() => {
    let params = {...testCaseFilterConfig}
    if(usersFilter.length){
      params.assignToUser = usersFilter
    }
    if(executorsFilter.length){
      params.executed_by = executorsFilter
    }

    let param = {
      ...config,
      ...orderRules,
      limit: sizePerPage,
      offset: 0,
      params: params,
    };

    if (typeOfParent !== 'archiveCycle') {
      param.parent = selectedCycle;
    }
    
    setNewConfigCallback(typeOfParent, param);
  }, [testCaseFilterConfig, orderRules, usersFilter])

  useEffect(() => {
    if (entityUUID) {
      getListOfTestCasesInSuite(entityUUID);
    }

    if (typeOfParent === 'cycle' && redirecttocycle) {
      updateHistoryAndExpand();
      return;
    }

    if (typeOfParent !== 'cycleArchive' && redirecttocycle) {
      clearSearchParams(['redirecttocycle']);
      return;
    }

    if (typeOfParent !== 'cycleArchive') {
      if (typeOfParent === 'cycle' && activeCaseCycle) {
        customChangeExpand(activeCaseCycle);
      }

      if (typeOfParent === 'suite' && activeCaseSuite) {
        customChangeExpand(activeCaseSuite);
      }
    }

    return () => {
      if (typeOfParent === 'cycle' && selectedCycle) {
        dispatch(clearCassesInCycle());
      }

      if (typeOfParent === 'suite' && testSuiteUUID) {
        dispatch(clearCassesInSuit());
      }
    };
  }, []);

  const pageSizeOptions = useMemo(() => ([5, 25, 50, 100]), []);

  useEffect(() => {    
    if (expandedRows.length !== 0) {
      closeAllExpandedRows();
    }

    if (typeOfParent === 'suite' && redirecttosuite) {
      changeSelectRows([]);
    }
  }, [testSuiteUUID]);

  useEffect(() => {
    if (expandedRows.length !== 0) {
      closeAllExpandedRows();
      changeSelectRows([]);
    }

    if (typeOfParent === 'cycle') {
      resetTags('ListCaseInCycle');
      if (redirecttocycle) {
        changeSelectRows([]);
      }
    } else {
      resetTags('ListArchiveCaseInCycle');
    }
  }, [selectedCycle]);

  useEffect(() => {
    if (selectRows.length !== 0) {
      changeSelectRows([]);
    }
  }, [dataTable.length]);

  useEffect(() => {
    if (currentUUIDFromExpand) {
      const historyParams = {
        entity: testCaseName,
        uuid: currentUUIDFromExpand,
        expandedRows,
      };

      customChangeExpand(currentUUIDFromExpand);
      changeQAHistory(historyParams);

      changeCurrentUUIDFromExpand({
        uuidFromExpandedIsEdit: false,
      });
    }
  }, [uuidFromExpandedIsEdit]);

  return (
    <div>
      <Row gutter={12} className={cn('ml-1', { 'mt-1': typeOfParent === 'suite' }, { 'mb-1': typeOfParent !== 'suite' })}>
        <Col
          span={typeOfParent === 'suite' ? 16 : 9}
        >
          <div className={cn('flex items-center', { 'mt-1': typeOfParent !== 'suite' })}>
            <h6 className="whitespace-nowrap mb-0">
              {capitalize(t('wms.projectflow.qa.cases', 'test cases'))}
              <Badge
                count={!isNil(dataTable) && dataTable.length}
                showZero
                style={{ backgroundColor: colorTable }}
                className="ml-1"
              />
            </h6>
            <Space
              className="ml-1"
              size={[5, 5]}
            >
              {(typeOfParent === 'suite') && (
                <NewTestCaseModalHook
                  partition={partitionType}
                  getListOfTestSuites={getListOfTestSuites}
                />
              )}

              <ImportTestCaseModal
                typeOfParent={typeOfParent}
                parentData={parentData}
                disabledBtnImport={disabledBtnImport}
              />

              <Dropdown
                overlayClassName="z-1"
                menu={{
                  items: [
                    {
                      label: (
                        <div onClick={stopPropagation}>
                          {(typeOfParent !== 'suite')
                            && (
                              <ReRunSelectedInCycleWrapper
                                parentData={parentData}
                                isArchive={typeOfParent === 'cycleArchive'}
                                getCustomTestCases={selectRows}
                                disabled={selectRows.length === 0}
                                selectRows={selectRows}
                              />
                            )}
                        </div>
                      ),
                      key: '1',
                    },
                    {
                      label: (
                        <div onClick={stopPropagation}>
                          <HardDeleteEntityContainer
                            actionForEntity={removeCallback}
                            antNotificationSuccess
                            checkHardDelete={false}
                            className={`${selectRows.length === 0 ? 'customAntOutDangerBtn_disabled' : 'customAntOutDangerBtn'} ml-1`}
                            customConstants={customConstantsForRemoveAction}
                            entityType="entity"
                            entityUUID={selectedKeys}
                            labelMode={false}
                            name="selected"
                            partitionType={partitionType}
                            sizeBtn="small"
                            disabled={selectRows.length === 0}
                          />
                        </div>
                      ),
                      key: '2',
                    },
                  ],
                }}
                // destroyPopupOnHide
                onClick={stopPropagation}
              >
                <Badge count={selectedKeys.length}>
                  <Button
                    type="primary"
                    ghost
                    size="small"
                    className='mt-1'
                  >
                    <Icon path={mdiDotsVertical} size={0.8} />
                  </Button>
                </Badge>
              </Dropdown>

            </Space>
          </div>
        </Col>
      </Row>

      <Col span={24} className="wrapper_test_case_table mb-3">
        <BaseTableWithPagination
          newCurrentPage={currentPage}
          pageLimit={sizePerPage}
          changePageLimit={changeLimit}
          useCustomPagination
          columns={typeOfParent === "suite" ? columns.slice(0, 6).concat(columns.slice(8)) : columns}
          data={dataTable}
          rowKey="uuid"
          pageSizeOptions={pageSizeOptions}
          rowSelection={rowSelection}
          size="small"
          total={totalCount}
          getPaginationOptions={changePaginationOptions}
          onRow={onRow}
          expandable={expandRow}
          rowClassName={rowClassName}
          currentSortCallback={currentSortCallback}
          headerRowClassName={headerWrapperClassReactTable[typeOfParent]}
          filterCallback={changeCaseFilterConfig}
        />
      </Col>

      {newIssueModalFlag && (
        <WrapperAddIssue
          partitionType={partitionType}
          hideView={hideViewCreateIssueModal}
          defaultIssueState={defaultIssueState}
          openFromOuterComponent={newIssueModalFlag}
          hideAddIssueBtn
          closeModalCallback={() => changeNewIssueModalFlag(false)}
          createIssueCallback={newIssueSuccessCallback}
        />
      )}
    </div>
  );
}

ListOfTestCases.propTypes = {
  colorTable: PropTypes.string,
  entityUUID: PropTypes.string,
  isArchive: PropTypes.bool,
  selectedCycle: PropTypes.string,
  typeOfParent: PropTypes.string,
  parentData: object,
  totalCount: number,
  config: object,
  setNewConfigCallback: func,
  dataTable: array,
  disabledBtnImport: bool,
};

export default ListOfTestCases;
