import { useState, useRef } from 'react';
import { string, func, bool } from 'prop-types';
import { Button } from 'antd';
import moment from 'moment';
// import BaseButton from '../../../../../components/_ui/BaseButton';

export default function ComputeButton({
  title,
  callback,
  timestamp,
  disabled,
  setSomeScoreFetching,
}) {
  const [fetching, setFetching] = useState(false);
  const disableClickRef = useRef(false);

  const handleCallback = async () => {
    if (disableClickRef.current === true) return;
    disableClickRef.current = true;
    setSomeScoreFetching(true);
    setFetching(true);
    await callback().finally(() => {
      setSomeScoreFetching(false);
      setFetching(false);
      disableClickRef.current = false;
    });
  };

  const handleTimestamp = (timestamp) => {
    const timestampObj = moment(timestamp, 'DD-MM-YYYY HH:mm:ss');
    const todayObj = moment();
    // console.log('timestampObj', timestampObj);
    const checkIsToday = todayObj.startOf('day').isSame(timestampObj.startOf('day'));
    if (checkIsToday) {
      // console.log('timestampObj', timestampObj.format('HH:mm'));
      return `Today ${moment(timestamp, 'DD-MM-YYYY HH:mm:ss').format('HH:mm')}`;
    }
    return moment(timestamp, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm');
  };

  return (
    <Button
      id={title.charAt(0).toLowerCase() + title.split(" ").map((el => el[0].toUpperCase() + el.slice(1))).join('').slice(1) + "Button"}
      style={{ height: '48px', transition: 'all .3s ease-in-out' }}
      className="btnPrimary-outline mr-1 mb-1"
      size="small"
      loading={fetching}
      disabled={!fetching ? disabled : false}
      onClick={handleCallback}
    >
      <div className="flex flex-col">
        <div>
          {title}
        </div>
        <div>{timestamp ? `(${handleTimestamp(timestamp)})` : ''}</div>
      </div>

    </Button>
  );
}

ComputeButton.propTypes = {
  title: string,
  callback: func,
  timestamp: string,
  disabled: bool,
  setSomeScoreFetching: func,
};
