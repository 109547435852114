// UPDATED 2023.10.03 //Format YYYY|MM|DD
import {
  useLocation, useNavigate, useSearchParams, createSearchParams,
} from 'react-router-dom';

const useURLParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useSearchParams();

  const {
    pathname: locationPathName,
  } = location || {};

  const getURLParams = () => Object.fromEntries(search);

  const transformSearchParams = (params) => (
    createSearchParams({
      ...params,
    }).toString()
  );

  const transformSearchParamsWithPrev = (params) => transformSearchParams({
    ...getURLParams(),
    ...params,
  });

  const routeNavigateTo = (path, searchParams, options) => {
    const navigateData = {
      pathname: path,
    };

    if (searchParams) {
      navigateData.search = transformSearchParamsWithPrev(searchParams);
    }

    // console.log('routeNavigateTo NAVIGATE TO ********', navigateData);

    navigate(navigateData, options);
  };

  const routeNavigateAndClearParams = (path, searchParams) => {
    const navigateData = {
      pathname: path,
    };

    if (searchParams) {
      navigateData.search = transformSearchParams(searchParams);
    }

    // console.log('routeNavigateAndClearParams NAVIGATE TO ********', navigateData);

    navigate(navigateData);
  };

  const removeKeysFromParams = (keys) => {
    const qParams = getURLParams();

    keys.forEach((name) => delete qParams[name]);

    return qParams;
  };

  const clearSearchParams = (keys) => {
    setSearch(removeKeysFromParams(keys));
  };

  const addAndRemoveSearchParams = (addKeys = {}, removeKeys = []) => {
    setSearch({
      ...removeKeysFromParams(removeKeys),
      ...addKeys,
    });
  };

  const setSearchParams = (searchParams) => {
    setSearch(transformSearchParamsWithPrev(searchParams));
  };

  const getStartDateParam = search?.get('startDate')?.toString();
  const getActorUUIDParam = search?.get('actor')?.toString();

  return {
    addAndRemoveSearchParams,
    clearSearchParams,
    currentLocation: location,
    currentLocationPathname: locationPathName,
    getActorUUIDParam,
    getStartDateParam,
    getURLParams,
    routeNavigateAndClearParams,
    routeNavigateTo,
    transformSearchParams,
    setSearchParams,
    navigate,
  };
};

export default useURLParams;
