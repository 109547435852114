import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getUserBoard, getUserBoardUUID } from "../selectors/userBoardSelector";
import dayjs from "dayjs";
import { get, uniq } from "lodash";
import { entityRead } from "../../entity/actions/entityActions";

const days = [dayjs().format('YYYY-MM-DD'), dayjs().add(1, 'day').format('YYYY-MM-DD')]

const useMyBoardPlan = ({
  actor,
  partition,
  projectUUID,
}) => {
  const dispatch = useDispatch();

  const userBoard = useSelector(getUserBoard);
  const userBoardUUID = useSelector(getUserBoardUUID);

  const [isFetching, setIsFetching] = useState(true);
  const [myBoardPlans, setMyBoardPlans] = useState({});

  const onClearMyBoardPlan = () => setMyBoardPlans({});

  const onBoardFormationForDay = ({
    day = '',
    issues = [],
    data = [],
    uuids = [],
    settings = {},
  }) => {
    let switchTime = 0;
    let chunkTime = 0;

    const totalDayHours = data.reduce((acc, issue) => {
      chunkTime += get(issue, 'chunk', 0);
      switchTime += get(issue, 'switch', 0);

      return acc + +get(issue, 'switch', 0) + +get(issue, 'chunk', 0)
    }, 0)

    const dayIssues = issues.filter(({ uuid = '' }) => uuids.includes(uuid));

    return {
      [day]: dayIssues.map(issue => ({
        ...issue,
        done: get(issue, 'params.completed', []).includes(actor),
        data: data.find(data => data.uuid === issue.uuid),
      })).sort((a, b) => get(a, ['data', 'key'], 0) - get(b, ['data', 'key'], 0)),
      [`${day}__params`]: {
        ...settings,
        totalDayHours: +(totalDayHours / 2).toFixed(1),
        totalDaySwitch: +(switchTime / 2).toFixed(1),
        totalDayChunk: +(chunkTime / 2).toFixed(1),
      },
      [`${day}__settings`]: {
        ...settings,
      },
      [`${day}__uuids`]: uuids,
      [`${day}__data`]: data,
    }
  }

  const onUpdateIssueInMyBoard = ({
    day,
    issues,
    data,
    uuids,
    settings,
  }) => {
    const newDayPlan = onBoardFormationForDay({
      day,
      issues: issues ?? get(myBoardPlans, ['all'], []),
      data: data ?? get(myBoardPlans, [`${day}__data`], []),
      uuids: uuids ?? get(myBoardPlans, [`${day}__uuids`], []),
      settings: settings ?? get(myBoardPlans, [`${day}__settings`], {}),
    });

    setMyBoardPlans(prev => ({
      ...prev,
      ...newDayPlan,
    }));
  }

  const getIssueAndFormationOfBoard = async (board) => {
    try {
      setIsFetching(true);

      const currentBoard = board || userBoard;

      const allPlanUUIDs = uniq(days.flatMap(day => get(currentBoard, ['params', day], [])));

      if (!allPlanUUIDs.length) {
        setMyBoardPlans({})
        return;
      }

      const res = await dispatch(entityRead({
        data: {
          parent: projectUUID,
          entity_type: 'issue',
          entity_uuids: allPlanUUIDs,
        },
        constants: [
          'GET_ISSUES_PLAN_FOR_DAY_REQUEST',
          'GET_ISSUES_PLAN_FOR_DAY_SUCCESS',
          'GET_ISSUES_PLAN_FOR_DAY_FAILURE',
        ],
        options: {
          partition,
        },
      }))

      if (res) {
        const newIssue = days.reduce((acc, day) => {
          const uuids = get(currentBoard, ['params', day], []);
          const settings = get(currentBoard, ['params', `${day}__settings`], {});
          const data = get(currentBoard, ['params', `${day}__data`], []);

          return {
            ...acc,
            ...onBoardFormationForDay({
              day,
              issues: res,
              uuids,
              settings,
              data,
            }),
          }
        }, {
          all: res,
        })
        setMyBoardPlans(newIssue)
      }
    } catch (e) {
      setMyBoardPlans({})
      console.log(e);
    } finally {
      setIsFetching(false)
    }
  };

  useEffect(() => {
    if (partition && projectUUID && userBoardUUID) {
      getIssueAndFormationOfBoard();
    }
  }, [partition, projectUUID, userBoardUUID])

  return {
    myBoardPlans,
    isFetchingMyBoardIssues: isFetching,
    onReloadMyBoardIssues: getIssueAndFormationOfBoard,
    onUpdateIssueInMyBoard,
    onClearMyBoardPlan,
  }
}

export default useMyBoardPlan;