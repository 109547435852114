import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Card, Row, Col, Empty, Dropdown,
} from 'antd';

// --------- components -------
import BaseButton from '../../components/_ui/BaseButton/BaseButton';
import SenderSelectListItem from './SenderSelectListItem';
import AntDesignSearchBox54origins from '../../54origins/components/AntDesignSearchBox54origins';

// --------- const, utils -----
import axiosRequest from '../../api/apiAxios';
import { getSenderFilterNotification, getSenders } from '../../projectFlow/selectors/selectors';
import { ProjectTypeCommonConstants } from '../../projectFlow/constants/Constants';
import { getNameForActor } from '../../54origins/utils54origins';
import { notificationUrl } from '../../api/appConfig';
import BaseCard from "../../components/_ui/BaseCard/BaseCard";

export default function SendersModal({ handleSenderCallback }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterBySender = useSelector(getSenderFilterNotification);
  const defaultSenders = useSelector(getSenders);

  const [visible, setVisible] = useState(false);

  const [senders, setSenders] = useState([]);
  const [fetching, setFetching] = useState(true);

  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);

  const [valueSearch, setValueSearch] = useState('');

  const toggleVisible = () => setVisible((prev) => !prev);

  const getAllMentionedProjects = async () => {
    setFetching(true);
    dispatch(
      axiosRequest.get(
        'projects_where_mentioned/',
        [
          ProjectTypeCommonConstants.GET_MENTIONED_PROJECTS_REQUEST,
          ProjectTypeCommonConstants.GET_MENTIONED_PROJECTS_SUCCESS,
          ProjectTypeCommonConstants.GET_MENTIONED_PROJECTS_FAILURE,
        ],
        {
          doNotCheckError: true,
          onSuccess: (response) => {
            getAllSenders(response?.data);
          },
          tokenName: 'Notification',
          url: notificationUrl,
        },
      ),
    );
  };

  const getAllSenders = async (projects_where_mentioned) => {
    const basic_filter_uuids = projects_where_mentioned?.map(
      (project) => project?.project_uuid,
    );
    const basic_filter_string = `basic_uuid=${basic_filter_uuids.join(
      '&basic_uuid=',
    )}`;

    dispatch(
      axiosRequest.get(
        `/senders/?limit=${limit || 100}&offset=${
          offset || 0
        }&${basic_filter_string}`,
        [
          ProjectTypeCommonConstants.GET_SENDERS_REQUEST,
          ProjectTypeCommonConstants.GET_SENDERS_SUCCESS,
          ProjectTypeCommonConstants.GET_SENDERS_FAILURE,
        ],
        {
          doNotCheckError: true,
          onSuccess: (response) => {
            const finalData = Array.isArray(response?.data)
              ? response?.data?.sort(
                (sender1, sender2) => sender2?.unread_messages - sender1?.unread_messages,
              )
              : [];
            setSenders(finalData);
            setFetching(false);
          },
          onFailure: () => setFetching(false),
          tokenName: 'Notification',
          url: notificationUrl,
        },
      ),
    );
  };

  useEffect(() => {
    if (visible) {
      getAllMentionedProjects();
      setValueSearch('');
    }
  }, [visible]);

  useEffect(() => {
    const finalData = defaultSenders.filter((user) => (valueSearch
      ? `${user?.user_data?.first_name} ${user?.uinfo?.last_name}`.toLowerCase().includes(valueSearch?.toLowerCase())
      : user));
    setSenders(finalData);
  }, [valueSearch]);

  const menu = [
    {
      key: 'notifications',
      className: 'p-0',
      label: (
        <BaseCard className="customNotifications-card" loading={fetching}>
          <Row className="customNotifications-head flex justify-between py-3">
            <Col className="flex align-item-end minWidth130 px-4" span={24}>
              <AntDesignSearchBox54origins
                valueSearch={valueSearch}
                onSearch={setValueSearch}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="customNotifications-body">
              {senders?.length !== 0 ? (
                senders.map((sender) => (
                  <div key={sender?.uuid}>
                    <SenderSelectListItem
                      {...sender}
                      toggleVisible={toggleVisible}
                      handleSenderCallback={handleSenderCallback}
                    />
                  </div>
                ))
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No senders to display"
                />
              )}
              {/* <ShowMoreBtn
                show={totalCount > viewedNotifications.length}
                toggleShowMoreCallback={toggleShowMore}
              /> */}
            </Col>
          </Row>
        </BaseCard>
      ),
    },
  ];

  return (
    <div className="customNotifications" style={{ marginLeft: '6px', borderColor: 'transparent' }}>
      <Dropdown
        menu={{ items: menu }}
        placement="right"
        trigger={['click']}
        open={visible}
        onOpenChange={toggleVisible}
        overlayClassName="customNotifications-dropdown"
      >
        <Row className="flex justify-center cursor-pointer w-full">
          <Col>
            <BaseButton className={filterBySender?.uuid ? 'btnPrimary-outline' : 'btnDefault-outline'} size="small">
              {filterBySender?.uuid ? getNameForActor(filterBySender) : (
                'Select the sender'
              )}
            </BaseButton>
          </Col>
        </Row>
      </Dropdown>
    </div>
  );
}
