import React from 'react';
import PropTypes, { string } from 'prop-types';
import { Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

import { capitalize } from 'lodash';
import { dateToLocalTimezone, FORMAT14 } from "../../../54origins/dateFormats54origins";


function CommonInfo({
  creator,
  createdDate,
  lastActivity,
  firstActivity,
  lastModifiedBy,
  executedBy,
  executedOn,
  version,
  placement,
  className = '',
  info = '',
}) {
  const { t } = useTranslation();
  const createdByTitle = capitalize(t('wms.table.headers.created_by', 'created by'));
  const createdTitle = capitalize(t('wms.labels.work.created', 'created'));
  const lastActivityTitle = capitalize(t('wms.labels.lastActivityTimestamp', 'last activity'));
  const lastModifiedByTitle = capitalize(t('wms.labels.last_modified_by', 'last modified by'));
  const firstActivityTitle = capitalize(t('wms.labels.firstActivityTimestamp', 'first activity'));
  const executedByTitle = capitalize(t('wms.table.headers.executed_by', 'Executed by'));
  const executedOnTitle = capitalize(t('wms.table.headers.executed_on'));
  const versionTitle = capitalize(t('wms.labels.version', 'version'));

  const creatorInfo = creator || '-';
  const createDateInfo = createdDate ?  dateToLocalTimezone(createdDate, FORMAT14) : '-';
  const lastActivityInfo = lastActivity ? dateToLocalTimezone(lastActivity, FORMAT14) : '-';
  const firstActivityInfo = lastActivity ? dateToLocalTimezone(firstActivity, FORMAT14) : '-';
  const lastModifiedByInfo = lastModifiedBy || '-';
  const executedOnInfo = executedOn;
  const versionInfo = version || '-';


  const tooltipTitle = (
    <div>
      {creator && (
        <>
          {createdByTitle}
          :&nbsp;
          {creatorInfo}
          {' '}
          <br />
        </>
      )}
      {createdDate && ((
        <>
          {' '}
          {createdTitle}
          :&nbsp;
          {createDateInfo}
          {' '}
          <br />
        </>
      ))}
      {version && (
        <>
          {versionTitle}
          :&nbsp;
          {versionInfo}
          <br />
        </>
      )}
      {firstActivity && (
        <>
          {firstActivityTitle}
          :&nbsp;
          {firstActivityInfo}
          <br />
        </>
      )}
      {lastActivity && (
        <>
          {lastActivityTitle}
          :&nbsp;
          {lastActivityInfo}
          <br />
        </>
      )}
      {lastModifiedBy && (
        <>
          {lastModifiedByTitle}
          :&nbsp;
          {lastModifiedByInfo}
          <br />
        </>
      )}
      {executedBy && (
        <>
          {executedByTitle}
          :&nbsp;
          {executedBy || '-'}
          <br />
        </>
      )}
      {executedOn && (
        <>
          {executedOnTitle}
          :&nbsp;
          {executedOnInfo}
          <br />
        </>
      )}
      {info && (info)}
    </div>
  );

  return (
    <Tooltip

      title={tooltipTitle}
      placement={placement || 'topLeft'}
      color="#3e8adb"
    // color="blue"
    >
      <BaseButton
        id="viewLinkInfoButton"
        shape="circle"
        className={`view_btn_link__info ${className}`}
      >
        <Icon
          // path={mdiInformationVariant}
          path={mdiInformationOutline}
          size={1}
        />
      </BaseButton>
    </Tooltip>
  );
}

CommonInfo.propTypes = {
  className: PropTypes.string,
  creator: PropTypes.string,
  createdDate: PropTypes.string,
  firstActivity: PropTypes.string,
  lastActivity: PropTypes.string,
  lastModifiedBy: PropTypes.string,
  placement: PropTypes.string,
  executedBy: string,
  executedOn: string,
  version: string,
  info: string,
};

export default CommonInfo;
