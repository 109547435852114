import PropTypes from 'prop-types';
import React, {
  useEffect, useState,
} from 'react';
import {
  Col, Tooltip, Tag, Flex,
  Progress,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  capitalize, get, intersection, isArray, isNil, uniq,
} from 'lodash';
import Icon from '@mdi/react';
import {
  mdiAccountMultipleOutline,
  mdiClockTimeThreeOutline,
  mdiFileDocumentOutline,
  mdiIdentifier,
  mdiPriorityLow,
  mdiTimerSand,
  mdiWeight,
  mdiTimerAlertOutline,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronLeft,
  mdiTrashCan,
  mdiHelpCircleOutline,
} from '@mdi/js';
import moment from 'moment';

import './css/ListOfIssuesAntDesignTable.scss';

import AssigningUsersRoot from '../../../assignUsersModal/AssigningUsersRoot';
import BaseTableWithPagination from '../../../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import EntityParamTag from '../../../components/entityParamTag/EntityParamTag';
import DateLeftForExp from '../../../components/DateLeftForExp';
import WrapperAvatarsGroupForProject from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProject';
import WrapperAvatarsGroupForProjectWithHook from '../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';

import { useResize } from '../../../hooks/useResize';
import useOutdatedScore from '../../../hooks/projectAuditHooks/useOutdatedScore';
import useActorsW54 from '../../../actors/hooks/useActorsW54';

import { uuidsSelectedIssuesFromStore, getIssuesScoringData } from '../../reducers/issues/IssuesSlicer';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { addTagsForTitle } from '../../../entity/entityUtils';
import { getProjectParams } from '../../selectors/selectors';

import { entityOutdatedScore, tableCellOutdatedTagColor, notActiveStatusesArrOutdated } from './utilsForOutdatedScoring';
import { getExpTypeAndValue } from '../issues/issueInfo/utils';
import { getUuid } from '../../../assignUsersModal/utils';
import {
  defaultOrderRules,
  priorityWeightFormatter,
  entityProjectWeight,
  estimatedStatusWeight,
  priorityWeight,
} from './utils';
import BaseTag from "../../../components/_ui/BaseTag/BaseTag";
import { capitalizeAndTranslateMsg } from '../../../MainUtils';
import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import EditIssueForIssuesTable from './EditIssueForIssuesTable';
import dayjs from 'dayjs';

function ListOfIssuesAntDesignTable({
  loading,
  data,
  disablePagination,
  newCurrentPage,
  totalCount,
  limit,
  setLimit,
  localSort,
  selectedIssues,
  hiddenSections = ['uuid_before', 'uuid_after', 'chunks'],
  aditionalCols = [],
  onActionCallback,
  isEditMode,
  onEditTicketCallback,
  filterColumnsConfig,
  filterConfig,
  filterCallback,
  getIssuesRequestSetting,
  onChangePageCallback,
  onChangeRowCallback,
  setSearchIssueCallback,
  propsRowClassName,
  rowHoverable = true
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { width } = useResize();

  const projectParams = useSelector(getProjectParams);
  const multiEditIssue = useSelector(uuidsSelectedIssuesFromStore);
  const issuesScoringData = useSelector(getIssuesScoringData);

  const { outdatedIssueTooltipText } = useOutdatedScore();

  const [selectedRowKeys, changeSelectedRowKeys] = useState([]);

  const [paginationOptions, changePaginationOptions] = useState({});
  // console.log('paginationOptions:', paginationOptions);
  const [orderRules, changeOrderRules] = useState(defaultOrderRules);
  const [valueSearch, setValueSearch] = useState('');

  const [isEditAssignUsers, setEditAssignUsers] = useState(false);

  const [usersFilter, setUsersFilter] = useState([]);



  // Получение issue пользователей
  const userUUIDs = data?.flatMap((item) => item?.usersSearch);
  useActorsW54({ actors: uniq(userUUIDs) });

  const changeLimit = (size) => {
    setLimit?.(size);
    getIssuesRequestSetting({ currentPage: 1, limit: size, offset: 0 });
  };

  const onSearchData = (value) => {
    if (setSearchIssueCallback) setSearchIssueCallback(value);

    setValueSearch(value);
  };

  const getBackgroundColor = (type) => {
    switch (type) {
      case 'ticket':
        return 'ticket-background';
      case 'bug':
        return 'bug-background';
      case 'feature':
        return 'feature-background';
      case 'low':
        return 'low-background';
      case 'medium':
        return 'medium-background';
      case 'high':
        return 'high-background';
      case 'urgent':
        return 'urgent-background';
      case 'easy':
        return 'easy-background';
      case 'normal':
        return 'normal-background';
      case 'difficult':
        return 'difficult-background';
      case 'very_complicated':
        return 'very-complicated-background';
      default:
        return '';
    }
  };

  const devScoreTitle = (
    <Tooltip
      placement="top"
      title={(
        <span>
          <strong>Dev score</strong>
          (weight index)
          - an index that shows the weight of the active issue in the project.
        </span>
      )}
    >
      <div>
        <Icon path={mdiWeight} size={1} className="text-secondary" />
      </div>
    </Tooltip>
  );

  const renderIssueDevScore = (row) => {
    const { priority, status } = row;
    const issuesIndexesSum = issuesScoringData?.sum || projectParams?.scoringData?.indexesSum || 0;
    return (
      <span className="whitespace-nowrap text-sm">
        {issuesIndexesSum ? entityProjectWeight(priority, issuesIndexesSum, status, true) : '-'}
      </span>
    );
  };

  const outdatedScoreTitle = (
    <Tooltip
      placement="top"
      title={outdatedIssueTooltipText}
    >
      <Icon path={mdiTimerAlertOutline} size={1} className="text-secondary" />
    </Tooltip>
  );

  const renderIssueOutdatedScore = (row) => {
    const { priority, status, modified } = row;
    const scoreVal = entityOutdatedScore(priority, status, modified);
    const isNotActiveIssue = notActiveStatusesArrOutdated.find((defStatus) => defStatus === status);
    const finalVal = typeof scoreVal === 'number' && !isNil(scoreVal) && Number.isFinite(scoreVal) ? scoreVal : 0;
    const tagColor = isNotActiveIssue ? 'default' : tableCellOutdatedTagColor(finalVal);
    return (
      <BaseTag color={tagColor}>
        <span className="whitespace-nowrap text-sm">
          {isNotActiveIssue ? '-' : finalVal}
        </span>
      </BaseTag>
    );
  };

  const sortByExpirationDate = (a, b) => {
    const typeA = getExpTypeAndValue(a?.status)?.type;
    const typeB = getExpTypeAndValue(b?.status)?.type;
    const daysA = moment(a?.[typeA], 'YYYY-MM-DD').diff(moment(moment(), 'YYYY-MM-DD'), 'days');
    const daysB = moment(b?.[typeB], 'YYYY-MM-DD').diff(moment(moment(), 'YYYY-MM-DD'), 'days');
    return daysA > daysB ? 1 : -1;
  };

  const changeSort = (a, b, paramName) => {
    // console.log('a, b, paramName', a, b, paramName);
    switch (paramName) {
      case 'id':
      case 'title':
      case 'tracker':
      case 'status': return a?.[paramName].toLowerCase().localeCompare(b?.[paramName].toLowerCase());
      case 'priority': return priorityWeight(a?.[paramName]) - priorityWeight(b?.[paramName]);
      case 'estimated_time': return estimatedStatusWeight(a?.[paramName]) - estimatedStatusWeight(b?.[paramName]);
      case 'lifeTime': return sortByExpirationDate(a, b);
      default:
    }
    return 0;
  };

  const columns = [
    {
      dataIndex: 'uuid',
      hidden: hiddenSections?.includes('uuid_before'),
      title: '',
      render: (cell, row) => (
        <Flex
          vertical
          className='w-100'
        >
          <BaseButton
            className="btnPrimary-outline text-xs"
            size="small"
            style={{ maxWidth: 150, maxHeight: 20 }}
            onClick={(e) => onActionCallback?.(cell, 'issue-add', e, dayjs().format('YYYY-MM-DD'))}
          >
            {/* <Flex className='-mr-2 -ml-1'>
              <Icon path={mdiChevronLeft} size={0.8} />
            </Flex> */}
            Add for today
          </BaseButton>
          <BaseButton
            className="btnPrimary-outline text-xs mt-0.5"
            size="small"
            style={{ maxWidth: 150, maxHeight: 20 }}
            onClick={(e) => onActionCallback?.(cell, 'issue-add', e, dayjs().add(1, 'day').format('YYYY-MM-DD'))}
          >
            {/* <Flex className='-mr-2 -ml-1'>
              <Icon path={mdiChevronLeft} size={0.8} />
            </Flex> */}
            Add for tomorrow
          </BaseButton>
        </Flex>
      ),
    },
    {
      dataIndex: 'id',
      title: (
        <Tooltip placement="top" title="tID">
          <Icon path={mdiIdentifier} size={1} className="text-secondary" />
        </Tooltip>
      ),
      hidden: hiddenSections?.includes('id'),
      key: 'id',
      className: 'wordBreakOneLine first-column',
      sorter: (row1, row2) => (localSort ? changeSort(row1, row2, 'id') : true),
      render: (cell) => <span className="whitespace-nowrap text-xs">{cell}</span>,
    },
    {
      dataIndex: 'title',
      key: 'title',
      sorter: (row1, row2) => (localSort ? changeSort(row1, row2, 'title') : true),
      title: (
        <div className="minWidth130">
          <AntDesignSearchBox54origins onSearch={onSearchData} />
        </div>
      ),
      render: (cell, row) => (cell ? (
        <EntityParamTag param="title" value={addTagsForTitle(row?.tags, cell)} type="value" />
      ) : (
        <span>{'<Empty title>'}</span>
      )),
    },
    {
      dataIndex: 'tracker',
      key: 'tracker',
      sorter: (row1, row2) => (localSort ? changeSort(row1, row2, 'tracker') : true),
      hidden: width < 1400 || hiddenSections?.includes('tracker'),
      ...(filterColumnsConfig && { ...filterColumnsConfig?.tracker }),
      ...(filterConfig?.tracker && { defaultFilteredValue: filterConfig?.tracker }),
      title: (
        <Tooltip
          placement="top"
          title={capitalize(t('wms.table.headers.tracker', 'Tracker'))}
        >
          <Icon path={mdiFileDocumentOutline} size={1} className="text-secondary" />
        </Tooltip>
      ),
      render: (cell, row) => cell && (
        <EditIssueForIssuesTable
          isEditMode={isEditMode}
          issue={row}
          onChangeFieldCallback={onEditTicketCallback}
          type="tracker"
        >
          <Flex
            align="center"
            justify="center"
            className={`${getBackgroundColor(cell)}`}
            style={{
              margin: '-8px',
              height: '50px',
              width: '50px',
              borderRadius: '4px',
            }}
          >
            <EntityParamTag param="tracker" value={cell} type="char" />
          </Flex>
        </EditIssueForIssuesTable>
      ),
    },
    {
      dataIndex: 'priority',
      key: 'priority',
      sorter: (row1, row2) => priorityWeight(row1?.priority) - priorityWeight(row2.priority),
      ...(filterColumnsConfig && { ...filterColumnsConfig?.priority }),
      ...(filterConfig?.priority && { defaultFilteredValue: filterConfig?.priority }),
      hidden: width < 1400 || hiddenSections?.includes('priority'),
      title: (
        <Tooltip
          placement="top"
          title={capitalize(t('wms.table.headers.priority', 'Priority'))}
        >
          <Icon path={mdiPriorityLow} size={1} className="text-secondary" />
        </Tooltip>
      ),
      render: (cell, row) => cell && (
        <EditIssueForIssuesTable
          isEditMode={isEditMode}
          issue={row}
          onChangeFieldCallback={onEditTicketCallback}
          type="priority"
        >
          <Flex
            align="center"
            justify="center"
            className={`${getBackgroundColor(cell)}`}
            style={{
              margin: '-8px',
              height: '50px',
              width: '50px',
              borderRadius: '4px',
            }}
          >
            <EntityParamTag param="priority" value={cell} type="char" />
          </Flex>
        </EditIssueForIssuesTable>
      ),
    },
    {
      dataIndex: 'estimated_time',
      key: 'estimated_time',
      sorter: (row1, row2) => (localSort ? changeSort(row1, row2, 'estimated_time') : true),
      ...(filterColumnsConfig && { ...filterColumnsConfig?.estimatedTime }),
      ...(filterConfig?.estimated_time && { defaultFilteredValue: filterConfig?.estimated_time }),
      hidden: width < 1400 || hiddenSections?.includes('estimated_time'),
      title: (
        <Tooltip
          placement="top"
          title={capitalize(t('wms.labels.estimated_time', 'estimated time'))}
        >
          <Icon path={mdiClockTimeThreeOutline} size={1} className="text-secondary" />
        </Tooltip>
      ),
      render: (cell, row) => (cell && (
        <EditIssueForIssuesTable
          isEditMode={isEditMode}
          issue={row}
          onChangeFieldCallback={onEditTicketCallback}
          type="estimated_time"
        >
          <Flex
            align="center"
            justify="center"
            className={`${getBackgroundColor(cell)}`}
            style={{
              margin: '-8px',
              height: '50px',
              width: '50px',
              borderRadius: '4px',
            }}
          >
            <EntityParamTag param="adjective" value={cell} type="char" />
          </Flex>
        </EditIssueForIssuesTable>
      )),
    },
    {
      dataIndex: 'status',
      key: 'status',
      hidden: hiddenSections?.includes('status'),
      sorter: (row1, row2) => (localSort ? changeSort(row1, row2, 'status') : true),
      ...(filterColumnsConfig && { ...filterColumnsConfig?.status }),
      ...(filterConfig?.status && { defaultFilteredValue: filterConfig?.status }),
      title: capitalize(t('wms.table.headers.status', 'Status')),
      render: (cell, row) => cell && (
        <EntityParamTag
          param="status"
          value={cell}
          type="iconWithStatus"
        />
      )
    },
    {
      dataIndex: 'weight',
      key: 'weight',
      hidden: hiddenSections?.includes('weight'),
      sorter: (row1, row2) => priorityWeightFormatter(row1?.priority, row1?.status) - priorityWeightFormatter(row2?.priority, row2?.status),
      title: devScoreTitle,
      render: (cell, row) => renderIssueDevScore(row),
    },
    {
      dataIndex: 'outdatedScore',
      key: 'outdatedScore',
      hidden: hiddenSections?.includes('outdatedScore'),
      sorter: (row1, row2) => entityOutdatedScore(row1?.priority, row1?.status, row1?.modified) - entityOutdatedScore(row2?.priority, row2?.status, row2?.modified),
      defaultSortOrder: localSort ? 'descend' : false,
      title: outdatedScoreTitle,
      render: (cell, row) => renderIssueOutdatedScore(row),
    },
    {
      dataIndex: 'usersSearch',
      hidden: hiddenSections?.includes('usersSearch'),
      title: (
        <AssigningUsersRoot
          header="User filter"
          isOpen={isEditAssignUsers}
          setIsOpen={setEditAssignUsers}
          isCheckWindowWidthMode={false}
          isClosePopoverOnLeaveMode
          isOneColumnMode={true}
          size={'extra small'}
          data={{
            uuids: projectParams?.usersSearch,
          }}
          isPopover
          onUpdateUsers={(usersData) => {
            const newUsers = usersData?.users?.map((el) => getUuid(el));
            getIssuesRequestSetting({ usersSearch: newUsers });
            setUsersFilter(newUsers);
          }}
          globalOptions={{
            uuids: projectParams?.usersSearch,
            isOneColumnMode: true,
            size: 'extra small',
          }}
          usersConfig={{
            label: 'Users',
            showSections: ['string-role'],
            textSelectButton: 'Select',
            textDeleteButton: 'Deselect',
            defaultItems: usersFilter,
          }}
          popoverChild={(
            <Flex className="ml-1 min-width-60px" align="center">
              <Tooltip
                placement="top"
                onClick={() => setEditAssignUsers(true)}
                style={{
                  cursor: 'pointer',
                }}
                title={capitalize(t('wms.table.headers.assigned', 'Assigned'))}
              >
                <Icon
                  path={mdiAccountMultipleOutline}
                  role="button"
                  size={1}
                  className="text-secondary"
                />
                <Icon
                  path={mdiChevronDown}
                  role="button"
                  className="text-secondary ml-1"
                  size={1}
                />
              </Tooltip>
              <WrapperAvatarsGroupForProjectWithHook
                actors={usersFilter || []}
                maxCount={3}
                avatarSize={22}
                popoverTrigger="click"
                popoverPlacement="right"
                checkProjectRole
              />
            </Flex>
          )}
        />
      ),
      render: (cell) => (
        cell?.length > 0 && (
          <WrapperAvatarsGroupForProject
            actors={cell}
            avatarSize="small"
            avatarGap={4}
            avatarStyles={{ cursor: 'pointer' }}
            groupButtonSize="small"
            checkProjectRole
          />
        )
      ),
    },
    {
      dataIndex: 'lifeTime',
      key: 'lifeTime',
      hidden: hiddenSections?.includes('lifeTime'),
      sorter: (row1, row2) => (localSort ? changeSort(row1, row2, 'lifeTime') : true),
      title: (
        <Tooltip placement="top" title={capitalize(t('wms.adjective.expired'))}>
          <Icon path={mdiTimerSand} size={1} className="text-secondary" />
        </Tooltip>
      ),
      render: (cell, row) => (
        <DateLeftForExp
          expirationDateDev={row?.lifeTime}
          status={row?.status}
        />
      ),
    },
    {
      dataIndex: 'chunks',
      key: 'chunks',
      hidden: hiddenSections?.includes('chunks'),
      sorder: (a, b) => a === b,
      title: <Tooltip
        color="white"
        title={(<Flex vertical>
          <Flex>
            <Progress
              strokeColor='#d6d6d6'
              showInfo={false}
              percent={100}
              steps={1}
            /> - switch
          </Flex>
        </Flex>)}
      >
        Chunks
        <Icon
          path={mdiHelpCircleOutline}
          className='ml-1'
          size={1}
        />
      </Tooltip>,
      render: (cell, row) => {
        const steps = get(cell, 'switch', 0) + get(cell, 'chunk', 0);
        let percent = 0;
        if (get(cell, 'switch', 0)) {
          percent = 100 / steps;
        }
        return <Progress
          strokeColor='#fa8c16'
          showInfo={false}
          percent={percent}
          steps={steps}
          trailColor="#1677ff"
        />
      },
    },
    {
      dataIndex: 'uuid',
      hidden: hiddenSections?.includes('uuid_after'),
      title: '',
      render: (cell, row) => (
        <Flex className='w-100'>
          {!hiddenSections?.includes('uuid_after-remove') && <BaseButton
            className="btnDanger-outline ml-2"
            size="small"
            style={{ maxWidth: 70 }}
            onClick={(e) => onActionCallback?.(cell, 'uuid_after-remove', e)}
          >
            <Icon path={mdiTrashCan} size={0.9} className="" />
          </BaseButton>}
        </Flex>
      ),
    },
    ...aditionalCols,
  ].filter((el) => !el.hidden);

  const onRow = (record) => ({
    onClick: () => {
      const { uuid } = record || {};

      if (onChangeRowCallback) {
        onChangeRowCallback(uuid);
      }
    },
  });

  const rowClassName = (row) => `
  ${get(row, 'priority') === 'urgent' ? 'red_table_row' : ''}
  select_table_row ${selectedRowKeys.some((i) => i === get(row, 'uuid')) ? 'selected' : ''} `;

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: multiEditIssue,
    onChange: (selectedRowKeysData) => {
      dispatch({
        type: ProjectTypeCommonConstants.SET_UUIDS_FOR_MULTI_SELECT,
        payload: selectedRowKeysData,
      });
    },
  };

  const currentSortCallback = (dataSort) => {
    if (!get(dataSort, 'order')) {
      changeOrderRules({
        orderBy: 'created',
        order: 'desc',
      });
    } else {
      changeOrderRules(dataSort);
    }
  };

  useEffect(() => {
    if (isArray(selectedIssues)) {
      changeSelectedRowKeys(selectedIssues);
    } else {
      changeSelectedRowKeys(selectedIssues ? [selectedIssues] : []);
    }
  }, [selectedIssues]);

  useEffect(() => {
    if (getIssuesRequestSetting) {
      getIssuesRequestSetting({
        ...paginationOptions,
        ...orderRules,
        ...filterConfig,
        search: valueSearch,
      });
    }
  }, [paginationOptions, orderRules, filterConfig, valueSearch]);

  // console.log('issues data', data);

  return (
    <Col span={24} className="collapsedTable-table">
      <BaseTableWithPagination
        loading={loading}
        newCurrentPage={newCurrentPage}
        pageLimit={limit}
        changePageLimit={changeLimit}
        useCustomPagination
        columns={columns}
        data={data}
        disablePagination={disablePagination}
        rowKey="uuid"
        rowSelection={rowSelection}
        size="small"
        total={totalCount}
        headerRowClassName="universal_header_table_row color-gray"
        getPaginationOptions={changePaginationOptions}
        onRow={onRow}
        onChangePageCallback={onChangePageCallback}
        rowClassName={propsRowClassName || rowClassName}
        currentSortCallback={currentSortCallback}
        filterCallback={filterCallback}
        rowHoverable={rowHoverable}
      />
    </Col>
  );
}

export default ListOfIssuesAntDesignTable;

ListOfIssuesAntDesignTable.propTypes = {
  aditionalCols: PropTypes.array,
  data: PropTypes.array,
  hiddenSections: PropTypes.array,
  disablePagination: PropTypes.bool,
  getIssuesRequestSetting: PropTypes.any,
  onChangePageCallback: PropTypes.func,
  onChangeRowCallback: PropTypes.func,
  selectedIssues: PropTypes.any,
  showQViewColumn: PropTypes.bool,
  quickViewFunc: PropTypes.func,
  setSearchIssueCallback: PropTypes.func,
  onActionCallback: PropTypes.func,
  totalCount: PropTypes.number,
  newCurrentPage: PropTypes.number,
  localSort: PropTypes.bool,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  loading: PropTypes.bool,
  filterColumnsConfig: PropTypes.object,
  filterConfig: PropTypes.object,
  filterCallback: PropTypes.func,
  propsRowClassName: PropTypes.func,
  rowHoverable: PropTypes.func
};
