import React, { useState } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';

import {
  Button,
  Dropdown,
  Flex, Spin,
} from 'antd';

import Icon from '@mdi/react';
import {
  mdiDotsVertical,
  mdiTrashCan,
} from '@mdi/js';

import BaseCard from "../../../../components/_ui/BaseCard/BaseCard";
import PlanForDayListOfIssues from './PlanForDayListOfIssues';
import PlanForDayInfoSection from './PlanForDayInfoSection';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';

import { stopPropagation } from '../../../../54origins/utils54origins';
import WrapperAddIssue from '../../issues/newIssue/WrapperAddIssue';

function PlansForDay({
  day,
  issues,
  dayParams = {},
  partition,
  loading,
  onActionCallback,
  onComputePlanCallback,
  customParentIssue = null,
  actorUUID,
  projectUsers,
  hideElements = [],
  isMainBoard = false
}) {
  const isToday = dayjs(day).isSame(dayjs(), 'day');
  const isTomorrow = dayjs(day).isSame(dayjs().add(1, 'day'), 'day');

  const btnBlue = "border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white";

  const btnDropdownStyle = { height: 30, };

  const dataTable = issues?.map(el => ({
    ...el?.params,
    done: el?.done,
    uuid: el?.uuid,
    data: el?.data
  })).filter(el => !isNil(el))

  const onActionClick = (value, type, e) => {
    onActionCallback(value, type, e, day)
  }

  const getClassComputeBtn = () => {
    if (isToday) {
      return cn('border border-green-500 text-green-500', {
        '!border-stone-500 !text-stone-500': loading,
        'hover:bg-green-500 hover:text-white': !loading,
      })
    }
    if (isTomorrow) {
      return cn('border border-blue-500 text-blue-500', {
        '!border-stone-500 !text-stone-500': loading,
        'hover:bg-blue-500 hover:text-white': !loading,
      })
    }
  }

  const DropdownForTitle = () => {
    return <Dropdown
      onClick={stopPropagation}
      menu={{
        items: [{
          key: '1',
          label: <BaseButton
            className={`btnDanger-outline w-full`}
            size="small"
            style={btnDropdownStyle}
            onClick={(e) => onActionClick?.(day, 'day-remove-all', e)}
          >
            <Flex
              className='w-full'
              align="center"
            >
              <Icon
                path={mdiTrashCan}
                size={0.8}
                className='mr-1'
              />
              Сlear the plan
            </Flex>
          </BaseButton>
        },
        ].filter(el => !el.hidden),
      }}
    >
      <BaseButton
        className={cn(`${btnBlue}`)}
        size="small"
        onClick={stopPropagation}
      >
        <Icon
          path={mdiDotsVertical}
          className='-mr-1'
          size={0.8}
        />
        Actions
      </BaseButton>
    </Dropdown>
  }

  return (
    <BaseCard
      className={cn("mb-2 pb-2 w-full overflow-x-scroll", {
        "myboard-plan-card-issues-view": isToday,
        "vector-card-issues-view": isTomorrow,
      })}
      title={(
        <Flex justify='space-between'>
          <Flex>
            <h6
              className='mt-1 mr-2'
            >
              {isToday && !isMainBoard && 'Plans for today'}
              {isMainBoard && 'List of issues for today plan'}
              {isTomorrow && 'Plans for tomorrow'}
            </h6>
            {!hideElements.includes('addIssueBtn') &&
            <WrapperAddIssue
              partitionType={partition}
              createIssueCallback={(uuid, issue) => onActionCallback?.({ uuid, ...issue, ...issue?.params }, 'issue-create-and-add', null, day)}
              hideView={['assignedProject']}
              disableBtn={false}
              customParentIssue={customParentIssue}
              defaultIssueState={{
                partition,
                users: [
                  {
                      uuid: actorUUID
                  }
              ],
              usersSearch: [actorUUID]
              }}
            />}
            {!hideElements.includes('addIssueBtn') &&
               <Button
               disabled={loading}
               className={`${getClassComputeBtn()} ml-2`}
               size="small"
               onClick={() => onComputePlanCallback(day)}
             >
               Compute plan
             </Button>
            }
          </Flex>
          <DropdownForTitle />
        </Flex>
      )}
    >
      <Spin spinning={loading}>
        <PlanForDayListOfIssues
          hideElements={hideElements}
          data={dataTable}
          day={day}
          onActionCallback={onActionClick}
          onChangeRowCallback={(uuid) => onActionClick(uuid, 'q-view')}
          customProjectUsers={projectUsers}
          isMainBoard={isMainBoard}
        />
        <Flex
          className="mx-2 mt-3 mb-2"
        >
          <PlanForDayInfoSection
            day={day}
            onActionCallback={onActionClick}
            dayParams={dayParams}
          />
        </Flex>
      </Spin>
    </BaseCard>
  );
}

export default PlansForDay;

PlansForDay.propTypes = {
  day: PropTypes.string,
  issues: PropTypes.array,
  dayParams: PropTypes.object,
  partition: PropTypes.string,
  loading: PropTypes.bool,
  onComputePlanCallback: PropTypes.func,
  onActionCallback: PropTypes.func,
  actorUUID: PropTypes.string,
  projectUsers: PropTypes.array,
  hideElements: PropTypes.array,
  isMainBoard: PropTypes.bool
};
