import React, { useState } from 'react';
import { mdiCheck, mdiMagnify, mdiWindowClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Col, Flex, Row, Tooltip } from 'antd';
import PropTypes from 'prop-types';

export default function AssignUsersTabs({
  items = [],
  selectedTab = '',
  showMagnify = false,
  valueSearch,
  isActive,
  setIsActive,
  setSelectedTab,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }
  const getBorderRadius = (index) => {
    if (items.length === 1) return '10px 10px 0 0';
    if (index === 0) return '10px 0 0 0';
    if (index === items.length - 1) return '0 10px 0 0';
    return '0 0 0 0';
  };

  const onTabClick = (value) => {
    if (setSelectedTab) {
      setSelectedTab?.(value);
    }
  };

  return (
    <Row>
      {items.map(({ label, value }, index) => (
        <Col
          key={value}
          span={Math.floor(24 / items.length)}
          className={`users-tab ${selectedTab === value && items.length !== 1 ? 'users-tab-selected' : ''}`}
          onClick={() => onTabClick(value)}
          style={{
            borderRadius: getBorderRadius(index),
          }}
        >
          {label}
          {showMagnify && valueSearch ?
            <Tooltip
              title={isActive ? "Click to activate the search" : "Click to deactivate the search"}
              color="orange"
            >
              <span
                onClick={() => setIsActive(!isActive)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className='ml-1'
                style={{
                  position: 'absolute',
                  left: 10,
                  // display: 'flex',
                  // alignItems: 'center',
                  // justifyContent: 'center',
                  // paddingTop: -8,
                  width: 26,
                  height: 26,
                  transition: '0.2s ',
                  background: isHovered ? '#1890ff' : 'white',
                  borderRadius: '50%'
                }}
              >
                <Icon
                  path={mdiMagnify}
                  style={{
                    marginTop: -7,
                    marginLeft: 2
                  }}
                  color={isHovered ? 'white' : '#1890ff'}
                  size={0.9}
                />
                {isActive ? <span
                  style={{
                    background: 'green',
                    position: 'absolute',
                    height: 15,
                    width: 15,
                    marginTop: -2,
                    marginLeft: -6,
                    borderRadius: '50%'
                  }}
                >
                  <Icon
                    color="white"
                    path={mdiCheck}
                    style={{
                      marginTop: -20,
                      marginLeft: 1
                    }}
                    size={0.55}
                  />
                </span> : <span
                  style={{
                    height: 15,
                    width: 15,
                    background: 'red',
                    position: 'absolute',
                    marginTop: -2,
                    marginLeft: -6,
                    borderRadius: '50%'
                  }}
                >
                  <Icon
                    color="white"
                    path={mdiWindowClose}
                    style={{
                      marginTop: -20,
                      marginLeft: 1
                    }}
                    size={0.55}
                  />
                </span>
                }
              </span></Tooltip> : null}
        </Col>
      ))
      }
    </Row >
  );
}

AssignUsersTabs.propTypes = {
  items: PropTypes.array,
  selectedTab: PropTypes.string,
  showMagnify: PropTypes.bool,
  setSelectedTab: PropTypes.func,
};
