import React from 'react';
import { array, bool, func, number, object, string } from 'prop-types';

import { get } from 'lodash';
import cn from 'classnames';

import { Dropdown, Flex, Space, Spin } from 'antd';

import Icon from '@mdi/react';
import { mdiDotsVertical } from '@mdi/js';

import { stopPropagation } from '../../../54origins/utils54origins';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import BaseCard from "../../../components/_ui/BaseCard/BaseCard";
import SuggestedDayListOfIssues from './SuggestedDayListOfIssues';
import { getClassBtn } from '../project/generalPlanboard/utils';
import TotalDayHoursButtons from './plansForDayComponents/TotalDayHoursButtons';

function SuggestedPlan({
  issues,
  loading,
  onActionCallback,
  paginationSettings = {},
  mode,
  changeMode,
  showClosed,
  setShowClosed,
  hideElements = [],
  isMainBoard = false,
  projects = [],
  sheduleHours = 0,
  selectedProjects = [],
  setSelectedProjects
}) {
  const {
    limit,
    total,
    setLimit,
    setPartOfConfig,
    setNewPage,
    newCurrentPage,
    issueFilterConfig,
    changeIssueFilterConfig,
  } = paginationSettings;

  const btnBlue = "border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white";
  const btnGreen = "border border-green-500 text-green-500 hover:bg-green-500 hover:text-white";
  const btnRed = "border border-red-500 text-red-500 hover:bg-red-500 hover:text-white";

  const btnDropdownStyle = { height: 25 };

  const DropDownBtn = () => {
    return <Dropdown
      menu={{
        items: [{
          key: '0',
          label: <BaseButton
            className={cn(`w-full`, {
              [btnGreen]: !showClosed,
              [btnRed]: showClosed,
            })}
            size="small"
            style={btnDropdownStyle}
            onClick={() => setShowClosed(prev => !prev)}
          >
            {showClosed ? 'Hide completed issues' : 'Show completed issues'}
          </BaseButton>
        },
        ].filter(el => !el.hidden),
      }}
    >
      <BaseButton
        className={cn(`${btnBlue}`)}
        size="small"
        onClick={(e) => stopPropagation(e)}
      >
        <Icon
          path={mdiDotsVertical}
          className='-mx-1'
          size={0.8}
        />
      </BaseButton>
    </Dropdown>
  }

  const titleRender = () => {
    const totalHours = issues.reduce((acc, el) => acc + get(el, 'data.chunk'), 0) / 2;

    const isUserMode = 'user' === mode;
    const isAllMode = 'all' === mode;

    return (
      <Flex justify='space-between' align='center' className='my-2'>
        <Flex align='center'>
          <h6 className='mt-2 mr-1'>
            {isUserMode && 'My unplanned issues'}
            {isAllMode && 'All unplanned issues'}
          </h6>
          <Space.Compact>
            {!hideElements.includes("showAllBtn") &&
                <BaseButton
                id="showMyIssuesButton"
                className="btnPrimary-outline ml-2"
                size="small"
                onClick={() => {
                  if (isUserMode) changeMode?.('all');
                  if (isAllMode) changeMode?.('user');
                }}
              >
                {isUserMode && 'Show all issues'}
                {isAllMode && 'Show my issues'}
              </BaseButton>
            }
            
            {isMainBoard && 
              <Flex align='center'>
                <BaseButton
              id="showMyIssuesButton"
              className={`${getClassBtn("save")} ml-2 mr-1`}
              size="small"
              onClick={() => console.log("time")}
            >
             Schedule issues for today for the total time: 
            </BaseButton>
              <TotalDayHoursButtons
              onActionCallback={onActionCallback}
              availableHours={sheduleHours}
              isMainBoard={true}
            /> 
              <span className="ml-1 text-base text-green-500">
                hours
              </span>
              </Flex>
            }

           {!hideElements.includes('showCompletedBtn') &&  <DropDownBtn />}
          </Space.Compact>
        </Flex>
        {totalHours >= 0 ? <span className='text-sm text-blue-500'>
          These issues will be completed in {totalHours} hours (~{Math.ceil(totalHours / 8)} days)
        </span> : null}
      </Flex>
    )

  }

  return (
    <BaseCard
      title={titleRender()}
      className={"issue-card-issues-view"}
    >
      <Spin spinning={loading}>
        <SuggestedDayListOfIssues
          data={issues}
          onChangeRowCallback={(uuid) => onActionCallback(uuid, 'q-view')}
          onActionCallback={onActionCallback}
          totalCount={total}
          newCurrentPage={newCurrentPage}
          onChangePageCallback={setNewPage}
          limit={limit}
          setLimit={setLimit}
          getIssuesRequestSetting={setPartOfConfig}
          issueFilterConfig={issueFilterConfig}
          changeIssueFilterConfig={changeIssueFilterConfig}
          isMainBoard={isMainBoard}
          projects={projects}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
        />
      </Spin>
    </BaseCard >

  );
}

SuggestedPlan.propTypes = {
  issues: array,
  loading: bool,
  mode: string,
  changeMode: func,
  onRowClickCallback: func,
  onActionCallback: func,
  paginationSettings: object,
  showClosed: bool,
  setShowClosed: func,
  hideElements: array,
  isMainBoard: bool,
  projects: array,
  sheduleHours: number,
  setSelectedProjects: func
}

export default SuggestedPlan;
