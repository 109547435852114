import {
  Col, Image, Popover, Row, Tooltip,
} from 'antd';
import { get, isEmpty, isNil } from 'lodash';
import React, { useCallback } from 'react';
import Icon from '@mdi/react';
import {
  mdiClose, mdiDownload, mdiFileMusicOutline, mdiFileOutline,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import {
  bytesToSize,
  defaultImg,
} from './utils';
import WrapperModalForViewVideo from '../video/WrapperModalForViewVideo';

export default function ViewFiles({
  files,
  audio,
  deleteFile,
  deleteAudio,
  disabledDelete,
  withContainerForVideo,
}) {
  const { t } = useTranslation();
  const viewAudio = (src, item, i) => (
    <div className="audio-box-in-popup">
      <div>
        <div className="audio-box__label">{get(item, 'filename', 'audio')}</div>
        <audio
          className="audio-box__source-wrap"
          controls
          key={i}
        >
          <source src={src} type={item?.content_type} />
          <source src={src} type="audio/mpeg" />
          {t(
            'wms.audio.no_support',
            'Your browser does not support the audio element',
          )}
          .
        </audio>
      </div>
    </div>
  );

  const viewName = useCallback((filename, name, fileSize) => (
    <Tooltip placement="bottom" title={`${filename || name} (${fileSize})`}>
      <span className="text-upload-file">
        {`${filename || name}(${fileSize})`}
      </span>

    </Tooltip>
  ), []);

  const iconDeleteFile = (i, isAudio) => !disabledDelete && (
  <Icon
    path={mdiClose}
    size={0.7}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isAudio) {
        deleteAudio(i);
      } else {
        deleteFile(i);
      }
    }}
    className="button-delete-upload-file"
  />
  );

  const saveFile = (e, file, filename) => {
    saveAs(file, filename);
  };

  return (
    <Row gutter={[8, 8]}>
      {(!isNil(files) && !isEmpty(files)) ? (
        <Image.PreviewGroup>
          {files.map((item, i) => {
            const {
              content_length = 0,
              content_type: contentType,
              size = '',
              filename = '',
              type,
              name = 'file',
              file,
              uuid,
              upload: {
                total = '',
              } = {},
            } = item || {};

            const fileSize = bytesToSize(content_length || total || size);

            if ((contentType && contentType.indexOf('video') !== -1) || (type && type.indexOf('video') !== -1)) {
              return (
                <Col className="relative flex flex-col items-center" key={`${uuid}${i}`}>
                  {iconDeleteFile(i)}
                  <WrapperModalForViewVideo
                    file={item}
                    withContainer={withContainerForVideo}
                  />
                  {viewName(filename, name, fileSize)}
                </Col>
              );
            }
            return (
              <Col className="relative flex flex-col items-center" key={`${uuid}${i}`}>
                {iconDeleteFile(i)}

                {(contentType && contentType.indexOf('image') !== -1) || (type && type.indexOf('image') !== -1) ? (
                  <div className="wrapper-file-icon">
                    <Image
                      width={37}
                      height={37}
                      src={type ? URL.createObjectURL(item) : `data:${contentType};base64, ${file}`}
                      fallback={defaultImg}
                    />
                  </div>
                ) : (
                  <div
                    className="flex items-center fileAttached"
                    onClick={(e) => saveFile?.(e, `data:${contentType};base64, ${file}`, filename)}
                  >
                    <Icon
                      className="fileIcon"
                      color="#bfbfbf"
                      path={mdiFileOutline}
                      size={2}
                    />
                    <Icon
                      className="fileLoad"
                      color="#bfbfbf"
                      path={mdiDownload}
                      size={1}
                    />
                  </div>
                )}
                {viewName(filename, name, fileSize)}
              </Col>
            );
          })}
        </Image.PreviewGroup>
      ) : null}

      {(!isNil(audio) && !isEmpty(audio)) ? (
        <>
          {audio.map((item, i) => {
            const {
              content_length,
              content_type: contentType,
              size = '',
              filename,
              name = 'audio',
              type,
              file,
              upload: {
                total = '',
              } = {},
            } = item || {};
            const fileSize = bytesToSize(content_length || total || size);
            return (
              <div
                key={`${item?.uuid}${i}${i?.size}`}
                className="flex flex-col items-center relative"
              >
                {iconDeleteFile(i, true)}

                <Popover
                  content={() => viewAudio(type ? URL.createObjectURL(item) : `data:${contentType};base64, ${file}`, item, i)}
                  trigger="click"
                  placement="top"
                >
                  <Icon
                    path={mdiFileMusicOutline}
                    size={2}
                    className="audio-icon"
                  />
                </Popover>
                {viewName(filename, name, fileSize)}
              </div>
            );
          })}
        </>
      ) : null}
    </Row>
  );
}
