import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';
import { mdiArrowRightBoldCircleOutline } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';

import BaseButton from '../_ui/BaseButton/BaseButton';

function GoToButton({
  btnClassName = 'btnPrimary flex items-center justify-center',
  btnSize = 'small',
  hiddenElements = [],
  iconClassName = '',
  iconPath = mdiArrowRightBoldCircleOutline,
  iconSize = 0.8,
  onClick,
  pathForLabelTranslate = 'wms.buttons.go_to_tasks',
  pathToRedirect,
  label,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // console.log('pathToRedirect', pathToRedirect);

  const onClickBtn = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(pathToRedirect);
    }
  };

  return (
    !hiddenElements.includes('button') && (
      <BaseButton
        size={btnSize}
        className={btnClassName}
        onClick={onClickBtn}
        // type="link"
      >
        <span>
          {label || capitalize(t(pathForLabelTranslate, 'go to'))}
        </span>
        <span
          style={{ fontSize: 16, height: '90%' }}
          className="m-1"
        >
          |
        </span>
        <Icon
          path={iconPath}
          size={iconSize}
          className={iconClassName}
        />
      </BaseButton>
    )
  );
}

export default GoToButton;

GoToButton.propTypes = {
  btnClassName: PropTypes.string,
  label: PropTypes.string,
  btnSize: PropTypes.string,
  hiddenElements: PropTypes.array,
  iconClassName: PropTypes.string,
  iconPath: PropTypes.element,
  iconSize: PropTypes.number,
  onClick: PropTypes.func,
  pathForLabelTranslate: PropTypes.string,
  pathToRedirect: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
