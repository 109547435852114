import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Divider, Input, Modal, Row, Tooltip,
} from 'antd';
import {
  mdiArrowLeft,
  mdiCogOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import { prop } from 'ramda';
import TextArea from 'antd/lib/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import BtnModal from '../BtnModalComponent';

import { capitalize } from 'lodash';
import { ProjectTypeCommonConstants } from '../../constants/Constants';
import { getMainAreaUUID } from '../../../entity/selectors/selectors';
import HardDeleteEntityContainer
  from '../../../entity/containers/HardDeleteEntityContainer';
import { entityCreate, entityUpdate } from '../../../entity/actions/entityActions';
import { getAreasRequest } from '../../actions/areasActions';
import NewArea from './NewArea';
import { partitionNamesConfig } from '../../../api/appConfig';

function AreasManagement({
  areas,
  partition = partitionNamesConfig.partition1,
  classNameButton,
}) {
  const areaPartitionUUID = useSelector(getMainAreaUUID);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filteredAreas, setFilteredAreas] = useState([...areas]);
  const [createAreaOpen, setCreateAreaOpen] = useState(false);

  const initialAreaData = {
    name: '',
    description: '',
    priority: 0,
    uuid: '',
    disabled: true,
  };
  const [areaData, setAreaData] = useState(initialAreaData);

  const initialModalData = {
    expanded: false,
    title: '',
    buttonTitle: '',
    mode: '',
  };
  const [modalData, setModalData] = useState(initialModalData);

  const searchWord = (searchWord, mainText) => mainText.toLowerCase()
    .includes(searchWord.toLowerCase());

  const onSearch = (input) => {
    setFilteredAreas(
      [...areas].filter((data) => searchWord(input, prop('name', data))),
    );
  };

  const handleChangeInput = (e) => {
    const { id, value } = e.target;

    switch (id) {
      case 'create_area_name':
        setAreaData((prev) => ({
          ...prev,
          name: value,
          disabled: !value || areas.some(
            (item) => item?.name === value,
          ),
        }));
        break;
      case 'create_area_description':
        setAreaData((prev) => ({ ...prev, description: value }));
        break;
      case 'create_area_priority':
        let priority = parseInt(value, 10);
        priority = isNaN(priority) ? 0 : priority;
        priority = priority < 0 ? 0 : priority;
        priority = priority > 1000 ? 1000 : priority;
        setAreaData((prev) => ({ ...prev, priority }));
        break;
      default:
        break;
    }
  };

  // Update area method
  const updateArea = () => {
    setAreaData((prev) => ({ ...prev, disabled: true }));
    const {
      name, description, priority, uuid,
    } = areaData;

    if (!name) {
      return;
    }

    const data = {
      entity_type: 'area',
      entity_uuid: uuid,
      params: {
        name,
        description,
        priority,
      },
    };

    const constants = [
      ProjectTypeCommonConstants.UPDATE_AREA_REQUEST,
      ProjectTypeCommonConstants.UPDATE_AREA_SUCCESS,
      ProjectTypeCommonConstants.UPDATE_AREA_FAILURE,
    ];

    const options = {
      onSuccess: () => {
        setAreaData(initialAreaData);
        setModalData(initialAreaData);
        dispatch(getAreasRequest(areaPartitionUUID));
      },
    };

    dispatch(entityUpdate({
      data,
      constants,
      options,
    }));
  };

  // Create area method
  const createArea = () => {
    setAreaData((prev) => ({ ...prev, disabled: true }));
    const { name, description, priority } = areaData;

    if (!name) {
      return;
    }

    const data = {
      entity_type: 'area',
      parent: areaPartitionUUID,
      params: {
        name,
        description,
        priority,
      },
    };

    const constants = [
      ProjectTypeCommonConstants.NEW_AREA_REQUEST,
      ProjectTypeCommonConstants.NEW_AREA_SUCCESS,
      ProjectTypeCommonConstants.NEW_AREA_FAILURE,
    ];

    const options = {
      onSuccess: () => {
        setAreaData(initialAreaData);
        dispatch(getAreasRequest(areaPartitionUUID));
      },
    };

    dispatch(entityCreate({
      data,
      constants,
      options,
    }));
  };

  // Switch modal type edit or create tag
  const switchModal = (mode) => {
    let title;
    let buttonTitle;

    switch (mode) {
      case 'create':
        title = capitalize(
          t('wms.modals.headers.create_tag', 'create tag'),
        );
        buttonTitle = capitalize(
          t('wms.buttons.create.area', 'create tag'),
        );
        setAreaData(initialAreaData);
        setModalData({
          expanded: true,
          title,
          buttonTitle,
          mode,
        });
        break;
      case 'edit':
        title = capitalize(
          t('wms.modals.headers.edit_tag', 'edit tag'),
        );
        buttonTitle = capitalize(
          t('wms.buttons.save_tag', 'save tag'),
        );
        setModalData({
          expanded: true,
          title,
          buttonTitle,
          mode,
        });
        break;
      default:
        setModalData(initialModalData);
        break;
    }
  };

  const deleteAreaCallback = (item, uuid) => {
    if (uuid === areaData?.uuid) {
      setAreaData(initialAreaData);
    }
    dispatch(getAreasRequest(areaPartitionUUID));
  };

  // Area list item
  const areaItem = (name, description, priority, uuid) => (
    <div key={uuid}>
      <Divider className="m-0" />
      <div className="p-2">
        <div className="flex items-center">
          <h6 className="m-0 whitespace-pre-wrap break-all">{name}</h6>
          <div className="flex ml-auto">
            <BaseButton
              type="link"
              className="px-1"
              onClick={() => {
                switchModal('edit');
                setAreaData((prev) => ({
                  ...prev,
                  name,
                  description,
                  priority,
                  uuid,
                  disabled: false,
                }));
                // switchModal('edit');
              }}
            >
              <Icon path={mdiSquareEditOutline} size={1} />
            </BaseButton>

            <HardDeleteEntityContainer
              name="area"
              entityUUID={uuid}
              entityType="area"
              type="entity"
              checkHardDelete={false}
              actionForEntity={deleteAreaCallback}
              typeBtn="link"
              className="px-1 customAntOutDangerLink"
              component={<Icon path={mdiTrashCanOutline} size={1} />}
            />
          </div>
        </div>
        <p className="text-secondary m-0">{description}</p>
      </div>
    </div>
  );

  const titleModal = () => (
    <Row className="flex">
      <Col
        span={modalData.expanded ? 12 : 24}
        className="flex items-center pr-3"
      >
        {capitalize(
          'tags managment',
        )}
      </Col>
      {modalData.expanded && (
        <Col
          span={modalData.expanded ? 12 : 24}
          className="flex items-center  pl-3"
        >
          <BaseButton
            onClick={() => switchModal(false)}
            type="link"
            className="flex items-center p-0"
          >
            <Icon path={mdiArrowLeft} size={0.8} className="mr-2" />
          </BaseButton>
          {modalData.title}
        </Col>
      )}
    </Row>
  );

  useEffect(() => {
    setFilteredAreas([...areas]);
  }, [areas]);

  useEffect(() => {
    dispatch(getAreasRequest(areaPartitionUUID));
  }, []);

  return (
    <Row>
      <BtnModal
        title={titleModal()}
        width={modalData.expanded ? '1200px' : '600px'}
        footer={null}
        afterClose={() => {
          switchModal();
          setFilteredAreas([...areas]);
        }}
        style={{ transition: 'width 0.1s ease-in-out', minHeight: 405 }}
        btnComponent={(
          <Button
            id="editTagsButton"
            className="btnPrimary-outline mr-1 mb-1"
            size="small"
          >
            Edit tags
          </Button>
          // <Tooltip placement="top" title="Areas management">
          //   <Icon
          //     path={mdiCogOutline}
          //     size={0.75}
          //     className={`${classNameButton}`}
          //   />
          // </Tooltip>
        )}
      >
        <Row className="flex">
          <Col
            span={modalData.expanded ? 12 : 24}
            className={modalData.expanded ? 'pr-3' : null}
          >
            <Col className="flex pb-3">
              <AntDesignSearchBox54origins
                placeholder={capitalize(
                  t('wms.placeholders.search_area', 'enter tag name'),
                )}
                onSearch={onSearch}
              />
              {/* <BaseButton */}
              {/*  className="btnPrimary-outline ml-auto" */}
              {/*  onClick={() => switchModal('create')} */}
              {/* > */}
              {/*  <Icon path={mdiPlus} size={0.9} className="mr-1" /> */}
              {/*  {capitalize(t('wms.buttons.create.area', 'create area'))} */}
              {/* </BaseButton> */}
            </Col>
            <Col>
              {filteredAreas.map(({
                name, description, priority = 0, uuid,
              }) => areaItem(
                name,
                description,
                priority,
                uuid,
              ))}
            </Col>
          </Col>

          {modalData.expanded && (
            <Col span={12} className={modalData.expanded && 'pl-3'}>
              <div className="mb-3">
                {capitalize(t('wms.labels.title', 'name'))}
                <span style={{ color: 'red' }}>*</span>
                <Input
                  id="create_area_name"
                  value={areaData.name}
                  showCount
                  maxLength={25}
                  onChange={handleChangeInput}
                  placeholder={capitalize(
                    'enter tag name',
                  )}
                />
              </div>
              <div className="mb-3">
                {capitalize(t('wms.labels.description', 'description'))}
                :
                <TextArea
                  id="create_area_description"
                  value={areaData.description}
                  onChange={handleChangeInput}
                  placeholder={capitalize(
                    t('wms.placeholders.description', 'enter description'),
                  )}
                />
              </div>
              <div className="flex">
                <div className="flex ml-auto">
                  <BaseButton
                    onClick={switchModal}
                    className="btnSecondary-outline"
                  >
                    {capitalize(t('wms.verb.cancel', 'cancel'))}
                  </BaseButton>
                  <BaseButton
                    className="btnPrimary ml-2"
                    onClick={
                      modalData.mode === 'create' ? createArea : updateArea
                    }
                    disabled={areaData.disabled}
                  >
                    {modalData.buttonTitle}
                  </BaseButton>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </BtnModal>
      <Button
        id="addTagButton"
        className="btnPrimary-outline mr-1 mb-1"
        size="small"
        onClick={() => setCreateAreaOpen(true)}
      >
        Add tag
      </Button>
      <Modal
        title="Create new tag"
        width="600px"
        open={createAreaOpen}
        footer={null}
        destroyOnClose
        onCancel={() => setCreateAreaOpen(false)}
        afterClose={() => {
          switchModal();
          setFilteredAreas([...areas]);
        }}
        style={{ transition: 'width 0.1s ease-in-out', minHeight: 405 }}
      >
        <NewArea
          t={t}
          closeModal={() => setCreateAreaOpen(false)}
          createArea={createArea}
          onFormChange={handleChangeInput}
          switchModal={switchModal}
          areaData={areaData}
        />
      </Modal>
    </Row>
  );
}

AreasManagement.propTypes = {
  areas: PropTypes.array,
};

export default AreasManagement;
