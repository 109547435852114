import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Col, Collapse, DatePicker, Flex, Form, Input, Radio, Row,
  Typography,
} from 'antd';
import { isEmpty, capitalize } from 'lodash';
import dayjs from 'dayjs';

import GeneratePassBtn from '../GeneratePassBtn';
import AddActorsForNewActor from '../AddActorsForNewActor';
import ReportsSwitchers from '../ReportsSwitchers';

import useLocalTranslateForComponent from '../../../54origins/hooks/useLocalTranslateForComponent';
import AssigningUsersRoot from '../../../assignUsersModal/AssigningUsersRoot';
import EditButton from '../../../projectFlow/components/commonComponents/EditButton';
import { useSelector } from 'react-redux';
import { getDefaultGroup, getDefaultGroupUUID } from '../../../actors/slices/defaultGroupsSlice';
import ListOfActorsTags from '../../../entity/components/ListOfActorsTags';

const translateForUserInfoForm = {
  birthday: ['birthday', 'день рождения'],
  email: ['e-mail', 'эл-почта'],
  firstName: ['first name', 'имя'],
  lastName: ['last name', 'фамилия'],
  login: ['login', 'логин'],
  missing: ['missing', 'отсутствует'],
  password: ['password', 'пароль'],
  passwordConfirm: ['password confirmation', 'подтверждение пароля'],
  phone: ['phone', 'телефон'],
};

function UserInfoFormItems(props) {
  const {
    actorData,
    hideElements = [],
    actorForm,
    actorType,
    actorUUID,
    creating,
    editMode,
    isProfile,
    getSelectedActorsForNewActors,
    getSwitchValueCallback,
    showDetailedReport,
    showBaseReport,
    showLunchBreak,
    onChangeIsChecked,
    rules = {},
  } = props;

  const { t } = useTranslation();

  const defaultGroupUUID = useSelector(getDefaultGroupUUID);
  const defaultGroup = useSelector(getDefaultGroup);

  const { checkLangAndGetTranslate } = useLocalTranslateForComponent(translateForUserInfoForm);

  const { setFieldsValue, getFieldsValue } = actorForm;
  const {
    typeOfUser = actorType,
    login,
    phone_number,
    email,
    newKeyPair = false,
  } = getFieldsValue(['typeOfUser', 'newKeyPair', 'phone_number', 'login', 'email']);

  const [, changeLocalState] = useState();

  const initLocalActors = actorType === 'user' || actorType === 'classic_user' ? [defaultGroupUUID] : [];
  const initLocalActorsData = actorType === 'user' || actorType === 'classic_user' ? [defaultGroup] : [];

  const [localActors, setLocalActors] = useState(initLocalActors);
  const [localActorsData, setLocalActorsData] = useState(initLocalActorsData);

  const [isEditGroups, setEditGroups] = useState(false)

  const checkOneOfRequiredInput = () => {
    if (isEmpty(login) && isEmpty(phone_number) && isEmpty(email)) {
      return true;
    }
    return !(isEmpty(login) || isEmpty(phone_number) || isEmpty(email));
  };

  const customRules = {
    email: [{ type: 'email' }, {
      required: checkOneOfRequiredInput(),
      message: 'Please input your email ',
    }],
    login: [
      {
        pattern: /^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/,
        message: 'Login length must be from 3 to 36 and it must contain alphanumeric values, dots or underscores. Only latin letters',
      },
      { min: 3 }, { max: 36 }, { required: checkOneOfRequiredInput() }],
    phone_number: [
      {
        pattern: /[\\+][0-9]*\d/,
        message: 'Phone number is not valid. Number must start with +',
      },
      { required: checkOneOfRequiredInput() },
    ],
  };

  const generatePass = (newPass) => {
    actorForm.setFieldsValue({
      password: newPass,
      password_confirmation: newPass,
    });
  };

  const switchValueCallback = (value) => {
    changeLocalState({ rerenderComponent: value });

    setFieldsValue({ newKeyPair: value });
  };

  const changeTypeOfUserCallback = (value) => {
    setFieldsValue({
      typeOfUser: value,
    });

    changeLocalState({ rerenderComponent: value });
  };

  const changeInput = () => {
    changeLocalState({ rerenderComponent: true });
  };

  const getSelectedActors = (actors) => {
    // console.log('actors', actors)

    setFieldsValue({
      newActorGroups: actors.map((item) => item.uuid),
    });
  };

  const getRemovedUserCallback = (actorsUUIDs) => {
    setFieldsValue({
      removedActorGroups: actorsUUIDs,
    });
  };

  const disabledDate = (current) => current > dayjs() || current < dayjs().subtract(80, 'year');

  const userIsClassic = actorType === 'classic_user' || typeOfUser === 'classic_user';

  const onChangeUsers = (data) => {
    setLocalActors(data.allUuids);
    setLocalActorsData(data?.allUsers?.map(el => ({
      actor_type: 'group',
      uuid: el?.value,
      uinfo: {
        group_name: el?.label,
      }
    })));
    getSelectedActorsForNewActors(data.allUuids);
  }

  return (
    <div>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            label={checkLangAndGetTranslate('firstName', true)}
            name="first_name"
            rules={rules.first_name}
          >
            <Input
              placeholder={checkLangAndGetTranslate('firstName', true)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={checkLangAndGetTranslate('lastName', true)}
            name="last_name"
            rules={rules.last_name}
          >
            <Input
              placeholder={checkLangAndGetTranslate('lastName', true)}
            />
          </Form.Item>
        </Col>
      </Row>
      {userIsClassic && (
        <Form.Item
          label={checkLangAndGetTranslate('login', true)}
          name="login"
          rules={customRules.login}
        >
          <Input
            onChange={changeInput}
            autoComplete="new-password"
            placeholder={checkLangAndGetTranslate('login', true)}
          />
        </Form.Item>
      )}
      {userIsClassic && (
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              label={creating
                ? `${capitalize(t('auth.headers.password', 'password'))} (generated by default)`
                : capitalize(t('auth.headers.new_password', 'new password'))}
              name="password"
              rules={rules.password}
            >
              <Input.Password
                placeholder={checkLangAndGetTranslate('password')}
                autoComplete="new-password"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            {!hideElements.includes('passwordConfirmation')
              && (
                <Form.Item
                  label={checkLangAndGetTranslate('passwordConfirm', true)}
                  name="password_confirmation"
                  dependencies={['password']}
                  rules={rules.password_confirmation}
                >
                  <Input.Password
                    placeholder={checkLangAndGetTranslate('passwordConfirm', true)}
                  />
                </Form.Item>
              )}
          </Col>
        </Row>
      )}
      <Row>
        <Col className="flex">
          {/* <AddActorsForNewActor
            onSaveCallBack={getSelectedActorsForNewActors}
          /> */}

          <div className="ml-auto">
            <Flex>
              <Typography
                onClick={() => setEditGroups(true)}
                style={{ color: '#6b6b6b' }}
                className="mr-2"
              >
                Groups:
              </Typography>
              <EditButton
                onClick={() => setEditGroups(true)}
              />
            </Flex>
            <AssigningUsersRoot
              header="Groups"
              isOpen={isEditGroups}
              setIsOpen={setEditGroups}
              isGroupMode={true}
              onUpdateUsers={onChangeUsers}
              globalOptions={{
                uuids: localActors ?? [],
              }}
              isModal
              tabs={{
                allConfig: {
                  label: 'All groups',
                  value: 'all',
                  textSelectButton: 'Add group',
                  showSections: ['button-add-user'],
                },
              }}
              usersConfig={{
                label: 'Groups',
                showSections: ['button-remove-user'],
                textDeleteButton: 'Delete group',
                defaultItems: localActors ?? [],
              }}
            />
            <ListOfActorsTags
              actors={localActorsData}
            />
          </div>
        </Col>
        <Col className="ml-auto">
          <GeneratePassBtn onClickCallBack={generatePass} />
        </Col>
      </Row>
      <Collapse className="mt-2" ghost>
        <Collapse.Panel header="Advanced settings:" key="1">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                // label={checkLangAndGetTranslate('birthday', true)}
                label="Birthday"
                name="birthday"
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  className="w-full"
                  placeholder="birthday"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <InternalOrExternalSwitcher
                getSwitchValueCallback={getSwitchValueCallback}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={checkLangAndGetTranslate('email', true)}
                name="email"
                rules={customRules.email}
              >
                <Input
                  placeholder={checkLangAndGetTranslate('email', true)}
                  onChange={changeInput}
                />
              </Form.Item>
            </Col>
            {userIsClassic && !hideElements.includes('phone') && (
              <Col span={12}>
                <Form.Item
                  label={checkLangAndGetTranslate('phone', true)}
                  name="phone_number"
                  rules={customRules.phone_number}
                >
                  <Input
                    placeholder={checkLangAndGetTranslate('phone', true)}
                    onChange={changeInput}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col className="flex">
              <ReportsSwitchers
                showDetailedReport={showDetailedReport}
                showBaseReport={showBaseReport}
                showLunchBreak={showLunchBreak}
                onChangeShowReportsConfirmation={onChangeIsChecked}
              />
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default UserInfoFormItems;

UserInfoFormItems.propTypes = {
  actorForm: PropTypes.object.isRequired,
  actorType: PropTypes.string.isRequired,
  creating: PropTypes.bool,
  editMode: PropTypes.bool,
  isProfile: PropTypes.bool,
  rules: PropTypes.object,

};

const optionsWithDisabled = [
  { label: 'internal', value: true },
  { label: 'external', value: false },
];

function InternalOrExternalSwitcher({
  getSwitchValueCallback,
  className,
}) {
  const [value, setValue] = useState(true);

  const onChange = ({ target: { value } }) => {
    setValue(value);

    getSwitchValueCallback?.(value);
  };

  return (
    <Form.Item
      label="Actor:"
      name="internal"
      className={`flex ${className}`}
    >
      <Radio.Group
        size="small"
        options={optionsWithDisabled}
        onChange={onChange}
        value={value}
        optionType="button"
        buttonStyle="solid"
      />
    </Form.Item>
  );
}
