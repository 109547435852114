import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import moment from "moment";
import dayjs from "dayjs";
import { isEmpty } from "ramda";
import { capitalize } from "lodash";

import { Col, Row, Spin, Input, List, Button, Result, Divider } from "antd";

import BaseModal from "../../../../components/_ui/BaseModal/BaseModal";
// import BaseButton from "../../../../components/_ui/BaseButton/BaseButton";
import DocTypeLabel from "../DocTypeLabel";
// import CKEditor from "../CKEditor5/CKEditor";
import HistoryListItem from "./HistoryListItem";

// import { getLocale } from "../../../../locale/selectors";
// import { getUserName } from "../../../../entity/selectors/selectors";
import {
  getAllDocumentStatesRequest,
  // updateDocumentStateRequest,
  // updateDocumentPartiallyRequest,
} from "../../../actions/DocumentsActions";
// import { ProjectTypeCommonConstants } from "../../../constants/Constants";

// import {
//   createNewStateID,
//   getStateID,
//   getStateNumber,
//   getPatchNumber,
// } from "../helpers/statesAndPatches/idHelpers";
// import buildContent from "../helpers/statesAndPatches/buildContent";
import { useQuill } from "../QuillEditor/useQuill/useQuill";


import "./DocHistoryComponent.scss";
import QuillEditor from "../QuillEditor/QuillEditor";

const RESTORE_VERSION = {
  SUCCESS: "RESTORE_VERSION_SUCCESS",
  FAIL: "RESTORE_VERSION_FAIL",
  NONE: "RESTORE_VERSION_NONE",
};

function DocHistoryComponent({
  currentActualState,
  defaultPartition,
  docUUID,
  entityType,
  // isProjectDescriptionDocument,
  title,
  reloadDocument,
  reloadDocumentCallback,
  setIsShowHistoryModal,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const lang = useSelector(getLocale);
  // const userName = useSelector(getUserName);

  const titleRef = useRef(null);

  const [selectedState, setSelectedState] = useState(currentActualState || "");

  const [isLoadingStates, setIsLoadingStates] = useState(false);
  const [statesData, setStatesData] = useState([]);
  const [statesList, setStatesList] = useState([]);

  const [showEditor, setShowEditor] = useState(false);
  const [currentDocumentContent, setCurrentDocumentContent] = useState(null);

  const [isRestoreVersion, setIsRestoreVersion] = useState(false);
  const [restoreResult, setRestoreResult] = useState(RESTORE_VERSION.NONE);
  const [showRestoreResult, setShowRestoreResult] = useState(false);

  const [showTitleError, setShowTitleError] = useState(false);

  const isDocumentOrIdea =
    entityType === "projectDocument" || entityType === "projectIdea";
  // const isSpreadsheet = entityType === "projectSpreadsheet";

  // const isStateLabel = !getPatchNumber(selectedState) ? ".0" : "";
  // const disableRestore = selectedState === currentActualState;

  const documentTypeText = getDocumentTypeText(entityType);

  const { parseCrdtDocumentStructure } = useQuill();

  function getDocumentTypeText(type) {
    switch (type) {
      case "projectIdea": {
        return "note";
      }
      case "projectSpreadsheet": {
        return "sheet";
      }
      case "projectDocument":
      default: {
        return "document";
      }
    }
  }

  const loadingDocumentStates = async () => {
    setIsLoadingStates(true);

    const config = {
      uuid: docUUID,
      partition: defaultPartition,
    };

    const { states } = await dispatch(getAllDocumentStatesRequest(config));
    if (Array.isArray(states)) {
  // console.log("crdt STATES:", states);
      const finalStates = states.map((item, idx, arr) => {
        const { text, from_state_id } = item || {};
        const finalText = from_state_id ? arr?.[from_state_id]?.text : text;
        return { ...item, text: finalText, baseIdx: idx };
      });
      // console.log('crdt finalStates', finalStates)
      setStatesData(finalStates);
    }
  
    setIsLoadingStates(false);
  };

  const prepareListData = async (data) => {
    const parsedData = Array.isArray(data)
      ? data
        .map((item, idx, arr) => {
          const { timestamp, text, from_state_id } = item || {};
          // const text = parseCrdtByteString(item?.text);
          const finalText = from_state_id ? arr[from_state_id] : text;
          const finalTimestamp = new Date(timestamp * 1000);
          return { version_id: `${idx + 1}`, timestamp: finalTimestamp, text: finalText };
        })
        .reverse()
      : [];
    setStatesList(parsedData);
  };

  const selectVersionHandler = (e) => {
    const selectedVersion = e?.currentTarget?.getAttribute("data-version");
    // console.log('crdt selectedVersion', selectedVersion)
    // console.log('crdt statesData', statesData)
    setSelectedState(selectedVersion);
  };

  function excludeRanges(ranges, array) {
    return array.filter((item, idx) => {
      return !ranges.some(([start, end]) => idx >= start && idx <= end);
    });
  }

  const buildAndShowDocumentContent = async() => {
    setShowEditor(false);
    // console.log('crdt statesData?.length', statesData?.length)
    if (statesData?.length) {
      // console.log('crdt buildAndShowDocumentContent states', statesData);
      // states [0,.. 5]
      // selectedState = 6

      const indexDB = Number(selectedState) - 1;
      const currentStateData = statesData?.[indexDB];
      // console.log('crdt indexDB', indexDB)
      const { excluded_versions = [] } = currentStateData || {};
      const currentDeltasArr = statesData.slice(0, Number(selectedState));
      // console.log('crdt currentDeltasArr', currentDeltasArr)
      const deltasForMerge = excludeRanges(excluded_versions, currentDeltasArr);

      // console.log('crdt deltasForMerge', deltasForMerge)

      const stateVector = parseCrdtDocumentStructure(deltasForMerge);
      // console.log('crdt stateVector', stateVector)

      setCurrentDocumentContent(stateVector);
    } else {
      setCurrentDocumentContent(null);
    }

    setTimeout(() => {
      setShowEditor(true);
    }, 350);
  };

  // const createDocumentState = async ({ stateID, from_state_id, excluded_versions }) => {

  //   const stateConfig = {
  //     uuid: docUUID,
  //     stateID,
  //     stateData: {
  //       createdBy: userName,
  //       timestamp: (new Date().getTime()) / 1000,
  //       from_state_id,
  //       excluded_versions
  //       // text: currentDocumentContent,
  //     },
  //     partition: defaultPartition,
  //   };
  //   // console.log('crdt createDocumentState', stateConfig);

  //   if (stateConfig.stateID && stateConfig.stateID !== "0") {
  //     return dispatch(updateDocumentStateRequest(stateConfig));
  //   }

  //   return Promise.reject(new Error("No state ID"));
  // };

  // const updateActualStateAndTitle = async (newActualState, newTitle) => {
  //   // console.log('Actual state: ', newActualState);
  //   // console.log('New title: ', newTitle);
  //   // console.log('Title ref: ', titleRef.current);

  //   const constants = [
  //     ProjectTypeCommonConstants.UPDATE_DOCUMENT_ACTUAL_STATE_REQUEST,
  //     ProjectTypeCommonConstants.UPDATE_DOCUMENT_ACTUAL_STATE_SUCCESS,
  //     ProjectTypeCommonConstants.UPDATE_DOCUMENT_ACTUAL_STATE_FAILURE,
  //   ];

  //   const config = {
  //     entity_type: entityType,
  //     entity_uuid: docUUID,
  //     params: {
  //       title: newTitle,
  //       actualState: newActualState,
  //     },
  //   };

  //   // console.log('crdt config', config)

  //   return dispatch(
  //     updateDocumentPartiallyRequest(config, defaultPartition, constants)
  //   );
  // };

  // const restoreDocumentVersion = async () => {

  //   // console.log("crdt statesList", statesList);
  //   const newActualState = (Number(currentActualState) + 1).toString();
  //   // console.log("crdt newActualState", newActualState);
  //   // console.log("crdt selected state data",statesData[Number(selectedState)]) 
  //   const selectedStateDatabaseId = Number(selectedState) - 1;
  //   const selectedStateData = statesData[Number(selectedState)] || {};
  //   const { excluded_versions } = selectedStateData;
  //   const currentExcludedVersionsStart = Number(selectedStateDatabaseId) + 1;
  //   const currentExcludedVersionsEnd = Number(currentActualState) - 1;
  //   const currentExcludedVersion = [currentExcludedVersionsStart, currentExcludedVersionsEnd];
  //   const newExcludedVersion = Array.isArray(excluded_versions) ? [...excluded_versions, currentExcludedVersion] : [currentExcludedVersion];
  //   // console.log('crdt newExcludedVersion', newExcludedVersion);
  //   const data = { stateID: newActualState, excluded_versions: newExcludedVersion, from_state_id: Number(selectedState) - 1 };


  //   try {
  //     await createDocumentState(data);
  //     await updateActualStateAndTitle(
  //       newActualState,
  //       titleRef.current.input.value
  //     );

  //     // setRestoreResult(RESTORE_VERSION.SUCCESS);
  //   } catch (error) {
  //     console.log("Error: ", error.message);
  //     setRestoreResult(RESTORE_VERSION.FAIL);
  //   } finally {
  //     // setIsRestoreVersion(false);
  //     // setShowRestoreResult(true);
  //   }
  // };

  const returnToDocument = () => {
    setIsShowHistoryModal(false);
  };

  const returnToDocumentWithReload = async () => {
    setIsShowHistoryModal(false);
    await reloadDocument();
    await reloadDocumentCallback?.();
  };

  const returnToHistory = () => {
    setRestoreResult(RESTORE_VERSION.NONE);
    setShowRestoreResult(false);
  };

  const initialFunction = async () => {
    await prepareListData(statesData);
    // buildAndShowDocumentContent();
  };

  useEffect(() => {
    loadingDocumentStates();
  }, []);

  useEffect(() => {
    if (!isEmpty(statesData)) {
      initialFunction();
    }
  }, [statesData]);

  useEffect(() => {
    if (!isLoadingStates && selectedState && statesData) {
      buildAndShowDocumentContent();
    }
  }, [selectedState, isLoadingStates, statesData]);


  return (
    <BaseModal
      title={capitalize("Document history")}
      width={showRestoreResult ? 600 : "80%"}
      centered
      open
      handleCancel={() => setIsShowHistoryModal(false)}
    >
      {!showRestoreResult && (
        <Spin spinning={isLoadingStates || isRestoreVersion} size="large">
          <Row
            id="history-doc-wrapper"
            style={{ columnGap: "24px" }}
            className="flex-nowrap"
          >
            <Col
              style={{ rowGap: "16px", width: 'calc(75% - 24px)' }}         
              className="flex flex-col p-0"
            >
              <Row gutter={[12, 12]}>
                <Col className="flex items-start justify-start flex-shrink-0">
                  <DocTypeLabel entityDocType={entityType} />
                </Col>
                <Col className="flex-auto">
                  <Input
                    ref={titleRef}
                    maxLength={100}
                    showCount
                    disabled={true}
                    name="title"
                    status={showTitleError && "error"}
                    placeholder={
                      showTitleError
                        ? capitalize(
                          t(
                            "wms.placeholders.title_error",
                            "Title can't be empty!"
                          )
                        )
                        : capitalize(t("wms.placeholders.title", "title"))
                    }
                    defaultValue={title}
                  // onChange={changeInput}
                  />
                </Col>
                {/* <Col className="flex-shrink-0">
                  <BaseButton
                    className="btnPrimary"
                    loading={isRestoreVersion}
                    disabled={
                      isLoadingStates ||
                      !showEditor ||
                      isRestoreVersion ||
                      disableRestore
                    }
                    // disabled={true}
                    onClick={restoreDocumentVersion}
                  >
                    {capitalize(
                      t("wms.buttons.restore_version", "restore this version (in dev)")
                    )}
                  </BaseButton>
                </Col> */}
              </Row>

              <Row className="flex-auto overflow-hidden">
                <Spin spinning={!showEditor} wrapperClassName="editor-spin">
                  <Col id="history-editor-wrapper">
                    {isDocumentOrIdea && showEditor && (
                      <QuillEditor
                        docUUID={selectedState}                
                        startHtmlString={""}
                        startStateVector={currentDocumentContent}
                        isOnlyVertical={true}
                        withoutConnection={true}
                        disabled={true}
                      />
                      // <CKEditor
                      //   // ckeditor={ckeditor}
                      //   id={docUUID}
                      //   entityDocType={entityType}
                      //   // getCKEditor={getCKEditor}
                      //   // getDescriptionForMerge={getDescriptionForMerge}
                      //   readOnly
                      //   defaultTextDescription={currentDocumentContent}
                      //   // changeDescriptionCallback={changeDescriptionCallback}
                      // />              
                    )}
                  </Col>
                </Spin>
              </Row>

              <Row>
                <Col>
                  <div>{`Version: ${selectedState}`}</div>
                </Col>
              </Row>
            </Col>
            <Col
              style={{ width: '25%' }}
              className="flex flex-shrink-0"
            >
              <List
                className="w-full grow versions-list"
                header={<h6 className="text-center">Version history:</h6>}
                dataSource={statesList}
                bordered
                renderItem={(item, idx) => {
                  const prevTimeStamp = Math.round(
                    statesList?.[idx - 1]?.timestamp || 0
                  );
                  const prevDate = dayjs(prevTimeStamp).format("DD/MM/YYYY");
                  const currentTimeStamp = Math.round(item?.timestamp);
                  // console.log("crdt currentTimeStamp", currentTimeStamp);
                  const currentDate =
                    dayjs(currentTimeStamp).format("DD/MM/YYYY");
                  const checkIsNewDate = prevDate !== currentDate;

                  return (
                    <Fragment key={`versions_list_${idx}_${item?.timestamp}`}>
                      {checkIsNewDate && (
                        <Divider
                          style={{
                            margin: "4px 0",
                            padding: "0 8px",
                            color: "rgba(0, 0, 0, 0.45)",
                            borderColor: "rgba(0, 0, 0, 0.45)",
                          }}
                        >
                          {currentDate}
                        </Divider>
                      )}
                      <HistoryListItem
                        item={item}
                        selectedState={selectedState}
                        currentActualState={currentActualState}
                        selectVersionHandler={selectVersionHandler}
                      />
                    </Fragment>
                  );
                }}
              />
            </Col>
          </Row>
        </Spin>
      )}

      {showRestoreResult && (
        <>
          {restoreResult === RESTORE_VERSION.SUCCESS && (
            <Result
              status="success"
              title="Success!"
              subTitle={`${capitalize(
                documentTypeText
              )} was successfully restored to version ${selectedState}`}
              extra={
                <Button
                  className="btnBlue"
                  onClick={returnToDocumentWithReload}
                >
                  Return to
                  {` ${documentTypeText}`}
                </Button>
              }
            />
          )}
          {restoreResult === RESTORE_VERSION.FAIL && (
            <Result
              status="error"
              title="Error!"
              subTitle={`Something went wrong while restoring the ${documentTypeText}`}
              extra={[
                <Button onClick={returnToHistory} key={'return_to_history'}>Return to history</Button>,
                <Button className="btnBlue" onClick={returnToDocument} key={`return_to_${documentTypeText}`}>
                  Return to
                  {` ${documentTypeText}`}
                </Button>,
              ]}
            />
          )}
        </>
      )}
    </BaseModal>
  );
}

DocHistoryComponent.propTypes = {
  currentActualState: PropTypes.string,
  defaultPartition: PropTypes.string,
  docUUID: PropTypes.string,
  entityType: PropTypes.string,
  isProjectDescriptionDocument: PropTypes.bool,
  title: PropTypes.string,
  reloadDocument: PropTypes.func,
  reloadDocumentCallback: PropTypes.func,
  setIsShowHistoryModal: PropTypes.func,
};

export default DocHistoryComponent;
