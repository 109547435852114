import React, {
  useRef,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func, string } from 'prop-types';
import cn from 'classnames';
import {
  Row,
  Col,
  Modal,
} from 'antd';
import dayjs from 'dayjs';

import IssueInfoRoot from '../issues/issueInfo/IssueInfoRoot';
import SuggestedPlan from './SuggestedPlan';

import useURLParams from '../../../hooks/useURLParams';
import {
  getIssueEntityFetching,
  getMe,
} from '../../selectors/selectors';
import { ProjectMainLayerContext } from '../../context/ProjectFlowListOfContexts';
import { antNotification } from '../../../MainUtils';
import { stopPropagation } from '../../../54origins/utils54origins';
import useUserBoard from '../../../myBoard/hooks/useUserBoard';
import useSuggestedIssues from '../../../myBoard/hooks/useSuggestedIssues';
import { computeIssueChunks, computePlan } from '../../../myBoard/utils';
import PlansForDay from './plansForDayComponents/PlansForDay';
import useMyBoardPlan from '../../../myBoard/hooks/useMyBoardPlan';
import { cloneDeep, concat, get, head, isEqual } from 'lodash';
import { entityUpdate } from '../../../entity/actions/entityActions';
import { updateUserBoard } from '../../../myBoard/actions/userBoardActions';
import { getUserBoardStorageUUID } from '../../../myBoard/selectors/userBoardStorageSelector';
import { getUserBoardUUID } from '../../../myBoard/selectors/userBoardSelector';

function MyBoardNew({
  partition,
  customActor,
  onChangePlanCallback,
}) {
  const dispatch = useDispatch();

  const abort = useRef();

  const issueFetching = useSelector(getIssueEntityFetching);
  const myActorUUID = useSelector(getMe);

  const userBoardStorageUUID = useSelector(getUserBoardStorageUUID);

  const userBoardUUID = useSelector(getUserBoardUUID);

  const actor = customActor || myActorUUID;

  const {
    isGlobalDisabled,
    getClearIssue,
  } = useContext(ProjectMainLayerContext);

  const {
    getURLParams,
    clearSearchParams,
    addAndRemoveSearchParams,
  } = useURLParams();

  const { activeIssue, activeProject } = getURLParams() || {};

  const {
    isFetchingUserBoard,
    onReloadUserBoard,
  } = useUserBoard({
    actor: actor,
    projectUUID: activeProject,
    partition: partition,
  });

  const {
    isFetchingSuggestedIssues,
    suggestedIssues,
    onUpdateSuggestedIssue,
    onReloadSuggestedIssues,
    onClearSuggestedIssues,
    suggestedOptions,
    paginationSettings,
  } = useSuggestedIssues({
    actor: actor,
    partition: partition,
    projectUUID: activeProject,
  });

  const {
    myBoardPlans,
    isFetchingMyBoardIssues,
    onReloadMyBoardIssues,
    onUpdateIssueInMyBoard,
    onClearMyBoardPlan,
  } = useMyBoardPlan({
    actor: actor,
    partition: partition,
    projectUUID: activeProject,
  });

  const today = dayjs().format('YYYY-MM-DD');
  const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');

  const onReloadAll = async () => {
    onClearMyBoardPlan();
    onClearSuggestedIssues();
    await onReloadUserBoard();
    onReloadMyBoardIssues();
    onReloadSuggestedIssues();
  }

  const requestUpdateIssue = (data, callback) => {
    const constants = [
      'UPDATE_ISSUE_FOR_MY_BOARD_REQUEST',
      'UPDATE_ISSUE_FOR_MY_BOARD_SUCCESS',
      'UPDATE_ISSUE_FOR_MY_BOARD_FAILURE',
    ];

    const options = {
      partition,
      onSuccess: callback,
      onFailure: () => {
        antNotification('error', 'Failed to update issue');
        onReloadAll();
      },
    };

    return entityUpdate({
      data,
      constants,
      options,
    });
  };

  const requestUpdateUserBoard = (newParams = {}, callback) => {
    if (userBoardUUID && userBoardStorageUUID && partition && actor) {
      dispatch(updateUserBoard({
        entity_uuid: userBoardUUID,
        actor: actor,
        entity_type: 'userBoard',
        params: {
          ...newParams,
        },
        parent: userBoardStorageUUID
      }, {
        partition,
        onSuccess: callback,
        onFailure: () => {
          antNotification('error', 'Failed to update your Plan board');
          onReloadAll();
        }
      }))
    }
  }

  const hiddenInIssueInfo = useMemo(() => {
    if (isGlobalDisabled) {
      return ['linkToProject', 'goButton', 'edit', 'deleteIssueBtn', 'cloneBtn', 'editBtn', 'bookmarkBtn', 'subscribeBtn'];
    }
    return ['linkToProject', 'goButton'];
  }, [isGlobalDisabled]);

  const disabledInIssueInfo = useMemo(() => (isGlobalDisabled ? ['fullEdit'] : []), [isGlobalDisabled]);

  const {
    issueSignal,
  } = abort?.currnet || {};


  const changeAbortingState = (data) => {
    abort.currnet = {
      ...abort?.currnet || {},
      ...data,
    };
  };

  const getNewSignalAndChangeAbortingState = (paramFromState) => {
    const newController = new AbortController();
    const { signal } = newController;

    changeAbortingState({ [paramFromState]: newController });
    return signal;
  };

  const changeMyBoardHistory = (key, uuid) => {
    switch (key) {
      case 'issue':
        addAndRemoveSearchParams({ activeIssue: uuid });
        break;
      case 'clearIssue':
        clearSearchParams(['activeIssue']);
        break;

      default:
        break;
    }
  };

  const onChangeIssue = async (uuid) => {
    if (!issueFetching) {
      if (issueSignal) {
        issueSignal.abort();
      }

      changeMyBoardHistory('issue', uuid);

      await getClearIssue(uuid, getNewSignalAndChangeAbortingState('issueSignal'))
        .catch((error) => {
          if (error.message) {
            antNotification('error', error.message);
          }
          if (error?.message !== 'canceled') {
            changeMyBoardHistory('clearIssue');
          }
        });
    }
  };

  const resetIssueCallback = () => {
    onReloadMyBoardIssues();
    onReloadSuggestedIssues();
    changeMyBoardHistory('clearIssue')
  };

  const onDeleteIssueCallback = async (data) => {
    const uuid = get(data, 'uuid', '');
    const prevTodayUUIDs = get(myBoardPlans, [`${today}__uuids`], []);
    const prevTodayData = get(myBoardPlans, [`${today}__data`], []);
    const newTodayUUIDs = prevTodayUUIDs.filter(el => el !== uuid);
    const newTodayData = prevTodayData.filter(el => newTodayUUIDs.includes(el.uuid));
    
    const prevUUIDs = get(myBoardPlans, [`${tomorrow}__uuids`], []);
    const prevData = get(myBoardPlans, [`${tomorrow}__data`], []);
    const newUUIDs = prevUUIDs.filter(el => el !== uuid);
    const newData = prevData.filter(el => newUUIDs.includes(el.uuid));
    
    await requestUpdateUserBoard({
      [today]: newTodayUUIDs,
      [`${today}__data`]: newTodayData,
      [tomorrow]: newUUIDs,
      [`${tomorrow}__data`]: newData

    }, (res) => {
      if (onChangePlanCallback) {
        onChangePlanCallback(head(res))
      }
      onReloadMyBoardIssues(head(res));
    })
    changeMyBoardHistory('clearIssue');
  }

  const onActionCallback = async (value, type, e, day) => {
    stopPropagation(e);
    switch (type) {
      case 'q-view': {
        if (value === activeIssue) {
          resetIssueCallback();
        } else {
          onChangeIssue(value);
        }
        break;
      }
      case 'issue-create-and-add': {
        const prevData = get(myBoardPlans, [`${day}__data`], []);
        const prevUUIDs = get(myBoardPlans, [`${day}__uuids`], []);
        const prevSettings = get(myBoardPlans, [`${day}__settings`], {});

        const uuid = get(value, 'uuid', '');
        const data = computeIssueChunks(value);

        requestUpdateUserBoard({
          [day]: [...prevUUIDs, uuid],
          [`${day}__settings`]: {
            ...prevSettings,
            last_key: prevSettings.last_key + 1,
          },
          [`${day}__data`]: [
            ...prevData,
            {
              ...data,
              uuid: uuid,
              key: prevSettings.last_key + 1,
            }
          ]
        }, async (res) => {
          if (onChangePlanCallback) {
            onChangePlanCallback(head(res));
          }
          onReloadMyBoardIssues(head(res));
          onReloadSuggestedIssues(head(res));
        });
        break;
      }
      case 'issue-add': {
        const prevData = get(myBoardPlans, [`${day}__data`], []);
        const prevUUIDs = get(myBoardPlans, [`${day}__uuids`], []);
        const prevSettings = get(myBoardPlans, [`${day}__settings`], {});

        const uuid = get(value, 'uuid', '');
        const data = get(value, 'data', {});

        requestUpdateUserBoard({
          [day]: [...prevUUIDs, uuid],
          [`${day}__settings`]: {
            ...prevSettings,
            last_key: prevSettings.last_key + 1,
          },
          [`${day}__data`]: [
            ...prevData,
            {
              ...data,
              uuid: uuid,
              key: prevSettings.last_key + 1,
            }
          ]
        }, async (res) => {
          if (onChangePlanCallback) {
            onChangePlanCallback(head(res));
          }
          onReloadMyBoardIssues(head(res));
          onReloadSuggestedIssues(head(res));
        });
        break;
      }
      case 'issue-remove': {
        const prevUUIDs = get(myBoardPlans, [`${day}__uuids`], []);
        const prevData = get(myBoardPlans, [`${day}__data`], []);

        const newUUIDs = prevUUIDs.filter(uuid => uuid !== value);
        const newData = prevData.filter(el => newUUIDs.includes(el.uuid));

        requestUpdateUserBoard({
          [day]: newUUIDs,
          [`${day}__data`]: newData
        }, (res) => {
          if (onChangePlanCallback) {
            onChangePlanCallback(head(res))
          }
          onReloadMyBoardIssues(head(res));
          onReloadSuggestedIssues(head(res));
        });
        break;
      }
      case 'issue-time-update': {
        const uuid = get(value, 'uuid', '');
        const newTime = get(value, 'value', '');

        const currentDayData = get(myBoardPlans, [`${day}__data`], []);

        const todayUUIDs = get(myBoardPlans, [`${today}__uuids`], []);
        const tomorrowUUIDs = get(myBoardPlans, [`${tomorrow}__uuids`], []);
        const todayData = get(myBoardPlans, [`${today}__data`], []);
        const tomorrowData = get(myBoardPlans, [`${tomorrow}__data`], []);

        const newCurrentDayData = currentDayData.map(el => {
          if (el.uuid === uuid) {
            return {
              ...el,
              chunk: newTime,
            }
          }
          return el
        });


        onUpdateIssueInMyBoard({
          day,
          data: newCurrentDayData,
        });

        requestUpdateUserBoard({ [`${day}__data`]: newCurrentDayData }, (res) => {
          if (!isEqual(get(res, ['0', 'params', `${day}__data`], []), newCurrentDayData)) {
            onReloadMyBoardIssues(head(res));
            onReloadSuggestedIssues(head(res));
          }
        });

        if (todayUUIDs.includes(uuid) || tomorrowUUIDs.includes(uuid)) {
          const todayIssuePart = todayData.find(el => el.uuid === uuid);
          const tomorrowIssuePart = tomorrowData.find(el => el.uuid === uuid);

          const todayChunk = day === today ? newTime : get(todayIssuePart, 'chunk', 0);
          const tomorrowChunk = day === tomorrow ? newTime : get(tomorrowIssuePart, 'chunk', 0);

          const issueBoardData = get(value, 'issue.boardData', {});

          dispatch(requestUpdateIssue({
            entity_uuid: uuid,
            parent: activeProject,
            params: {
              boardData: {
                ...issueBoardData,
                chunk: todayChunk + tomorrowChunk,
              }
            }
          }));
        }
        break;
      }
      case 'issue-users-update': {
        const uuid = get(value, ['uuid'], '');
        const users = get(value, ['users'], []);

        dispatch(requestUpdateIssue({
          entity_uuid: uuid,
          parent: activeProject,
          params: {
            users: users.map(el => ({ uuid: el })),
            usersSearch: users,
          }
        }, () => {
          onReloadMyBoardIssues()
        }));
        break;
      }
      case 'issue-complete': {
        const uuid = get(value, ['issue', 'uuid'], '');
        const newStatus = get(value, ['newStatus'], '');
        const completed = get(value, ['issue', 'completed'], []);
        const needComplete = get(value, ['complete'], false);

        const newCompleted = needComplete ? [...completed, actor] : completed

        dispatch(requestUpdateIssue({
          entity_uuid: uuid,
          parent: activeProject,
          params: {
            status: newStatus,
            completed: newCompleted
          }
        }, () => {
          onReloadMyBoardIssues()
        }));
        break;
      }
      case 'issue-reopen': {
        const uuid = get(value, ['uuid'], '');
        const completed = get(value, ['completed'], []);

        dispatch(requestUpdateIssue({
          entity_uuid: uuid,
          parent: activeProject,
          params: {
            completed: completed.filter(el => el !== actor)
          }
        }, () => {
          onReloadMyBoardIssues()
        }));
        break;
      }
      case 'issue-update-total-time': {
        const uuid = get(value, 'uuid', '');
        const prevboardData = get(value, 'issue.boardData', {})
        const newBoardData = {
          ...prevboardData,
          chunk: get(value, 'value', ''),
        }

        onUpdateSuggestedIssue({
          uuid,
          boardData: newBoardData
        });
        dispatch(requestUpdateIssue({
          entity_uuid: uuid,
          parent: activeProject,
          params: {
            boardData: newBoardData
          }
        }));
        break;
      }
      case 'day-remove-all': {
        requestUpdateUserBoard({
          [value]: [],
          [`${value}__data`]: [],
          [`${value}__settings`]: {},
        }, (res) => {
          if (onChangePlanCallback) {
            onChangePlanCallback(head(res))
          }
          onReloadMyBoardIssues(head(res));
          onReloadSuggestedIssues(head(res));
        });
        break;
      }
      case 'day-move-overtime-issues': {
        const nextDay = dayjs(day).add(1, 'day').format('YYYY-MM-DD');
        let newDayData = cloneDeep(get(myBoardPlans, [`${day}__data`], []));
        let newDayUUIDs = cloneDeep(get(myBoardPlans, [`${day}__uuids`], []));
        let newNextDayData = cloneDeep(get(myBoardPlans, [`${nextDay}__data`], []));
        let newNextDayUUIDs = cloneDeep(get(myBoardPlans, [`${nextDay}__uuids`], []));
        let newNextDaySettings = get(myBoardPlans, [`${nextDay}__settings`], {});
        let last_key = get(newNextDaySettings, 'last_key', 0);
        let newOvertime = get(value, 'overtime', 0) * 2;

        let prevKey = 0;

        while (newOvertime > 0) {
          const issue = newDayData?.[newDayData?.length - 1];
          if (newOvertime >= issue?.chunk) {
            prevKey--;
            newDayData.pop();
            newDayUUIDs = newDayUUIDs?.filter(el => el !== issue?.uuid)
            newNextDayUUIDs.push(issue?.uuid)
            newNextDayData.push({ ...issue, key: prevKey });
            newOvertime -= issue.chunk;
          } else {
            prevKey--;
            issue.chunk = issue?.chunk - newOvertime;
            newNextDayData.push({ ...issue, chunk: newOvertime, key: prevKey });
            newNextDayUUIDs.push(issue?.uuid)
            newOvertime = 0;
          }
        }

        newNextDayData?.sort((a, b) => get(a, ['key'], 0) - get(b, ['key'], 0))
        last_key = newNextDayData?.length;

        requestUpdateUserBoard({
          [day]: newDayUUIDs,
          [nextDay]: newNextDayUUIDs,
          [`${nextDay}__settings`]: {
            ...newNextDaySettings,
            last_key: last_key,
          },
          [`${day}__data`]: newDayData,
          [`${nextDay}__data`]: newNextDayData?.map((el, index) => ({ ...el, key: index })),
        }, (res) => {
          if (onChangePlanCallback) {
            onChangePlanCallback(head(res))
          }
          onReloadMyBoardIssues(head(res));
          onReloadSuggestedIssues(head(res))
        });
        break;
      }
      case 'day-available-time-update': {
        const prevDaySettings = get(myBoardPlans, [`${day}__settings`], {});

        onUpdateIssueInMyBoard({
          day,
          settings: {
            ...prevDaySettings,
            wasChangedTime: true,
            hours: value,
          }
        });
        requestUpdateUserBoard({
          [`${day}__settings`]: {
            ...prevDaySettings,
            wasChangedTime: true,
            hours: value,
          }
        });
        break;
      }
      case 'day-change-order-of-issues': {
        const { prevKey, newKey } = value;
        const prevData = get(myBoardPlans, [`${day}__data`], []);

        const newData = prevData.map(issue => {
          const key = get(issue, ['key'], 0);
          if (prevKey === key) {
            return { ...issue, key: newKey }
          }
          if (newKey === key) {
            return { ...issue, key: prevKey }
          }
          return issue;
        });

        onUpdateIssueInMyBoard({
          day,
          data: newData,
        });

        requestUpdateUserBoard({
          [`${day}__data`]: newData,
        });

        break;
      }
      default: return;
    }
  };

  const onComputePlan = async (day) => {
    const daySettings = get(myBoardPlans, [`${day}__settings`], {});
    const hours = get(daySettings, ['hours'], 8);
    const dayData = get(myBoardPlans, [`${day}`], [])
      .map(el => ({
        ...el,
        ...el.params,
      }))
      .filter(el => !el?.done);

    const uncompletedIssue = suggestedIssues?.filter(el => !el?.done)


    const { data, uuids } = computePlan(concat(uncompletedIssue, dayData), hours * 2);

    requestUpdateUserBoard({
      [day]: uuids,
      [`${day}__data`]: data,
      [`${day}__settings`]: {
        ...daySettings,
        last_key: data.length - 1,
      },
    }, (res) => {
      if (onChangePlanCallback) {
        onChangePlanCallback(head(res))
      }
      onReloadMyBoardIssues(head(res));
      onReloadSuggestedIssues(head(res));
    })
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        {[today, tomorrow].map(day => {
          const dayPlan = get(myBoardPlans, [day], []);
          const dayParams = get(myBoardPlans, [`${day}__params`], {});
          return (<PlansForDay
            key={day}
            day={day}
            issues={dayPlan}
            dayParams={dayParams}
            partition={partition}
            loading={isFetchingMyBoardIssues || isFetchingUserBoard}
            onReloadMyBoardIssues={onReloadMyBoardIssues}
            onActionCallback={onActionCallback}
            onComputePlanCallback={onComputePlan}
          />)
        })}
      </Col>
      <Col span={12}>
        <SuggestedPlan
          issues={suggestedIssues}
          loading={isFetchingSuggestedIssues}
          onActionCallback={onActionCallback}
          paginationSettings={paginationSettings}
          {...suggestedOptions}
        />
      </Col>
      {activeIssue && (
        <Modal
          open
          footer={null}
          destroyOnClose
          closable={false}
          onCancel={resetIssueCallback}
          width={820}
        >
          {activeIssue && <IssueInfoRoot
            hiddenView={hiddenInIssueInfo}
            disabledView={disabledInIssueInfo}
            partitionType={partition}
            resetIssueCallback={resetIssueCallback}
            deleteCallback={onDeleteIssueCallback}
            onChangeRowCallback={(uuid) => onActionCallback(uuid, 'q-view')}
          />}
        </Modal>
      )}
    </Row>
  );
}

MyBoardNew.propTypes = {
  partition: string,
  customActor: string,
  onChangePlanCallback: func,
};

export default MyBoardNew;
