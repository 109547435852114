import React, {
    useEffect, useMemo, useState,
  } from 'react';
  import { useTranslation } from 'react-i18next';
  import { useDispatch, useSelector } from 'react-redux';
  import { Spin } from 'antd';
  import _, {
    difference,
    get, head, isEmpty, isNil, xor,
  } from 'lodash';
  import PropTypes, { bool, func, number, object, string } from 'prop-types';
  import { reverse } from 'ramda';
  import moment from 'moment';
  

  import '../vector/css/VectorNewInfo.scss'
  import VectorTopBar from '../vector/vectorInfo/VectorTopBar';

  // add import 
  import WorkBlockInfoViewForVectorsTable from './WorkBlockInfoViewForVectorsTable';
  
  import {
    getProjectParams,
    getVectorsScoringData,
    getProjectUUID,
  } from '../../selectors/selectors';
  import { getUserPublicProfileUUID } from '../../../userFlow/store/selectors/selectors';
  import {
    antNotification,
    dateStartAndCreatedDateValidator,
  } from '../../../MainUtils';
  import { sendNotification } from '../../../api/notificationsAPI';
  import {
    sendSystemComment,
    updateVectorRequest,
    updateProjectUsersAndPerms,
    updateListComment,
  } from '../../actions/projectFlowActions';
  import { entityPartialRead } from '../../../entity/actions/entityActions';
  import { COMMENT_TYPE } from '../comment/commentType';
  import { ProjectTypeCommonConstants } from '../../constants/Constants';
  import { updateEntityScoring } from '../issuesView/utils';
  import { partitionNamesConfig } from '../../../api/appConfig';
  
  function WorkBlockInfoForVectorsTable({
    activitiesTabs,
    notSavedData,
    setNotSavedData,
    afterDeleteABookmark,
    defaultTemplateState,
    deleteVectorCallback,
    restoreActionCallback,
    viewMode = 'vector',
    partitionType = partitionNamesConfig.partition1,
    hiddenView = [],
    disabledView = [],
    resetVectorCallback,
    updateVectorModal,
    setIsModalVisible,
    reloadData,
    dragIssueCallback,
    clickActivitiesHandler,
    clickDashboardButtonHandler,
    // add props
    disabledMultiEdit,
    isGlobalDisabled,
    newCurrentPage,
    countOfData,
    getIssue,
    getIssues,
    getIssuesRequestSetting,
    filterConfig,
    filterCallback,
    onChangeRowCallback,
    newIssueSuccessCallback,
    //add vector props
    vectorData,
    vectorUUID,
    vectorDataFetching,
      //add document modal props
  activeDocumentModal,
  setActiveDocumentModal,
  activeDocument,
  setActiveDocument,
  activeDocumentType,
  setActiveDocumentType,
  // add issue modal props
  activeIssue,
  setActiveIssue,
  activeIssueModal,
  setActiveIssueModal,
  // add hide props 
  setHideVectors,
  saveDocument,
  }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const myPublicUUID = useSelector(getUserPublicProfileUUID);
    const projectParams = useSelector(getProjectParams);
    const projectUUID = useSelector(getProjectUUID);
  

    const vectorsScoringData = useSelector(getVectorsScoringData);
  
    const defaultVectorData = useMemo(() => ({
      visibleUsers: vectorData?.params?.users?.map((el) => el?.uuid) || [],
    }), [vectorUUID, vectorData?.params?.users]);
  
    const [dataProject, setDataProject] = useState({
      projectData: {},
      usersUuidsForAssigned: [],
    });
  
    const defaultCommentsCount = {
      [COMMENT_TYPE.USER]: vectorData?.params?.[COMMENT_TYPE.USER],
      [COMMENT_TYPE.SYSTEM]: vectorData?.params?.[COMMENT_TYPE.SYSTEM],
      [COMMENT_TYPE.TIME_LOG]: vectorData?.params?.[COMMENT_TYPE.TIME_LOG],
    };
  
    const getFromParams = (key, defaultValue = '') => get(vectorData, `params.${key}`, defaultValue);
  
    const deleteVector = () => {
      deleteVectorCallback(vectorData).then(() => {
        // console.log('deleteVectorCallback vectorData', vectorData);
        const oldScoringdata = {
          actionType: 'delete',
          sum: vectorsScoringData?.sum,
          total: vectorsScoringData?.total,
          currentPriority: vectorData?.params?.priority,
          currentStatus: vectorData?.params?.status,
        };
        const newScoringData = updateEntityScoring(oldScoringdata);
        // console.log('newScoringData updateVector', newScoringData);
        if (newScoringData) {
          dispatch({
            type: ProjectTypeCommonConstants.UPDATE_VECTORS_PRIORITY_INDEXES_DATA,
            payload: newScoringData,
          });
        }
      });
    };
  
    const sendCustomComment = async (params) => {
      const loggedParams = [
        'status',
        'priority',
        'type',
        // 'subType',
        'dateStart',
        'dateEnd',
        'lifeTime',
      ];
      const fromVersion = params.type
        // && params.subType
        && params.status;
  
      let changedParams = loggedParams.filter((item) => params[item]);
  
      if (fromVersion) {
        changedParams = changedParams.filter((item) =>
        // item !== 'subType'
          // &&
          item !== 'status');
      }
  
      if (changedParams.length === 0) {
        return;
      }
  
      const param = reverse(changedParams);
      const value = param.map((item) => params[item]);
  
      const comment = {
        entity: 'vector',
        action: 'changed',
        param,
        value,
      };
  
      if (vectorUUID) {
        await dispatch(sendSystemComment(vectorUUID, comment, partitionType));
        // dispatch(setNeedUpdateSystemComments(vectorUUID));
        // ???
        dispatch(updateListComment(vectorUUID));
      }
    };
  
    const sendAssignNotifications = (users) => {
      const { uuid, parent, params } = vectorData;
  
      const receiver = difference(
        users,
        params?.usersSearch,
      );
  
      const entityData = {
        projectUUID: parent,
        vectorUUID: uuid,
        entity_type: 'vector',
        partition: partitionType,
  
        project_uuid: parent,
        project_id: projectParams?.id,
        project_title: projectParams?.title,
        assigned_entity_type: 'vector',
        assigned_entity_title: params?.title,
        assigned_entity_id: params?.id,
        assigned_entity_uuid: uuid,
      };
  
      // console.log('entityData', entityData)
  
      if (partitionType === partitionNamesConfig.partition3) {
        entityData.user = myPublicUUID || '';
      }
  
      if (!_.isEmpty(receiver)) {
        dispatch(
          sendNotification('assigned_vector', receiver, getFromParams('title'), {
            entity: entityData,
          }),
        );
      }
    };
  
    const updateVector = (params) => {
      const data = {
        uuid: vectorUUID,
        params,
        partition: partitionType,
      };
  
      data.params.lastActivityTimestamp = new Date().toUTCString();
  
      if (isNil(vectorData?.params?.firstActivityTimestamp)) {
        data.params.firstActivityTimestamp = new Date().toUTCString();
      }
  
      dispatch(updateVectorRequest(data)).then((response) => {
        if (!response) {
          antNotification('error', t('notifications.text.error.base', 'Error'));
        } else {
          antNotification('success', t('wms.noun.success', 'Success'));
        }
  
        if (params.usersSearch) {
          sendAssignNotifications(params.usersSearch);
        }
  
        sendCustomComment(params);
  
        if (defaultTemplateState) {
          defaultTemplateState(response[0]);
        }
        if (updateVectorModal) {
          updateVectorModal(response[0]);
        }
        if (setIsModalVisible) {
          setIsModalVisible(false);
        }
        if (reloadData) {
          reloadData();
        }
        const oldScoringdata = {
          actionType: 'update',
          sum: vectorsScoringData?.sum,
          total: vectorsScoringData?.total,
          currentPriority: response[0]?.params?.priority,
          currentStatus: response[0]?.params?.status,
          oldPriority: vectorData?.params?.priority,
          oldStatus: vectorData?.params?.status,
        };
        // console.log('updateVectorsScoringData', oldScoringdata);
        const newScoringData = updateEntityScoring(oldScoringdata);
        // console.log('newScoringData updateIssue', newScoringData);
        if (newScoringData) {
          dispatch({
            type: ProjectTypeCommonConstants.UPDATE_VECTORS_PRIORITY_INDEXES_DATA,
            payload: newScoringData,
          });
        }
      });
    };
  
    const createdDate = moment(vectorData?.created || new Date())
      .toDate();
  
    const onChangeData = (key, value) => {
      switch (key) {
        case 'lifeTime':
          if (dateStartAndCreatedDateValidator(createdDate, value)) {
            return updateVector({ [key]: value });
          }
          antNotification('warning', t('notifications.text.warning.incorrect_date', 'incorrect_date'));
          return updateVector({ [key]: createdDate });
        case 'deployVectors':
        case 'includedVersions':
        case 'type':
        case 'priority':
        case 'status': {
          return updateVector({ [key]: value });
        }
        default:
          return updateVector({ [key]: value });
      }
    };
  
    const onChangeProjectUsers = (usersData) => {
      const {
        usersSearch,
      } = usersData || {};
      const needUpdateProjectUsers = xor(projectParams.usersSearch, usersSearch).length > 0;
  
      if (needUpdateProjectUsers) {
        updateProjectUsersAndPerms({
          dispatch,
          data: usersData,
          partition: partitionType,
          projectUUID,
        });
      }
    };
  
    const onChangeKeys = (data) => updateVector({ ...data });
  
    const resetVector = () => {
      if (resetVectorCallback) {
        resetVectorCallback(vectorData);
        setHideVectors(false)
        setActiveIssueModal(false)
        setActiveDocumentModal(false)
      }
    };
  
    const getClearProject = async (uuidProject) => {
      const data = {
        entity_type: 'project',
        entity_uuid: uuidProject,
        params_fields: {
          usersSearch: 'usersSearch',
          users: 'users',
        },
      };
  
      const constants = [
        'GET_PROJECT_FOR_ISSUE_CARD_NO_REDUX_REQUEST',
        'GET_PROJECT_FOR_ISSUE_CARD_NO_REDUX_SUCCESS',
        'GET_PROJECT_FOR_ISSUE_CARD_NO_REDUX_FAILURE',
      ];
  
      const options = {
        partition: partitionType,
      };
  
      return dispatch(entityPartialRead({
        data,
        constants,
        options,
      }));
    };
  
    const getProject = async () => {
      if (vectorData?.parent) {
        const res = await getClearProject(vectorData?.parent);
  
        setDataProject({
          projectData: head(res),
          usersUuidsForAssigned: get(head(res), 'usersSearch', []),
        });
      }
    };
  
    const onChangeAssignedUsers = (data) => {
      const {
        users = [],
      } = data;
  
      const uuids = users?.map((el) => el?.uuid) ?? [];
      const wholeItems = [...users];
  
      onChangeKeys({
        users: wholeItems,
        usersSearch: uuids,
      });
      getProject();
    };
  
    useEffect(() => {
      getProject();
    }, [vectorData?.parent]);
  
    return (
      <Spin spinning={vectorDataFetching} size="middle">
        <VectorTopBar
          partitionType={partitionType}
          viewMode={viewMode}
          afterDeleteABookmark={afterDeleteABookmark}
          hiddenView={hiddenView}
          restoreActionCallback={restoreActionCallback}
          notSavedData={notSavedData}
          setNotSavedData={setNotSavedData}
          dragIssueCallback={dragIssueCallback}
          deleteVectorCallback={deleteVector}
          resetVector={resetVector}
          openSidePanel={clickActivitiesHandler}
        />
  
        <hr className="mt-1 mb-2" />
  
        <WorkBlockInfoViewForVectorsTable
          activitiesTabs={activitiesTabs}
          defaultCommentsCount={defaultCommentsCount}
          defaultVectorData={defaultVectorData}
          disabledView={disabledView}
          vectorData={vectorData}
          partitionType={partitionType}
          dataProject={dataProject}
          onChangeData={onChangeData}
          getFromParams={getFromParams}
          onChangeAssignedUsers={onChangeAssignedUsers}
          clickDashboardButtonHandler={clickDashboardButtonHandler}
          // add props
          disabledMultiEdit={disabledMultiEdit}
          isGlobalDisabled={isGlobalDisabled}
          newCurrentPage={newCurrentPage}
          countOfData={countOfData}
          getIssue={getIssue}
          getIssues={getIssues}
          getIssuesRequestSetting={getIssuesRequestSetting}
          filterConfig={filterConfig}
          filterCallback={filterCallback}
          onChangeRowCallback={onChangeRowCallback}
          newIssueSuccessCallback={newIssueSuccessCallback}
          vectorFetching={vectorDataFetching}
          uuid={vectorUUID}
        // add documentModal props 
        activeDocumentModal={activeDocumentModal}
        setActiveDocumentModal={setActiveDocumentModal}
        activeDocument={activeDocument}
        setActiveDocument={setActiveDocument}
        activeDocumentType={activeDocumentType}
        setActiveDocumentType={setActiveDocumentType}
        // add issue modal props
        activeIssue={activeIssue}
        setActiveIssue={setActiveIssue}
        activeIssueModal={activeIssueModal}
        setActiveIssueModal={setActiveIssueModal}
        saveDocument={saveDocument}
        />
      </Spin>
    );
  }
  
  WorkBlockInfoForVectorsTable.propTypes = {
    activitiesTabs: PropTypes.arrayOf(PropTypes.object),
    defaultTemplateState: PropTypes.func,
    deleteVectorCallback: PropTypes.func,
    restoreActionCallback: PropTypes.func,
    viewMode: PropTypes.string,
    hiddenView: PropTypes.arrayOf(PropTypes.string),
    disabledView: PropTypes.arrayOf(PropTypes.string),
    partitionType: PropTypes.string,
    resetVectorCallback: PropTypes.func,
    updateVectorModal: PropTypes.func,
    setIsModalVisible: PropTypes.func,
    reloadData: PropTypes.func,
    dragIssueCallback: PropTypes.func,
    afterDeleteABookmark: PropTypes.func,
    clickActivitiesHandler: PropTypes.func,
    clickDashboardButtonHandler: PropTypes.func,
    // add Props 
    countOfData: number,
    getIssuesRequestSetting: func,
    newIssueSuccessCallback: func,
    onChangeRowCallback: func,
    filterConfig: object,
    filterCallback: func,
    getIssues: func,
    isGlobalDisabled: bool,
    newCurrentPage: number,
    getIssue: func,
    disabledMultiEdit: bool,
    vectorData: object,
    vectorDataFetching: bool,
    vectorUUID: string,
      //add modals props
    activeDocumentModal: bool,
    setActiveDocumentModal: func,
    activeDocument: string,
    setActiveDocument: func,
    activeDocumentType: string,
    setActiveDocumentType: func,
    activeIssue: string,
    setActiveIssue: func,
    activeIssueModal: bool,
    setActiveIssueModal: func,
    setHideVectors: func,
    saveDocument: bool,
    setSpinModal: func
  };
  
  export default WorkBlockInfoForVectorsTable;
  