import React, { Suspense, useEffect } from 'react';
import { oneOf } from 'prop-types';
import {
  Route, Navigate, Outlet, RouterProvider, createBrowserRouter, createRoutesFromElements,
} from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';

import '../../i18n';

import MainLayout from '../../MainLayout';
import Login54Origins from '../../54origins/components/Login54Origins';
import ShortLinksRedirect from '../ShortLinksRedirect';
import StartPage from '../StartPage';
import CookBookMenuSection from '../../projectFlow/components/cookbook/CookBookMenuSection';
import ProjectMainLayer from '../../projectFlow/components/project/ProjectMainLayer';
import ProfilePage54origins from '../../54origins/components/ProfilePage54origins';
// import DashboardComponent from './userFlow/components/DashboardComponent';
import WeekPlanningStartPage from '../../userFlow/components/WeekPlanning/WeekPlanningStartPage';
import ProjectFlowProjectPage from '../../projectFlow/components/project/ProjectFlowProjectPage';
import ProjectsAudit from '../../projectFlow/components/project/projectsAudit/ProjectsAudit';
import BookmarksStartPage from '../../userFlow/components/Bookmarks/bookmarksStartPage/BookmarksStartPage';
import ListOfMasqueradingActors from '../../admin/components/ListOfMasqueradingActors';
import PwaAppInstruction from '../../toolsFlow/components/PwaAppInstruction.jsx';
import UserReportFromSidebar from '../../toolsFlow/components/userReports/UserReportFromSidebar.jsx';
// import UsersReportsRoot from '../../toolsFlow/components/usersReports/UsersReportsRoot.jsx';
import ProjectReportsRoot from '../../toolsFlow/components/projectReports/ProjectReportsRoot.jsx';
import AdminDashboardRoot from '../../admin/components/AdminDashboardRoot';
import MemberDashboard from '../../admin/components/members/MemberDashboard';
import Loader from '../../projectFlow/components/Loader';
import GitVersion54origins from '../../54origins/components/GitVersion54origins';
import WhatsNew from '../../toolsFlow/components/WhatsNew.jsx';
import PersonalDashboardRoot from '../../userFlow/components/PersonalDashboard/PersonalDashboardRoot';
import PersonalProjectsCardRoot from '../../userFlow/components/PersonalProjects/PersonalProjectsCardRoot';
import AppModeProvider from '../../context/AppModeProvider';

import { AuthHelper } from '../../auth/reducers/AuthReducer';
import { getUserPublicFetching } from '../../userFlow/store/selectors/selectors';
import { isAdminOrRoot } from '../../entity/selectors/selectors';
import { getAuthConfigFetching } from '../../config/selectors/selectors';
import { setFlagNotSaveData } from '../un-save-data-confirm/unSaveDataSlicer';
import useURLParams from '../../hooks/useURLParams';
import configureStore from '../../redux/store/configureStore';
import { appLabel, configUrlEntity } from '../../api/appConfig';
import { entityBackendURL } from '../../api/api';
import { ConfigProvider } from "antd";
import { theme } from './theme';
import GeneralPlanboard from '../../projectFlow/components/project/generalPlanboard/GeneralPlanboard.js';

export const { store } = configureStore();

function getColorForFavicon(name) {
  // Convert username to a numeric value
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate a color based on the numeric value
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += (`00${value.toString(16)}`).substr(-2);
  }

  return color;
}

const onChangeColorFavicon = () => {
  // get svg element.
  // const svg = document.getElementById('svg');

  // get svg source.
  // const serializer = new XMLSerializer();
  // let source = serializer.serializeToString(svg);
  let source = `<svg class="hidden" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" id="svg" clip-rule="evenodd" viewBox="0 0 512 512" fill="${getColorForFavicon(window.location.hostname)}"><g fill-rule="nonzero"><path d="M255.998 0c70.69 0 134.694 28.658 181.017 74.982C483.342 121.308 512 185.312 512 256.002c0 70.689-28.658 134.69-74.985 181.016C390.692 483.342 326.688 512 255.998 512c-70.689 0-134.687-28.658-181.017-74.982C28.657 390.689 0 326.691 0 256.002c0-70.69 28.657-134.694 74.981-181.017C121.308 28.658 185.309 0 255.998 0z"/><path fill="#fff" d="M316.455 162.24h62.71l-29.406 187.523H271.45l-13.8-69.008h-3.601l-13.503 69.008h-78.309L132.834 162.24h62.707l14.104 104.712h1.799l21.603-104.712h45.906l21.302 104.712h1.799z"/></g></svg>`;

  // add name spaces.
  if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
    source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
  }
  if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
    source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
  }

  // add xml declaration
  source = `<?xml version="1.0" standalone="no"?>\r\n${source}`;

  // convert svg source to URI data scheme.
  const url = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(source)}`;

  // set url value to a element's href attribute.
  const linkNode = document.querySelector('link[rel="icon"]');
  if (linkNode) {
    linkNode.href = url;
  }
};

function LoginRouteWrapper() {
  return (
    AuthHelper.isAuthorised() ? <Navigate to="/" /> : <Login54Origins url={configUrlEntity} serviceName={appLabel} reloadJsAfterLoadHTML />
  );
}

function CheckAuthToken() {
  return (
    AuthHelper.isAuthorised() ? <Outlet /> : <Navigate to="/login" replace />
  );
}

function DataNotSaved() {
  const dispatch = useDispatch();

  const { routeNavigateTo, navigate, currentLocation } = useURLParams();

  useEffect(() => {
    dispatch(setFlagNotSaveData(false));

    if (currentLocation?.state?.path) {
      routeNavigateTo(currentLocation?.state?.path, currentLocation?.state?.search);
    } else {
      navigate(-1);
    }
  }, []);

  return null;
}

function PrivateRouteNext({
  isAdminRoute,
  children,
}) {
  const userIsAdmin = useSelector(isAdminOrRoot);
  const authConfigFetching = useSelector(getAuthConfigFetching);

  const {currentLocation, navigate} = useURLParams();

  if (AuthHelper.isAuthorised() && authConfigFetching) {
    return <Loader />;
  } else {
    if(currentLocation.key === 'default' && localStorage.getItem('prevPath')){
      navigate(localStorage.getItem('prevPath'))
    } else {
      localStorage.removeItem('prevPath')
    }
  }

  if (!AuthHelper.isAuthorised()) {
    localStorage.setItem('prevPath', `${currentLocation.pathname}${currentLocation.search}`)

    return <Navigate to="/login" />;
  }

  if (isAdminRoute && !userIsAdmin) {
    return <Navigate to="/" replace />;
  }

  return children;
}

function PublicRouteWrapper({ type }) {
  const userPublicFetching = useSelector(getUserPublicFetching);

  if (type === 'dashboard') {
    return (userPublicFetching
      ? <Loader />
      : <PersonalDashboardRoot />
    );
  }

  if (type === 'projects') {
    return (userPublicFetching
      ? <Loader />
      : <PersonalProjectsCardRoot location="dashboard" />
    );
  }

  PublicRouteWrapper.propTypes = {
    type: oneOf(['dashboard', 'projects']),
  };
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login" element={<LoginRouteWrapper />} />
      <Route path="/data-not-saved" element={<DataNotSaved />} />
      <Route path="/" element={<PrivateRouteNext>
        <MainLayout
          fixedHeader={true}
          headerHeight={70}
          fixedSidebar={true}
          sidebarWidth={240}
        />
      </PrivateRouteNext>}>

        {/* MAIN ROUTES */}
        <Route index path="/" element={<Navigate to={{ pathname: '/pm/projects', search: '?page=1' }} />} />

        <Route path="s" element={<ShortLinksRedirect />}>
          <Route path=":partition/*" />
        </Route>
        <Route path="startpage" element={<StartPage />} />
        <Route path="robots.txt" />
        <Route path="version" element={<GitVersion54origins />} />

        {/* PROJECT FLOW ROUTES */}

        <Route path="pm" element={<CheckAuthToken />}>
          <Route index element={<Navigate to="/pm/projects" />} />
          <Route
            path="projects"
            element={(
              <PrivateRouteNext>
                <ProjectFlowProjectPage showOtherProjects />
              </PrivateRouteNext>
            )}
          />
          <Route
            path="projects/:activeTab/*"
            element={<ProjectMainLayer />}
          />
          <Route path='generalPlanboard' element={<GeneralPlanboard/>}/>
          <Route path="audit" element={<ProjectsAudit />} />
          <Route path="cookbook" element={<CookBookMenuSection />} />
        </Route>

        {/* USER FLOW ROUTES */}

        <Route path="user" element={<CheckAuthToken />}>
          <Route index element={<Navigate to="/pm/projects" />} />
          <Route path="bookmarks" element={<BookmarksStartPage />} />
          <Route path="masquerading" element={<ListOfMasqueradingActors />} />
        </Route>

        <Route path="public/user" element={<CheckAuthToken />}>
          <Route path="profile" element={<ProfilePage54origins url={entityBackendURL()} />} />
          <Route path="planning" element={<WeekPlanningStartPage />} />
          <Route path=":id/dashboard" element={<PublicRouteWrapper type="dashboard" />} />
          <Route path=":id/projects/*" element={<PublicRouteWrapper type="projects" />} />
          <Route path=":id/projects/:activeTab" element={<PublicRouteWrapper type="projects" />} />
        </Route>

        {/* TOOLS FLOW ROUTES */}

        <Route path="tools" element={<CheckAuthToken />}>
          <Route path="pwaAppInstruction" element={<PwaAppInstruction />} />
          <Route path="userReport" element={<UserReportFromSidebar />} />
          <Route path="projectReports" element={<ProjectReportsRoot />} />
          <Route path="whatsnew" element={<WhatsNew />} />
        </Route>

        {/* {ADMIN ROUTES} */}

        <Route path="admin/dashboard" element={<CheckAuthToken />}>
          <Route index element={<AdminDashboardRoot />} />
          <Route path="public/user/:uuid/*" element={<MemberDashboard />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </>,
  ),
);

function AppComponent() {
  useEffect(() => {
    onChangeColorFavicon();
  }, []);

  return (
    <ConfigProvider theme={theme}>
      <Suspense fallback={null}>
        <Provider store={store}>
          <AppModeProvider>
            <RouterProvider router={router} />
          </AppModeProvider>
        </Provider>
      </Suspense>
    </ConfigProvider>
  );
}

export default AppComponent;
