import React, { useEffect, useState } from "react";
import InfoTip from "../../../../components/infoTip/InfoTip";
import { useDispatch, useSelector } from "react-redux";
import {getPartitionPMUUID} from '../../../../config/selectors/selectors'
import { getListAndPartialReadEntities, getListAndReadEntities } from "../../../../entity/actions/entityActions";
import dayjs from "dayjs";
import { Col, Flex} from "antd";
import './GeneralPlanboard.scss'
import { ProjectTypeCommonConstants } from "../../../constants/Constants";
import { getIssue } from "../../../actions/projectFlowActions";
import axiosRequest from "../../../../api/apiAxios";
import { getOrCreateUserBoardStorage } from "../../../../myBoard/actions/userBoardStorageActions";
import { getOrCreateUserBoard } from "../../../../myBoard/actions/userBoardActions";
import UsersTable from "../../../../toolsFlow/components/userReports/UsersTable.jsx";
import { find, get } from "lodash";
import {  isAdminOrRoot } from "../../../../entity/selectors/selectors";
import { getMyInfo } from "../../../selectors/selectors";
import { partitionNamesConfig } from "../../../../api/appConfig";
import MainBoard from "./MainBoard";
import UserCard from "./UserCard";
import { getActorLabel } from "./utils";

const GeneralPlanboard = () => {

  const today = dayjs().format("YYYY-MM-DD")
  const defaultPartition = partitionNamesConfig.partition1

  const dispatch = useDispatch()
  const partitionPM = useSelector(getPartitionPMUUID);
  // const actorUUID = useSelector(getMe);
  const myInfo = useSelector(getMyInfo)
  const adminOrRoot = useSelector(isAdminOrRoot);

  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState({actor: myInfo.uuid, label: getActorLabel(myInfo)})



  const [projects, setProjects] = useState(null)
  const [userBoards, setUserBoards] = useState(null)
  const [loading, setLoading] = useState(false)

  const getProjects = async () => {
    const data = {
      entity_type: "project",
      depth: 0,
      parent: partitionPM,
      params: {
        usersSearch: [selectedUser.actor],
        status: ['active', 'created']
      },
      params_fields: {
        title: "title",
        usersSearch: "usersSearch",
      }
    };
    const constants = [
      "GET_ENTITY_REQUEST",
      "GET_ENTITY_SUCCESS",
      "GET_ENTITY_FAILURE"
    ]

    const options = {
      partition: defaultPartition,
    }
    const projects = await dispatch(getListAndPartialReadEntities({
      data,
      constants,
      options,
    }))
    setProjects(projects.data)
    console.log("projects", projects);
  }
  // add get board func

  const getUserBoards = async () => {
    const data = {
      entity_type: "userBoard",
      actor: selectedUser.actor,
      owner: selectedUser.actor,
      depth: 0,
      parent: partitionPM,
      params_fields: {
        [`${today}`]: today,
        [`${today}__data`]: `${today}__data`,
        [`${today}__settings`]: `${today}__settings`,
        project: 'project'
      }
    };
    const constants = [
      "GET_ENTITY_REQUEST",
      "GET_ENTITY_SUCCESS",
      "GET_ENTITY_FAILURE"
    ]

    const options = {
      partition: defaultPartition,

    }
    const userBoards = await dispatch(getListAndReadEntities({
      data,
      constants,
      options,
    }))
    setUserBoards(userBoards.data)
    console.log("userBoards", userBoards);

  }

  const createUserBoard = async (projectUUID, actor) => {
    try {
      const storage = await dispatch(getOrCreateUserBoardStorage({
        actor: actor,
        parent: projectUUID,
        partition: defaultPartition,
      }));

      const board = await dispatch(getOrCreateUserBoard({
        parent: storage.uuid,
        partition: defaultPartition,
        actor: actor,
        project: projectUUID,
      }));

      return board;

    } catch (error) {
      console.error('error:', error);
    }
  }

  const getBoardForProject = async (projectUUID, projectTitle) => {
    const userBoard = find(userBoards, el => get(el, 'params.project', []).includes(projectUUID))
      ?? await createUserBoard(projectUUID, selectedUser.actor);

    return {...userBoard, projectTitle: projectTitle};
  };

  const fetchBoards = async () => {
    setLoading(true)
    const fetchedBoards = await Promise.all(projects.map(project => getBoardForProject(project.uuid, project.title)));
    return fetchedBoards
    // setFetchedBoards(fetchedBoards);
    // setLoading(false)

  };

  const getFetchBoards = async () => {
    await getUserBoards()
    const boards = await fetchBoards()
    return boards
  }


  const changeUser = (user) => {
    if(selectedUser !== user.actor) {
      setSelectedUser(null)
      setUserBoards(null)
      setSelectedUser(user)
      console.log("selectedUser", user);

    }
  }

  const getClearIssue = (uuid, signal) => {
    axiosRequest.abort('', ProjectTypeCommonConstants.ISSUE_ENTITY_REQUEST);

    const config = {
      uuid,
      partition: defaultPartition,
      signal,
      withFiles: false,
      params: {},
    };

    return dispatch(getIssue(config));
  };


  useEffect(() => {
    setSelectedUser({actor: myInfo.uuid, label: getActorLabel(myInfo)})
  }, [myInfo.uuid])


  useEffect(() => {
    if(selectedUser) {
      getProjects()
      // getUserBoards()
    }
  }, [selectedUser])

  // useEffect(() => {
  //   if (projects && userBoards) {
  //     fetchBoards();
  //   }
  // }, [projects, userBoards]);


  return (
    <>
      <InfoTip className={"mb-2"} title="General planboard"
               text="General planboard allows you to view your today's tasks from all projects."
      />
      <div className="relative h-full w-full">
        <div className="wrapper-user-report">

          {adminOrRoot &&
            <Flex vertical span={6} style={{position: 'sticky', top: 0}}>
              <UserCard
                user={myInfo}
                setSelectedUser={setSelectedUser}
              />
              <Col className=" bg-white mr-2 mt-2 overflowHidden user-table-wrapper">
                <UsersTable
                  selectedUser={selectedUser.actor}
                  users={users}
                  setSelectedFullUser={changeUser}
                  setUsers={setUsers}
                />
              </Col>
            </Flex>
          }
          <div className="w-full" >
            <MainBoard
              // fetchedBoards={fetchedBoards}
              today={today}
              partitionPM={partitionPM}
              selectedUser={selectedUser}
              partition={defaultPartition}
              customActor={selectedUser.actor}
              getClearIssue={getClearIssue}
              getUserBoards={getUserBoards}
              projects={projects}
              getFetchBoards={getFetchBoards}
            />
            {/* <Spin spinning={loading} >
              {projects && userBoards && fetchedBoards && projects.map((project, index) => {
              const userBoard = fetchedBoards[index];
              return (
              <Flex vertical className="projectCard w-full" key={project.uuid}>
                <Flex vertical>
                <b>Title: {project.title}</b>
                </Flex>
                  {userBoard &&
                  <MyBoardForGeneralPlanboard
                  projectUUID={project.uuid}
                  userBoard={userBoard}
                  customActor={selectedUser}
                  getClearIssue={getClearIssue}
                  projectUsers={project.usersSearch}
                  partition={defaultPartition}
                  today={today}
                  />}
                </Flex>)
             })}
          </Spin>  */}
          </div>
        </div>
      </div>
    </>
  )
}

export default GeneralPlanboard
