import { assign, find, get, includes, join, map } from "lodash";
import cn from 'classnames';

export const getActorLabel = (myInfo) => {
    return join([get(myInfo, 'uinfo.first_name'), get(myInfo, 'uinfo.last_name')], ' ');
}

export const btnInfoClass = "pl-0.3 mt-1 rounded-full cursor-pointer border border-sky-500 hover:bg-sky-500 hover:text-white";
export const btnInfoStyle = {
  width: 20,
  height: 20,
};

export const getClassBtn = (type) => {
  if (type === "save") {
    return cn('border border-green-500 text-green-500', {
      '!border-stone-500 !text-stone-500': false,
      'hover:bg-green-500 hover:text-white': !false,
    })
  }
  if (type === 'get') {
    return cn('border border-blue-500 text-blue-500', {
      '!border-stone-500 !text-stone-500': false,
      'hover:bg-blue-500 hover:text-white': !false,
    })
  }
}

export const getUserIssuesUUIDS = (boards, today) => {
   return boards.flatMap(board => get(board, [`params`, `${today}__data`], []).map(issue => get(issue, 'uuid')));
}

export const addDataToIssues = (issues, boards, today) => {
  const dataMap = new Map();
  boards.forEach(board => {
    const todayData = board.params[`${today}__data`];
      if(todayData) {
        todayData.forEach(el => {
          dataMap.set(el.uuid, {
            ...el, userBoardUUID: board.uuid, 
            // todayData: todayData, 
            projectTitle: board.projectTitle});
        });
      }
  });

      return issues.map(issue => {
        const data = dataMap.get(issue.uuid);
        if (data) {
          return { ...issue, data }; 
        }
        return issue; 
      }).filter(issue => issue.data); 
};

export const getDayParams = (todayIssues) => {
  if(todayIssues) {
    return todayIssues.reduce((acc, el) => acc += el.data.chunk, 0) / 2
  }
   
}

export const addDataToUnplannedIssues = (issues, projects) => {

    return issues.map(el => {
      const data = el.boardData ? el.boardData : { chunk: 3 };
      const project = find(projects, project => includes(project.uuid, el.project));

      return assign({}, el, {
        data,
        projectTitle: project ? project.title : null
      });
    });
}

// update issue params 

export const updateTodayParam = (data, param, projectUUID, projectsSet) => {

    if(!projectsSet.has(projectUUID)) {
      return [param]
    }
 return  data.some(el => el === param) ? data : [...data, param];
}

export const updateTodayDataParam = (data, param, projectUUID, projectsSet) => {

  if(!projectsSet.has(projectUUID)) {
    return [param]
  } 
  else {
    const findParam = find(data, { uuid: param.uuid });
    if (!findParam) {
      return [...data, param];
    }
  return map(data, el => 
    el.uuid === findParam.uuid 
      ? { key: el.key, uuid: param.uuid, chunk: param.chunk } 
      : el
  );
  }
 
};