import { isNull, isEmpty, get } from 'lodash';

import { ConfigConstants } from '../config/constants/Constants';
import { store } from '../components/AppComponent/AppComponent';
import { axiosRequestPost } from './apiAxios';
import {
  checkBackendUrlEntity,
  getConfig,
  getPartitionUUIDForHeaderByName,
} from '../config/selectors/selectors';
import { EntityConstants } from '../entity/constants/Constants';
import {
  configUrlAuth, configUrlEntity, configUrlWMS, getSessionTokenFor,
  partitionNamesConfig,
} from './appConfig';

export const entityBackendURL = () => checkBackendUrlEntity(store.getState()) || configUrlEntity;

export function getPublicInterfaces(callback) {
  return async (dispatch, getState) => {
    const getConfigs = () => new Promise((resolve, reject) => {
      dispatch(
        axiosRequestPost(
          'public_interfaces/bulk',
          [
            EntityConstants.GET_AUTH_CONFIG_REQUEST,
            EntityConstants.GET_AUTH_CONFIG_SUCCESS,
            EntityConstants.GET_AUTH_CONFIG_FAILURE,
          ],
          { service_domain: configUrlWMS },
          {
            header: {
              'Content-Type': 'application/json',
              'Http-Accept-Language': 'en',
            },
            url: configUrlAuth,
            onSuccess: resolve,
            onFailure: reject,
          },
        ),
      );
    });

    function getConfigAuthFromStore() {
      const configFromStore = get(getState(), ['config.configReducer.authConfig.public_interface'], []);
      return configFromStore;
    }

    const reduxAutConfigStore = getConfigAuthFromStore();

    if (reduxAutConfigStore.length === 0 || !checkBackendUrlEntity(getState())) {
      const conf = await getConfigs();

      if (callback) {
        callback(conf);
      }
    }
  };
}

export default function getConfigEntity() {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const reduxEntityConfig = getConfig(getState());

    const entityURL = checkBackendUrlEntity(getState());

    if (isEmpty(reduxEntityConfig) && !isNull(entityURL)) {
      dispatch(
        axiosRequestPost(
          'partition/get',
          [
            ConfigConstants.GET_CONFIG_REQUEST,
            ConfigConstants.GET_CONFIG_SUCCESS,
            ConfigConstants.GET_CONFIG_FAILURE,
          ],
          {},
          {
            header: {
              'Content-Type': 'application/json',
              'Http-Accept-Language': 'en',
              'Session-Token': getSessionTokenFor.entity(),
            },
            url: entityURL,
            onSuccess: resolve,
            onFailure: reject,
          },
        ),
      );
    } else {
      resolve(reduxEntityConfig);
    }
  });
}

export const getPartitionUUID = (name = partitionNamesConfig.partition1) => getPartitionUUIDForHeaderByName(store.getState(), name);
