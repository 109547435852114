import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  setCurrentQuillInstance,
  setDeletedDocHtml,
} from "../../../reducers/slicers/quillSlicer";

// ------------------ selectors -------------------
import { getUser } from "../../../../entity/selectors/selectors";
import { getConfig } from "../../../../config/selectors/selectors";

// ------------------ Yjs, Quill ------------------
import Quill from "quill";
import "quill/dist/quill.snow.css";
import QuillCursors from "quill-cursors";
import { QuillBinding } from "y-quill";
import * as Y from "yjs";
import { WebsocketProvider } from "y-websocket";

// ------------------ custom modules -----------------
import { CustomImageResize } from "./customModules/CustomImageResize";
import { CustomClipboard } from "./customModules/CustomPasteModule";

// ------------------ components ---------------------
import QuillToolbar from "./QuillToolbar";
import { Modal, Spin } from "antd";
import { ErrorBlock } from "./ErrorBlock/ErrorBlock";

// ------------------  utils, const ------------------
import { toolbarFontSizeArr } from "./toolbarOptions";
import { getSessionTokenFor } from "../../../../api/appConfig";
import { useExpandModeContext } from "../../commonComponents/expandModeLayout/ExpandModeLayout";
import "./QuillEditor.scss";
import "./customModules/blots/DividerBlot";
import {
  handleDivider,
  setupDeleteHandler,
} from "./customModules/blots/DividerBlot";
import { handleQuillImage } from "./customModules/blots/ImageBlot";
import { configUrlEntity } from "../../../../api/appConfig";
import { handleTable } from "./customModules/blots/TableBlot";


const Size = Quill.import("attributors/style/size");

Size.whitelist = toolbarFontSizeArr;
Quill.register(Size, true);
Quill.register("modules/imageResize", CustomImageResize);
Quill.register("modules/cursors", QuillCursors);
Quill.register("modules/clipboard", CustomClipboard, true);

window.Quill = Quill;

const QuillEditor = ({
  defaultPartition,
  docUUID,
  defaultActualState = "0",
  isProjectDescriptionDocument = false,
  deletedDocHtml = "",
  startHtmlString = "",
  startStateVector = "",
  isOnlyVertical = false,
  withoutConnection,
  disabled,
  quillUnmountFunction,
  reloadDocumentWithoutSaving,
}) => {
  const editorRefWrapper = useRef(null);
  const editorRef = useRef(null);
  const providerRef = useRef(null);
  const documentRef = useRef(null);
  const toolbarRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);

  const [update, setUpdate] = useState(0);
  const isUpdate = useRef(false);
  const [isSave, setIsSave] = useState(true);

  const user = useSelector(getUser);
  const config = useSelector(getConfig);

  const dispatch = useDispatch();

  const [allUsers, setAllUsers] = useState([]);

  const [focused, setFocused] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [isErrorBlock, setIsErrorBlock] = useState(false);

  const { isVerticalMode, isFullscreenMode } = !withoutConnection
    ? useExpandModeContext()
    : {};

  const handleReload = () => {
    if (reloadDocumentWithoutSaving) {
      reloadDocumentWithoutSaving();
    }
  };

  const handleCloseByError = async () => {
    setIsErrorModal(true);
    setIsConnected(false);
    editorRef.current.enable(false);
    const message =
      "Something went wrong! You have been disconnected from the server with the document. Try to reload and connect again?";
    Modal.confirm({
      title: "Warning",
      content: message,
      // icon: <ExclamationCircleOutlined />,
      cancelText: "Cancel",
      okText: "Reload",
      maskClosable: true,
      onCancel: () => {
        setIsErrorBlock(true);
        setIsErrorModal(false);
      },
      onOk: () => {
        setIsErrorModal(false);
        handleReload();
      },
    });
  };

  const getPartitionName = (config, defaultPartition) => {
    const defaultPartitionName = "project_management";
    if (!Array.isArray(config) || !defaultPartition) {
      return defaultPartitionName;
    }
    const result = config.find(
      (item) => item?.params?.name === defaultPartition
    );
    return result?.partition_name || defaultPartitionName;
  };

  const myName = useMemo(() => `${user?.uinfo?.first_name} ${user?.uinfo?.last_name}`, [user]);
  const parsedUrl = useMemo(() => new URL(configUrlEntity), [configUrlEntity]);
  const wsUrl = useMemo(() => `wss://${parsedUrl.hostname}/ws`, [parsedUrl]);

  const handleTabVisibilityChange = useCallback(() => {
    if (document.hidden) {
      providerRef.current?.shouldConnect = false;
      providerRef.current?.disconnect();
    } else {
      providerRef.current?.shouldConnect = true;
      providerRef.current?.connect();
    }
  }, []);

  const handleConnectionError = useCallback(() => {
    providerRef.current.shouldConnect = false;
    if (!isErrorModal) {
      handleCloseByError(providerRef.current);
    }
  }, []);


  useEffect(() => {
    if (isConnected && update > 0) {
      setIsSave(false);
      const timer = setTimeout(() => setIsSave(true), 1000);
      return () => clearTimeout(timer);
    }
  }, [update, isConnected]);

  useEffect(() => {
    if (!docUUID) return;

    const ydoc = new Y.Doc();
    const ytext = ydoc.getText("quill");
    documentRef.current = ydoc;

    const editor = new Quill(editorRefWrapper.current, {
      theme: "snow",
      modules: {
        table: true,
        tableUI: true,
        cursors: true,
        toolbar: withoutConnection ? false : {
          container: "#quill_toolbar",
          handlers: { divider: handleDivider, image: handleQuillImage, table: handleTable }
        },
        imageResize: { disabled: withoutConnection },
        clipboard: true,
        history: { delay: 1000, maxStack: 500, userOnly: true }
      },
    });

    editor.keyboard.addBinding({ key: "y", ctrlKey: true, handler: () => { editor.history.redo(); return false; } });
    editor.keyboard.addBinding({ key: "l", ctrlKey: true, altKey: true, handler: handleDivider });

    setupDeleteHandler(editor);

    editorRef.current = editor;
    dispatch(setCurrentQuillInstance(editor));

    if (startStateVector) {
      Y.applyUpdate(ydoc, startStateVector);
    }

    ydoc.on("update", () => {
      setUpdate(prev => prev + 1);
      isUpdate.current = true;
    });

    const partition_name = getPartitionName(config, defaultPartition);
    const provider = new WebsocketProvider(wsUrl, docUUID, ydoc, {
      params: {
        user_name: myName,
        token: getSessionTokenFor.entity(),
        partition_name: partition_name,
      },
      connect: !withoutConnection,
    });

    providerRef.current = provider;
    provider.awareness.setLocalStateField("user", { name: myName, color: "green", uuid: user?.uuid });

    // Связывание Yjs и Quill
    new QuillBinding(ytext, editor, withoutConnection ? undefined : provider.awareness);

    if (deletedDocHtml) {
      editor.clipboard.dangerouslyPasteHTML(deletedDocHtml);
      editor.setSelection(deletedDocHtml.length);
      dispatch(setDeletedDocHtml(""));
    }

    if (startHtmlString) {
      editor.clipboard.dangerouslyPasteHTML(startHtmlString);
      editor.setSelection(startHtmlString.length);
    }

    // Управление видимостью вкладки
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Handle tab visibility
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);

      if (!withoutConnection) {
        const newTextLength = isUpdate.current && isProjectDescriptionDocument
          ? editorRef?.current.getText()?.length || 0
          : null;

        const userDataArr = Array.from(providerRef.current.awareness.getStates());
        quillUnmountFunction({
          docUUID,
          defaultPartition,
          defaultActualState,
          withoutConnection,
          newTextLength,
          userDataArr,
          editorRef,
          providerRef,
          documentRef,
        });
      }
    };
  }, [docUUID, myName, startStateVector, startHtmlString, deletedDocHtml, withoutConnection]);

// Обновление состояния из startStateVector
  useEffect(() => {
    if (withoutConnection && editorRef.current && documentRef.current && startStateVector) {
      Y.applyUpdate(documentRef.current, startStateVector);
    }
  }, [startStateVector]);

// Включение/выключение редактора
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.enable(!disabled);
    }
  }, [disabled]);

// Обновление статуса подключения провайдера
  useEffect(() => {
    const provider = providerRef.current;
    if (provider) {
      provider.on("status", ({ status }) => {
        setIsConnected(status === "connected");
      });
    }
  }, []); // Единожды при инициализации провайдера

// Управление списком пользователей
  useEffect(() => {
    const provider = providerRef.current;
    if (provider && !withoutConnection) {
      const updateClients = () => {
        const userDataArr = Array.from(provider.awareness.getStates());
        const allUsers = userDataArr.map(([_, state]) => state?.user?.uuid).filter(Boolean);
        const uniqueUsers = [...new Set(allUsers)];
        setAllUsers(uniqueUsers);
      };

      provider.awareness.on("update", updateClients);
      updateClients();

      return () => provider.awareness.off("update", updateClients);
    }
  }, [providerRef?.current, withoutConnection]);



  const handleFocusIn = () => {
    setFocused(true);
  };

  const handleFocusOut = () => {
    setFocused(false);
  };

  return (
    <>
      <div className="quill-editor-wrapper">
        {!withoutConnection && (
          <QuillToolbar
            toolbarRef={toolbarRef}
            isConnected={isConnected}
            isSave={isSave}
            allUsers={allUsers}
          />
        )}
        <div
          id={docUUID}
          className={`ql-container ql-snow quill-inner-wrapper ${
            (isOnlyVertical || isVerticalMode || isFullscreenMode) && "vertical"
          }`}
          ref={editorRefWrapper}
          onFocus={handleFocusIn}
          onBlur={handleFocusOut}
          style={{
            position: "relative",
            width: "100%",
            border: "1px solid",
            borderColor: focused ? "#1677ff" : "#ccc",
            backgroundColor: "white",
            minHeight: "400px"
          }}
        />
        {!isConnected && !isErrorBlock && <div className="quill-disable-block-wrapper start-loader"><Spin /></div>}
        {isErrorBlock && <ErrorBlock callback={handleReload} />}
      </div>
    </>
  );
};

QuillEditor.propTypes = {
  defaultPartition: PropTypes.string,
  docUUID: PropTypes.string,
  isProjectDescriptionDocument: PropTypes.bool,
  startHtmlString: PropTypes.string, // diff-match-patch parsed initial state (only when you first log in to old documents)
  startStateVector: PropTypes.any, // CRDT parsed initial state
  deletedDocHtml: PropTypes.string, // the initial data for the restored document, after it has been deleted by another user
  parentWrapperRef: PropTypes.any,
  isOnlyVertical: PropTypes.bool,
  withoutConnection: PropTypes.bool,
  disabled: PropTypes.bool,
  quillUnmountFunction: PropTypes.func,
  defaultActualState: PropTypes.string,
  reloadDocumentWithoutSaving: PropTypes.func,
};

export default QuillEditor;
