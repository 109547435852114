import { bool, func, string } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import BaseModal from '../../../../components/_ui/BaseModal/BaseModal';
import { capitalize } from 'lodash';
import DragTasks from './DragTasks';

export default function DragTasksWrapper({
  disabled,
  partition,
  parent,
  updateCallback,
}) {
  const { t } = useTranslation();

  const [isShowModal, setIsShowModal] = useState(false);

  const handleCancel = () => {
    setIsShowModal(false);
  };
  const toggleBtn = () => setIsShowModal(true);

  return (
    <>
      <BaseButton
        className="btnPrimary-outline"
        onClick={toggleBtn}
        size="small"
        disabled={disabled}
      >
        {capitalize(t('wms.labels.transfer_of_issues'))}
      </BaseButton>

      <BaseModal
        width={1000}
        centered
        destroyOnClose
        open={isShowModal}
        handleCancel={handleCancel}
      >
        <DragTasks
          partition={partition}
          parent={parent}
          setIsShowModal={setIsShowModal}
          updateCallback={updateCallback}
        />
      </BaseModal>
    </>
  );
}

DragTasksWrapper.propTypes = {
  disabled: bool,
  partition: string,
  parent: string,
  updateCallback: func,
};
