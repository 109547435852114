import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import BtnModal from '../../BtnModalComponent';
import NewTestSuite from './NewTestSuite';

import { capitalize } from 'lodash';
import useActorsW54ModalStyle from '../../../../actors/hooks/useActorsW54ModalStyle';

function NewTestSuiteModalBtn({
  customBtn,
  newTestSuiteCallback,
}) {
  const { t } = useTranslation();

  const [isAssignOpen, setIsAssignOpen, styleNewSuiteModal] = useActorsW54ModalStyle();

  const finalBtnComponent = (
    <div>
      {customBtn || (
        <BaseButton
          className="btnPrimary-outline"
          size="small"
        >
          {capitalize(t('wms.buttons.new.test_suite', 'add suite'))}
        </BaseButton>
      )}
    </div>
  );

  return (
    <BtnModal
      title={capitalize(t('wms.modals.headers.new_test_suite', 'new test suite'))}
      width="40%"
      style={styleNewSuiteModal}
      handleCancelCallbackFunc={() => setIsAssignOpen(false)}
      btnComponent={finalBtnComponent}
      // confirmOnClose
      // confirmDescription={capitalize(t('wms.modals.body.close_modal_new_issue', 'the entered data will be lost, are you sure?'))}
    >
      <NewTestSuite
        isAssignOpen={isAssignOpen}
        setIsAssignOpen={setIsAssignOpen}
        newTestSuiteCallback={newTestSuiteCallback}
      />
    </BtnModal>
  );
}

NewTestSuiteModalBtn.propTypes = {
  customBtn: PropTypes.element,
  newTestSuiteCallback: PropTypes.func,
};

export default NewTestSuiteModalBtn;
