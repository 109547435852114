import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  has, head, pathOr, prop,
} from 'ramda';
import { isEmpty } from 'lodash';
import { saveAs } from 'file-saver';
import { Empty, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Icon from '@mdi/react';
import { mdiChatProcessingOutline } from '@mdi/js';

import './CommentsStartPage.scss';

import {
  createComment,
  getListOfComments,
  updateListComment,
  updateCommentsCountStore,
} from '../../../actions/projectFlowActions';
import {
  getCommentsFetching,
  getVectorInfo,
  issueEntity,
  getProjDoc,
  getProjectUUID,
  needUpdateListComments,
  getProjectParams,
} from '../../../selectors/selectors';
import { getProjectGoal } from '../../../reducers/slicers/projectGoalsSlicer';
import { getProjectMilestone } from '../../../reducers/slicers/projectMilestonesSlicer';
import { getWorkConfigData } from '../../../../userFlow/store/selectors/workConfigSelecors';
import { getProjectActorsForSelectList } from '../../../../selectors/selectors';
import {
  sendNotification,
} from '../../../../api/notificationsAPI';
import axiosRequest from '../../../../api/apiAxios';
import { getUserPublicProfileUUID } from '../../../../userFlow/store/selectors/selectors';
import { capitalize } from 'lodash';

import CommentList from '../CommentList';
import ShowMoreBtn from '../ShowMoreBtn';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import WindowCreateComment from '../WindowCreateComment';
import { maxLengthInput, preparedComments } from '../utils';

import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { COMMENT_TYPE } from '../commentType';
import { partitionNamesConfig } from '../../../../api/appConfig';

function CommentsStartPage({
  isFullscreen = false,
  inPanel = false,
  isPanelOpen = false,
  parentUUID,
  partitionType,
  commentType = COMMENT_TYPE.TOTAL,
  sizeBtn,
  type,
  readOnlyComments = false,
  onSuccessCallback,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const firstLoad = useRef(true);

  // const commentsFetching = useSelector(getCommentsFetching);
  const fetching = useSelector(getCommentsFetching);
  const allUsersForMentions = useSelector(getProjectActorsForSelectList);
  const projectUUID = useSelector(getProjectUUID);
  const selectedVector = head(useSelector(getVectorInfo));
  const selectedIssue = head(useSelector(issueEntity));
  const workConfig = head(useSelector(getWorkConfigData));
  const selectedProjDoc = head(useSelector(getProjDoc));
  const selectedGoal = head(useSelector(getProjectGoal));
  const selectedMilestone = head(useSelector(getProjectMilestone));
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const needUpdateList = useSelector(needUpdateListComments);
  // const commentsList = useSelector(getValidComments);
  const projectParams = useSelector(getProjectParams);
  const entity = selectedGoal
    || selectedMilestone
    || selectedProjDoc
    || selectedIssue
    || selectedVector
    || projectUUID
    || workConfig;

  const initialMentionsUsers = allUsersForMentions.map(
    ({ label, value }) => ({ id: value, display: label }),
  );
  const [mentionsUsers, setMentionsUsers] = useState(initialMentionsUsers);

  const initialNewCommentData = {
    text: '',
    value: '',
    entity: entity?.entity_type || entity?.affix_type || entity,
    files: [],
    audio: [],
    mentions: [],
  };

  const defaultConfig = { limit: 20, offset: 0 };
  const [newCommentData, setNewCommentData] = useState(initialNewCommentData);
  const [fetchingNewComment, setFetchingNewComment] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [config, setConfig] = useState('');
  // const [commentsWidthFullscreen, setCommentsWidthFullscreen] = useState(400);

  const initialReplyStatus = {
    actorUUID: '',
    actorName: '',
    text: '',
  };
  const [replyStatus, setReplyStatus] = useState(initialReplyStatus);

  const getAllFiles = (files) => {
    setNewCommentData((prev) => ({ ...prev, ...files }));
  };

  const onResetReply = () => {
    setReplyStatus(initialReplyStatus);
  };

  const getListComments = async () => {
    // setNewCommentData(initialNewCommentData);
    // onResetReply();

    const data = {
      entity_uuid: parentUUID,
      params: {},
      ...config,
    };

    switch (commentType) {
      case COMMENT_TYPE.USER: {
        data.params.system = false;
        break;
      }
      case COMMENT_TYPE.SYSTEM: {
        data.params.system = true;
        break;
      }
      case COMMENT_TYPE.TOTAL:
      default: {
        break;
      }
    }

    const res = await dispatch(getListOfComments(data, partitionType || partitionNamesConfig.partition1));

    // console.log('RES', res);

    const newData = preparedComments(prop('data', res));
    setCommentsList([...newData]);

    dispatch(updateCommentsCountStore(commentType, prop('total', res)));
    setTotalCount(prop('total', res));
  };

  const checkParams = () => {
    const {
      entity_type, uuid, parent, params,
    } = entity;

    const old_data = {
      uuid,
      entity_type,
      projectUUID,
    };
    const default_data = {
      ...old_data,
      project_uuid: projectUUID,
      project_id: projectParams?.id,
      project_title: projectParams?.title,
      assigned_entity_type: entity_type || 'project',
      assigned_entity_title: params?.title || projectParams?.title,
      assigned_entity_id: params?.id || projectParams?.id,
      assigned_entity_uuid: uuid || projectUUID,
    };
    switch (entity_type) {
      case 'project': return { ...default_data, projectUUID: uuid };
      case 'vector': return { ...default_data, vectorUUID: uuid };
      case 'issue': return { ...default_data, vectorUUID: params?.vector || '' };
      case 'projectDocument':
      case 'projectIdea':
      case 'projectSpreadsheet':
      case 'projectGoal':
      case 'projectMilestone': return { ...default_data, projectUUID: parent };
      default: return default_data;
    }
  };

  const sendNewNotification = (type, actors, additionalData) => {
    const entityData = {
      ...additionalData,
      projectUUID,
      partition: partitionType,
    };

    if (partitionType === partitionNamesConfig.partition3) {
      entityData.user = myPublicUUID || '';
    }

    dispatch(
      sendNotification(
        type,
        actors,
        newCommentData.text,
        { entity: entityData },
      ),
    );
  };

  const setDefaultConfigAndGetList = () => {
    setConfig({ entity_uuid: parentUUID, ...defaultConfig });

    if (+prop('limit', config) === 20 && +prop('offset', config) === 0 && config.entity_uuid === parentUUID) {
      return getListComments();
    }
  };

  const sendNewCommentSuccess = (response) => {
    setNewCommentData(initialNewCommentData);

    if (has('uuid', response[0])) {
      setDefaultConfigAndGetList();
    }

    if (newCommentData.mentions.length > 0) {
      sendNewNotification(
        'mention',
        newCommentData.mentions.map(({ id }) => id),
        checkParams(),
        // pick(['entity_type', 'uuid', 'parent'], entity),
      );
    }
  };

  const sendNewComment = () => {
    setFetchingNewComment(true);

    // const radioText = getRadioText();
    let subscribers = pathOr([], ['params', 'subscribers'], entity);

    if (replyStatus.actorUUID) {
      subscribers = subscribers.filter(
        (item) => item !== replyStatus.actorUUID,
      );
    }

    if (newCommentData.mentions.length > 0) {
      subscribers = subscribers.filter(
        (item) => !newCommentData.mentions.some(({ id }) => id === item),
      );
    }

    const params = {
      comment: `${newCommentData.text.trim()}`,
      entity: entity?.entity_type || entity?.affix_type || entity,
    };

    if (replyStatus.actorUUID) {
      params.reply = {
        ...replyStatus,
      };

      // sendNewNotification(
      //   'mention',
      //   replyStatus.actorUUID,
      //   checkParams(),
      //   // pick(['entity_type', 'uuid'], entity),
      // );

      setReplyStatus(initialReplyStatus);
    }

    if (subscribers.length > 0) {
      // sendNewNotification(
      //   'comment',
      //   subscribers,
      //   checkParams(),
      //   // pick(['entity_type', 'uuid'], entity),
      // );
    }

    const data = {
      parent: parentUUID,
      files: [...newCommentData.files, ...newCommentData.audio],
      partition: partitionType,
      params,
      onSuccess: sendNewCommentSuccess,
    };
    onSuccessCallback?.(newCommentData.text);

    console.log('data', data);

    dispatch(createComment(data));
  };

  const handleChangeNewComment = (_e, value, text, mentions) => {
    const cutToMax = (val) => val.slice(0, maxLengthInput);

    setMentionsUsers(
      allUsersForMentions.filter(
        ({ value }) => !mentions.some(({ id }) => id === value),
      )
        .map(({ value, label }) => ({ id: value, display: label })),
    );

    setNewCommentData((prev) => ({
      ...prev,
      value: cutToMax(value),
      text: cutToMax(text),
      mentions,
    }));
  };

  const afterRemoveComment = () => {
    setFetchingNewComment(true);
    setDefaultConfigAndGetList();
  };

  const onReply = (data) => setReplyStatus(data);

  const toggleShowMore = () => {
    setConfig((state) => ({ ...state, offset: +state.offset + defaultConfig.limit }));
  };

  const showBtn = (prop('limit', config) + prop('offset', config)) < totalCount;

  useEffect(() => {
    if (parentUUID) {
      setNewCommentData(initialNewCommentData);
      onResetReply();

      setDefaultConfigAndGetList();
      setTotalCount(0);
    }
  }, [parentUUID]);

  useEffect(() => {
    if (config) {
      getListComments();
    }
    return () => {
      axiosRequest.abort('', ProjectTypeCommonConstants.ISSUE_COMMENTS_REQUEST);
    };
  }, [JSON.stringify(config)]);

  useEffect(() => {
    if (needUpdateList && needUpdateList === parentUUID && config) {
      setDefaultConfigAndGetList();
      dispatch(updateListComment(''));
    }
  }, [needUpdateList]);

  useEffect(() => {
    if (!firstLoad.current && parentUUID && inPanel && isPanelOpen) {
      getListComments();
    }
    return () => {
      if (firstLoad.current) {
        firstLoad.current = false;
      }
    };
  }, [isPanelOpen]);


  return (
    <div className={cn('comments-wrapper', { _fullscreen: isFullscreen, '--side-panel': inPanel })}>
      {(isFullscreen) && (
        <div className="comments-actions mb-2">
          {isFullscreen && (
            <BaseButton
              className="btnPrimary-outline ant-btn-flex comment-button"
              size="small"
              loading={fetchingNewComment}
              onClick={() => { }}
            >
              <Icon path={mdiChatProcessingOutline} size={0.8} className="mr-1" />
              {capitalize(t('wms.buttons.load_comments', 'load comments'))}
            </BaseButton>
          )}
        </div>
      )}
      {!readOnlyComments && (
        <WindowCreateComment
          parentUUID={parentUUID}
          projectUUID={projectUUID}
          partitionType={partitionType}
          getCommentsFetching={fetching}
          maxLengthInput={maxLengthInput}
          mentionsUsers={mentionsUsers}
          newComment={newCommentData}
          replyStatus={replyStatus}
          type={type}
          sizeBtn={sizeBtn}
          getAllFiles={getAllFiles}
          handleChangeNewComment={handleChangeNewComment}
          onResetReply={onResetReply}
          sendNewComment={sendNewComment}
          isFullscreen={isFullscreen}
          inPanel={inPanel}
        />
      )}
      <div className={cn('wrapper_log', { '_fullscreen-overflow': isFullscreen, '--side-overflow': inPanel })}>
        <Spin spinning={fetching && !fetchingNewComment}>
          {!isEmpty(commentsList) ? (
            <>
              <CommentList
                entityType="comment"
                listData={commentsList}
                partitionType={partitionType}
                onReply={onReply}
                afterRemoveComment={afterRemoveComment}
                readOnlyComments={readOnlyComments}
              />
              <ShowMoreBtn
                show={showBtn}
                toggleShowMoreCallback={toggleShowMore}
              />
            </>
          )
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Spin>

      </div>
    </div>
  );
}

CommentsStartPage.propTypes = {
  commentType: PropTypes.string,
  inPanel: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  isPanelOpen: PropTypes.bool,
  parentUUID: PropTypes.string,
  partitionType: PropTypes.string,
  readOnlyComments: PropTypes.bool,
  type: PropTypes.string,
};

export default CommentsStartPage;
