import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import cn from 'classnames';
import {
  Card, Col, Flex, Input, List, message, Progress, Row, Space, Spin, Tooltip,
} from 'antd';
import { useSelector } from 'react-redux';
import Icon from '@mdi/react';
import {
  mdiClose,
  mdiSend,
  mdiStop,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';

import './style/ChatGpt.scss';
import './style/ChatGptMedia.scss';

import ChatMessages from './ChatMessages';
import BaseButton from '../components/_ui/BaseButton/BaseButton';
import NewOrEditChatForm from './NewOrEditChatForm';
import ExpandModeLayout, { useExpandModeContext } from '../projectFlow/components/commonComponents/expandModeLayout/ExpandModeLayout';
import GptRules from './GptRules';
import GptAlert from './GptAlert';

import useGptRequestsHook from './hooks/useGptRequestsHook';
import { getMe } from '../projectFlow/selectors/selectors';
import { SocketContext } from '../socket/SocketProvider';
import { appLabel, getSessionTokenFor } from '../api/appConfig';
import { checkStringIsEmpty } from '../54origins/utils54origins';
import { getPrivateUserSettings, getPrivateUserSettingsError } from '../userFlow/store/selectors/selectors';
import {
  dateFormat12, dateToFormat13,
} from '../54origins/dateFormats54origins';
import { isAdminOrRoot } from '../entity/selectors/selectors';
import { antNotification, getFirstStringOfText } from '../MainUtils';
import { GPTContext } from './GptContext';
import { getFetchingDeleteChatForUser } from './selectors/chatGptSelector';
import BaseCard from "../components/_ui/BaseCard/BaseCard";

const { TextArea } = Input;

function ChatGpt({
  showChat,
  toggleChat,
  userInfo,
  getSelectedChatCallback,
  defaultSelectedChat = null,
}) {
  const { t } = useTranslation();

  const newMessageInputRef = useRef(null);

  const { subscribeGpt, sendGptEmit } = useContext(SocketContext);
  const [messageApi, contextHolder] = message.useMessage();
  const {
    isBaseMode,
    isWideMode,
    isVerticalMode,
    isFullscreenMode,
  } = useExpandModeContext();

  const {
    requestGetListOfChatForUserOnGPT,
    requestSendActionForUserChatOnGPT,
    requestCreateNewChatForUserOnGPT,
  } = useGptRequestsHook();

  const {
    addNewMessage,
    chatMessages,
    changeChatMessages,
  } = useContext(GPTContext);

  const privateUserSettings = useSelector(getPrivateUserSettings);

  const privateUserSettingsError = useSelector(getPrivateUserSettingsError);
  const actorUUID = useSelector(getMe);
  const isAdmin = useSelector(isAdminOrRoot);

  const isFetchingDeleteChat = useSelector(getFetchingDeleteChatForUser);

  const {
    remained_requests = '',
    active_chats = [],
    requests_cost = '',
  } = userInfo || {};

  const gptTermsOfUse = privateUserSettings?.params?.gptTermsOfUse || false;

  const defaultCountOfRequest = 4;

  const [newChat, setNewChat] = useState(false);
  const [listOfChats, setListOfChats] = useState(active_chats);
  const [selectedChat, setSelectedChat] = useState(defaultSelectedChat);
  const [newMessage, setNewMessage] = useState('');
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [waitGptResponse, setWaitGptResponse] = useState(false);

  const [stopAutoScroll, setStopAutoScroll] = useState(false);
  const [countOfRequest, setCountOfRequest] = useState(defaultCountOfRequest);
  const [chatTitleIsEdit, setChatTitleIsEdit] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [maxScrollPos, setMaxPrevScrollPos] = useState(0);

  const defaultTitleForNewChat = `New chat ${listOfChats.length + 1}`;
  const [newChatTitle, setNewChatTitle] = useState(defaultTitleForNewChat);

  const focusOnMessageInput = () => {
    if (newMessageInputRef.current) {
      newMessageInputRef.current.focus();
    }
  };

  const { id: selectedChatID, user_uuid: userChatUUID } = selectedChat || {};

  const handleScroll = (e) => {
    // console.log('handleScroll handleScroll', e?.target?.scrollTop);
    if (!loadingMessages) {
      const currentScrollPos = e?.target?.scrollTop;

      if (currentScrollPos > prevScrollPos) {
        // Scrolling down
        // console.log('Scrolling down');
        if (currentScrollPos > maxScrollPos) {
          setMaxPrevScrollPos(currentScrollPos);
        }

        if (currentScrollPos === maxScrollPos) {
          setStopAutoScroll(false);
        }
      } else {
        // Scrolling up
        // console.log('Scrolling up');

        setStopAutoScroll(true);
      }

      setPrevScrollPos(currentScrollPos);
    }
  };

  const getChats = () => {
    requestGetListOfChatForUserOnGPT()
      .then((listOfChatsRes) => {
        setListOfChats(listOfChatsRes);
      })
      .catch(() => {
        setWaitGptResponse(false);
      });
  };

  const showNewChat = () => {
    setNewChatTitle(defaultTitleForNewChat);
    setNewChat(!newChat);
    setSelectedChat(null);
    changeChatMessages([]);
    setNewMessage('');
    setChatTitleIsEdit(false);
  };

  const clearContext = () => {
    requestSendActionForUserChatOnGPT(actorUUID, selectedChatID, 'clear_context').then((res) => {
      messageApi.open({
        type: 'success',
        content: 'Chat context cleared',
      });
    });
    setCountOfRequest(0);
  };

  const autoCleanContextOrContinue = (param) => {
    if (param) {
      setCountOfRequest(defaultCountOfRequest);
    } else if (countOfRequest < 0 || countOfRequest === 0) {
      clearContext();
    }
  };

  const selectChat = (chat) => {
    setSelectedChat(chat);
    setNewChat(false);
    setMaxPrevScrollPos(0);
    setStopAutoScroll(false);

    autoCleanContextOrContinue(true);
    // getMessagesForChat(chat?.id, defaultGptPagination.end);

    getSelectedChatCallback?.(chat);
  };

  const selectOrDeselectChat = (chat) => {
    const { id } = chat;

    // setGptPagination(defaultGptPagination);

    if (id === selectedChatID) {
      setSelectedChat(null);
      changeChatMessages([]);
      setNewMessage('');
    } else {
      selectChat(chat);
    }

    setWaitGptResponse(false);
    focusOnMessageInput();
  };

  const editChatCallback = () => {
    getChats();
    focusOnMessageInput();
  };

  const sendTitleOfChat = (titleOfChat) => {
    requestSendActionForUserChatOnGPT(userChatUUID, selectedChatID, 'change_title', titleOfChat)
      .then(() => {
        antNotification('success', 'Success');
        editChatCallback?.(selectedChatID);
      });
  };

  const sendNewChatCallback = (resID) => {
    getChats();
    selectChat(resID);
  };

  const sendMessage = (chatId) => {
    const data = {
      chat_id: chatId || selectedChatID,
      user_uuid: actorUUID,
      input_text: newMessage,
      session_token: getSessionTokenFor.proxyGPT(),
      as_stream: true,
    };

    sendGptEmit('chatgpt_popup_prompt', data);

    addNewMessage({
      input_text: newMessage,
      sent_at: dateFormat12(),
    });

    setNewMessage('');
    setWaitGptResponse(true);

    const newCountOfRequest = countOfRequest - 1;

    // setCountOfRequest(countOfRequest - 1);
    if (newCountOfRequest < 0 || newCountOfRequest === 0) {
      clearContext();
    } else {
      setCountOfRequest(newCountOfRequest);
    }
  };

  const sendMessageOrCreateNewChat = () => {
    if (newChat) {
      requestCreateNewChatForUserOnGPT(newChatTitle).then((res) => {
        sendNewChatCallback?.(res);

        sendMessage(res?.id);
      });
    } else {
      sendMessage();
    }
  };

  const stopReplyGpt = () => {
    sendGptEmit('chatgpt_popup_stop', {
      session_token: getSessionTokenFor.proxyGPT(),
    });

    setLoadingMessages(false);
    setWaitGptResponse(false);
  };

  const saveNewMessage = (e) => {
    const msg = e?.target?.value;
    setNewMessage(msg);

    if (newChat && !chatTitleIsEdit) {
      setNewChatTitle(getFirstStringOfText(msg, 50));
    }
  };

  const onChangeTitleCallback = (title) => {
    setChatTitleIsEdit(true);
    setNewChatTitle(title);
  };

  const removeChatCallback = () => {
    setSelectedChat(null);
    getChats();
    setWaitGptResponse(false);
  };

  const handleKeyPress = (event) => {
    if (event.ctrlKey && event.keyCode === 13 && !checkStringIsEmpty(newMessage)) {
      sendMessageOrCreateNewChat();
    }
  };

  const reselectCurrentChat = () => selectChat(selectedChat);

  const clearContextAndReloadChat = () => {
    clearContext();
    reselectCurrentChat();
  };

  const gptReplyCallback = () => {
    setWaitGptResponse(false);
  };

  const initFunc = () => {
    getChats();
  };

  useEffect(() => {
    if (actorUUID && showChat && !selectedChatID) {
      // createAndSelectNewChat();
      showNewChat();
      // console.log('useEffect useEffect NEW CHAT');
    } else {
      selectChat(selectedChat);
    }
  }, [showChat]);

  useEffect(() => {
    initFunc();
  }, []);

  // useEffect(() => {
  //   autoCleanContextOrContinue();
  // }, [countOfRequest]);

  return (
    <div
      className={cn('chat-gpt-inner', {
        wide: isWideMode,
        vertical: isVerticalMode,
        fullscreen: isFullscreenMode,
      })}
    >
      <BaseCard
        className="h-full chat-gpt-card p-2"
        styles={{
          body: {
            height: '100%',
            padding: '0.5px',
          },
        }}
      >
        <Space>
          <ExpandModeLayout.Actions
            parentType={`${appLabel} Helper`}
            isFetching={loadingMessages}
            showMetaBtn={false}
          />
          <BaseButton
            className="btnDanger-outline"
            onClick={toggleChat}
          >
            <Icon path={mdiClose} size={0.8} className="mr-1" />
            Close
          </BaseButton>
        </Space>
        <Row gutter={[8]} className="chat-gpt-content mt-2">
          {(gptTermsOfUse || !!privateUserSettingsError) ? (
            <>
              <Col
                className="h-full"
                span={!isBaseMode ? 4 : 6}
              >
                <List
                  className="chat-gpt-list"
                  header={(
                    <BaseButton
                      onClick={showNewChat}
                      type="primary"
                      danger={!!newChat}
                      className="w-full"
                    >
                      {newChat ? 'Close' : 'New chat'}
                    </BaseButton>
                  )}
                  // footer={<div>Footer</div>}
                  bordered
                  loading={isFetchingDeleteChat}
                  size="small"
                  dataSource={listOfChats}
                  renderItem={(item) => {
                    const { id, title, created_at_iso } = item || {};
                    return (
                      <List.Item
                        className={cn('wordBreak cursor-pointer p-2 chat-gpt-list-item', {
                          universal_table_row: id === selectedChatID,
                          selected: id === selectedChatID,
                        })}
                        onClick={() => selectOrDeselectChat(item)}
                      >
                        {title}
                        <span className="chat-gpt-list-item-created-at">
                          Created:
                          {` ${dateToFormat13(created_at_iso)}`}
                        </span>
                      </List.Item>
                    );
                  }}
                />
              </Col>
              <Col
                className="chat-gpt-messages-wrapper"
                span={!isBaseMode ? 20 : 18}
              >
                {newChat ? (
                  <NewOrEditChatForm
                    defaultTitle={newChatTitle}
                    isNewMode
                    onRevertCallBack={showNewChat}
                    sendNewChatCallback={sendNewChatCallback}
                    onChangeTitleCallback={onChangeTitleCallback}
                  />
                ) : (
                  <>
                    {selectedChatID && (
                      <NewOrEditChatForm
                        clearContext={clearContext}
                        editChatCallback={editChatCallback}
                        onRefreshCallBack={reselectCurrentChat}
                        removeChatCallback={removeChatCallback}
                        selectedChat={selectedChat}
                        sendTitleOfChat={sendTitleOfChat}
                      />
                    )}
                  </>
                )}
                {(selectedChatID || newChat) ? (
                  <>
                    <Row
                      className="mt-2 mb-2 chat-gpt-messages"
                      justify="center"
                      onScroll={handleScroll}
                    >
                      {contextHolder}
                      <Col
                        className="chat-gpt-messages-content"
                        span={24}
                        align={loadingMessages ? 'middle' : null}
                      >
                        <ChatMessages
                          stopAutoScroll={stopAutoScroll}
                          selectedChatID={selectedChatID}
                          setStopAutoScroll={setStopAutoScroll}
                          waitGptResponse={waitGptResponse}
                          clearContextAndReloadChat={clearContextAndReloadChat}
                          focusOnMessageInput={focusOnMessageInput}
                          gptReplyCallback={gptReplyCallback}
                          setLoadingMessages={setLoadingMessages}
                          loadingMessages={loadingMessages}
                        />
                      </Col>
                    </Row>
                    <Flex className="chat-gpt-request" gap="small">
                      <Flex gap="middle" flex="1 1 100%">
                        <Tooltip
                          title={`Если число равно нулю, значит контекст чата будет очищаться после каждого
                                     ответа ${appLabel}. Нажмите на цифру чтобы увеличить количество ответов без
                                     автоматической очистки контекста. Для принудительного обнуления контекста,
                                     воспользуйтесь кнопкой очистки контекста в правом верхнем углу`}
                        >
                          <Progress
                            className="cursor-pointer"
                            type="circle"
                            percent={countOfRequest * (100 / defaultCountOfRequest)}
                            size="small"
                            format={(percent) => `${percent / (100 / defaultCountOfRequest)}`}
                            onClick={autoCleanContextOrContinue}
                          />
                        </Tooltip>

                        <TextArea
                          autoFocus
                          size="large"
                          placeholder={`Remained requests: ${remained_requests}
${isAdmin ? `Today requests cost: ${requests_cost}$` : ''}`}
                          onChange={saveNewMessage}
                          value={newMessage}
                          onKeyDown={handleKeyPress}
                          ref={newMessageInputRef}
                          autoSize={{ minRows: 2, maxRows: 6 }}
                        />
                      </Flex>
                      <Flex>
                        <Tooltip
                          title={waitGptResponse ? 'stop answering' : ''}
                        >
                          <BaseButton
                            type="primary"
                            className="h-full w-full"
                            danger={waitGptResponse}
                            onClick={waitGptResponse ? stopReplyGpt : sendMessageOrCreateNewChat}
                            disabled={waitGptResponse ? false : checkStringIsEmpty(newMessage)}
                          >
                            <Icon
                              path={waitGptResponse ? mdiStop : mdiSend}
                              size={1}
                            />
                          </BaseButton>
                        </Tooltip>
                      </Flex>
                    </Flex>
                  </>
                ) : <GptAlert />}
              </Col>
            </>
          ) : (
            <Col span={24} className="p-5">
              <GptRules
                cancelCallBack={toggleChat}
              />
            </Col>
          )}
        </Row>
      </BaseCard>
    </div>
  );
}

export default ChatGpt;

ChatGpt.propTypes = {
  showChat: PropTypes.bool,
  toggleChat: PropTypes.func,
  userInfo: PropTypes.any,
};
