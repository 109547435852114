import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Icon from '@mdi/react';
import { mdiStar, mdiStarOutline } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Tooltip } from 'antd';
import BaseButton from '../../components/_ui/BaseButton/BaseButton';

import {
  getFavoriteProjects,
  getFavoriteUUID,
} from '../../userFlow/store/selectors/selectors';

import { capitalize } from 'lodash';

function AddProjectToFavorite({
  className,
  projectUUID,
  showTitle,
  addToFavoriteCallback,
}) {
  const { t } = useTranslation();

  const favoriteSettingsUUID = useSelector(getFavoriteUUID);
  const favoriteProjects = useSelector(getFavoriteProjects);
  // console.log('favoriteProjects:', favoriteProjects);

  const isFavorite = useMemo(() => {
    const findedFavorite = [...favoriteProjects].filter((favItem) => favItem?.uuid === projectUUID);
    return findedFavorite.length !== 0;
  }, [favoriteProjects, projectUUID, favoriteSettingsUUID]);
  // console.log('isFavorite:', isFavorite);

  const clickFavorite = async (e) => {
    addToFavoriteCallback?.(e, isFavorite);
  };

  return (
    <Tooltip
      title={capitalize(
        t(
          `wms.buttons.${isFavorite ? 'remove' : 'add'}_favorite`,
          `${isFavorite ? 'Remove from' : 'Add to'} favorite`,
        ),
      )}
      placement="top"
    >
      <BaseButton
        id='addToFavoriteButton'
        shape="circle"
        className={`view_btn_link__orange ${className}`}
        onClick={clickFavorite}
      >
        <Icon path={isFavorite ? mdiStar : mdiStarOutline} size={1} />
      </BaseButton>
    </Tooltip>
  );
}

export default AddProjectToFavorite;

AddProjectToFavorite.propTypes = {
  className: PropTypes.string,
  projectUUID: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
  addToFavoriteCallback: PropTypes.func,
};
