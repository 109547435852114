import { Col } from 'antd';
import {
  difference, get, head, isEmpty, omit, last, uniq
} from "lodash";
import { pathOr } from 'ramda';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  array, func, object, string,
} from 'prop-types';

import { sendNotification } from '../../../../api/notificationsAPI';
import { entityUpdate, incrementAndGetCount } from '../../../../entity/actions/entityActions';
import { getUserPublicProfileUUID } from '../../../../userFlow/store/selectors/selectors';
import {
  getProjectParams,
  getIssue,
  getVectorInfo,
} from '../../../selectors/selectors';
import { antNotification, getFirstStringOfText } from '../../../../MainUtils';
import {
  clearIssue,
  sendSystemComment,
  getCommentsAndUpdateStore,
  getProjectScoring, updateProjectScoring,
} from '../../../actions/projectFlowActions';
import { getTestCase, getTestCycleFunc, updateTestCycle } from '../../../actions/QaActions';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { updateIssueRequest } from '../../../actions/issues-actions';

import IssueInfoView from './IssueInfoView';
import CardSidePanel from '../../commonComponents/cardSidePanel/CardSidePanel';
import ActivitiesWrapper from '../../commonComponents/activities/activitiesWrapper/ActivitiesWrapper';

import useActivitiesDashboard from '../../commonComponents/activities/useActivitiesDashboard';

import { ACTIVITIES_TABS } from '../../commonComponents/activities/activitiesTabs';
import { COMMENT_TYPE } from '../../comment/commentType';
import { getProjectActors } from '../../../../selectors/selectors';
import { updateEntityScoring } from '../../issuesView/utils';
import { getIssuesScoringData } from '../../../reducers/issues/IssuesSlicer';
import useURLParams from '../../../../hooks/useURLParams';
import { partitionNamesConfig } from '../../../../api/appConfig';

const activitiesTabs = [
  { name: ACTIVITIES_TABS.COMMENTS },
  { name: ACTIVITIES_TABS.TIME_LOG },
  { name: ACTIVITIES_TABS.HISTORY_OF_CHANGES },
];


export default function IssueInfoCard({
  defaultData,
  disabledView,
  hiddenView,
  partitionType,
  resetIssueCallback,
  afterDeleteABookmark,
  restoreEntityCallback,
  deleteCallback,
  updateCallback,
  creatorIssue,
  customProject
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { getURLParams } = useURLParams();

  const { activeVector } = getURLParams() || {};

  const selectedIssue = useSelector(getIssue);
  const vectorData = head(useSelector(getVectorInfo));
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const projectParams = useSelector(getProjectParams);
  const projectActors = useSelector(getProjectActors);

  const issuesScoringData = useSelector(getIssuesScoringData);

  const { uuid, params } = selectedIssue;

  const defaultCommentsCount = {
    [COMMENT_TYPE.USER]: params?.[COMMENT_TYPE.USER],
    [COMMENT_TYPE.SYSTEM]: params?.[COMMENT_TYPE.SYSTEM],
    [COMMENT_TYPE.TIME_LOG]: params?.[COMMENT_TYPE.TIME_LOG],
  };

  const [form, setForm] = useState({});

  const {
    isSidePanelOpen,
    selectedActivity,
    setSelectedActivity,
    closeSidePanel,
    clickActivitiesHandler,
    clickDashboardButtonHandler,
  } = useActivitiesDashboard();

  const {
    users,
    usersSearch = [],
    changedFields = [],
    firstActivityTimestamp,
    description,
    estimated_time,
    priority,
    tracker,
    status,
    title,
    tags,
    lifeTime,
    usersUuidsForAssigned,
    selectedVectorData,
    projectData,
    needSendNotification,
    expirationDateDev,
    expirationDateQa,
    vector,
    project,
    result,
    descriptionComment = [],
    links = [],
    completed = [],
  } = form || {};

  const disabledCloneIssue = activeVector && pathOr('', ['params', 'status'], vectorData) === 'protected';

  const formTitle = String(title)?.trim();

  const makeComment = async () => {
    if (changedFields.length) {
      await Promise.all(
        changedFields.reduce((acc, item) => {
          if (
            item === 'status'
            || item === 'priority'
            || item === 'tracker'
            || item === 'lifeTime'
            || item === 'date_start'
            || item === 'estimated_time'
            || item === 'title'
            || item === 'usersSearch'
            || item === 'result'
            || item === 'links'
            || item === 'selectedVectorData'
            || item === 'description'
          ) {
            const systemType = item.toLowerCase();

            const comment = {
              entity: 'issue',
              action: 'changed',
              param: systemType,
              value: form[item],
            };

            if (item === 'selectedVectorData') {
              comment.value = form[item].title;
              comment.param = 'vector';
            }

            if (item === 'usersSearch') {
              comment.value = form[item];
              comment.param = 'users';
            }

            if (!isEmpty(descriptionComment) && descriptionComment.some((p) => p?.name === item)) {
              const data = descriptionComment.find((p) => p?.name === item);
              comment.descriptionComment = get(data, 'description', '');
            }

            return [
              ...acc,
              dispatch(sendSystemComment(uuid, comment, partitionType, systemType))
                .then(() => dispatch(getCommentsAndUpdateStore({
                  commentType: COMMENT_TYPE.SYSTEM,
                  parent: uuid,
                  partition: partitionType,
                }))),
            ];
          }
          return acc;
        }, []),
      );
    }

    if (!isEmpty(descriptionComment)) {
      setForm((prev) => ({
        ...prev,
        descriptionComment: [],
      }));
    }
    // ???
    // dispatch(updateListComment(uuid));
  };

  const sendAssignNotifications = () => {
    if (users.length && needSendNotification) {
      const currentOption = users.map((el) => el.uuid);

      const receiver = difference(currentOption, get(params, 'usersSearch', []));

      if (!isEmpty(receiver)) {
        const data = {
          projectUUID: get(projectData, 'uuid'),
          vectorUUID: get(selectedVectorData, 'uuid', ''),
          uuid,
          entity_type: 'issue',
          partition: partitionType,
          project_uuid: get(projectData, 'uuid', ''),
          project_id: get(projectParams, 'id', ''),
          project_title: get(projectParams, 'title', ''),
          assigned_entity_type: 'issue',
          assigned_entity_title: params?.title,
          assigned_entity_id: params?.id,
          assigned_entity_uuid: uuid,
        };

        if (partitionType === partitionNamesConfig.partition3) {
          data.user = myPublicUUID || '';
        }

        dispatch(
          sendNotification(
            'assigned',
            receiver,
            title,
            { entity: data },
          ),
        );
      }
    }
  };

  const updateProject = async (props) => {
    const {
      avgVal: issuesScoringAVG, sum: indexesSum, total: totalCount, projectUuid,
    } = props;
    const data = {
      uuid: customProject || projectData?.uuid || projectUuid,
      params: { scoringData: { issuesScoringAVG: Number(issuesScoringAVG), indexesSum, totalCount } },
      partition: partitionType,
    };
    // console.log('project_uuid', projectParams?.uuid || projectData?.uuid || projectUuid);
    // console.log('updateProjectScoring data', data);
    dispatch(updateProjectScoring(data));
  };

  const updateIssuesScoringData = async (oldScoringdata) => {
    // actionType, total, prevSum, currentPriority, oldPriority
    // console.log('updateIssuesScoringData oldScoringdata', oldScoringdata);
    try {
      dispatch(getProjectScoring(
        oldScoringdata?.projectUuid,
        (resp) => {
          const oldScoringData = resp?.[0]?.params?.scoringData;
          // console.log('oldScoringData', oldScoringData);
          const data = {
            sum: oldScoringData?.indexesSum || issuesScoringData?.sum,
            total: oldScoringData?.totalCount || issuesScoringData?.total,
            ...oldScoringdata,
          };
          const newScoringData = updateEntityScoring(data);
          if (newScoringData) {
            dispatch({
              type: ProjectTypeCommonConstants.UPDATE_ISSUES_PRIORITY_INDEXES_DATA,
              payload: newScoringData,
            });
            // console.log('activeProject', activeProject);
            updateProject({ ...newScoringData, projectUuid: oldScoringdata?.projectUuid });
          }
        },
        partitionType,
      ));
    } catch (e) {
      console.log('e', e);
    }
  };

  const updateRequest = (data) => dispatch(
    updateIssueRequest(data, partitionType, (res) => {
      if (!res) {
        antNotification('error', t('notifications.text.error.base', 'Error'));
      } else {
        antNotification('success', t('wms.noun.success', 'Success'));
      }

      makeComment();

      if (needSendNotification) {
        // sendAssignNotifications();
      }

      if (updateCallback) {
        updateCallback?.(get(res, '[0].uuid', ''), get(res, '[0]', '')).then(() => {
          // console.log('defaultData', defaultData);
          const oldScoringdata = {
            actionType: 'update',
            currentPriority: data?.params?.priority || defaultData?.priority,
            currentStatus: data?.params?.status || defaultData?.status,
            oldPriority: defaultData?.priority,
            oldStatus: defaultData?.status,
            projectUuid: defaultData?.project,
          };
          updateIssuesScoringData(oldScoringdata);
        });
      }
    }),
  );

  const getIdIssue = async () => {
    const countIssue = await dispatch(
      incrementAndGetCount(
        {
          entity_uuid: vector || project,
          field_name: 'issue',
        },
        partitionType,
      ),
    );

    let idVector = '';

    if (vector) {
      idVector = get(selectedVectorData, 'id');
    }
    return `${idVector}T${get(countIssue, 'increment')}`;
  };

  const getTitle = useMemo(() => getFirstStringOfText(description, 128), [description]);

  const updateIssue = async () => {
    // Сначала соберем данные из изменённых полей
    const data = changedFields.reduce((acc, item) => {
      acc[item] = form[item];
      return acc;
    }, {});

    // Если есть описание, обновляем заголовок
    if (data.description) {
      data.title = getTitle;
    }

    // Формируем параметры для запроса
    const newParams = {
      entity_type: 'issue',
      entity_uuid: uuid,
      params: omit(data, [
        'changedFields',
        'projectData',
        'selectedVectorData',
        'usersUuidsForAssigned',
        'files',
        'needSendNotification',
        'descriptionComment',
      ]),
    };

    // Обработка вектора
    if (vector !== get(params, 'vector')) {
      newParams.params.id = await getIdIssue();
      newParams.parent = vector || get(params, 'project');
    }

    // Устанавливаем временные метки активности
    const currentTimestamp = new Date().toUTCString();
    if (!firstActivityTimestamp) {
      newParams.params.firstActivityTimestamp = currentTimestamp;
    }
    newParams.params.lastActivityTimestamp = currentTimestamp;

    // Логирование и отправка запроса
    // console.log('newParamsIssue', newParams);


    setForm((prev) => ({
      ...prev,
      changedFields: []
    }))
    updateRequest(newParams);
  };

  const onChangeFilesCallback = ({ files: newFiles }) => {
    const newFormData = new FormData();

    newFormData.append('data', JSON.stringify({
      entity_type: 'issue',
      entity_uuid: uuid,
      params: {},
    }));

    if (newFiles.length !== 0) {
      // for (let i = newFiles.length; i < newFiles.length; i += 1) {
      //   newFormData.append('file', newFiles[newFiles.length]);
      // }
      newFormData.append('file', last(newFiles));
    }



    const constants = [
      ProjectTypeCommonConstants.UPDATE_FILES_FOR_ISSUE_REQUEST,
      ProjectTypeCommonConstants.UPDATE_FILES_FOR_ISSUE_SUCCESS,
      ProjectTypeCommonConstants.UPDATE_FILES_FOR_ISSUE_FAILURE,
    ];

    const options = {
      partition: partitionType,
    };

    dispatch(
      entityUpdate({
        data: newFormData,
        constants,
        options,
      }),
    );
  };

  const onChangeField = (fields) => {
    const newData = { changedFields };
    Object.entries(fields).forEach(([name, value]) => {
      newData[name] = value;
      newData.changedFields = uniq([name, ...newData.changedFields]);

      if (name === 'status'
        && value !== 'acquainted'
        && value !== 'accepted'
        && value !== 'rejected'
      ) {
        newData.changedFields = newData.changedFields.filter(
          (i) => i !== 'date_start',
        );
      }
    });
    setForm((prev) => ({ ...prev, ...newData }));
  };

  const updateCase = (uuidCase, response) => {
    const counterBug = get(response, '[0].params.counterBug', 1);
    const data = {
      entity_type: 'testCase',
      entity_uuid: uuidCase,
      params: {
        counterBug: counterBug - 1,
      },
    };

    const constants = [
      ProjectTypeCommonConstants.EDIT_TEST_CASE_REQUEST,
      ProjectTypeCommonConstants.EDIT_TEST_CASE_SUCCESS,
      ProjectTypeCommonConstants.EDIT_TEST_CASE_FAILURE,
    ];

    const options = {
      partition: partitionType,
    };

    dispatch(
      entityUpdate({
        data,
        constants,
        options,
      }),
    );
  };

  const updateCycle = (cycleUuid, response) => {
    const counterBug = get(response, '[0].params.counterBug', 1);
    const data = {
      uuid: cycleUuid,
      params: {
        counterBug: counterBug - 1,
      },
      partition: partitionType,
    };
    dispatch(updateTestCycle(data));
  };

  const deleteIssueCallback = useCallback(async () => {
    // console.log('deleteIssueCallback', params);
    const cycleUuid = get(params, 'testCycleUuid', null);
    const caseUuid = get(params, 'testCaseUuid', null);

    if (caseUuid && caseUuid.length !== 0) {
      const res = await dispatch(
        getTestCase({
          uuid: caseUuid,
          partition: partitionType,
          customConstants: ['REQUEST', 'SUCCESS', 'FAILURE'],
        }),
      ).catch((error) => console.log('error', error));
      updateCase(caseUuid, res);
    }

    if (cycleUuid && cycleUuid.length !== 0) {
      const res = await dispatch(
        getTestCycleFunc({
          uuid: cycleUuid,
          partition: partitionType,
          customConstants: ['REQUEST', 'SUCCESS', 'FAILURE'],
        }),
      ).catch((error) => console.log('error', error));
      updateCycle(cycleUuid, res);
    }
    if (deleteCallback) {
      deleteCallback(selectedIssue);
    }

    // console.log('delete entity scoring update', params?.priority);
    const data = {
      actionType: 'delete',
      currentPriority: params?.priority,
      currentStatus: params?.status,
      projectUuid: params?.project,
    };
    // console.log('deleteIssueCallback data', data);
    updateIssuesScoringData(data);

    dispatch(clearIssue());
  }, [uuid, params]);

  const getListUsersFromAssignedUsersCallback = (type) => {
    const isProject = type === 'project';

    if (isProject) {
      return setForm((prev) => ({
        ...prev,
        usersUuidsForAssigned: get(projectData, 'usersSearch', []),
      }));
    }
    setForm((prev) => ({
      ...prev,
      usersUuidsForAssigned: get(selectedVectorData, 'params.usersSearch', []),
    }));
  };

  const saveFilesToLocalState = (data) => {
    setForm((prev) => ({ ...prev, files: data }));
  };

  useEffect(() => {
    setForm(defaultData);
  }, [JSON.stringify(defaultData)]);

  useEffect(() => {
    // console.log('useEffectuseEffectuseEffect', changedFields);
    if (changedFields.length) {
      updateIssue();
    }
  }, [changedFields.length]);

  // console.log('changedFields', changedFields);

  return (
    <Col
      id="issue_info_card_component"
      style={{ minHeight: isSidePanelOpen ? '72vh' : 'unset' }}
      className="position-static p-0"
    >
      <IssueInfoView
        getTitle={getTitle}
        completed={completed}
        defaultData={defaultData}
        uuid={uuid}
        activitiesTabs={activitiesTabs}
        defaultCommentsCount={defaultCommentsCount}
        result={result}
        defaultLinks={links}
        creatorIssue={creatorIssue}
        vectorUuid={vector}
        projectUuid={project}
        selectedIssue={selectedIssue}
        lifeTime={lifeTime}
        estimated_time={estimated_time}
        descriptionComment={descriptionComment}
        usersSearch={usersSearch}
        users={users}
        title={formTitle}
        expirationDateDev={expirationDateDev}
        expirationDateQa={expirationDateQa}
        tags={tags}
        description={description}
        priority={priority}
        tracker={tracker}
        status={status}
        selectedVectorData={selectedVectorData}
        usersUuidsForAssigned={usersUuidsForAssigned}
        projectUsers={get(projectData, 'users', [])}
        disabledView={disabledView}
        hiddenView={hiddenView}
        partitionType={partitionType}
        resetIssueCallback={resetIssueCallback}
        afterDeleteABookmark={afterDeleteABookmark}
        restoreEntityCallback={restoreEntityCallback}
        createIssueCallback={updateCallback}
        deleteIssueCallback={deleteIssueCallback}
        onChangeFieldCallback={onChangeField}
        getListUsersFromAssignedUsersCallback={getListUsersFromAssignedUsersCallback}
        saveFilesToLocalState={saveFilesToLocalState}
        onChangeFilesCallback={onChangeFilesCallback}
        clickActivitiesHandler={clickActivitiesHandler}
        clickDashboardButtonHandler={clickDashboardButtonHandler}
        disabledCloneIssue={disabledCloneIssue}
        customProject={customProject}
      />

      <CardSidePanel
        isVisible={isSidePanelOpen}
        closeSidePanel={closeSidePanel}
      >
        {!hiddenView.includes('activities') && (
          <ActivitiesWrapper
            inPanel
            isPanelOpen={isSidePanelOpen}
            parentUUID={uuid}
            partitionType={partitionType}
            selectedActivity={selectedActivity}
            setSelectedActivity={setSelectedActivity}
            tabs={activitiesTabs}
            targetData={selectedIssue}
          />
        )}
      </CardSidePanel>
    </Col>
  );
}

IssueInfoCard.propTypes = {
  partitionType: string,
  hiddenView: array,
  disabledView: array,
  resetIssueCallback: func,
  afterDeleteABookmark: func,
  restoreEntityCallback: func,
  deleteCallback: func,
  updateCallback: func,
  defaultData: object,
  creatorIssue: string,
  customProject: string
};
