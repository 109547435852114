import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Flex, Input, Row, Tooltip } from "antd";
import { array, bool, func, object, string } from "prop-types";
import { capitalize } from "lodash";
import Icon from "@mdi/react";
import { mdiCreation } from "@mdi/js";

import EditButton from "../../../commonComponents/EditButton";
import UnSaveDataConfirmNew from "../../../../../components/un-save-data-confirm/UnSaveDataConfirmNew";
import ListOfProjectTagsForIssue, {
  ListOfSelectedIssueTags,
} from "../../ListOfProjectTagsForIssue";

import { defaultStateBtn } from "../../../issuesView/utils";

export default function TitleWithTags({
  disabled,
  title,
  isEditAllMode,
  defaultSelectedTags,
  onChangeFieldCallback,
  entityUUID,
  onChangeAllEditDataCallback,
  stateBtnTitle,
  setStateBtnTitle,
}) {
  const { t } = useTranslation();

  const [selectedTags, setSelectedTags] = useState([]);

  const isVisibleTags = stateBtnTitle.isEdit || isEditAllMode;

  const toggleBtn = (e, closeEdit) => {
    if (stateBtnTitle.isEdit && !closeEdit) {
      onChangeFieldCallback({ tags: selectedTags });
    }
    setStateBtnTitle((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
  };

  const onClickTag = (tags) => {
    if (isEditAllMode) {
      onChangeAllEditDataCallback?.({ tags });
    }

    setSelectedTags(tags);
    setStateBtnTitle((prev) => ({ ...prev, isValid: true }));
  };

  useEffect(() => {
    if (stateBtnTitle.isEdit && entityUUID) {
      setStateBtnTitle(defaultStateBtn);
    }
  }, [entityUUID]);

  useEffect(() => {
    if (defaultSelectedTags) {
      setSelectedTags(defaultSelectedTags);
    }
  }, [defaultSelectedTags]);

  return (
    <Row className="py-1 px-1" gutter={8}>
      <Col xxl={4} xl={6}>
        <Flex align="center" className="pb-0" vertical={stateBtnTitle.isEdit}>
          <span className="mr-2">{`${capitalize(
            t("wms.labels.title", "Title")
          )} / ${capitalize(t("wms.noun.tags", "Tags"))}:`}</span>
          <UnSaveDataConfirmNew
            dataIsNotSave={stateBtnTitle.isValid && stateBtnTitle.isEdit}
          >
            <EditButton
              id="editTitleButton"
              isHidden={isEditAllMode}
              isEditFlag={stateBtnTitle.isEdit}
              onClick={toggleBtn}
              disabled={
                (!stateBtnTitle.isValid && stateBtnTitle.isEdit) || disabled
              }
            />
          </UnSaveDataConfirmNew>
        </Flex>
      </Col>
      <Col xxl={20} xl={18} className="title-description">
        {isVisibleTags ? (
          <ListOfProjectTagsForIssue
            selectedTags={defaultSelectedTags}
            onClickCallback={onClickTag}
          />
        ) : (
          <ListOfSelectedIssueTags selectedTags={defaultSelectedTags} />
        )}
        {stateBtnTitle.isEdit || isEditAllMode ? (
          <Tooltip
            placement="bottomLeft"
            title="The issue title will be filled in automatically based on description"
          >
            <Input
              prefix={<Icon path={mdiCreation} size={1} />}
              id="title"
              className="text-base mt-1"
              placeholder="Enter title"
              maxLength={128}
              defaultValue={title}
              disabled
            />
          </Tooltip>
        ) : (
          <span className="text-base text-blue-500">{title}</span>
        )}
      </Col>
    </Row>
  );
}

TitleWithTags.propTypes = {
  entityUUID: string,
  title: string,
  defaultSelectedTags: array,
  onChangeFieldCallback: func,
  onChangeAllEditDataCallback: func,
  disabled: bool,
  stateBtnTitle: object,
  setStateBtnTitle: func,
  isEditAllMode: bool,
};
