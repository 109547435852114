import React from 'react';
import PropTypes from 'prop-types';
import './Sidebar.scss';
import SidebarMenu from "../SidebarMenu/SidebarMenu";
import BaseSidebar from "../_ui/BaseSidebar/BaseSidebar";

function Sidebar({ fixed, collapsible, collapsed, sidebarWidth, ...props }) {
  return (
    <BaseSidebar
      trigger={null}
      collapsible={collapsible}
      collapsed={collapsed}
      fixed={fixed}
      sidebarWidth={sidebarWidth}
      {...props}
    >
      <SidebarMenu
        collapsed={collapsed}
      />
    </BaseSidebar>
  );
}

export default Sidebar;

Sidebar.propTypes = {
  fixed: PropTypes.bool,
  collapsible: PropTypes.bool,
  collapsed: PropTypes.bool,
  children: PropTypes.node,
  sidebarWidth: PropTypes.number,
};
