import React from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next/';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import NewTestCase from './NewTestCase';
import { capitalize } from 'lodash';
import BtnModal from '../../BtnModalComponent';

export default function NewTestCaseModalHook({
  partition,
  getListOfTestSuites,
}) {
  const { t } = useTranslation();

  return (
    <BtnModal
      title={capitalize(t('wms.modals.headers.add_test_case', 'new test case'))}
      width="45%"
      // confirmOnClose
      // confirmDescription={
      //   capitalize(t('wms.modals.body.close_modal_new_issue', 'the entered data will be lost, are you sure?'))
      // }
      btnComponent={(
        <BaseButton
          className="px-2 ant-btn customAntOutPrimaryBtn ant-btn-default ant-btn-sm"
        >
          {capitalize(t('wms.modals.headers.add_test_case'))}
        </BaseButton>
      )}
    >
      <NewTestCase
        partition={partition}
        getListOfTestSuites={getListOfTestSuites}
      />
    </BtnModal>
  );
}
NewTestCaseModalHook.propTypes = {
  partition: string,
  getListOfTestSuites: func,
};
