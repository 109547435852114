import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import {
  string,
  func,
  bool,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { prop } from 'ramda';
import { Col, Spin, Button } from 'antd';

import CommentList from '../../../comment/CommentList';

import { getListOfComments } from '../../../../actions/projectFlowActions';
import {
  getCommentsFetching,
  getUserComments,
  getSystemComments,
  getTotalComments,
} from '../../../../selectors/selectors';
import { preparedComments } from '../../../comment/utils';
import { COMMENT_TYPE } from '../../../comment/commentType';
import { ACTIVITIES_TABS } from '../activitiesTabs';
import { partitionNamesConfig } from '../../../../../api/appConfig';

function LastComments({
  partition = partitionNamesConfig.partition1,
  parentUUID,
  allCommentsMode = false,
  readOnlyComments = true,
  classNameForTitle,
  showMoreComments,
}) {
  // console.log('parentUUID', parentUUID);
  const dispatch = useDispatch();

  const firstLoad = useRef(true);

  const fetchingComments = useSelector(getCommentsFetching);
  const commentsSelectors = {
    [COMMENT_TYPE.USER]: useSelector(getUserComments),
    [COMMENT_TYPE.SYSTEM]: useSelector(getSystemComments),
    [COMMENT_TYPE.TOTAL]: useSelector(getTotalComments),
  };

  const defaultConfig = useMemo(() => ({
    entity_uuid: parentUUID,
    limit: 3,
    offset: 0,
  }), [parentUUID]);

  const [showLastComments, setShowLastComments] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [commentTabForShowMore, setCommentTabForShowMore] = useState('');

  const resetStates = () => {
    setShowLastComments(false);
    setCommentsList([]);
    setCommentTabForShowMore(null);
  };

  const getListComments = async (commentType) => {
    const config = {
      params: {},
      ...defaultConfig,
    };

    switch (commentType) {
      case COMMENT_TYPE.USER: {
        config.params.system = false;
        break;
      }
      case COMMENT_TYPE.SYSTEM: {
        config.params.system = true;
        break;
      }
      case COMMENT_TYPE.TOTAL: {
        break;
      }
      default: return;
    }

    const comments = await dispatch(getListOfComments(config, partition));
    // console.log('RES', comments);

    const modifiedComments = preparedComments(prop('data', comments));
    // console.log('modifiedComments:', modifiedComments);

    // eslint-disable-next-line consistent-return
    return modifiedComments;
  };

  const loadAllComments = async () => {
    const allComments = await getListComments(COMMENT_TYPE.TOTAL);
    // console.log('all comments !!!:', allComments?.length);
    if (allComments?.length) {
      setCommentsList([...allComments]);
      setShowLastComments(true);
      setCommentTabForShowMore(ACTIVITIES_TABS.TOTAL_COMMENTS);
    } else setShowLastComments(false);
  };

  // eslint-disable-next-line consistent-return
  const loadUserOrSystemComments = async () => {
    const userComments = await getListComments(COMMENT_TYPE.USER);
    // console.log('user comments !!!:', userComments?.length);
    if (userComments?.length) {
      setCommentsList([...userComments]);
      setCommentTabForShowMore(ACTIVITIES_TABS.COMMENTS);
      setShowLastComments(true);
      return false;
    }

    const systemComments = await getListComments(COMMENT_TYPE.SYSTEM);
    // console.log('system comments !!!:', systemComments?.length);
    if (systemComments?.length) {
      setCommentsList([...systemComments]);
      setCommentTabForShowMore(ACTIVITIES_TABS.HISTORY_OF_CHANGES);
      setShowLastComments(true);
      return false;
    }

    resetStates();
  };


  useEffect(() => {
    if (parentUUID && !firstLoad.current && allCommentsMode) loadAllComments();
    firstLoad.current = false;
  }, [commentsSelectors[COMMENT_TYPE.TOTAL]]);

  useEffect(() => {
    if (parentUUID && !firstLoad.current && !allCommentsMode) loadUserOrSystemComments();
    firstLoad.current = false;
  }, [commentsSelectors[COMMENT_TYPE.USER], commentsSelectors[COMMENT_TYPE.SYSTEM]]);

  return (
    <Spin spinning={fetchingComments}>
      {showLastComments && (
        <Col span={24} className={`${showLastComments && 'mt-1 px-2 py-1 last-comments-container'}`}>
          <Col className="flex items-center justify-between text-secondary">
            <div className={`${classNameForTitle}`}>Last 3 comments:</div>
            <Button
              className=""
              type="link"
              size="small"
              onClick={() => showMoreComments?.(commentTabForShowMore)}
            >
              Show more
            </Button>
          </Col>
          <CommentList
            entityType="comment"
            partitionType={partition}
            listData={commentsList}
            readOnlyComments={readOnlyComments}
          />
        </Col>
      )}
    </Spin>
  );
}

LastComments.propTypes = {
  partition: string,
  parentUUID: string,
  allCommentsMode: bool,
  readOnlyComments: bool,
  classNameForTitle: string,
  showMoreComments: func,
};

export default LastComments;
