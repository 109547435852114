import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { mdiEmailCheckOutline, mdiEyeOutline, mdiFilterOff } from '@mdi/js';
import Icon from '@mdi/react';
import {
  isNil, pathOr, prop, propOr,
} from 'ramda';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, isEmpty } from 'lodash';

import './notifications.scss';

import { Divider } from 'antd';
import BaseButton from '../components/_ui/BaseButton/BaseButton';
import ReplyToNotification from './ReplyToNotification';
import TagsSettingList from '../components/filter/TagsSettingList';
import BaseTableWithPagination from '../components/_ui/BaseTableWithPagination/BaseTableWithPagination';
import SendersModal from './GroupInterface/SendersModal';

import { getListOfActorsFetching } from '../entity/selectors/selectors';
import { getSenderFilterNotification, getFetchingNotifications } from '../projectFlow/selectors/selectors';
import {
  defaultWmsSortDirections,
  timeToCalendar,
} from '../MainUtils';
import {
  checkExist,
  defaultFilters,
  generateLinkNotifications,
  getNotifyData,
} from './utilesNotifications';
import useURLParams from '../hooks/useURLParams';
import { setNotificationSenderFilter } from '../projectFlow/actions/PtcActionsForHook';
import useActorsW54 from '../actors/hooks/useActorsW54';

function ListOfNotifications({
  allNotifications,
  remotePagination,
  onMarkAsViewed,
  onMarkAsViewedAll,
  onSelectTags,
  unseenNotifications,
  changeOrderRules,
  // getPaginationOptions,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { routeNavigateAndClearParams } = useURLParams();

  const listOfActorsFetching = useSelector(getListOfActorsFetching);
  const filterBySender = useSelector(getSenderFilterNotification);
  const fetchingNotifications = useSelector(getFetchingNotifications);
  // const myPublicUUID = useSelector(getUserPublicProfileUUID);

  const [listData, setListData] = useState([]);
  const [pageLimit, changePageLimit] = useState(10);

  const userUUIDs = allNotifications?.map?.((el) => el?.sender);
  const { users: usersW54 } = useActorsW54({ actors: userUUIDs });

  const changeSort = (param, sortOrder) => {
    // console.log('sort',param, sortOrder)
    changeOrderRules({
      orderBy: param,
      order: sortOrder === 'ascend' ? '' : '_desc',
    });
  };

  const eventFormatter = (cell, row) => {
    const data = {
      color: '#1890ff',
      ...getNotifyData(cell),
    };

    if (row.viewed) {
      data.color = 'rgb(160, 160, 160)';
    }

    return (
      <div style={{ color: data.color }} className="flex">
        <Icon path={data.path} size={1} className="mr-2" />
        <p className="flex items-center m-0">
          {capitalize(t(data.title))}
        </p>
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    const event = row.event_name;
    const { uuid } = row;
    const setViewed = () => {
      if (!row.viewed) {
        onMarkAsViewed(uuid);
      }
    };

    const link = generateLinkNotifications(row);
    // console.log('notification link', link);

    return (
      <div className="flex justify-end" id={`markAsViewed_${uuid}`}>
        {(event === 'mention'
          || event === 'assigned'
          || event === 'comment'
          || event === 'assigned_goal'
          || event === 'assigned_milestone'
          || event === 'assigned_vector'
          || event === 'assigned_release'
          || event === 'assigned_test_case'
          || event === 'assigned_test_cycle'
          || event === 'assigned_test_suite') && (
            <BaseButton
              size="small"
              className="btnPrimary-outline mr-1"
              onClick={() => {
                setViewed();
                dispatch(
                  checkExist(row, () => {
                    routeNavigateAndClearParams(link?.pathname, link?.search);
                  }),
                );
              }}
            >
              <Icon path={mdiEyeOutline} size={0.7} className="mr-1" />
              {capitalize(t('notifications.buttons.view', 'view'))}
            </BaseButton>
          )}

        <ReplyToNotification
          customReplayData={row}
          disabled={
            event === 'assigned'
            || event === 'assigned_vector'
            || event === 'assigned_release'
            || event === 'assigned_goal'
            || event === 'assigned_milestone'
          }
          replyCallback={setViewed}
        />

        <BaseButton
          size="small"
          className="btnPrimary-outline"
          disabled={cell}
          onClick={() => onMarkAsViewed(uuid)}
        >
          <Icon path={mdiEmailCheckOutline} size={0.7} className="mr-1" />
          {capitalize(
            t('notifications.buttons.mark_viewed', 'mark as viewed'),
          )}
        </BaseButton>
      </div>
    );
  };

  const columns = [
    // {
    //   dataIndex: 'uuid',
    //   title: 'ID',
    //   hidden: true,
    //   render: (cell, row, index) => index + 1,
    // },
    {
      dataIndex: 'event_name',
      title: capitalize(
        t('notifications.table.headers.event', 'event'),
      ),
      sorter: (a, b, sortOrder) => changeSort('event_name', sortOrder),
      sortDirections: defaultWmsSortDirections,
      render: eventFormatter,
      width: '200px',
    },
    {
      dataIndex: 'message',
      title: capitalize(
        t('notifications.table.headers.message', 'message'),
      ),
    },
    {
      dataIndex: 'senderUinfo',
      title: capitalize(
        t('notifications.table.headers.sender', 'sender'),
      ),
      sorter: (a, b, sortOrder) => changeSort('sender', sortOrder),
      sortDirections: defaultWmsSortDirections,
      render: (cell) => (isNil(cell) ? (
        <span>
          {' '}
          {capitalize(
            t('notifications.table.body.user_was_deleted', 'user was deleted'),
          )}
        </span>
      ) : (
        <span>
          {`${propOr('', 'first_name', cell)} ${propOr(
            '',
            'last_name',
            cell,
          )}`}
        </span>
      )),
      width: '150px',
    },
    {
      dataIndex: 'created_at',
      title: capitalize(
        t('notifications.table.headers.created', 'Created'),
      ),
      sorter: (a, b, sortOrder) => changeSort('created_at', sortOrder),
      defaultSortOrder: 'descend',
      sortDirections: defaultWmsSortDirections,
      render: (cell) => timeToCalendar(cell, true),
      width: '150px',
    },
    {
      dataIndex: 'viewed',
      title: capitalize(
        t('notifications.table.headers.action', 'action'),
      ),
      render: actionFormatter,
      width: '300px',
    },
  ];

  const rowClassName = (row) => `universal_table_row ${row.viewed ? 'row_viewed_notifications' : ''}`;

  useEffect(() => {
    if (!listOfActorsFetching) {
      let result = [];
      if (!isEmpty(allNotifications)) {
        result = allNotifications.map((item) => {
          const userData = usersW54.find(
            (user) => user.uuid === item.sender,
          );

          return userData
            && pathOr('', ['uinfo', 'first_name'], userData)
            && pathOr('', ['uinfo', 'last_name'], userData)
            ? { ...item, senderUinfo: userData.uinfo }
            : item;
        });
      }
      setListData(result);
    }
  }, [JSON.stringify(allNotifications),
  JSON.stringify(usersW54),
    listOfActorsFetching,
  ]);
  // console.log('listData', listData);

  const handleResetSenderFilter = () => {
    dispatch(setNotificationSenderFilter(null));
    onSelectTags();
  };

  useEffect(() => () => {
    handleResetSenderFilter();
  }, []);

  return (
    <>
      <div className="flex items-center w-full mb-3">
        <h5 className="mb-0">
          {capitalize(t('wms.labels.notifications', 'notifications'))}
        </h5>

        <div className="flex items-center ml-2 w-full">
          <div className="flex items-center ml-2">
            <TagsSettingList
              allTags={defaultFilters}
              id="notificationsFilter"
              onSelectTags={onSelectTags}
              size="small"
            />
            <Divider type="vertical" />

            <SendersModal handleSenderCallback={onSelectTags} />
            {filterBySender?.uuid && (
              <BaseButton onClick={handleResetSenderFilter} size="small">
                <Icon path={mdiFilterOff} size={0.7} className="mr-1" />
              </BaseButton>
            )}
          </div>

          <BaseButton
            size="small"
            onClick={onMarkAsViewedAll}
            className="ml-auto btnPrimary-outline"
            disabled={unseenNotifications.length === 0}
          >
            {capitalize(
              t('notifications.buttons.mark_all_read', 'mark all as read'),
            )}
          </BaseButton>
        </div>
      </div>

      <BaseTableWithPagination
        loading={fetchingNotifications}
        pageLimit={pageLimit}
        changePageLimit={changePageLimit}
        useCustomPagination
        columns={columns}
        data={listData}
        total={prop('totalSize', remotePagination)}
        pageSizeOptions={prop('sizePerPageList', remotePagination)}
        disablePagination={
          prop('totalSize', remotePagination)
          <= prop('defaultPageLimit', remotePagination)
        }
        newCurrentPage={prop('page', remotePagination)}
        defaultPageLimit={prop('defaultPageLimit', remotePagination)}
        getPaginationOptions={prop('onChange', remotePagination)}
        rowClassName={rowClassName}
        paginationSize="small"
      // headerRowClassName="universal_header_table_row"
      />
    </>
  );
}

ListOfNotifications.propTypes = {
  allNotifications: PropTypes.array.isRequired,
  onMarkAsViewed: PropTypes.func.isRequired,
  onMarkAsViewedAll: PropTypes.func.isRequired,
  onSelectTags: PropTypes.func.isRequired,
  remotePagination: PropTypes.object.isRequired,
  // sizePerPage: PropTypes.number.isRequired,
  unseenNotifications: PropTypes.array.isRequired,
};

export default ListOfNotifications;
