import PropTypes from 'prop-types';
import React from 'react';
import { Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  mdiAccountCancelOutline,
  mdiAccountCheckOutline,
  mdiAccountEyeOutline, mdiAccountQuestion,
  mdiAccountSearchOutline,
  mdiAlertCircleOutline,
  mdiAlertOutline,
  mdiArchive,
  mdiArchiveSettingsOutline,
  mdiBellOutline,
  mdiBlockHelper,
  mdiBugOutline,
  mdiCached,
  mdiCalendarClock,
  mdiChatOutline,
  mdiCheck,
  mdiCheckBold,
  mdiCheckboxMarkedCircleOutline,
  mdiChevronDown,
  mdiClockCheckOutline,
  mdiClockTimeThreeOutline,
  mdiCloseThick,
  mdiCog,
  mdiEyeOutline,
  mdiFaceAgent,
  mdiHelp,
  mdiInformationOffOutline,
  mdiLayersOutline,
  mdiLayersRemove,
  mdiLayersSearchOutline,
  mdiMagnify,
  mdiNotebookOutline,
  mdiPause,
  mdiPencil,
  mdiProgressClock,
  mdiProgressCheck,
  mdiShieldSunOutline,
  mdiWrenchOutline,
} from '@mdi/js';
import { take } from 'ramda';
import Icon from '@mdi/react';

import './EntityParamTag.scss';
import { capitalize } from 'lodash';
import BaseTag from "../_ui/BaseTag/BaseTag";

function EntityParamTag({
  param = '',
  type = 'title',
  value = '',
  maxLength,
  tooltip,
  isOpen = false,
  canHover = true,
  fontSizeClass = 'fontSize15',
}) {
  const { t } = useTranslation();

  let title = '';

  if (value && typeof (value) !== 'object') {
    let newValue;

    switch (value) {
      case 'projectDocument': newValue = 'document'; break;
      case 'qaDocument': newValue = 'qadocument'; break;
      case 'projectIdea': newValue = 'note'; break;
      case 'projectSpreadsheet': newValue = 'spreadsheet'; break;
      case 'projectGoal': newValue = 'goal'; break;
      case 'projectMilestone': newValue = 'milestone'; break;
      case 'deployed': newValue = 'deployed_closed'; break;
      case 'very_complicated': newValue = 'very complicated'; break;
      case 'systemic': newValue = 'system'; break;
      default: newValue = value;
    }
    newValue = newValue.toString().toLowerCase();

    title = capitalize(t(`wms.${param.toLowerCase()}.${newValue}`, newValue));
  }
  const colorType = {
    root: 'purple',
    admin: 'volcano',
    user: 'blue',
    group: 'gold',
    classic_user: 'blue',
    classic: 'blue',
  };

  const getClassName = () => {
    // console.log('value', value);
    switch (value.toLowerCase()) {
      case 'bug':
        return 'bug-tracker';

      case 'feature':
        return 'feature-tracker';

      case 'ticket':
        return 'ticket-tracker';

      case 'qadocument':
        return 'lightOrange';
      case 'document':
      case 'projectdocument':
        // case 'comment':
        case 'creator':
        case 'archive':
        return 'orange';

      case 'production':
      case 'project':
      case 'current':
      case 'failed':
      case 'workblock':
        return 'red';

      case 'general':
      case 'testcase':
      case 'comment':
      case 'no-planning':
      case 'expired-milestone':
      case 'dev-score':
      case 'outdated-score':
      case 'low-tests-level':
      case 'low-tests-efficiency':
      case 'low-archive-level':
      case 'today':
      case 'this week':
      case 'this month':
      case 'last month':
        return 'blue';

      case 'easy':
        return 'created';

      case 'issue':
      case 'none':
        // case 'sprint':
        return 'lightBlue';

      case 'testcycle':
      case 'project flow':
        return 'skyBlue';

      case 'testSuite':
        return 'lightBlue';

      case 'user flow':
      case 'project description':
      case 'project infrastructure':
        return 'lightOrange';
      case 'projectmilestone':
      case 'member':
        return 'darkRed';

      case 'vector':
      case 'version':
      case 'owner':
        return 'violet';

      case 'projectgoal':
        return 'darkblue';

      case 'next_plus':
      case 'spreadsheet':
      case 'projectspreadsheet':
      case 'personal':
        return 'green';

      case 'next':
      case 'goal':
        return 'lightYellow';

      case 'next_2plus':
      case 'idea':
      case 'projectidea':
      case 'timelog':
      case 'note':
      case 'launch':
        return 'yellow';

      case 'previous':
        return 'lightGrey';

      case 'old':
        return 'none';

      case 'in progress':
      case 'planning':
      case 'support_only':
        return 'progress';

      case 'ready for test':
      case 'bugfix':
        return 'test';
      case 'deploy':
        return 'green';

      case 'completed':
      case 'developing':
        return 'active';

      case 'archived':
        return 'archive';

      case 'protected':
      case 'partially completed':
        return 'protected';

      case 'important':
        return 'important';

      case 'outdated':
        return 'outdated';

      case 'backlog':
        return 'backlog';

      case 'draft':
        return 'draft';

      case 'system':
        return 'system';

      case 'functional':
      case 'non-functional':
      case 'interface':
      case 'usability':
      case 'smoke & sanity':
      case 'security':
      case 'regression':
      case 'acceptance':
      case 'integration':
      case 'compability':
      case 'perfomance':
      case 'unit':
      case 'system':
      case 'unexecuted':
      case 'fail':
      case 'pass':
      case 'block':
        return 'none'

      default:
        return value.toLowerCase();
    }
  };

  const getIconPath = () => {
    switch (value.toLowerCase()) {
      case 'protected':
        return mdiShieldSunOutline;
      case 'created':
        return mdiBellOutline;
      case 'acquainted':
        return mdiAccountEyeOutline;
      case 'accepted':
        return mdiAccountCheckOutline;
      case 'rejected':
        return mdiAccountCancelOutline;
      case 'reopened':
        return mdiCached;
      case 'in progress':
        return mdiProgressClock;
      case 'developing':
        return mdiLayersOutline;
      case 'resolved':
        return mdiCheckBold;
      case 'active':
        return mdiCheckboxMarkedCircleOutline;
      case 'planning':
        return mdiNotebookOutline;
      case 'ready for test':
      case 'test':
        return mdiMagnify;
      case 'testing':
      case 'bugfix':
        return mdiLayersSearchOutline;
      case 'self testing':
        return mdiAccountSearchOutline;
      case 'review':
        return mdiEyeOutline;
      case 'paused':
        return mdiPause;
      case 'production':
        return mdiAlertCircleOutline;
      case 'discussion':
        return mdiChatOutline;
      case 'backlog':
        return mdiClockTimeThreeOutline;
      case 'blocked':
        return mdiBlockHelper;
      case 'close':
      case 'closed':
      case 'failed':
        return mdiCloseThick;
      case 'not_supported':
      case 'support_only':
        return mdiFaceAgent;
      case 'bugs':
        return mdiBugOutline;
      case 'fixing':
        return mdiWrenchOutline;
      case 'test failed':
      // return mdiLayersRemove;
      case 'not enough info':
        return mdiInformationOffOutline;
      case 'not reproducible':
        return mdiAlertOutline;
      case 'ready for deploy':
        return mdiClockCheckOutline;
      case 'deployed':
      case 'completed':
        return mdiCheckBold;
      case 'partially completed':
        return mdiProgressCheck;
      case 'abandoned':
        return mdiArchiveSettingsOutline;
      case 'discuss':
        return mdiAccountQuestion;
      case 'archived':
        return mdiArchive;
      case 'important':
        return mdiAlertCircleOutline;
      case 'outdated':
        return mdiCalendarClock;
      case 'system':
      case 'systemic':
        return mdiCog;
      case 'draft':
        return mdiPencil;
      default:
        return mdiHelp;
    }
  };

  if (!value) {
    return null;
  }

  switch (type) {
    case 'badge':
      return (
        <BaseTag className={`entityTagBadge ${getClassName()}`}>
          {title}
        </BaseTag>
      );
    case 'char':
      return (
        <Tooltip
          placement="top"
          title={tooltip || title}
        >
          <span className={`entityTag ${getClassName()} icon default_style_icon_table text-xs`}>
            {title[0].toUpperCase()}
          </span>
        </Tooltip>
      );
    case 'charNoCut':
      return (
        <Tooltip
          placement="top"
          title={tooltip || title}
        >
          <span className={`entityTag ${getClassName()} icon default_style_icon_table_no_cut`}>
            {capitalize(title)}
          </span>
        </Tooltip>
      );
    case 'icon':
      return (
        <Tooltip
          placement="top"
          title={tooltip || title}
        >
          <span className={`entityTag ${getClassName()} icon default_style_icon_table`}>
            <Icon path={getIconPath()} size={0.7} />
          </span>
        </Tooltip>

      );
    case 'iconWithStatus':
      return (
        <BaseTag className={`entityTag ${getClassName()} with-border`}>
          <Icon path={getIconPath()} size={0.6} className="mr-1" />
          <span>{title}</span>
        </BaseTag>
      );
    case 'valueWithBorder':
      return (
        <BaseTag className={`entityTag ${getClassName()} with-border`}>
          <span>{title}</span>
        </BaseTag>
      );
    case 'iconWithArrow':
      return (
        <Tooltip
          placement="top"
          title={tooltip || title}
        >
          <BaseTag className={`entityTag ${getClassName()} with-border p-1`}>
            <Icon path={getIconPath()} size={0.8} className="mr-1" />
            <Icon path={mdiChevronDown} size={0.7} />
          </BaseTag>
        </Tooltip>
      );
    case 'valueWithArrow':
      return (
        <BaseTag className={`entityTag ${getClassName()} with-border ${fontSizeClass} cursor-pointer py-1`}>
          <span className="1">{title}</span>
          <Icon path={mdiChevronDown} size={0.7} className="ml-1" />
        </BaseTag>
      );
    case 'hoverValueWithArrow':
      return (
        <BaseTag className={`entityTag ${getClassName()} ${canHover ? '_hovered' : ''} with-border ${fontSizeClass} py-1`}>
          <span className="ml-1">{title}</span>
          <Icon path={mdiChevronDown} size={0.7} className={`ml-1 chevron ${isOpen ? '_open' : ''}`} />
        </BaseTag>
      );
    case 'valueAndIconWithArrow':
      return (
        <BaseTag className={`entityTag ${getClassName()} with-border ${fontSizeClass} cursor-pointer py-1`}>
          <Icon path={getIconPath()} size={0.8} className="mr-1" />
          <span>{title}</span>
          <Icon path={mdiChevronDown} size={0.7} className="ml-1" />
        </BaseTag>
      );
    case 'hoverValueAndIconWithArrow':
      return (
        <BaseTag className={`entityTag ${getClassName()} ${canHover ? '_hovered' : ''} with-border ${fontSizeClass} py-1`}>
          <Icon path={getIconPath()} size={0.8} className="mr-1" />
          <span>{title}</span>
          <Icon path={mdiChevronDown} size={0.7} className={`ml-1 chevron ${isOpen ? '_open' : ''}`} />
        </BaseTag>
      );
    case 'iconAndHideWrapper':
      return (

        // <span className={`entityTag_without_background ${getClassName()}`}>
        <Icon path={getIconPath()} size={0.7} className="mr-1" />
        // </span>

      );
    case 'title':
      return (
        <Tooltip
          title={tooltip || value}
          trigger={tooltip || (maxLength && title.length > maxLength)}
        >
          <span className="text-neutral-600">
            {maxLength && title.length > maxLength ? `${take(maxLength, title)}...` : title}
          </span>
        </Tooltip>
      );
    case 'user':
      return (<BaseTag className="ml-1" color={colorType[value]}>{value}</BaseTag>);
    case 'tags':
      return(
        <Tooltip
          title={tooltip || value}
          placement="right"
          trigger={tooltip || (maxLength && title.length > maxLength) ? 'hover' : ''}
        >
          {value?.map?.(item => {
              return(
                <Tag
                  key={item}
                  color={param === 'suite' ? 'orange' : param === 'cycle' ? 'blue' : 'yellow'}
                  className="ml-2"
                  style={{cursor: "pointer"}}
                >
                  {capitalize(item)}
              </Tag>
              )
            })}
        </Tooltip>
      )

    case 'value':
    default:
      return (
        <Tooltip
          title={tooltip || value}
          placement="right"
          trigger={tooltip || (maxLength && title.length > maxLength) ? 'hover' : ''}
        >
          <span className="text-neutral-600 wordBreak">
            {maxLength && value.length > maxLength ? `${take(maxLength, value)}...` : value}
          </span>
        </Tooltip>
      );
  }
}

export default EntityParamTag;

EntityParamTag.propTypes = {
  maxLength: PropTypes.number,
  param: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  canHover: PropTypes.bool,
  fontSizeClass: PropTypes.string,
};
