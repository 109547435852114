import PropTypes from 'prop-types';
import React from 'react';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import BaseButton from '../../../components/_ui/BaseButton/BaseButton';

import { getColorForActor } from '../../../54origins/components/CircleActorAvatar54origins';
import {Tooltip} from "antd";

function CommentReply({
  actorName,
  actorUUID,
  isComment,
  onResetReply,
  text,
}) {
  return (
    <div
      className={`comment-reply-wrapper ${isComment ? 'isComment' : ''}`}
    >
      <div className="comment-reply-body">
        <Tooltip
          title={actorName}
          placement="topLeft"
        >
          {actorName?.length <= 24 ? (
            <h6
              style={{ fontSize: '14px', color: getColorForActor(actorUUID) }}
            >
              {actorName}
            </h6>
          ) : (
            <h6
              style={{ fontSize: '14px', color: getColorForActor(actorUUID) }}
            >
              {actorName.slice(0, 24)}...
            </h6>
          )}
        </Tooltip>
        {/*<h6*/}
        {/*  className="comment-reply-name"*/}
        {/*  style={{ fontSize: '14px', color: getColorForActor(actorUUID) }}*/}
        {/*>*/}
        {/*  {actorName}*/}
        {/*</h6>*/}
        <p className="comment-reply-text wordBreak">
          {text}
        </p>
      </div>
      {onResetReply && !isComment && (
      <BaseButton
        className="comment-reply-clear btnSecondary-link"
        onClick={onResetReply}
      >
        <Icon className="comment-reply-icon" path={mdiClose} size={0.7} />
      </BaseButton>
      )}
    </div>
  );
}

export default CommentReply;

CommentReply.propTypes = {
  actorName: PropTypes.string,
  actorUUID: PropTypes.string,
  isComment: PropTypes.bool,
  onResetReply: PropTypes.func,
  text: PropTypes.string,
};
