import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiLoginVariant, mdiCommentTextMultipleOutline } from '@mdi/js';
import {
  Col, Row, Space, Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import {
  capitalize, get, head, isEqual, isNil, reverse,
} from 'lodash';
import moment from 'moment';

import '../css/ProjectInfo.scss';

import ActivitiesWrapper from '../../commonComponents/activities/activitiesWrapper/ActivitiesWrapper';
import AddToBookmark from '../../AddToBookmark';
import BaseButton from '../../../../components/_ui/BaseButton/BaseButton';
import AreasList from '../../areas/areasList/AreasList';
import CardSidePanel from '../../commonComponents/cardSidePanel/CardSidePanel';
import CheckMainAreaContainer from '../../../containers/areas/CheckMainAreaContainer';
import CommonInfo from '../../commonComponents/CommonInfo';
import CopyURL from '../../../../components/CopyURL';
import DescriptionDocument from './components/DescriptionDocument';
import DropDownParamsEntity from '../../../../entity/components/dropDownParamsEntity/DropDownParamsEntity';
import EditButton from '../../commonComponents/EditButton';
import GoToButton from '../../../../components/buttons/GoToButton';
import HardDeleteEntityContainer from '../../../../entity/containers/HardDeleteEntityContainer';
import HeaderCard from '../../commonComponents/HeaderCard';
import ProjectInfoGoals from './components/ProjectInfoGoals';
import ProjectTagsSettings from '../ProjectTagsSettings';
import RelatedProjects from './components/RelatedProjects';
import SpecialTextArea from '../../commonComponents/SpecialTextArea/SpecialTextArea';
import TagsSettingList from '../../../../components/filter/TagsSettingList';
import UnSaveDataConfirmNew from '../../../../components/un-save-data-confirm/UnSaveDataConfirmNew';
import WrapperActivitiesDashboard from '../../commonComponents/activities/activitiesDashboard/WrapperActivitiesDashboard';
import WrapperAddToFavorite from '../WrapperAddToFavorite';
import WrapperAvatarsGroupForProjectWithHook from '../../commonComponents/actorAvatarsGroup/WrapperAvatarsGroupForProjectWithHook';
import InfrastructureDocument from './components/InfrastructureDocument';
import ProjectScoring from './components/projectScoring/ProjectScoring';

import { clearVectorsScoringData, clearIssuesScoringData } from '../../../actions/PtcActionsForHook';
import { clearCurrentProjectScoreData } from '../../../reducers/slicers/projectAllScoringSlicer';
import {
  getAreasForSelect,
  getMe,
  getProject,
  getProjectParams,
  getProjectUUID,
} from '../../../selectors/selectors';
import {
  antNotification,
  capitalizeAndTranslateMsg,
} from '../../../../MainUtils';
import {
  getProjectRequest,
  sendSystemComment,
  updateProjectOwnerOrCreator,
  updateProjectRequest,
} from '../../../actions/projectFlowActions';
import {
  deleteEntityPerm,
  createEntityPermissions,
  getEntityPermission,
} from '../../../../entity/actions/entityPermActions';
import { getUserPublicProfileUUID } from '../../../../userFlow/store/selectors/selectors';
import { ProjectTypeCommonConstants } from '../../../constants/Constants';
import { permsNormalUser } from './components/assignedUsersProject/utils';
import { defaultStateBtn } from '../../issuesView/utils';
import useCheckPerm from '../../../../hooks/useCheckPerm';
import { actorIsRootOrAdmin, getNameForActor } from '../../../../54origins/utils54origins';
import useActivitiesDashboard from '../../commonComponents/activities/useActivitiesDashboard';
import { ACTIVITIES_TABS } from '../../commonComponents/activities/activitiesTabs';
import { COMMENT_TYPE } from '../../comment/commentType';
import { setFlagNotSaveData } from '../../../../components/un-save-data-confirm/unSaveDataSlicer';
import DividerForInfoCard from '../../../../components/DividerForInfoCard';
import AssigningUsersRoot from '../../../../assignUsersModal/AssigningUsersRoot';
import { isAdminOrRoot } from '../../../../entity/selectors/selectors';
import useGetUserPerm from '../../../../permissions/hooks/useGetUserPerm';
import { checkAdminPerms, permKeys } from '../../../../actors/actorsUtils';
import { partitionNamesConfig } from '../../../../api/appConfig';

const activitiesTabs = [
  { name: ACTIVITIES_TABS.COMMENTS },
  { name: ACTIVITIES_TABS.TIME_LOG },
  { name: ACTIVITIES_TABS.HISTORY_OF_CHANGES },
];

const transformAreasForTags = (data) => data.map((item) => ({
  label: get(item, 'label') || item,
  value: get(item, 'label') || item,
}));

function ProjectInfo({
  afterDeleteABookmark,
  partitionType = partitionNamesConfig.partition1,
  deleteEntityCallback,
  getProjectMembers,
  updateProjectCallback,
  view,
  pathGoToTask,
  resetProjectCallback,
  isInModal = false,
}) {
  // console.log('project partition:', partitionType);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const refTitle = useRef({});
  const refAreas = useRef([]);
  const refExpirationDate = useRef(null);

  const myUUID = useSelector(getMe);
  const projectUUID = useSelector(getProjectUUID);
  const project = head(useSelector(getProject));
  const projectParams = useSelector(getProjectParams);
  const areasForSelect = useSelector(getAreasForSelect);
  const myPublicUUID = useSelector(getUserPublicProfileUUID);
  const adminOrRoot = useSelector(isAdminOrRoot);

  const { perm: myPerms } = useGetUserPerm({
    entityUUID: projectUUID,
    partitionType,
  });

  const [stateBtnTitle, setStateBtnTitle] = useState(defaultStateBtn);
  const [stateBtnAreas, setStateBtnAreas] = useState(defaultStateBtn);
  const [stateBtnLifeTime, setStateBtnLifeTime] = useState(defaultStateBtn);

  const [isEditMembers, setEditMembers] = useState(false);
  const [isEditAdmins, setEditAdmins] = useState(false);

  const [admins, setAdmins] = useState([]);

  const {
    delete: isAllowDelete = false,
    isAllAllow,
  } = useCheckPerm({
    entityUUID: projectUUID,
    partitionType,
  });

  const {
    isSidePanelOpen,
    selectedActivity,
    setSelectedActivity,
    closeSidePanel,
    clickActivitiesHandler,
    clickDashboardButtonHandler,
  } = useActivitiesDashboard();

  const {
    areas = [],
    title,
    users = [],
    usersSearch = [],
    status,
    lifeTime = null,
    relatedProjects,
    type: projectType,
    firstActivityTimestamp,
    lastActivityTimestamp,
  } = projectParams || {};

  const defaultCommentsCount = {
    [COMMENT_TYPE.USER]: projectParams?.[COMMENT_TYPE.USER],
    [COMMENT_TYPE.SYSTEM]: projectParams?.[COMMENT_TYPE.SYSTEM],
    [COMMENT_TYPE.TIME_LOG]: projectParams?.[COMMENT_TYPE.TIME_LOG],
  };

  const {
    actor: projectCreatorUUID,
    owner: projectOwnerUUID,
    parent,
    created,
  } = project || {};

  const isAdminOrActor = isAllAllow || projectCreatorUUID === myUUID;
  const isGlobalDisabled = projectType === 'ACTIVITIES_USER';
  const isSystemic = status === 'systemic';
  const actualLifeTime = lifeTime || moment(created).format('"YYYY-MM-DD"');

  const userInProject = users.find((el) => el.uuid === myUUID);

  const areasTags = transformAreasForTags([...areasForSelect]);
  const tags = [{ lettersLimit: 25, tags: areasTags }];
  const defaultSelectedTags = areasTags.filter((area) => {
    const value = get(area, 'label') || get(area, 'value') || area;
    return areas.includes(value);
  });

  const addPermissionsToProject = async (usersWithPerms = []) => {
    const data = {
      entity_uuid: projectUUID,
      data: [
        ...usersWithPerms.map(({ uuid, perms }) => ({
          actor: uuid,
          ...perms,
        })),
      ],
    };

    await dispatch(
      createEntityPermissions({
        data,
        partition: partitionType,
      }),
    );
  };

  // const addPermissionsToProject = (normalUsers, admins) => {
  //   const data = {
  //     entity_uuid: projectUUID,
  //     data: [
  //       ...normalUsers.map((uuid) => ({
  //         actor: uuid,
  //         ...permsNormalUser,
  //       })),
  //       ...admins.map((uuid) => ({
  //         actor: uuid,
  //         ...permsAdmin,
  //       })),
  //     ],
  //   };

  //   dispatch(
  //     createEntityPermissions({
  //       data,
  //       partition: partitionType,
  //     }),
  //   );
  // };

  const deletePermissionsFromProject = (usersForDelete) => {
    const data = {
      actors: usersForDelete,
      entity_uuid: projectUUID,
    };

    dispatch(
      deleteEntityPerm({
        data,
        partition: partitionType,
      }),
    );
  };

  const updateOwner = async (owner) => {
    const params = {
      entity_uuid: projectUUID,
      partition: partitionType,
    };

    if (owner && owner !== projectOwnerUUID) {
      params.owner = owner;

      const res = await dispatch(updateProjectOwnerOrCreator(params));
      antNotification('success', t('wms.noun.success', 'Success'));
      dispatch({ type: ProjectTypeCommonConstants.EDIT_PROJECT_SUCCESS, payload: [res] });

      updateProjectCallback?.();
    }
  };
  const makeComment = async (params) => {
    const loggedParams = [
      'status',
      'lifeTime',
    ];

    const changedParams = Object.keys(params).filter((item) => loggedParams.includes(item));

    if (changedParams.length === 0) {
      return;
    }

    const param = reverse(changedParams);
    const value = param.map((item) => params[item]);

    const comment = {
      entity: 'project',
      action: 'changed',
      param,
      value,
    };

    if (projectUUID) {
      await dispatch(sendSystemComment(projectUUID, comment, partitionType));
    }
  };

  const updateProject = async (params) => {
    const data = {
      uuid: projectUUID,
      params: { ...params },
      partition: partitionType,
    };

    dispatch(updateProjectRequest(data))
      .then(() => {
        if (params.status !== 'archive') {
          const members = get(data, 'usersSearch');
          if (members && getProjectMembers) {
            getProjectMembers(members);
          }

          updateProjectCallback?.(data);
        }
        makeComment(params);

        antNotification('success', t('wms.noun.success', 'Success'));

        dispatch(getProjectRequest(projectUUID, null, partitionType));
        dispatch(setFlagNotSaveData(false));
      })
      .catch(() => antNotification('error', 'Access denied!'));
  };

  const updateProjectWithCustomsParams = (params) => {
    const data = {
      uuid: projectUUID,
      params: { ...params },
      partition: partitionType,
    };

    return dispatch(updateProjectRequest(data)).catch(() => antNotification('error', 'Access denied!'));
  };

  const setIsEdit = (e, closeEdit) => {
    const { id } = e.currentTarget;
    switch (id) {
      case 'title':
        if (stateBtnTitle.isEdit && !closeEdit) {
          updateProject({ title: refTitle.current?.trim() });
        }
        refTitle.current = title;
        setStateBtnTitle((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      case 'lifeTime':
        if (stateBtnLifeTime.isEdit && !closeEdit) {
          updateProject({ lifeTime: refExpirationDate.current });
        }
        refExpirationDate.current = actualLifeTime;
        setStateBtnLifeTime((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      case 'areas':
        if (stateBtnAreas.isEdit && !closeEdit) {
          updateProject({ areas: refAreas.current });
        }
        refAreas.current = areas;
        setStateBtnAreas((prev) => ({ ...defaultStateBtn, isEdit: !prev.isEdit }));
        break;
      default:
        break;
    }
  };

  const onChangeTitle = ({ value }) => {
    refTitle.current = value;
    if (!stateBtnTitle?.isValid || !value?.trim()?.length) {
      setStateBtnTitle((prev) => ({ ...prev, isValid: value.trim()?.length }));
    }
  };

  const onSelectTagsArea = (a) => {
    refAreas.current = [...a.map(({ label }) => label)];

    if (!stateBtnAreas?.isValid) {
      setStateBtnAreas((prev) => ({ ...prev, isValid: true }));
    }
  };

  const onChangeLifeTime = (data) => {
    const { lifeTime: time } = data;

    refExpirationDate.current = time;
    if (!stateBtnLifeTime?.isValid) {
      setStateBtnLifeTime((prev) => ({ ...prev, isValid: true }));
    }
  };

  const onChangeUsers = async (usersData) => {
    const {
      allUuids, allUsers,
      newPerms,
      deleteUsers,
    } = usersData;

    const defaultUuids = projectParams?.usersSearch ?? [];
    const defaultRoles = projectParams?.users ?? [];

    const hasChanges = !isEqual(allUuids, defaultUuids) || allUsers?.some(el => defaultRoles?.find(i => i?.uuid === el?.uuid)?.role !== el?.role)

    const params = { users: allUsers, usersSearch: allUuids };

    if (hasChanges) {
      await updateProject(params);
    }

    if (newPerms.length > 0) {
      await addPermissionsToProject(newPerms);
      if (!hasChanges) {
        getAdmins(allUuids);
      }
    }

    if (deleteUsers.length > 0) {
      deletePermissionsFromProject(deleteUsers);
    }
  };

  const joinOrLeaveProject = () => {
    const params = {};
    if (!userInProject) {
      params.users = [{ uuid: myUUID, role: '' }, ...users];
      params.usersSearch = [myUUID, ...usersSearch];
      addPermissionsToProject([{ uuid: myUUID, perms: permsNormalUser }]);
    } else {
      params.users = users.filter((item) => item.uuid !== myUUID);
      params.usersSearch = usersSearch.filter((item) => item !== myUUID);
      deletePermissionsFromProject([myUUID]);
    }

    updateProject(params);
  };

  const subTitle = (
    <div className="flex w-full items-center gap5">
      <CommonInfo
        creator={getNameForActor(project)}
        createdDate={created}
        lastActivity={lastActivityTimestamp}
        firstActivity={firstActivityTimestamp}
      />
      <CopyURL partition={partitionType} entityType="project" entityUUID={projectUUID} />
      <WrapperAddToFavorite
        entityUUID={projectUUID}
        entityType="project"
        entityPartition={partitionType}
        partitionType={partitionType}
      />
      <AddToBookmark
        afterDeleteABookmark={afterDeleteABookmark}
        entityUUID={projectUUID}
        partition={partitionType}
      />
      <GoToButton
        hiddenElements={view === 'projectFlow' || view === 'bookmark' ? [] : ['button']}
        pathToRedirect={
          pathGoToTask || {
            pathname:
              partitionType === partitionNamesConfig.partition1
                ? '/pm/projects/issuesnew'
                : `/public/user/${myPublicUUID}/projects/issuesnew`,
            search: `?activeProject=${projectUUID}`,
          }
        }
      />
    </div>
  );

  const headerButtons = (
    <Space className="flex-wrap justify-end" align="center" size={[5, 5]}>
      {/* {!hideActivityBtn && ( */}
      <BaseButton
        id='activitiesHandlerButton'
        className="btnPrimary-outline"
        size="small"
        onClick={clickActivitiesHandler}
        disabled={false}
      >
        <Icon path={mdiCommentTextMultipleOutline} size={0.7} className="mr-1" />
        {capitalize(t('', 'activities'))}
      </BaseButton>
      {/* )} */}

      <HardDeleteEntityContainer
        actionForEntity={deleteEntityCallback}
        checkHardDelete
        className="customAntOutDangerBtn customAntOutBtn"
        entityUUID={projectUUID}
        entityType="project"
        type="entity"
        labelMode
        name="remove"
        disabled={isGlobalDisabled}
        typeInLabel="project"
        doubleConfirm
        partitionType={partitionType}
        sizeBtn="small"
      // isAllowDelete={isAllowDelete}
      />
    </Space>
  );

  const getAdmins = async (uuids = []) => {
    if (!uuids.length || (!myPerms.set && !adminOrRoot)) return;

    const perms = await new Promise((resolve) => dispatch(
      getEntityPermission({
        partition: partitionType,
        params: {
          entity_uuid: projectUUID,
          actors: uuids,
        },
      }, (res) => { resolve(res.data); }),
    )).catch((e) => console.log(e));

    setAdmins(perms.filter((el) => permKeys.every((key) => el[key]) || actorIsRootOrAdmin(el))
      .map((el) => el.actor_uuid));
  };

  useEffect(() => {
    if (projectUUID) {
      if (stateBtnTitle.isEdit) {
        setStateBtnTitle(defaultStateBtn);
      }
      if (stateBtnAreas.isEdit) {
        setStateBtnAreas(defaultStateBtn);
      }
      if (stateBtnLifeTime.isEdit) {
        setStateBtnLifeTime(defaultStateBtn);
      }
    }
  }, [projectUUID]);

  const handleClearData = () => {
    dispatch(clearIssuesScoringData);
    dispatch(clearVectorsScoringData);
    dispatch(clearCurrentProjectScoreData());
    // console.log('unmount_modal');
  };

  useEffect(() => () => {
    if (isInModal) {
      handleClearData();
    }
  }, []);

  useEffect(() => {
    getAdmins(users?.map((el) => el.uuid));
  }, [JSON.stringify(users), myPerms.set, adminOrRoot]);

  return (
    <Col style={{ minHeight: isSidePanelOpen ? '72vh' : 'unset' }} className={`${isInModal ? 'p-1' : 'p-4'} overflow-hidden`}>
      <HeaderCard
        title={capitalizeAndTranslateMsg('wms.noun.project.0', 'Project')}
        spanTitleSection={15}
        spanDescriptionSection={9}
        subTitle={subTitle}
        extra={headerButtons}
        backCallback={resetProjectCallback}
      />

      <DividerForInfoCard />

      <div className="flex items-center px-1">
        <div className="wrapper-title pb-0 flex items-center">
          <UnSaveDataConfirmNew
            id="projectTitle"
            dataIsNotSave={stateBtnTitle.isEdit && !!stateBtnTitle.isValid}
          >
            <EditButton
              id="title"
              isEditFlag={stateBtnTitle.isEdit}
              disabled={!stateBtnTitle.isValid && stateBtnTitle.isEdit}
              onClick={setIsEdit}
            />
          </UnSaveDataConfirmNew>
          {`${capitalize(t('wms.labels.title', 'Title'))}:`}
        </div>
        <div className="grow wrapper-description title-description pt-0 pl-0 ml-2">
          <SpecialTextArea
            id="title"
            placeholder="Enter title"
            maxLength={128}
            defaultValue={title}
            isEdit={stateBtnTitle.isEdit}
            onChange={onChangeTitle}
          />
        </div>
      </div>

      <DividerForInfoCard />

      <div className="flex items-center px-1">
        <div className="wrapper-title pb-0">
          {`${capitalize(t('wms.status.title', 'status'))}:`}
        </div>
        <div className="grow wrapper-description pt-0 ml-2">
          <DropDownParamsEntity
            type="project_status"
            disabled={isSystemic}
            currentSelect={isSystemic ? 'system' : status}
            dropDownCallback={(data) => {
              updateProject({
                status: data.project_status,
                archive: data.project_status === 'archive',
              });
            }}
          />
        </div>
      </div>
      <DividerForInfoCard />
      {/* <div className="flex items-center w-full px-1">
        <div className="wrapper-title pb-0 flex items-center">
          <EditButton
            onClick={() => setEditOwner(true)}
          />

          <AssigningUsersRoot
            header="Assigning owner"
            isOpen={isEditOwner}
            setIsOpen={setEditOwner}
            onUpdateUsers={(data) => {
              updateOwner(data?.users?.[0]?.uuid);
            }}
            isModal
            globalOptions={{
              uuids: [projectOwnerUUID],
              roles: projectParams?.users ?? [],
              isOwner: true,
              defaultColumn: 'all',
            }}
            tabs={{
              allConfig: {
                label: 'All',
                value: 'all',
                textSelectButton: 'Assign owner',
                showSections: ['button-add-user'],
              },
            }}
            usersConfig={{
              label: 'Owner',
              showSections: ['string-role'],
              defaultItems: [projectOwnerUUID] ?? [],
            }}
            partition={partitionType}
          />
          <Row
            onClick={() => setEditOwner(true)}
            style={{ cursor: 'pointer' }}
          >
            {`${capitalize(t('wms.labels.project_owner', 'Project owner'))}:`}
          </Row>
        </div>
        <div className="grow wrapper-description flex items-center ml-2">
          <WrapperAvatarsGroupForProjectWithHook
            actors={[projectOwnerUUID]}
            avatarSize={28}
            popoverTrigger="click"
            popoverPlacement="right"
            checkProjectRole
          />
        </div>
      </div>
      <DividerForInfoCard /> */}
      <div className="flex items-center w-full px-1">
        <div className="wrapper-title pb-0 flex items-center">
          <EditButton
            id='setEditMembersButton'
            disabled={!myPerms.set && !adminOrRoot}
            onClick={() => setEditMembers(true)}
          />
          <AssigningUsersRoot
            header="Assigning members"
            isOpen={isEditMembers}
            setIsOpen={setEditMembers}
            onUpdateUsers={onChangeUsers}
            globalOptions={{
              uuids: projectParams?.usersSearch ?? [],
              roles: projectParams?.users ?? [],
              needLoadPerms: true,
              defaultColumn: 'all',
            }}
            placement="left"
            isModal
            tabs={{
              allConfig: {
                label: 'All',
                value: 'all',
                textSelectButton: 'Add members',
                showSections: ['button-add-user'],
              },
            }}
            usersConfig={{
              label: 'Project users',
              showSections: ['perms', 'admin', 'input-role', 'button-delete-user', 'string-role'],
              defaultItems: projectParams?.usersSearch ?? [],
            }}
            projectUuid={projectUUID}
            partition={partitionType}
          />

          <Row
            onClick={() => {
              if (myPerms.set || adminOrRoot) setEditMembers(true);
            }}
            style={{ cursor: 'pointer' }}
          >
            Members:
          </Row>
        </div>
        <div className="grow wrapper-description flex items-center ml-2">
          <WrapperAvatarsGroupForProjectWithHook
            actors={users}
            avatarSize={28}
            popoverTrigger="click"
            popoverPlacement="right"
          // checkProjectRole
          />
          {isAdminOrActor && (
            <Tooltip
              title={capitalize(
                userInProject
                  ? t('wms.buttons.you_are_in_the_project', 'you are in the project')
                  : t(
                    'wms.buttons.you_are_not_a_member_of_the_project',
                    'you are not a member of the project',
                  ),
              )}
            >
              <BaseButton
                id='joinOrLeaveProjectMembersButton'
                className="ml-3 btn-leave-or-join"
                size="small"
                type="link"
                onClick={joinOrLeaveProject}
                disabled={isGlobalDisabled}
              >
                <Icon path={mdiLoginVariant} size={0.7} className="mr-1" />
                {capitalize(
                  userInProject ? t('wms.buttons.leave', 'leave') : t('wms.buttons.join', 'join'),
                )}
              </BaseButton>
            </Tooltip>
          )}
        </div>
      </div>
      <DividerForInfoCard />
      <div className="flex items-center w-full px-1">
        <div className="wrapper-title pb-0 flex items-center">
          <EditButton
            id="setEditAdminsButton"
            disabled={!myPerms.set && !adminOrRoot}
            onClick={() => setEditAdmins(true)}
          />
          <AssigningUsersRoot
            header="Assigning admins"
            isOpen={isEditAdmins}
            setIsOpen={setEditAdmins}
            onUpdateUsers={onChangeUsers}
            globalOptions={{
              uuids: projectParams?.usersSearch ?? [],
              roles: projectParams?.users ?? [],
              needLoadPerms: true,
              defaultColumn: 'all',
              isAdminMode: true,
            }}
            placement="left"
            isModal
            tabs={{
              allConfig: {
                label: 'All',
                value: 'all',
                textSelectButton: 'Add admin',
                showSections: ['button-add-user'],
              },
            }}
            usersConfig={{
              label: 'Project users',
              showSections: ['perms', 'admin', 'input-role', 'button-delete-user', 'string-role'],
              defaultItems: projectParams?.usersSearch ?? [],
            }}
            projectUuid={projectUUID}
            partition={partitionType}
          />

          <Row
            onClick={() => {
              if (myPerms.set || adminOrRoot) setEditAdmins(true);
            }}
            style={{ cursor: 'pointer' }}
          >
            Admins:
          </Row>
        </div>
        <div className="grow wrapper-description flex items-center ml-2">
          <WrapperAvatarsGroupForProjectWithHook
            actors={admins}
            avatarSize={28}
            popoverTrigger="click"
            popoverPlacement="right"
          // checkProjectRole
          />
          {isAdminOrActor && (
            <Tooltip
              title={capitalize(
                userInProject
                  ? t('wms.buttons.you_are_in_the_project', 'you are in the project')
                  : t(
                    'wms.buttons.you_are_not_a_member_of_the_project',
                    'you are not a member of the project',
                  ),
              )}
            >
              <BaseButton
                id='joinOrLeaveProjectAdminsButton'
                className="ml-3 btn-leave-or-join"
                size="small"
                type="link"
                onClick={joinOrLeaveProject}
                disabled={isGlobalDisabled}
              >
                <Icon path={mdiLoginVariant} size={0.7} className="mr-1" />
                {capitalize(
                  userInProject ? t('wms.buttons.leave', 'leave') : t('wms.buttons.join', 'join'),
                )}
              </BaseButton>
            </Tooltip>
          )}
        </div>
      </div>

      <DividerForInfoCard />

      <div style={{ rowGap: '8px' }} className="flex flex-wrap items-center px-1">
        <div className="grow wrapper-description pt-0 pr-4">
          <DescriptionDocument
            partitionType={partitionType}
            isGlobalDisabled={isGlobalDisabled}
            userUUID={myPublicUUID}
          />
        </div>
        <div className="grow wrapper-description pt-0">
          <InfrastructureDocument
            partitionType={partitionType}
            isGlobalDisabled={isGlobalDisabled}
            userUUID={myPublicUUID}
          />
        </div>

      </div>
      {isInModal && (
        <>
          <DividerForInfoCard />
          <div style={{ marginLeft: '12px' }}>
            <ProjectScoring
              parent={project?.uuid}
              partitionType={partitionType}
              isInModal
            />
          </div>
        </>
      )}

      <DividerForInfoCard />
      <ProjectInfoGoals partitionType={partitionType} disabled={isGlobalDisabled} />

      <DividerForInfoCard />

      <Row gutter={10} className="pt-1 pb-2">
        <Col span={12} className="flex items-stretch">
          <RelatedProjects
            isEdit={false}
            customParent={parent}
            currentProject={projectUUID}
            disabled={isGlobalDisabled}
            partitionType={partitionType}
            relatedProjects={relatedProjects}
            updateProjectWithCustomsParams={updateProjectWithCustomsParams}
          />
        </Col>

        <Col span={12} className="flex items-stretch">
          <div className="flex  flex-col items-start w-full">
            <div className="wrapper-title pb-0 flex items-center">
              <UnSaveDataConfirmNew notSavedData={stateBtnAreas.isValid && stateBtnAreas.isEdit}>
                <EditButton
                  id="areas"
                  isEditFlag={stateBtnAreas.isEdit}
                  disabled={!stateBtnAreas.isValid && stateBtnAreas.isEdit}
                  onClick={setIsEdit}
                />
              </UnSaveDataConfirmNew>
              {`${capitalize(t('wms.table.headers.tags', 'tags'))}:`}
            </div>
            <div className="grow wrapper-description pl-0 w-full">
              {stateBtnAreas.isEdit ? (
                <TagsSettingList
                  onSelectTags={onSelectTagsArea}
                  allTags={tags}
                  defaultSelectedTags={defaultSelectedTags}
                  size="small"
                />
              ) : (
                <CheckMainAreaContainer>
                  <AreasList areas={areas} onlyProjectAreas />
                </CheckMainAreaContainer>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <DividerForInfoCard />
      <ProjectTagsSettings partitionType={partitionType} />

      <DividerForInfoCard />
      <WrapperActivitiesDashboard
        defaultCommentsCount={defaultCommentsCount}
        entityType="project"
        parentUUID={projectUUID}
        partition={partitionType}
        tabs={activitiesTabs}
        clickDashboardButton={clickDashboardButtonHandler}
      />
      <CardSidePanel
        isVisible={isSidePanelOpen}
        closeSidePanel={closeSidePanel}
      >
        <ActivitiesWrapper
          inPanel
          isPanelOpen={isSidePanelOpen}
          parentUUID={projectUUID}
          partitionType={partitionType}
          selectedActivity={selectedActivity}
          setSelectedActivity={setSelectedActivity}
          tabs={activitiesTabs}
          targetData={project}
        />
      </CardSidePanel>
    </Col>
  );
}

ProjectInfo.propTypes = {
  deleteEntityCallback: PropTypes.func,
  getProjectMembers: PropTypes.func,
  updateProjectCallback: PropTypes.func,
  view: PropTypes.string,
  partitionType: PropTypes.string,
  afterDeleteABookmark: PropTypes.func,
  pathGoToTask: PropTypes.object,
  resetProjectCallback: PropTypes.func,
  isInModal: PropTypes.bool,
};

export default ProjectInfo;
